
import { Component } from 'vue-property-decorator'
import moment from 'moment'

import { ModuleManager } from '@/modules/moduleManager'
import { getStatisticsForMonth, getStatisticsPerDay, getStatisticsPerTime } from '@/helpers/statisticsHelper'
import SessionManager from '@/database/sessionManager'


import AsidManager from '@/database/asidManager'

import VTimeSeriesGraph from '@/components/VTimeSeriesGraph.vue'
import { AsidDB } from '@/types/typeAsid'
import { timeSeriesPreparationHelper, typedOrderBy } from '@/database/dbHelper'


import { BaseResponseDB, BaseModuleDB, ElementWithTypeAndID, ModuleStatistics } from '@/modules/typeModules'
import { hasDBid } from '@/types/typeGeneral'
import { Statistics } from '@/types/typeBase'
import VueApexCharts from 'vue-apexcharts'
import { AppSessionDB } from '@/types/typeAppSession'
import VResponseTimelineView from '@/components/VResponsesTimelineView.vue'
import { FormElementDB, FormResponseDB } from '@/modules/form/typeFormModule'
import FormModule from '@/modules/form/formModule'
import StorageManager from '@/helpers/StorageManager'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import { PlanDB } from '@/types/typePlan'
import { cloneObject } from '@/helpers/dataShapeUtil'
import databaseSchema from '@/database/databaseSchema'
import db from '@/firebase'

@Component({
  components: {
    VTimeSeriesGraph,
    VueApexCharts,
    VResponseTimelineView
  }
})
export default class BackendDashboard extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public isLoading = false
  public moduleDBs: BaseModuleDB[] = []


  public async created() {
    try {
      this.isLoading = true
      await this.initActivatedModules()
      await this.initPlanData()
      await this.initAsidGraphs()
      await this.initLoadResponses()
    } catch (error: unknown) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading = false
    }
  }

  get filteredModuleDBs() {
    return this.moduleDBs
      .filter(mod => !['Html', 'I18n', 'Data', 'Ci'].includes(mod.public.type))
  }


  //#region latest Form Responses
  public responsesForm: Array<hasDBid & FormResponseDB> = []
  public formElements: (FormElementDB)[] = []

  public get formElementsTypeAndID(): ElementWithTypeAndID[] {
    return this.formElements.map(fe => ({
      type: 'Form',
      id: (fe as any).id,
      ...fe
    }))
  }

  public limit = 5

  public async loadMoreResponses() {
    this.limit += 10
    await this.loadResponses()
  }

  private async loadResponses() {
    await this.$firestoreBind('responsesForm',
      typedOrderBy<FormResponseDB>(
        FormModule
          .getResponsesDbReference(this.$auth.tenant.id)
          .limit(this.limit)
        , { _meta: { dateCreated: '' as any } }, 'desc'),
      { wait: true })
  }

  private async initLoadResponses() {
    await this.loadResponses()

    await this.$firestoreBind('formElements', FormModule.getElementsQuery(this.$auth.tenant.id), { wait: true })
  }
  //#endregion latest Form Responses

  // ASID GRAPH
  public latestAssignedAsids: Array<AsidDB & hasDBid> = []
  public latestActivatedAsids: Array<AsidDB & hasDBid> = []
  public latestInteractions: Array<BaseResponseDB & hasDBid> = []


  get timeSeriesAsids() {

    const groupedDataActivated = timeSeriesPreparationHelper(
      this.latestActivatedAsids.filter(a => a.dateActivated),
      (obj) => obj.dateActivated.toDate()
    )

    const groupedDataAssigned = timeSeriesPreparationHelper(
      this.latestAssignedAsids.filter(a => a.dateAssigned),
      (obj) => obj.dateAssigned.toDate()
    )


    return [
      {
        name: 'Activated Echo Codes',
        // data: [Date.now(),Date.now(),Date.now(),Date.now()]
        data: [...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 30].map((t) => ({ x: moment(groupedDataActivated[0]?.x, 'MM/DD/YYYY').add(t, 'days'), y: 0 })), ...groupedDataActivated]
      }, {
        name: 'Assigned Echo Codes',
        // data: [Date.now(),Date.now(),Date.now(),Date.now()]
        data: groupedDataAssigned
      }
    ]
  }


  get timeSeriesInteractions() {

    const groupedDataInteractions = timeSeriesPreparationHelper(
      this.latestInteractions.filter(a => a._meta.dateCreated !== undefined),
      (obj) => obj._meta.dateCreated.toDate()
    )

    return [
      {
        name: 'Interactions',
        // data: [Date.now(),Date.now(),Date.now(),Date.now()]
        data: [...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 30].map((t) => ({ x: moment(groupedDataInteractions[0]?.x, 'MM/DD/YYYY').add(t, 'days'), y: 0 })), ...groupedDataInteractions]
      }
    ]
  }


  get interactionsPerModule(): { name: string, statistics: Statistics<ModuleStatistics> }[] {
    return this.filteredModuleDBs.map((mdb) => ({
      name: mdb.public.type,
      statistics: mdb._computed.statistics
    }))
  }


  get timeSeriesInteractionsPerModulePerDay() {

    return this.interactionsPerModule
      .map(ipm => {
        return {
          name: ipm.name,
          data: getStatisticsPerDay(ipm.statistics).map(d => ({ x: d.date, y: d.events.rc }))
        }
      })
  }

  public get timeSeriesInteractionsChartOptions() {
    return {
      colors: this.timeSeriesInteractionsPerModuleColors,
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      },
      chart: {
        type: 'area', // bar has super bad performance
        // type: 'bar',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          show: true,
          autoSelected: 'zoom',
          download: true,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false
        }
      },
      dataLabels: {
        enabled: false
      },
      // markers: {
      //   size: 0
      // },
      // title: {
      //   // text: 'Assigned Codes',
      //   align: 'left'
      // },
      plotOptions: {
        bar: {
          columnWidth: '50%' // 3 % for 30 day period?
          // endingShape: 'rounded'  
        }
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            // console.log(val)

            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)

          }
        }
        // title: {
        //   // text: 'Price'
        // }
      },
      xaxis: {
        type: 'datetime',
        min: moment(new Date()).subtract(1, 'month').toDate().getTime(),
        max: moment(new Date()).toDate().getTime()
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val: number) {
            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
      }
    }
  }

  private appSessionStatistics: AppSessionDB | null = null

  get totalAppSessions() {
    const totalEvents = this.appSessionStatistics?._computed?.statistics?.date?.e
    return (totalEvents?.c || 0) + (totalEvents?.u || 0)
  }

  get lastMonthAppSessions() {
    const currentYear = new Date().getUTCFullYear()
    const currentMonth = new Date().getUTCMonth() + 1

    const year = (currentMonth === 1) ? currentYear - 1 : currentYear
    const lastMonth = (currentMonth === 1) ? 12 : currentMonth - 1

    const statistics = this.appSessionStatistics?._computed?.statistics
    if (!statistics) return 0
    const events = getStatisticsForMonth(statistics, year, lastMonth)
    return events.c || 0 + events.u || 0
  }

  get currentMonthAppSessions() {
    const currentYear = new Date().getUTCFullYear()
    const currentMonth = new Date().getUTCMonth() + 1

    const statistics = this.appSessionStatistics?._computed?.statistics
    if (!statistics) return 0
    const events = getStatisticsForMonth(statistics, currentYear, currentMonth)
    return events.c || 0 + events.u || 0
  }

  get timeSeriesAppSessionsAndInteractions() {
    return [{
      name: 'Sessions',
      data: this.appSessionStatistics?._computed?.statistics
        && getStatisticsPerDay(this.appSessionStatistics._computed.statistics)
          .map(d => ({ x: d.date, y: d.events.c || 0 + d.events.u || 0 }))
    },
    {
      name: 'Interactions',
      data: this.planDB._computed.statistics
        && getStatisticsPerDay(this.planDB._computed.statistics)
          .map(d => ({ x: d.date, y: d.events.ti || 0 }))
    }
    ]
  }

  public get timeSeriesAppSessionsChartOptions() {
    return {
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      },
      colors: ['#9368B7', '#AA3E98', '#9297C4', '#84C7D0', '#75DDDD'],
      chart: {
        type: 'area',
        // type: 'bar',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          show: true,
          autoSelected: 'zoom',
          download: true,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false
        }
      },
      dataLabels: {
        enabled: false
      },
      // markers: {
      //   size: 0
      // },
      // title: {
      //   // text: 'Assigned Codes',
      //   align: 'left'
      // },
      // fill: {
      //   type: 'gradient',
      //   gradient: {
      //     shadeIntensity: 1,
      //     inverseColors: false,
      //     opacityFrom: 0.5,
      //     opacityTo: 0,
      //     stops: [0, 90, 100]
      //   }
      // },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            // console.log(val)

            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)

          }
        }
        // title: {
        //   // text: 'Price'
        // }
      },
      xaxis: {
        type: 'datetime',
        min: moment(new Date()).subtract(1, 'month').toDate().getTime(),
        max: moment(new Date()).toDate().getTime()
        // max: moment(new Date()).add(1,'week').toDate().getTime()
        // min: moment(new Date()).startOf('month').toDate().getTime(),
        // max: moment(new Date()).endOf('month').toDate().getTime()
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val: number) {
            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
      }
    }
  }


  get timeSeriesInteractionsPerModulePerTime() {

    return this.interactionsPerModule.map(ipm => {
      const statisticsData = getStatisticsPerTime(ipm.statistics)
      // hydrate statistiscs with all possible time, since apexcharts needs all entries when providing data
      const data = Array.from(Array(24).keys())
        .flatMap(i => [`${String(i).padStart(2, '0')}:00`, `${String(i).padStart(2, '0')}:30`])
        .map((time) => {
          const entryForTime = statisticsData.find(sd => sd.time === time)
          return { time, events: { rc: entryForTime?.events.rc || 0 } }
        })
      return {
        name: ipm.name,
        data: data.map(d => ({ x: d.time, y: d.events.rc || 0 }))
      }
    })
  }


  public async initAsidGraphs() {
    try {
      await this.$firestoreBind('appSessionStatistics',
        SessionManager.getStatisticsDbDocReference(this.$auth.tenant.id)
      )

      // await this.$firestoreBind('latestAssignedAsids',
      //   typedWhere<AsidDB>(
      //     typedWhere<AsidDB>(
      //       typedOrderBy<AsidDB>(AsidManager.getDbCollectionReference(), { dateAssigned: null as Timestamp }, 'desc')
      //       , { dateAssigned: null as Timestamp }, '!=', null)
      //     , { tenantID: null }, '==', this.$auth.tenant.id).limit(1000)
      // )
      // await this.$firestoreBind('latestActivatedAsids',
      //   typedWhere<AsidDB>(
      //     typedWhere<AsidDB>(
      //       typedOrderBy<AsidDB>(AsidManager.getDbCollectionReference(), { dateActivated: null as Timestamp }, 'desc')
      //       , { dateActivated: null as Timestamp }, '!=', null)
      //     , { tenantID: null }, '==', this.$auth.tenant.id).limit(1000)
      // )

      // await this.$firestoreBind('latestInteractions',
      //   typedWhere<AsidDB>(
      //     typedOrderBy<AsidDB>(db.collectionGroup(databaseSchema.COLLECTIONS.TENANTS.MODULES.RESPONSES.__NAME__), { _meta: { dateCreated: undefined } }, 'desc')
      //     , { tenantID: null }, '==', this.$auth.tenant.id).limit(1000)
      // )

    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
    }
  }
  //// ASID GRAPH

  // ACTIVE_MODULES
  public timeSeriesInteractionsPerModuleColors: string[] = []

  public async initActivatedModules() {
    this.$unbindHandle(ModuleManager.onSnapshotActivatedModules(this.$auth.tenant.id, this.$auth.userPrivileges, (modules) => {
      console.log('ouou')
      this.moduleDBs = modules
      this.timeSeriesInteractionsPerModuleColors = this.filteredModuleDBs.map(m => ModuleManager.getModuleClassByType(m.public.type).color)
      this.isLoading = false
    }))
  }
  //// ACTIVE_MODULES


  // PLAN_DATA
  public planActivatedAsids = 0
  public planAvailableAsidSlots = 0
  public planTotalUsedInteractions = 0

  public planAvailableInteractions = 0

  public planStorageQuotaTotal = ''
  public rawStorageTotal = ''
  public planStorageQuotaApp = ''
  public planStorageQuotaBackend = ''

  private planDB: PlanDB = cloneObject(databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__EMPTY_DOC__)

  private async initPlanData() {
    return this.$bindSnapshot<PlanDB>('planDB',
      db.doc(databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__DOCUMENT_PATH__(this.$auth.tenant.id)),
      (data) => {
        this.planActivatedAsids = data._computed.activatedAsids
        this.planAvailableAsidSlots = data.availableAsidSlots
        this.planTotalUsedInteractions = data._computed.totalUsedInteractions
        this.planStorageQuotaTotal = StorageManager.returnFileSize(data._computed.totalUsedStorageQuota)
        this.rawStorageTotal = String(data._computed.totalUsedStorageQuota) + ' bytes'
        this.planStorageQuotaApp = StorageManager.returnFileSize(data._computed.totalUsedAppStorageQuota)
        this.planStorageQuotaBackend = StorageManager.returnFileSize(data._computed.totalUsedBackendStorageQuota)
        console.log(data)

        this.planAvailableInteractions = AsidManager.getAvailableInteractionsCount(data)

        return data
      }
    )
  }
  //// PLAN_DATA


  // ASID_STATS

  //// ASID_STATS

}
