
import { Component, Watch } from 'vue-property-decorator'

import { RawLocation } from 'vue-router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import Avatar from 'vue-avatar'
import vAuth from '@/components/mixins/VAuth.vue'

library.add(faAngleDoubleLeft)

import { ModuleManager } from '@/modules/moduleManager'

import { mixins } from 'vue-class-component'
import { intersectSome } from '@/helpers/arrayHelper'
import { ModuleType } from '@/modules/typeModules'
import VCustomVueFireBindMixin from './mixins/VCustomVueFireBindMixin.vue'


@Component({
  components: { Avatar }
})
export default class TheSideMenu extends mixins(vAuth, VCustomVueFireBindMixin) {
  public moduleNavigationItems: { type: ModuleType, color: string, navItems: Array<{ to: RawLocation, displayName: string }> }[] = []

  public async created() {
    // await AuthHelper.onUser((U) => {
    //   this.username = U.name
    // })
  }


  @Watch('$auth.user', { immediate: true })
  public async onUserChanged() {

    this.$unbindHandle(ModuleManager
      .onSnapshotActivatedModuleClasses(this.$auth.tenant.id, this.$auth.userPrivileges, Ms => {
        this.moduleNavigationItems = []

        Ms
          .filter(M => intersectSome(M.authPrivileges.view, this.$auth.userPrivileges))
          .forEach(M => {
            let moduleNavigationItems = M.getNavigationItems()
            // filter nav items which require non matching privileges
            moduleNavigationItems = moduleNavigationItems.filter(NI => !NI.requiredPrivileges || this.$auth.userHasAllPrivilege(NI.requiredPrivileges))

            this.moduleNavigationItems.push({ type: M.type, color: M.color, navItems: moduleNavigationItems })
          }
          )
      }, (e) => {
        this.$helpers.notification.Error(e)
      }, 'sideMenu'))

  }


  private mounted() {
    // fitty('.fit')
  }
}
