
import { Component, Vue } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import VEchoCode from '@/components/VEchoCode.vue'

import 'prismjs'
import 'prismjs/themes/prism.css'
// import PrismEditor from 'vue-prism-editor'
import { CodeConfig } from '@/types/typeBackendConfig'
import { defaultCodeConfig } from '@/database/databaseSchema'
import { cloneObject } from '@/helpers/dataShapeUtil'

library.add(faUndo)


@Component({
  components: { VEchoCode }
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class AdminSVGCodeEditor extends Vue {

  public svgTemplate = ''
  public isLoading = false
  public variables = {
    outerCornerRadius: 23,
    echoCodeBGCornerRadius: 11,
    codeWidth: 224,
    codeHeight: 224,
    printPadding: 25,
    codeTopPadding: 84,
    codeSidePadding: 20,
    codeBottomPadding: 50,
    QRCodePadding: 12
  }
  public echoCodeBGCornerRadius = 0

  public formShowPrint = false
  public codeConfig: CodeConfig = { ...cloneObject(defaultCodeConfig), customText: 'some custom text', customText2: '   line 2' }
  public formSampleAsid = ''

  // private interpolate(str: string, o: any) {

  //   return str.replace(/{([^{}]*)}/g, (a, b) => {
  //     const r = o[b]
  //     return typeof r === 'string' || typeof r === 'number' ? r : a
  //   })

  // }
  private interpolate(template: string, vars: any) {
    try {

      const handler = new Function('vars', [
        'const tagged = ( ' + Object.keys(vars).join(', ') + ' ) =>',
        '`' + template + '`',
        'return tagged(...Object.values(vars))'
      ].join('\n'))

      return handler(vars)
    } catch (error: any) {
      return ''
    }
  }


  get interpolatedSVG() {
    return this.interpolate(this.svgTemplate, this.variables)
  }

  public async mounted() {
    // this.isLoading = true
    // this.tasks.forEach((t) => {
    //   t.inputPayloadString = JSON.stringify(t.taskData.payload)
    // })
    // this.isLoading = false
  }

}
