export const moduleOrder = {
  Ci: 1,
  Html: 2,
  Form: 3,
  File: 4,
  I18n: 5,
  Protection: 6,
  Script: 7,
  Custom: 8,
  Notification: 9,
  Data: 10,
  Service: 11,
  Abc: 99,
  Session: 100
}
