
import { Component, Vue, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import TheMainMenu from '@/components/TheMainMenu.vue'
import TheSideMenu from '@/components/TheSideMenu.vue'
import VFormLoginView from '@/components/VFormLoginView.vue'
import AdminNotificationManager from '@/database/adminNotificationManager'
import AdminManager from '@/database/adminManager'
import { GlobalAdminNotification } from '@/types/typeAdmin'
import databaseSchema from '@/database/databaseSchema'
import VEditorHtml from '@/components/global/VEditorHtml.vue'
import { getMenuNotification as getMenuNotificationEmails } from './Admin_Emails_List.vue'
import { getMenuNotification as getMenuNotificationTenants } from './Admin_Tenant_List.vue'
import { MenuNotifications } from '@/components/global/VNotificationCounts.vue'

library.add(faExclamationCircle) // used in forms with error
library.add(faEye, faEyeSlash) // used in forms to view the pw

Vue.filter('truncate', function (text: string = '', stop: number = 0, clamp: string) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '…' : '')
})
Vue.filter('truncateMid', function (text: string = '', length: number, clamp: string = '...') {
  return length < text.length
    ? text.substring(0, length / 2) + clamp + text.substring(text.length - length / 2, text.length)
    : text
})


@Component({
  components: {
    TheMainMenu,
    TheSideMenu,
    VFormLoginView,
    VEditorHtml
  }
})
export default class Admin extends Vue {
  get route() {
    return this.$route
  }

  public isLoading = true

  public menuNotifications: { email: MenuNotifications, tenants: MenuNotifications } = {
    email: { info: { count: 0, text: '' }, warning: { count: 0, text: '' }, error: { count: 0, text: '' } },
    tenants: { info: { count: 0, text: '' }, warning: { count: 0, text: '' }, error: { count: 0, text: '' } }
  }

  public async mounted() {
    try {
      this.isLoading = true
      this.showNotifications()
    } catch (error: any) {
      this.$helpers.notification.Error(JSON.stringify(error))
    } finally {
      this.isLoading = false
    }

    getMenuNotificationEmails().then((menuNotifications) => {
      this.menuNotifications.email = menuNotifications
    }).catch((e) => console.error(e))


    getMenuNotificationTenants().then((menuNotifications) => {
      this.menuNotifications.tenants = menuNotifications
    }).catch((e) => console.error(e))
  }


  @Watch('$auth.user')
  private async onUser() {
    if (!this.$auth.user) return

    try {
      this.isLoading = true
      await AdminManager.initAdminDB(this.$auth.userEmail)
      await AdminNotificationManager.initAdminNotificationDB(this.$auth.userEmail)
      this.showNotifications()
    } catch (error: any) {
      this.$helpers.notification.Error(JSON.stringify(error))
    } finally {
      this.isLoading = false
    }
  }

  public globalBackendNotification: GlobalAdminNotification = databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__EMPTY_DOC__.backendNotification
  public globalAppNotification: GlobalAdminNotification = databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__EMPTY_DOC__.appNotification
  public globalBackendNotificationActive = false
  public globalAppNotificationActive = false

  public showNotifications() {
    let intervalBackend: any = undefined
    let intervalApp: any = undefined
    AdminNotificationManager.onSnapshot((notification) => {
      this.globalBackendNotification = notification.backendNotification
      this.globalAppNotification = notification.appNotification

      clearInterval(intervalBackend)
      clearInterval(intervalApp)

      const applyShowBackend = () => {
        this.globalBackendNotificationActive = this.globalBackendNotification.show
      }
      const applyShowApp = () => {
        this.globalAppNotificationActive = this.globalAppNotification.show
      }
      intervalBackend = setInterval(applyShowBackend, this.globalBackendNotification.reshowEverySeconds * 1000)
      intervalApp = setInterval(applyShowApp, this.globalAppNotification.reshowEverySeconds * 1000)
      applyShowBackend()
      applyShowApp()

    }, (e) => console.error(e))
  }
}
