
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Component
export default class VCrudControl extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public disabled!: boolean

  @Prop({ type: String, required: false, default: 'save' })
  public saveButtonText!: string
  
  @Prop({ type: String, required: false, default: 'is-danger' })
  public deleteButtonType!: string

  @Prop({ type: String, required: false, default: 'cancel' })
  public cancelButtonText!: string

  @Prop({ type: String, required: false, default: 'remove' })
  public deleteButtonText!: string

  @Prop({ type: Boolean, required: false, default: () => false })
  public isSaving!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public isCanceling!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public isRemoving!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public hideSave!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public hideCancel!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public hideRemove!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isAutosave!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public hideDeleteIcon!: boolean

  @Emit('save')
  onClickSave() {
    if (this.isAutosave) this.$helpers.notification.Info('Data on this page is automatically saved when changed. It is not required to click the Autosave button.')
    return true
  }

  @Emit('cancel')
  onClickCancel() {
    return true
  }

  @Emit('remove')
  onClickRemove() {
    return true
  }
}
