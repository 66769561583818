
import { Component, Vue, Prop, ModelSync, Watch } from 'vue-property-decorator'
import { BaseElementDB } from '../modules/typeModules'
import { CategoryCollection } from '../types/typeCategory'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'


import { dbPrivileges } from '@/helpers/privileges'

import AsidManager from '@/database/asidManager'
import { AsidDB, IdentifierValue, IdentifierValues, isIdentifierKey } from '@/types/typeAsid'
import { typedWhere, typedWhereSartsWith } from '@/database/dbHelper'
import { arrayUnique, getDuplicates, intersect, removeAfromB } from '@/helpers/arrayHelper'
import { DebounceInstance, debounce } from 'vue-debounce'
import { hasDBid } from '@/types/typeGeneral'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import { DataDefinition } from '@/types/typeDataDefinition'
import CategoryHelper from '@/database/categoryHelper'

library.add(faListAlt)

@Component({
  components: {
    VInputMultiCategorySelection
  }
})
export default class VModuleElementReferences extends Vue {
  @ModelSync('elementDoc')
  public formBaseData!: BaseElementDB & hasDBid

  @Prop({ type: Boolean, required: false, default: () => false })
  public disabled!: boolean


  public get categories(): CategoryCollection {
    return this.$categories
  }

  public get enabledCategoryIDs() {
    return Object.keys(CategoryHelper.getAvailableUserCategoriesCollection(this.$categories, this.$auth?.user?.visibleCategories))
  }

  public mounted() {
    // 
  }

  public activeTab = 0
  public displayedCategoryLines = 1

  @Watch('sortedCategoryReferenceValues', { deep: true, immediate: true })
  private onChangeFormBaseData() {

    this.sortedCategoryReferenceValues
      .map(c => c.length).forEach((numberOfCategories, i) => {
        if (numberOfCategories > 0) this.displayedCategoryLines = i + 1
      })
  }

  public fieldMessages = {
    c1: '',
    c2: '',
    c3: ''
  }

  // public setValueOnIdentifier(key: keyof IdentifierValues, value: string) {
  //   if (value === undefined) {
  //     this.formBaseData.reference.identifierValues[key] = []
  //   } else {
  //     this.formBaseData.reference.identifierValues[key][0] !== undefined
  //       ? this.formBaseData.reference.identifierValues[key][0] = value
  //       : this.formBaseData.reference.identifierValues[key].push(value)
  //   }
  // }

  @Watch('formBaseData.reference.categoryIDs', { deep: true, immediate: true })
  private onChangeCategoryReferences() {
    const duplicates = getDuplicates(Object.values(this.formBaseData.reference.categoryIDs).flat())

    Object.entries(this.formBaseData.reference.categoryIDs)
      .forEach(([key, value]) => {
        const dups = intersect(value, duplicates)
        this.fieldMessages[key as 'c1' | 'c2' | 'c3'] = (dups.length > 0)
          ? `Using the same Category '${dups.map(d => this.$getCategoryName(d)).join(',')}' in multiple references on the same element is not possible.`
          : ''
      })
  }

  get sortedCategoryRefKeys() {
    return Object.keys(this.formBaseData.reference.categoryIDs).sort()
  }

  get sortedCategoryReferenceValues() {
    return Object.entries(this.formBaseData.reference.categoryIDs)
      .sort((a, b) => a[0].localeCompare(b[0], undefined, { numeric: true, sensitivity: 'base' })).map(d => d[1])
  }

  public get assignmentExplanationText() {
    let text = ''

    let first = true
    this.sortedCategoryReferenceValues.forEach(catIDs => {
      if (catIDs.length > 0) {
        if (!first) text += 'AND '
        text += catIDs.length > 1 ? 'any of the categories or subcategories of ' : 'the category or subcategory of '
        text += `"${catIDs.map(cId => this.$getCategoryName(cId)).join(', ')}"`
        text += ' '

        first = false
      }
    })

    this.identifierDefinitions.forEach(identDef => {
      const title = this.getIdentifierDefinitionTitle(identDef.__identifierKey__)
      const identifierValue = this.formBaseData.reference.identifierValues[identDef.__identifierKey__]
      if (identifierValue.length > 0) {
        if (!first) text += 'AND '
        text += identifierValue.length > 1 ? 'any of the identifiers ' : 'the identifier '
        text += `"${title}:'${identifierValue.join(', ')}'" `
      }
    })

    this.formBaseData.reference.asidIDs.forEach(asidID => {


      if (asidID) {
        if (!first) text += 'AND '
        text += `has the ECHO ID "${asidID}"`
      }
    })

    if (text.length === 0) {
      text = 'Assign references to define when this element is shown on a ECHO CODE.'
    } else {
      text = 'This element is shown when the ECHO CODE is assigned to ' + text
    }

    return text
  }

  //#region identifiers

  public get identifierDefinitions(): (DataDefinition & { __identifierKey__: keyof IdentifierValues })[] {
    return Object.entries(this.$backendConfig.asid.identifierDefinition).map(([key, value]) => ({
      __identifierKey__: key as keyof IdentifierValues,
      ...value
    }))
      .filter(e => e.name || e.title)
      // sort first by order and if order is the same, sort by __identifierKey__
      .sort((a, b) => a.order - b.order || a.__identifierKey__.localeCompare(b.__identifierKey__, 'en', { numeric: true }))

  }

  public isFetchingIdentifierAutocompleteData = false
  public identifierAutocompleteData: { [key: string]: (string | number | null | object | boolean)[] } = {}

  get hasAsidReadPrivilege() {
    return this.$auth.userHasAllPrivilege([dbPrivileges.ASID_READ])
  }

  public async onGetIdentifierAutocomplData(identifierKey: keyof IdentifierValue, text: string) {

    if (this.$backendConfig.asid.identifierDefinition[identifierKey].validatorType === 'validatorType_choices') {
      // if choices are the validator, just show the choices in the dropdown
      this.identifierAutocompleteData[identifierKey] = this.$backendConfig.asid.identifierDefinition[identifierKey].validator.choices || []
    } else if (this.hasAsidReadPrivilege) {

      this.isFetchingIdentifierAutocompleteData = true

      const query = typedWhere<AsidDB>(AsidManager.getDbCollectionReference(), { tenantID: '' }, '==', this.$auth.tenant.id)
      const queryOC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text)
      const queryUC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text.toUpperCase())
      const queryLC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text.toLowerCase())

      const queries = [queryOC, queryUC, queryLC]
      const queryRequests = queries.map(q => q.limit(50).get())

      const queryResults = (await Promise.all(queryRequests))

      const responseDocuments = queryResults.flatMap(r => r.docs.map((d) => d.data() as AsidDB).map(asid => asid.identifierValue[identifierKey]))
      this.identifierAutocompleteData[identifierKey] = arrayUnique(responseDocuments)
      this.isFetchingIdentifierAutocompleteData = false
    } else {
      this.identifierAutocompleteData[identifierKey] = [text]
    }

  }

  public validationIdentifierMessage: { [indentifierName: string]: string } = {}

  private debounceInstanceValidateIdentifierReferences?: DebounceInstance<any>
  @Watch('formBaseData.reference.identifierValues', { deep: true, immediate: true })
  private async onIdentifierReferenceChange() {
    if (!this.debounceInstanceValidateIdentifierReferences)
      this.debounceInstanceValidateIdentifierReferences = debounce(async () => {
        await this.validateIdentifierReferences()
      }, 500)

    await this.debounceInstanceValidateIdentifierReferences()
  }

  public getIdentifierDefinitionName(identifierKey: keyof IdentifierValues) {
    return this.$backendConfig.asid.identifierDefinition[identifierKey].name
  }

  public getIdentifierDefinitionTitle(identifierKey: keyof IdentifierValues) {
    // title is optional, fallback to name
    return this.$backendConfig.asid.identifierDefinition[identifierKey].title || this.$backendConfig.asid.identifierDefinition[identifierKey].name
  }

  private async validateIdentifierReferences() {

    let identifierKey: keyof IdentifierValues
    for (identifierKey in this.formBaseData.reference.identifierValues) {
      if (Object.prototype.hasOwnProperty.call(this.formBaseData.reference.identifierValues, identifierKey)) {

        // reset message
        this.$set(this.validationIdentifierMessage, identifierKey, '')
        if (!this.hasAsidReadPrivilege) {
          this.$set(this.validationIdentifierMessage, identifierKey, 'Input validation not possible due to missing ECHO Code read privilege')
        } else {
          try {
            // check if an asid with the given identifiers exists

            // query each selected identifier and check if it exists for an asid
            const asyncPromises = this.formBaseData.reference.identifierValues[identifierKey].map(identifier => {
              const query = typedWhere<AsidDB>(AsidManager.getDbCollectionReference(), { tenantID: '' }, '==', this.$auth.tenant.id)
              return typedWhere<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, '==', identifier)
                .limit(1)
                .get()
            })

            // check that no query was empty
            const asidResponses = (await Promise.all(asyncPromises))
              .flatMap(reponse => reponse.docs)
              .map(doc => doc.data() as AsidDB)
              .map(asidDB => asidDB.identifierValue[identifierKey])

            // get list of identifiers that were not found on an asid
            const notFoundIdentifiers = removeAfromB(asidResponses, this.formBaseData.reference.identifierValues[identifierKey])

            if (notFoundIdentifiers.length > 0)
              this.$set(this.validationIdentifierMessage, identifierKey,
                `No matching ECHO Code was found for the identifier "${this.getIdentifierDefinitionTitle(identifierKey)}" with the values "${notFoundIdentifiers.join(',')}".`)
          } catch (error: any) {
            this.$helpers.notification.Error(error.toString())
          }


        }
      }
    }
  }
  //#endregion identifiers

  // #regiopn url presets

  @Watch('formBaseData.id', { deep: false, immediate: true }) // not deep to prevent loop
  public async onRouteChanged() {
    let elRefPresetAsid = this.$route.query.elRefPresetAsid
    if (elRefPresetAsid && this.formBaseData.reference.asidIDs.length === 0) {
      if (!Array.isArray(elRefPresetAsid)) elRefPresetAsid = [elRefPresetAsid]
      this.formBaseData.reference.asidIDs = elRefPresetAsid as string[]
      this.activeTab = 2
    }

    let elRefPresetIdentifier = this.$route.query.elRefPresetIdentifier as (string | string[])
    if (elRefPresetIdentifier) {
      if (!Array.isArray(elRefPresetIdentifier)) elRefPresetIdentifier = [elRefPresetIdentifier]
      elRefPresetIdentifier.forEach(identifierString => {
        const [identifier, value] = identifierString.split('__-__')
        if (value && value !== 'null')
          this.formBaseData.reference.identifierValues[identifier as isIdentifierKey] = [value]
      })
      this.activeTab = 1
    }
  }
  // #endregiopn url presets

  //#region asid autocomplete

  public isFetchingAsidAutocompleteData = false
  public validationAsidMessage: string = ''
  public asidAutocompleteData: string[] = []

  public async onGetAsidAutocomplData(text: string) {
    this.isFetchingAsidAutocompleteData = true

    if (this.hasAsidReadPrivilege && text.length > 0) {
      const query = typedWhere<AsidDB & hasDBid>(AsidManager.getDbCollectionReference(), { tenantID: '' }, '==', this.$auth.tenant.id)
      const queryOC = typedWhereSartsWith<AsidDB & hasDBid>(query, { id: '' }, text)
      const queryUC = typedWhereSartsWith<AsidDB & hasDBid>(query, { id: '' }, text.toUpperCase())
      const queryLC = typedWhereSartsWith<AsidDB & hasDBid>(query, { id: '' }, text.toLowerCase())

      const queries = [queryOC, queryUC, queryLC]
      const queryRequests = queries.map(q => q.limit(10).get())

      const queryResults = (await Promise.all(queryRequests))

      const responseDocuments = queryResults.flatMap(r => r.docs.map((d) => d.id))


      this.asidAutocompleteData = arrayUnique(responseDocuments)
      this.isFetchingAsidAutocompleteData = false
    } else {
      this.asidAutocompleteData = [text]
      this.isFetchingAsidAutocompleteData = false
    }

  }

  private debounceInstanceValidateAsidReferences?: DebounceInstance<any>
  @Watch('formBaseData.reference.asidIDs', { deep: true, immediate: true })
  private async onAsidReferenceChange() {
    if (!this.debounceInstanceValidateAsidReferences)
      this.debounceInstanceValidateAsidReferences = debounce(async () => {
        await this.validateReference()
      }, 500)

    await this.debounceInstanceValidateAsidReferences()
  }


  private async validateReference() {
    this.validationAsidMessage = ''

    if (this.hasAsidReadPrivilege && this.formBaseData.reference.asidIDs[0]) {
      try {
        await AsidManager.get(this.formBaseData.reference.asidIDs[0])
      } catch (error: any) {
        this.validationAsidMessage = `No matching ECHO Code with ID "${this.formBaseData.reference.asidIDs[0]}" was found`
      }

    }
  }
  //#endregion asid autocomplete
}
