
import { Component, Vue, PropSync } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilter, faTimesCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'

import { acessorObjectToString } from '@/database/dbHelper'
import { CategoryID } from '@/types/typeCategory'
import CategoryHelper from '@/database/categoryHelper'
import { FilterConfig } from './mixins/VPaginateMixin.vue'

library.add(faTimesCircle, faFilter, faTimes)

@Component({
  components: {
    VInputMultiCategorySelection
  },
  inheritAttrs: false,
  props: {
    default: {
      type: Boolean,
      required: false,
      default: () => true
    }
  }
})
export default class VFilterCategoriesDropdownView extends Vue {
  public isLoading = false
  public dropdownIsOpen = false
  public showMorePresets = false


  @PropSync('config', { type: Object })
  public filterConfig!: FilterConfig<any>

  public formSelectedCategories: CategoryID[] = []
  public formIncludeChildCats = false


  get propertyAcessorPath() {
    return acessorObjectToString(this.filterConfig.objAcessor)
  }


  public onApplyFilter() {
    this.filterConfig.in = (this.formIncludeChildCats)
      ? CategoryHelper.getAllChildCategoriesArray(this.formSelectedCategories, this.$categories)
      : this.formSelectedCategories
      ; (this.$refs.dropdown as any).toggle()
  }

  public onResetFilter() {
    this.filterConfig.in = []
    this.formSelectedCategories = []
    if (this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }

  public onOpenFilter() {

    // update formSelectedCategories based on filterConfig.in
    this.formSelectedCategories = this.filterConfig.in as CategoryID[]

    if (!this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }

  public getAvailableUserCategories() {
    return CategoryHelper.getAvailableUserCategoriesCollection(this.$categories, this.$auth?.user?.visibleCategories)
  }

  get isUserSelectionSubsetOfPresets() {
    const presets = this.filterConfig.presets || []

    return presets.length > 0
      ? this.formSelectedCategories.every((catID) => presets.includes(catID))
      : true
  }
}
