
import { Component } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faTrash,
  faQrcode,
  faChevronLeft,
  faStream
} from '@fortawesome/free-solid-svg-icons'

import VImportExport from '@/components/VImportExport.vue'
import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'
import VEchoCode from '@/components/VEchoCode.vue'
import VFilterDropdownView from '@/components/VFilterDropdownView.vue'
import VFilterDateDropdownView from '@/components/VFilterDateDropdownView.vue'


import BatchManager from '@/database/batchManager'
import { TenantDB } from '../../types/typeTenant'
import { mixins } from 'vue-class-component'
import VPaginationMixin from '../../components/mixins/VPaginateMixin.vue'
import TenantManager from '../../database/tenantManager'

import BackendConfigManager from '@/database/backendConfigManager'
import { BackendConfigDB } from '@/types/typeBackendConfig'

import databaseSchema, { asidStates } from '@/database/databaseSchema'

import { cloneObject } from '@/helpers/dataShapeUtil'
import { hasDBid } from '@/types/typeGeneral'
import { DataCache } from '@/helpers/dataCache'
import { DataElementDB } from '@/modules/data/typeDataModule'
import { BatchDB } from '@/types/typeBatch'
import { accessorStringToValue } from '@/database/dbHelper'
import moment from 'moment'


library.add(faArrowUp, faChevronRight, faStream, faAngleRight, faAngleLeft, faMars, faVenus, faTrash, faQrcode, faChevronLeft)

@Component({
  components: {
    VImportExport,
    VFormConfigureSticker,
    VEchoCode,
    VFilterDropdownView,
    VFilterDateDropdownView
  }
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class BatchList extends mixins<VPaginationMixin<BatchDB>>(VPaginationMixin) {

  public pagination_sortDirection: 'asc' | 'desc' = 'desc'
  public pagination_sortField: string = 'id'
  public pagination_perPage = 50
  public pagination_liveUpdateOnFirstPage = true

  public pagination_checkedRows: Array<BatchDB & hasDBid & { tenantName: string, tenantNumber: string, baseUrl: string, dataElement?: DataElementDB }> = []
  public isLoading = false
  public pagination_paginatedData: Array<BatchDB & hasDBid & { tenantName: string, tenantNumber: string, baseUrl: string, dataElement?: DataElementDB }> = []

  protected pagination_collectionReference = BatchManager.getDbCollectionReference()

  public asidStates = asidStates

  public onSort(field: string, order: 'asc' | 'desc') {
    console.log(field, order)

    this.pagination_sortField = field
    this.pagination_sortDirection = order
  }

  public onPageChange(page: number = 1) {
    this.pagination_currentPage = page
  }

  public formatPadded(number: number) {
    return TenantManager.formatPaddedNumber(number)
  }

  get anyLoading() {
    return this.isLoading || this.pagination_isPaginationLoading
  }

  public selectedFilterTenant: (TenantDB & hasDBid) | null = null
  public selectedFilterTenantName = ''
  public selectedFilterASID = ''

  public accessorStringToValue(obj: any, acessor: string) {
    return accessorStringToValue(obj, acessor)
  }

  public get tableColumnsHash() {
    return this.tableColumns.map(c => c.field).join('')
  }
  public get tableColumns() {
    return [
      // {
      //   field: 'asidID',
      //   label: 'ECHO CODE',
      //   numeric: false,
      //   searchable: true,
      //   sortable: true
      // },
      {
        field: 'name',
        label: 'Name',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false

      }, {
        field: 'tenantName',
        label: 'Tenant Name',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false

      }, {
        field: 'tenantNumber',
        label: 'Tenant Number',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false

      }, {
        field: 'responsible',
        label: 'Responsible Person',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false

      }, {
        field: '_meta.dateCreated',
        label: 'Date Created',
        formatter: (d: any) => moment(d?.toDate?.() || 0).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false
      }, {
        field: 'reference',
        label: 'Reference',
        // formatter: (d: any) => moment(d?.toDate?.() || 0).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false
      }, {
        field: 'orderConfNumber',
        label: 'AB Number',
        // formatter: (d: any) => moment(d?.toDate?.() || 0).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false
      }, {
        field: 'invoiceNumber',
        label: 'invoiceNo',
        // formatter: (d: any) => moment(d?.toDate?.() || 0).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false
      }, {
        field: 'orderNumber',
        label: 'orderNo',
        // formatter: (d: any) => moment(d?.toDate?.() || 0).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false
      }, {
        field: 'type',
        label: 'Type',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false
      }, {
        field: '_computed.asidCount',
        label: 'Asid Count',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: true

      }
    ]
  }

  // public pagination_filter(): FilterConfigNew<BatchDB & hasDBid>[] {

  //   return [
  //     {
  //       fieldAccessor: { id: '2' },
  //       opStr: '<',
  //       values: ['x'],
  //       indexGroups: [],
  //       isMandatory: true
  //     }
  //   ]
  // }

  public pagination_filterConfig = [{
    fieldAccesor: { batchName: '' },
    collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
    objAcessor: { batchName: '' },
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    range: []
  }, {
    fieldAccesor: { id: '' },
    collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
    objAcessor: { id: '' },
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    range: []
  }, {
    fieldAccesor: { tenantID: '' },
    collectionPath: databaseSchema.COLLECTIONS.TENANTS.__COLLECTION_PATH__(),
    objAcessor: { id: '' },
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    range: []
  }, {
    fieldAccesor: { name: '' } as BatchDB,
    collectionPath: databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__COLLECTION_PATH__(),
    objAcessor: { name: '' } as BatchDB,
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    range: []
  }, {
    fieldAccesor: { responsible: '' } as BatchDB,
    collectionPath: databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__COLLECTION_PATH__(),
    objAcessor: { responsible: '' } as BatchDB,
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    range: []
  }, {
    fieldAccesor: { tenantID: '' } as BatchDB,
    tableColumnField: 'tenantName',
    collectionPath: databaseSchema.COLLECTIONS.TENANTS.__COLLECTION_PATH__(),
    objAcessor: { id: '' } as TenantDB & hasDBid,
    objDisplayAcessor: { name: '' } as TenantDB,
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    range: []
  }, {
    fieldAccesor: { tenantID: '' } as BatchDB,
    tableColumnField: 'tenantNumber',
    collectionPath: databaseSchema.COLLECTIONS.TENANTS.__COLLECTION_PATH__(),
    objAcessor: { id: '' } as TenantDB & hasDBid,
    objDisplayAcessor: { _number: 0 } as TenantDB,
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    range: []
  }, {
    fieldAccesor: { _meta: { dateCreated: '' } } as BatchDB & hasDBid,
    collectionPath: databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__COLLECTION_PATH__(),
    objAcessor: { _meta: { dateCreated: '' } } as BatchDB & hasDBid,
    type: 'date' as const,
    in: [] as string[],
    notBackendSortable: false,
    range: []
  }, {
    fieldAccesor: { reference: '' } as BatchDB & hasDBid,
    collectionPath: databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__COLLECTION_PATH__(),
    objAcessor: { reference: '' } as BatchDB & hasDBid,
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    emptyValue: '',
    range: []
  }, {
    fieldAccesor: { orderConfNumber: '' } as BatchDB & hasDBid,
    collectionPath: databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__COLLECTION_PATH__(),
    objAcessor: { orderConfNumber: '' } as BatchDB & hasDBid,
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    emptyValue: '',
    range: []
  }, {
    fieldAccesor: { orderNumber: '' } as BatchDB & hasDBid,
    collectionPath: databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__COLLECTION_PATH__(),
    objAcessor: { orderNumber: '' } as BatchDB & hasDBid,
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    emptyValue: '',
    range: []
  }, {
    fieldAccesor: { invoiceNumber: '' } as BatchDB & hasDBid,
    collectionPath: databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__COLLECTION_PATH__(),
    objAcessor: { invoiceNumber: '' } as BatchDB & hasDBid,
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    emptyValue: '',
    range: []
  }, {
    fieldAccesor: { type: '' } as BatchDB & hasDBid,
    collectionPath: databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__COLLECTION_PATH__(),
    objAcessor: { type: '' } as BatchDB & hasDBid,
    type: 'exact' as const,
    in: [] as string[],
    notBackendSortable: false,
    emptyValue: '',
    range: []
  }]

  private dataCacheTenant = new DataCache<TenantDB & hasDBid>(async (key) => {
    return await TenantManager.get(key)
  })

  private dataCacheBackendConfig = new DataCache<BackendConfigDB & hasDBid>(async (key) => {
    return await BackendConfigManager.get(key)
  })


  // used to identify if some data is still loading and the export must wait for all to complete
  private isAdditionalRowPropsLoading = 0
  protected async pagination_foreachDoc(doc: BatchDB & hasDBid) {
    if (doc.tenantID) {

      // TenantManager.get(doc.tenantID).then(cDoc => {
      //   if (cDoc)
      //     this.$set(doc, 'tenantName', cDoc.name)
      // })

      this.dataCacheTenant.get(doc.tenantID).then((d) => {
        this.$set(doc, 'tenantName', d.name)
        this.$set(doc, 'tenantNumber', d._number)
      }).catch(() => {
        this.$set(doc, 'tenantName', 'not assigned')
        this.$set(doc, 'tenantNumber', 'not assigned')
      })

      this.isAdditionalRowPropsLoading++
      this.dataCacheBackendConfig.get(doc.tenantID).then((d) => {
        this.$set(doc, 'baseUrl', d.asid.baseUrl)
        this.isAdditionalRowPropsLoading--
      }).catch(() => {
        this.$set(doc, 'baseUrl', 'not assigned')
        this.isAdditionalRowPropsLoading--
      })

      // this.isAdditionalRowPropsLoading++
      // this.dataCacheDataElement.get(doc.tenantID).then(async (d) => {
      //   // get the date Element with the
      //   const dataElement = ModuleManager.filterElementsMatchingReferences(d,
      //     await this.dataCacheCategories.get(doc.tenantID || ''),
      //     doc.categoryIDs,
      //     doc.id,
      //     doc.identifierValue
      //   )
      //   this.$set(doc, 'dataElement', dataElement[0])
      //   this.isAdditionalRowPropsLoading--
      // })

    } else {
      this.$set(doc, 'tenantID', 'not assigned') // use 0 for proper sorting
      this.$set(doc, 'backendConfig', cloneObject(BackendConfigManager.defaultDocDB))
    }
  }


  public mounted() {

    this.getData()

  }


}
