
import { Component, Vue, Watch } from 'vue-property-decorator'


// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { CategoryID, CategoryTree } from '@/types/typeCategory'
import PrivilegeHelper from '../helpers/privilegeHelper'
import { UserPrivilegeIdDB } from '../types/typeUser'
import LiquorTree from 'liquor-tree'
import { arrayUnique } from '@/helpers/arrayHelper'
import { TreeData } from '@/types/typeGeneral'

const state = {
  'selected': false,
  'selectable': true,
  'checked': false,
  'expanded': true,
  'disabled': false,
  'visible': true,
  'indeterminate': false,
  'matched': false,
  'editable': true,
  'dragging': false,
  'draggable': true,
  'dropable': true
}


interface LiquorTreeElement extends CategoryTree {
  data: {
    intermediate: boolean
    checked: boolean
    [key: string]: any
  }
  text: string
  state: typeof state
  parent: LiquorTreeElement
  children: LiquorTreeElement[]
  remove: () => void
}

@Component({
  components: {
    Treeselect,
    LiquorTree
  },
  model: {
    prop: 'selectedUserRoles',
    event: 'selected'
  },
  props: {
    selectedUserRoles: {
      type: Array,
      required: true
    },
    hideViews: {
      type: Boolean,
      required: false
    }
  }
})
export default class VInputUserRolesTags extends Vue {
  public privilegesTree = PrivilegeHelper.getAvailableRolesAndPrivilegesTree(this.$props.hideViews)
  public formSelectedIds: UserPrivilegeIdDB[] = []
  public filterText = ''
  public treeOptions = {
    propertyNames: {
      text: 'name',
      children: 'children',
      state: 'state'
    },
    filter: {
      emptyText: ''
    },
    multiple: true,
    dnd: false,
    checkbox: false
  }
  private programmaticChecking = false

  get privilegesLiquorTree() {
    return this.privilegesTree
  }


  // public normalizer(node: TreeData) {
  //   const tmpNode = {
  //     id: node.name,
  //     label: node.name,
  //     children: (node.children && node.children.length > 0) ? node.children : undefined // to remove the child prop when empty
  //   }
  //   return {
  //     ...tmpNode // to remove the child prop when empty
  //   }
  // }


  // Does not work cause unique index is not in db  
  // private index = 0

  // public normalizer(node: TreeData) {
  //   const tmpNode = {
  //     id: `${this.index++}___${node.name}`, // treeselect need unique data index
  //     label: node.name,
  //     children: (node.children && node.children.length > 0) ? node.children : undefined // to remove the child prop when empty
  //   }
  //   return {
  //     ...tmpNode // to remove the child prop when empty
  //   }
  // }

  // public onInputChange(selectedIDs: CategoryID[]) {
  //   this.$emit('selected', selectedIDs.map(id=>id.split('___')[1])) // remove unique id
  // }

  // 
  private selectAllChildrenByNodeID(selectedIDs: string[]) {
    const allSelectedIdLeafs: string[] = []

    function iterateTreeDown(subtree: TreeData, addToResult = false) {
      addToResult = selectedIDs.includes(subtree.id) || addToResult

      if (addToResult && !(subtree.children)) allSelectedIdLeafs.push(subtree.name)

      subtree.children?.forEach(child => iterateTreeDown(child, addToResult))
    }

    this.privilegesTree.forEach(t => iterateTreeDown(t))

    return allSelectedIdLeafs
  }

  private aggregateTopMostSelection(selectedIDs: string[]) {
    const allSelectedIdLeafs: string[] = []

    function iterateTreeUp(subtree: TreeData): boolean {

      if (!subtree.children) {
        return selectedIDs.includes(subtree.name)
      } else {
        const allChildsMatch = subtree.children?.map(child => iterateTreeUp(child)).every(e => e)
        if (allChildsMatch) allSelectedIdLeafs.push(subtree.name)
        return allChildsMatch
      }
    }
    console.log('aggregating')


    this.privilegesTree.forEach(t => iterateTreeUp(t))

    return allSelectedIdLeafs
  }

  public onCheckedChange(node: LiquorTreeElement, checked: boolean) {
    if (checked) {
      const checkedPrivileges = arrayUnique([...this.formSelectedIds, ...this.selectAllChildrenByNodeID([node.id])])

      this.$emit('selected', checkedPrivileges)
    } else {
      const checkedPrivileges = this.formSelectedIds
      const toBeUnchecked = this.selectAllChildrenByNodeID([node.id])
      // the unchecked node may appear multiple times in the tree and ths still be in the result set
      const newCheckedPrivileges = checkedPrivileges.filter(cp => !toBeUnchecked.includes(cp))
      this.$emit('selected', newCheckedPrivileges)
    }
  }

  // public onChecked(node: LiquorTreeElement) {
  //   if (this.programmaticChecking) return

  //   const checkedItems = (this.$refs.tree as any).checked() as LiquorTreeElement[]
  //   console.log('checked', node.text)
  //   const checkedPrivileges = arrayUnique([node.text, ...this.selectAllChildrenByNodeID(checkedItems.map(i => i.text))])

  //   this.$emit('selected', checkedPrivileges)
  // }

  // public onUnchecked(node: LiquorTreeElement) {
  //   if (this.programmaticChecking) return

  //   const checkedItems = (this.$refs.tree as any).checked() as LiquorTreeElement[]
  //   console.log('unchecked', node.text)

  //   const checkedPrivileges = arrayUnique(this.selectAllChildrenByNodeID(checkedItems.map(i => i.text)))

  //   // the unchecked node may appear multiple times in the tree and ths still be in the result set
  //   const newCheckedPrivileges = checkedPrivileges.filter(cp => cp !== node.text)
  //   this.$emit('selected', newCheckedPrivileges)
  // }

  public isNodeChecked(node: LiquorTreeElement) {
    if (this.formSelectedIds.includes(node.text as UserPrivilegeIdDB)) return true

    const iterateTreeNodeHasEveryCheckedChildren = (node: LiquorTreeElement): boolean => {
      if (this.formSelectedIds.includes(node.text as UserPrivilegeIdDB)) {
        return true
      } else if (node.children.length > 0) {
        return node.children.every(node => iterateTreeNodeHasEveryCheckedChildren(node))
      } else {
        return false
      }
    }

    return iterateTreeNodeHasEveryCheckedChildren(node)
  }

  /**
   * some but not all child nodes are checked
   */
  public isNodeIntermediateChecked(node: LiquorTreeElement) {
    if (this.formSelectedIds.includes(node.text as UserPrivilegeIdDB)) return false

    const iterateTreeNodeHasCheckedChildren = (node: LiquorTreeElement): boolean => {
      if (this.formSelectedIds.includes(node.text as UserPrivilegeIdDB)) {
        return true
      } else if (node.children.length > 0) {
        return node.children.some(node => iterateTreeNodeHasCheckedChildren(node))
      } else {
        return false
      }
    }

    return iterateTreeNodeHasCheckedChildren(node) && !this.isNodeChecked(node)
  }

  public onInputChange(selectedIDs: CategoryID[]) {
    this.$emit('selected', (selectedIDs))
    // this.$emit('selected', this.selectAllChildrenByNodeID(selectedIDs))
  }

  public onBlur(selectedIDs: CategoryID[]) {
    this.$emit('blur', (selectedIDs))
    // this.$emit('blur', this.selectAllChildrenByNodeID(selectedIDs))
  }

  // todo see AppAdress on how its done
  @Watch('selectedUserRoles', { immediate: true })
  onselectedCategoryIDChanged(val: UserPrivilegeIdDB[], oldVal: string[]) {

    this.formSelectedIds = val
    this.updateCheckedNodes()
    // this.formSelectedIds = this.aggregateTopMostSelection(val) as UserPrivilegeIdDB[]
  }

  mounted() {
    this.updateCheckedNodes()
  }


  private updateCheckedNodes() {
    if (this.$refs.tree) {
      this.programmaticChecking = true
      // const findNodesRegex = new RegExp(`${this.formSelectedIds.join('|')}`)

      //   ; (this.$refs.tree as any).checked().uncheck()
      // const selection = (this.$refs.tree as any).findAll({ text: findNodesRegex, state: { disabled: false } })
      // console.log(selection)
      // selection.check(true)
      this.programmaticChecking = false
    }
  }
}

