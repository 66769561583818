
import { Component, Vue } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus
} from '@fortawesome/free-solid-svg-icons'


import { configChildRoutes } from './routerConfigChildRoutes'
import { intersectEvery } from '@/helpers/arrayHelper'


library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus)


@Component({
  components: {
    SlickList,
    SlickItem
  },
  directives: {
    handle: HandleDirective
  }
})
export default class BackendConfigList extends Vue {

  get settings() {
    return configChildRoutes
      .filter((route) => intersectEvery(route.meta.privileges, this.$auth.userPrivileges))
      .filter(route => (!route.meta.staffOnly || this.$auth.isEchoPrmStaff))
  }

  public created() {
    //
  }

}
