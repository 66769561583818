
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {

  }
})
export default class BackendPageNotFound extends Vue {
  public get url(){
    return location.href
  }
}
