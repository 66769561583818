
import { Component, Watch, Vue, Prop } from 'vue-property-decorator'

import VTooltipIconHelp from '@/components/global/VTooltipIconHelp.vue'
import VueGallery from 'vue-gallery'


import { CategoryCollection } from '@/types/typeCategory'


import { capitalize } from '@/helpers/stringHelper'
import ResponseViewHelper from '@/helpers/responseViewHelper'

import { FormResponseDB } from '@/modules/form/typeFormModule'

import { CustomResponseData, CustomResponseDB } from '@/modules/custom/typeCustomModule'
import { FileResponseDB } from '@/modules/file/typeFileModule'
import { ModuleType, ElementWithTypeAndID, BaseResponseDB, PublishingState } from '@/modules/typeModules'
import { ModuleManager } from '@/modules/moduleManager'
import { ScriptResponseDB } from '@/modules/script/typeScriptModule'

import { ProtectionResponseDB } from '@/modules/protection/typeProtectionModule'
import { hasDBid, objectID } from '@/types/typeGeneral'
import { SessionDB } from '@/types/typeAppSession'
import { downloadBlob } from '@/helpers/downloadHelper'
import VResponseTimelineForm from '@/components/VResponsesTimelineForm.vue'
import { getLocaleName } from '@/helpers/i18nUtil'
import { locale } from '@/types/typeI18n'
import { I18nResponseDB } from '@/modules/i18n/typeI18nModule'

@Component({
  components: {
    VTooltipIconHelp,
    VueGallery,
    VResponseTimelineForm
  },
  props: {
    asid: {
      type: String,
      required: false,
      default: () => ''
    }
  }
})
export default class VResponseTimelineView extends Vue {
  // used for form controls


  public isLoading = false
  public isLoadingOverlay = false
  public categoryCollection: CategoryCollection = this.$categories
  public elementsByID: { [key: string]: ElementWithTypeAndID } = {}

  private MERGE_TIME_DELTA = 10 * 60 // 10 minutes

  @Prop({ type: Array, required: false, default: () => ([]) })
  public sessions!: Array<hasDBid & SessionDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesProtection!: Array<hasDBid & ProtectionResponseDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesForm!: Array<hasDBid & FormResponseDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesI18n!: Array<hasDBid & I18nResponseDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesFile!: Array<hasDBid & FileResponseDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesCustom!: Array<hasDBid & CustomResponseDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesScript!: Array<hasDBid & ScriptResponseDB>

  @Prop({ type: Array, required: true })
  public moduleElementsWithType!: ElementWithTypeAndID[]

  @Prop({ type: Boolean, required: false, default: () => false })
  public showAsidInfo!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public showPublishingState!: boolean

  public filterEmpty(rspValues: CustomResponseData) {
    return Object.fromEntries(Object.entries(rspValues).filter(([key, value]) => value !== null))
  }

  public getLocalesPerSession(responsesPerSession: BaseResponseDB[]) {
    const locales: locale[] = []
    responsesPerSession.forEach(rsp => {
      if (rsp.public && rsp.public.locale && !locales.includes(rsp.public.locale)) {
        locales.push(rsp.public.locale)
      }
    })
    return locales
  }

  public getLocaleName(locale: locale) {
    return getLocaleName(locale)
  }

  public async onDownloadFile(file: { content: string, name: string }) {

    this.isLoading = true

    try {
      const response = await fetch(file.content)
      const blob = await response.blob()

      // create a new file from the blob object
      const fileData = new File([blob], `${file.name}`)

      // save the file
      downloadBlob(fileData, fileData.name)

      this.isLoading = false
    } catch (error) {
      this.isLoading = false
      console.error(error)
    }

  }

  public getPublicDataForElement(elementID: objectID) {
    const el = this.elementsByID[elementID]
    if (!el) return
    return el.public
  }

  public async onChangeAcknowledge(id: objectID, v: boolean) {
    const rsp = this.allResponses.find(me => me.id === id)
    if (!rsp) throw '[20211104] Response not found'

    rsp.backendData.acknowledge = v

    await this.onChangeBackendData(id)

  }

  public async onChangeBackendData(id: objectID) {
    this.isLoadingOverlay = true

    try {
      const rsp = this.allResponses.find(me => me.id === id)
      if (!rsp) throw '[20211104] Response not found'

      await ModuleManager.getModuleClassByType(rsp.type).updateResponse(this.$auth.tenant.id, this.$auth.userEmail, id, {
        backendData: rsp.backendData
      })
      this.$helpers.notification.Success(`Data for session ${rsp.responseSession} has been updated`)
    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
    } finally {
      this.isLoadingOverlay = false
    }
  }

  public onClickEditNote(id: objectID) {
    const rsp = this.allResponses.find(me => me.id === id)
    if (!rsp) {
      this.$helpers.notification.Error('[20211105] Response not found')
      return
    }

    this.$buefy.dialog.prompt({
      message: 'Add Note for Response',
      inputAttrs: {
        placeholder: 'your note',
        maxlength: 10000,
        value: rsp.backendData.note
      },
      trapFocus: true,
      onConfirm: async (note: string) => {
        rsp.backendData.note = note
        await this.onChangeBackendData(id)
      }
    })
  }

  @Watch('moduleElementsWithType', { immediate: true })
  public onChangeModuleElementsWithType() {
    this.moduleElementsWithType.forEach(el => {
      if (el.id in this.elementsByID) return
      this.$set(this.elementsByID, el.id, el)
    })
  }

  public getModuleColor(type: ModuleType) {
    return ModuleManager.getModuleClassByType(capitalize(type) as ModuleType).color
  }

  public getAsidForSession(session: string) {
    return this.allResponses.find(e => e.responseSession === session)?.public.asidID || ''
  }

  public onRemoveSession(session: string) {

    const responsesToBeDeleted = this.allResponses
      .filter(e => e.responseSession === session)
      .filter(e => (e.type as any) !== 'Session')

    if (responsesToBeDeleted.length === 0) {
      this.$buefy.dialog.alert({
        message: 'View events can not be removed',
        type: 'is-warning',
        ariaModal: true
      })
    } else {
      this.$buefy.dialog.confirm({
        title: 'Deleting Responses',
        message: `Are you sure you want to <b>delete all ${responsesToBeDeleted.length} responses for the session ${session}</b>? Visit events are not removed. This action cannot be made undone.`,
        confirmText: 'Delete Responses',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true
          const promises: Promise<any>[] = []
          responsesToBeDeleted.forEach(rsp => {
            promises.push(
              ModuleManager
                .getModuleClassByType(rsp.type)
                .deleteResponse(this.$auth.tenant.id, this.$auth.userEmail, rsp.id)
            )
          })

          try {
            await Promise.all(promises)
          } catch (error: any) {
            this.$helpers.notification.Error(error.toString())
          } finally {

            this.isLoading = false
          }

        }
      })
    }
  }

  public onArchiveSession(session: string) {
    this.changePublishingStateOnAllReponsesForSession(session, 'archived')
      .then(() => {
        this.$helpers.notification.Success(`All responses for session ${session} have been archived`)
      })
      .catch(error => {
        this.$helpers.notification.Error(error.toString())
      })
  }

  public onPublishSession(session: string) {
    this.changePublishingStateOnAllReponsesForSession(session, 'published')
      .then(() => {
        this.$helpers.notification.Success(`All responses for session ${session} have been published`)
      })
      .catch(error => {
        this.$helpers.notification.Error(error.toString())
      })
  }

  private async changePublishingStateOnAllReponsesForSession(session: string, state: PublishingState) {
    try {
      this.isLoading = true

      const responsesToBePublished = this.allResponses
        .filter(e => e.responseSession === session)
        .filter(e => (e.type as any) !== 'Session')

      const promises: Promise<any>[] = []
      responsesToBePublished.forEach(rsp => {
        promises.push(
          ModuleManager
            .getModuleClassByType(rsp.type)
            .updateResponse(this.$auth.tenant.id, this.$auth.userEmail, rsp.id, {
              publishingState: state
            }) as Promise<void>
        )
      })

      await Promise.all(promises)
    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
    } finally {
      this.isLoading = false
    }
  }


  public getLinkToElement(elID: objectID) {
    const el = this.elementsByID[elID]
    if (!el) return { name: 'not-found' }
    const name = ModuleManager.getModuleClassByType(el.type).routeNameElement
    return { name, params: { id: el.id } }
  }

  public created() {
    //
  }


  get allResponses() {
    return [
      ...ResponseViewHelper.prepareResponses(this.responsesProtection, 'Protection'),
      ...ResponseViewHelper.prepareResponses(this.responsesFile, 'File'),
      ...ResponseViewHelper.prepareResponses(this.responsesCustom, 'Custom'),
      ...ResponseViewHelper.prepareResponses(this.responsesScript, 'Script'),
      ...ResponseViewHelper.prepareResponses(this.responsesForm, 'Form'),
      ...ResponseViewHelper.prepareResponses(this.responsesI18n, 'I18n'),
      ...ResponseViewHelper.prepareResponses(this.sessions.map(s => ({ ...s, responseSession: s.sessionID })) as unknown as (BaseResponseDB & hasDBid)[], 'Session' as any)
    ]
  }

  get allResponsesGrouped() {
    return ResponseViewHelper.groupAndSortResponses(this.allResponses, this.MERGE_TIME_DELTA)
  }
}
