
import { Component, Vue, Prop } from 'vue-property-decorator'

import VTooltipIconHelp from '@/components/global/VTooltipIconHelp.vue'
import VueGallery from 'vue-gallery'
import { FormElementPublicData, FormResponsePublicData } from '@/modules/form/typeFormModule'
import { BaseResposePublicData } from '@/modules/typeModules'
import { hasDBid } from '@/types/typeGeneral'
import FormHelper from '@/modules/form/formHelper'
import { downloadBlob } from '@/helpers/downloadHelper'
import { Fragment } from 'vue-fragment'

@Component({
  components: {
    VTooltipIconHelp,
    VueGallery,
    Fragment
  },
  props: {
    asid: {
      type: String,
      required: false,
      default: () => ''
    }
  }
})
export default class VResponseTimelineForm extends Vue {
  public i = Math.floor(Math.random() * 1000000)
  public isLoading = false

  @Prop({ type: Object, required: true })
  public elementPublicData!: FormElementPublicData

  @Prop({ type: Object, required: true })
  public rsp!: BaseResposePublicData & hasDBid


  public async onDownloadFile(file: { content: string, name: string }) {

    this.isLoading = true

    try {
      const response = await fetch(file.content)
      const blob = await response.blob()

      // create a new file from the blob object
      const fileData = new File([blob], `${file.name}`)

      // save the file
      downloadBlob(fileData, fileData.name)

      this.isLoading = false
    } catch (error) {
      this.isLoading = false
      console.error(error)
    }

  }


  //#region Form

  public isNonImageFile = (file: any) => !file.type.startsWith('image')
  public isImageFile = (file: any) => file.type.startsWith('image')

  public activeImageIndices: any = {} //{1:0,4:3}

  /**
   * get array of single question and response tuple for the provided responses
   */
  public getQuestionsAndResponses(rsp: FormResponsePublicData) {
    return FormHelper.getQuestionsAndResponsesAndHeaderFromQuestionContainer(rsp, this.elementPublicData.questions)
  }

  get FormHelper() {
    return FormHelper
  }

  //#endregion Form

  public created() {
    //
  }


}
