import { ModuleType } from '@/modules/typeModules'

import { RouteConfig, RawLocation } from 'vue-router'
import BaseModule from '@/modules/baseModule'

// import Backend_Module_File_List from './Backend_Module_File_List.vue'
import {
  FileModuleDB,
  FileElementDB,
  FileGroupDB,
  FileRessourceType,
  RequiredFilePrivileges
} from './typeFileModule'

import { TenantID } from '@/types/typeTenant'

import { locale } from '@/types/typeI18n'
import StorageManager from '@/helpers/StorageManager'
import { uniqueID } from '@/database/dbHelper'
import { defaultFileModuleDB, defaultFileElementDB, defaultFileGroupDB } from '@/database/databaseSchema'
import { objectID } from '@/types/typeGeneral'
import { UserPrivilegeIdDB } from '@/types/typeUser'

export default class FileModule extends BaseModule {
  public static type: ModuleType = 'File'
  public static displayName = 'File'
  public static color = '#38bc7d'
  public static description = 'Provide user viewable and downloadable files.'
  public static descriptionLong =
    'Upload the files you want to display to the Web-App users or include links to other websites. You can always set-up which documents/links are shown to which categories.'

  public static MAX_FILESIZE = 1024 * 1024 * 500 // 500MB

  public static authPrivileges: RequiredFilePrivileges = {
    r: ['file:read'],
    w: ['file:write'],
    view: ['file:view']
  }

  public static moduleDB: FileModuleDB = defaultFileModuleDB
  public static elementDB: FileElementDB = defaultFileElementDB
  public static groupDB: FileGroupDB = defaultFileGroupDB

  constructor() {
    super()
  }

  private static defaultLocale: locale = 'default' // needed for eslint to not remove locale type import
  public static async uploadAndAddFile(
    uploaderEmail: string,
    file: File,
    locale: locale = this.defaultLocale,
    tenantId: TenantID,
    authEmail: string,
    elementId: objectID,
    progress: (progress: number) => void,
    maxFileSize = 1024 * 1024,
    allowedFileType: string[] = []
  ) {
    const moduleElementDoc = await this.getElementDocDbReference(tenantId, elementId).get()
    if (!moduleElementDoc.exists) {
      throw `[8013] moduleElement does not exist (${moduleElementDoc.id})`
    }

    const moduleElement: FileElementDB & { id?: string } = {
      ...(moduleElementDoc.data() as FileElementDB),
      id: moduleElementDoc.id
    }

    // log all function parameters
    console.log('uploadAndAddFile:file', file)
    console.log('uploadAndAddFile:locale', locale)
    console.log('uploadAndAddFile:tenantId', tenantId)
    console.log('uploadAndAddFile:authEmail', authEmail)
    console.log('uploadAndAddFile:elementId', elementId)
    console.log('uploadAndAddFile:maxFileSize', maxFileSize)
    console.log('uploadAndAddFile:allowedFileType', allowedFileType)

    let fileType: FileRessourceType = 'other'

    if (file.type.includes('zip') || file.type.includes('7z')) fileType = 'archive'
    if (file.type.includes('pdf')) fileType = 'pdf'
    if (file.type.includes('image')) fileType = 'image'
    if (file.type.includes('video')) fileType = 'video'

    const fileName = `${uniqueID()}-${file.name}`

    if (moduleElement.public.ressourceType !== '' && moduleElement.public.ressourceType !== fileType) {
      throw `[8012] ressource type does not match (${moduleElement.public.ressourceType}, ${fileType})`
    }

    const downloadUrl = await StorageManager.uploadFile(
      uploaderEmail,
      file,
      fileName,
      this.getUploadPath(tenantId),
      progress,
      maxFileSize,
      allowedFileType,
      {},
      moduleElementDoc.ref.path
    )

    moduleElement.public.url.locales[locale] = downloadUrl

    if (!moduleElement.name) moduleElement.name = file.name
    if (!moduleElement.public.title.locales[locale]) moduleElement.public.title.locales[locale] = file.name
    moduleElement.public.ressourceType = fileType

    delete moduleElement.id
    await this.updateElement(tenantId, authEmail, moduleElementDoc.id, moduleElement)
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return [
      {
        to: { name: 'module-file-list' },
        displayName: this.displayName
      }
    ]
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'module/files',
        name: this.routeNameList,
        component: () => import('./Backend_Module_File_List.vue'),
        meta: {
          label: this.displayName,
          description: 'Manage your Files',
          breadcrumbs: false,
          isFullsize: true
        },
        children: [
          {
            path: 'groups/:groupID',
            name: this.routeNameGroup,
            component: () => import('./Backend_Module_File_Group_Single.vue'),
            meta: {
              label: 'File Widget',
              description: 'Manage your Widget',
              isFullsize: true
            },
            props: true
          },
          {
            path: ':id',
            name: this.routeNameElement,
            component: () => import('./Backend_Module_File_Single.vue'),
            meta: {
              label: this.displayName,
              description: 'Manage your File',
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          }
        ]
      }
    ]
  }
}
