import {
  collectionGroup,
  CollectionReference,
  query as fbQuery,
  Query,
  where,
  collection as fbCollection,
  limit,
  getDocs,
  Timestamp,
  orderBy,
  onSnapshot,
  getCountFromServer
} from 'firebase/firestore'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { DeepPartial, hasDBid } from '@/types/typeGeneral'
import { acessorObjectToString } from './dbHelper'
import indexes from '../../firebase/indexes/firestore.indexes.json'

import db from '@/firebase'

// define some default console color types
const error = (d: string) => d
const warning = (d: string) => d
const debug = (d: string) => d

const ENABLE_DEBUG_LOG = false

// logging
const logGroupCollapsed = (...args: any[]) => {
  if (ENABLE_DEBUG_LOG) {
    console.groupCollapsed(...args)
  }
}

const logGroup = (...title: any[]) => {
  if (ENABLE_DEBUG_LOG) {
    console.group(...title)
  }
}

const logGroupEnd = () => {
  if (ENABLE_DEBUG_LOG) {
    console.groupEnd()
  }
}

const logDebug = (...args: any[]) => {
  if (ENABLE_DEBUG_LOG) {
    console.debug(...args)
  }
}

const logInfo = (...args: any[]) => {
  if (ENABLE_DEBUG_LOG) {
    console.info(...args)
  }
}

const log = (...args: any[]) => {
  if (ENABLE_DEBUG_LOG) {
    console.log(...args)
  }
}

// schema for the firestore.indexes.json
type FirestoreIndex = {
  collectionGroup: string // Labeled "Collection ID" in the Firebase console
  queryScope: 'COLLECTION' | 'COLLECTION_GROUP' // One of "COLLECTION", "COLLECTION_GROUP"
  fields: Array<{
    fieldPath: string
    order?: 'ASCENDING' | 'DESCENDING' // One of "ASCENDING", "DESCENDING"; excludes arrayConfig property
    arrayConfig?: 'CONTAINS' // If this parameter used, must be "CONTAINS"; excludes order property
  }>
}

type FirestoreIndexFieldOverride = {
  collectionGroup: string // Labeled "Collection ID" in the Firebase console
  fieldPath: string
  ttl?: boolean // Set specified field to have TTL policy and be eligible for deletion
  indexes: Array<{
    // Use an empty array to disable indexes on this collectionGroup + fieldPath
    queryScope: 'COLLECTION' | 'COLLECTION_GROUP' // One of "COLLECTION", "COLLECTION_GROUP"
    order: 'ASCENDING' | 'DESCENDING' // One of "ASCENDING", "DESCENDING"; excludes arrayConfig property
    arrayConfig?: 'CONTAINS' // If this parameter used, must be "CONTAINS"; excludes order property
  }>
}

export type FirestoreIndexesConfig = {
  indexes: Array<FirestoreIndex>
  fieldOverrides: Array<FirestoreIndexFieldOverride>
}

export type FilterConfigNew<T = any> = {
  /** Field accessor for the filtered dataset (e.g. {_meta: {dateCreated: '2020-01-01'}}) */
  fieldAccessor: DeepPartial<T>
  /** Field path of the filtered dataset (e.g. '_meta.dateCreated') */
  // fieldPath: string
  /** Indicates if this is a mandatory filter which must be applied on each query */
  isMandatory: boolean
  /** Defines existing index groups for direct firebase query */
  indexGroups: Array<number>
} & (
  | {
      /**
       * Operation to check if a field value is within the range specified in "values":
       *
       * min_value <= fieldValue <= max_value
       */
      opStr: 'in-range'

      /**
       * Filter value is a "tuple" with exactly two items [min_value, max_value].
       * The expected field value is just one item of the same type.
       */
      values: [string, string] | [number, number] | [Date, Date]
      // REVIEW: this maybe better to avoid bugs
      //values: [string | number | Date, string | number | Date]
    }
  | {
      /** Relational and comparison operations */
      opStr: '<' | '<=' | '==' | '!=' | '>=' | '>'

      /**
       * Filter value consists of one item. The expected field value is also one
       * item of the same type.
       */
      values: [string | number | Date | null | boolean] | []
    }
  | {
      /** Operation to check if the field value is contained or not in the filter "values" array */
      opStr: 'in' | 'not-in'

      /**
       * Filter value is an array of items of a specific type. The expected field value
       * is one item of the same type.
       */
      //values: Array<string | number | Date>
      // REVIEW: would the type below not be better?
      values: Array<string | null> | Array<number | null> | Array<Date | null> | Array<boolean | null>
    }
  | {
      /** Operation to check if the filter value is contained in the field values array */
      opStr: 'array-contains'

      /**
       * Filter value is one item of a specific type. The expected field value is an array
       * of items of the same type.
       */
      values: [string | number | Date | boolean | null]
    }
  | {
      /**
       * Operation to check if any of the items contained in the filter "values" array is also
       * contained in the field values array
       */
      opStr: 'array-contains-any'

      /**
       * Filter value is an array of items of a specific type. The expected field value is also an
       * array of items of the same type.
       */
      values: Array<string> | Array<number> | Array<Date> | Array<boolean>
      // REVIEW: this maybe better to avoid something like that: [1, 'hello', new Date()]
      //values: Array<string | number | Date>
    }
)

export type SortConfig = {
  fieldPath: string
  directionStr: 'asc' | 'desc'
  indexGroups: Array<number>
}

export type SnapshotData<T> = T & hasDBid & { _local: { docPath: string } }
export type SnapshotDatas<T> = SnapshotData<T>[]

/**
 * This class is used to filter and sort data from a collection.
 * It is designed to be used with the onSnapshot() method of a collection reference.
 * The onSnapshot() method is called with a callback function which is called whenever new data is available.
 * The callback function is called with the filtered and sorted data.
 */
export class FilterUtil<T> {
  private static readonly FIREBASE_MAX_COUNT_ARRAY_ITEMS = 10
  private collectionRef: CollectionReference | Query
  private collection: string
  private referenceType: 'collectionGroup' | 'collection'
  private paginationLimit: number = 0
  private maxQueryCount: number
  private totalCount: number = -1

  // question mark means that it may be undefined
  private callback?: (data: SnapshotDatas<T>, totalCount: number) => void
  private unbindHandles: (() => void)[] = []

  private firestoreIndexesConfig: FirestoreIndexesConfig
  private collectionIndexes: FirestoreIndex[] = []

  private filterConfigs: FilterConfigNew<T>[] = []
  private sortConfigs: SortConfig[] = []
  private updateDataOnce: boolean = false

  private snapshotDataBuffer: SnapshotDatas<T>[] = []
  private dataComplete: boolean[] = []

  /**
   *
   * @param collectionRef Firestore collection reference
   * @param paginationLimit Limit of the result set for each query
   * @param maxQueryCount Maximum number of docs to query when counting result sets
   */
  constructor(collectionPath: string, referenceType: 'collectionGroup' | 'collection', maxQueryCount: number) {
    this.collection = collectionPath
    this.referenceType = referenceType
    this.collectionRef =
      this.referenceType === 'collectionGroup' ? collectionGroup(db, collectionPath) : fbCollection(db, collectionPath)
    this.maxQueryCount = maxQueryCount

    this.firestoreIndexesConfig = Object.assign(indexes)

    // import only indexes for the actual collection (group)
    // get the last part of the collection path (e.g. '1234' from 'users/1234')
    const collection = this.collection.split('/').pop()
    logDebug('Importing Firestore indexes for collection:', collection)
    this.collectionIndexes = this.firestoreIndexesConfig.indexes.filter((index) => {
      return (
        index.collectionGroup === collection &&
        index.queryScope === (this.referenceType === 'collectionGroup' ? 'COLLECTION_GROUP' : 'COLLECTION')
      )
    })
    logDebug(`Imported Firestore indexes (count: ${this.collectionIndexes.length})`)
  }

  public async updateConfig(
    filters: FilterConfigNew<T>[],
    sortConfigs: SortConfig[],
    paginationLimit: number,
    once: boolean = false
  ) {
    log(debug('updateConfig()'))

    // unsubscribe if handles exist
    this.unbindHandles.forEach((handle) => handle())
    this.unbindHandles = []

    // reset data
    this.snapshotDataBuffer = []
    this.dataComplete = []
    this.totalCount = -1

    // update configs
    this.filterConfigs = filters
    this.sortConfigs = sortConfigs
    this.paginationLimit = paginationLimit
    this.updateDataOnce = once

    this.initIndexGroups()

    return this.updateData()
  }

  public onSnapshot(callback: (data: SnapshotDatas<T>, totalCount: number) => void): SnapshotUnbindHandle {
    // call this callback whenever new data is available
    this.callback = callback

    return () => {
      // unbind all
      log(`Unbind called for collection path: '${this.collectionRef}'`)
      this.unbindHandles.forEach((handle) => handle())
    }
  }

  public async testLocalFilter(filterConfig: FilterConfigNew, dataCount: number) {
    // get data
    log('Get snapshot (limit=%d)'), dataCount

    const dbQuery = fbQuery(this.collectionRef, limit(dataCount))
    const snapshot = await getDocs(dbQuery)

    // convert data to generic type
    let data: SnapshotDatas<T> = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as T),
      _local: { docPath: doc.ref.path }
    }))

    // show data
    this.callback?.(data, data.length)

    // apply the filter
    log('Apply local filter')
    log(filterConfig)
    data = this.applyFilterConfig(data, filterConfig)

    // show data
    this.callback?.(data, data.length)
  }

  private hasMatchingIndex(
    firestoreIndexes: Array<FirestoreIndex>,
    filterConfigs: Array<FilterConfigNew>,
    sortConfigs: Array<SortConfig>
  ): boolean {
    // We want to find an index in the indexes array which matches the given filter and sort configs.
    // Filter configs are used for the "where" clause and sort configs for the "orderBy" clause.
    // For a filter config we look for an index which contains the 'fieldPath' while the 'order' is not important.
    // For a filter config with an array operation (e.g. 'array-contains') we look for an index which contains the
    // 'fieldPath' and the 'arrayConfig'.
    // For a sort config we look for an index which contains the 'fieldPath' and the 'order'.
    // The order of the filter and sort configs is not important.
    // The index must only contain all field paths from the filter and sort configs.
    // TODO: But the filter configs may contain a field path multiple times with different operations (e.g. '>', '<').
    //       In this case the index must contain the field path only once with 'ascending' or 'descending' order.
    // If there is also a sort config for the same field path, the 'order' in the index must match the sort config.

    logGroupCollapsed('Searching a matching Firestore index')

    logGroup('Configs:')
    this.printFilterConfigs(filterConfigs)
    this.printSortConfigs(sortConfigs)
    logGroupEnd() // configs

    // get all field paths from filter and sort configs...
    const filterAndSortFieldPaths = filterConfigs.map((filterConfig) =>
      acessorObjectToString(filterConfig.fieldAccessor)
    )
    sortConfigs.forEach((sortConfig) => {
      if (!filterAndSortFieldPaths.includes(sortConfig.fieldPath)) {
        filterAndSortFieldPaths.push(sortConfig.fieldPath)
      }
    })

    logDebug('Searching index with fields:', filterAndSortFieldPaths)

    // ... and filter the Firestore indexes which contain only the required field paths
    let matchingIndexes = firestoreIndexes.filter((fIndex) => {
      // check if only the filter and sort field paths are contained in the index
      const indexFieldPaths = fIndex.fields.map((field) => field.fieldPath)
      return (
        filterAndSortFieldPaths.every((fieldPath) => indexFieldPaths.includes(fieldPath)) &&
        indexFieldPaths.every((fieldPath) => filterAndSortFieldPaths.includes(fieldPath))
      )
    })

    logDebug('Indexes with same fields:', matchingIndexes)

    // finally check if there is an index which contains all required field paths with the correct order
    matchingIndexes = matchingIndexes.filter((fIndex) => {
      logGroupCollapsed('Checking index:', fIndex)

      // use copies of the filter/sort configs and fields (from the Firestore index)
      // to avoid side effects while reducing the arrays by the matching fields
      let remainingFilterConfigs = [...filterConfigs]
      let remainingSortConfigs = [...sortConfigs]
      const remainingFields = [...fIndex.fields]

      // 1. remove all filter configs (but not the ones with an array operation) which are also contained in the sort configs
      remainingFilterConfigs = remainingFilterConfigs.filter((filterConfig) => {
        return (
          ['array-contains', 'array-contains-any'].includes(filterConfig.opStr) ||
          !remainingSortConfigs.some((sortConfig) => {
            return sortConfig.fieldPath === acessorObjectToString(filterConfig.fieldAccessor)
          })
        )
      })

      logGroup('Reduced filter configs (remove all filter configs which are also contained in the sort configs)')
      this.printFilterConfigs(remainingFilterConfigs)
      logGroupEnd()

      // 2. remove all filter configs which have a matching firestore index
      // a) get the array indices of the matching filter configs
      const filterConfigArrayIndicesToRemove: number[] = []
      remainingFilterConfigs.forEach((filterConfig, idxFilterConfig) => {
        const idxField = remainingFields.findIndex((field) => {
          // check if index contains the field path with the correct order or array config
          // I. field path
          if (field.fieldPath !== acessorObjectToString(filterConfig.fieldAccessor)) {
            return false
          }
          // II. order
          if (filterConfig.opStr !== 'array-contains' && filterConfig.opStr !== 'array-contains-any') {
            if (field.order !== 'ASCENDING' && field.order !== 'DESCENDING') {
              return false
            }
          }
          // III. array config
          if (filterConfig.opStr === 'array-contains' || filterConfig.opStr === 'array-contains-any') {
            if (field.arrayConfig !== 'CONTAINS') {
              return false
            }
          }
          return true
        })
        // push array indices of filter config to remove them later and remove matching field
        if (idxField !== -1) {
          filterConfigArrayIndicesToRemove.push(idxFilterConfig)
          remainingFields.splice(idxField, 1)
        }
      })

      // b) remove the matching filter configs
      remainingFilterConfigs = remainingFilterConfigs.filter((filterConfig, i) => {
        return !filterConfigArrayIndicesToRemove.includes(i)
      })

      logGroup('Reduced filter configs (remove all filter configs which have a matching firestore index)')
      this.printFilterConfigs(remainingFilterConfigs)
      logGroupEnd()

      // 3. remove all sort configs which have a matching firestore index
      // a) get the array indices of the matching sort configs
      const sortConfigArrayIndicesToRemove: number[] = []
      remainingSortConfigs.forEach((sortConfig, idxSortConfig) => {
        const idxField = remainingFields.findIndex(
          (field) =>
            field.fieldPath === sortConfig.fieldPath &&
            field.order === (sortConfig.directionStr === 'asc' ? 'ASCENDING' : 'DESCENDING')
        )

        if (idxField !== -1) {
          sortConfigArrayIndicesToRemove.push(idxSortConfig)
          remainingFields.splice(idxField, 1)
        }
      })

      // b) remove the matching sort configs
      remainingSortConfigs = remainingSortConfigs.filter((sortConfig, i) => {
        return !sortConfigArrayIndicesToRemove.includes(i)
      })

      logGroup('Reduced sort configs (remove all sort configs which have a matching firestore index)')
      this.printSortConfigs(remainingSortConfigs)
      logGroupEnd()

      logGroupEnd() // checking index

      // 4. check if all filter and sort configs are matched
      return remainingFilterConfigs.length === 0 && remainingSortConfigs.length === 0
    })

    logDebug('Matching indexes:', matchingIndexes)

    logGroupEnd() // searching a matching Firestore index

    return matchingIndexes.length > 0
  }

  // for debug purposes
  private printSortConfigs(sortConfigs: SortConfig[]) {
    logGroup(`Sort configs [${sortConfigs.length}]`)
    for (let i = 0; i < sortConfigs.length; i++) {
      logDebug(`[${i}]: ${sortConfigs[i].fieldPath} ${sortConfigs[i].directionStr}`)
    }
    logGroupEnd()
  }

  // for debug purposes
  private printFilterConfigs(filterConfigs: FilterConfigNew<any>[]) {
    logGroup(`Filter configs [${filterConfigs.length}]`)
    for (let i = 0; i < filterConfigs.length; i++) {
      logDebug(
        `[${i}]: ${acessorObjectToString(filterConfigs[i].fieldAccessor)} ${filterConfigs[i].opStr} ${
          filterConfigs[i].values
        }`
      )
    }
    logGroupEnd()
  }

  private initIndexGroups() {
    logGroup('Initialize index groups')

    // reset index groups
    for (const filterConfig of this.filterConfigs) {
      filterConfig.indexGroups = []
    }

    // try to compose index groups (0: all filter/sort configs)
    let indexGroupNo = 0

    // check if index exists for all filter configs together
    if (this.hasMatchingIndex(this.collectionIndexes, this.filterConfigs, this.sortConfigs)) {
      // add index group to filter/sort configs
      for (const filterConfig of this.filterConfigs) {
        filterConfig.indexGroups.push(indexGroupNo)
      }
      for (const sortConfig of this.sortConfigs) {
        sortConfig.indexGroups.push(indexGroupNo)
      }
      logInfo(`Found matching Firestore index for index group ${indexGroupNo} (all filter/sort configs)`)
      // no need for other index groups so return
      logGroupEnd() // initialize index groups
      return
    }

    // combine equality operations ('==', 'in')
    indexGroupNo = 1
    const filterGroupEquality: FilterConfigNew[] = []
    for (const filterConfig of this.filterConfigs) {
      switch (filterConfig.opStr) {
        // TODO: is it possible to combine other operations also without composite index?
        case '==':
        case 'in':
          filterConfig.indexGroups.push(indexGroupNo)
          filterGroupEquality.push(filterConfig)
          break
      }
    }

    // try to include sorting to filter group "equality"
    if (this.hasMatchingIndex(this.collectionIndexes, filterGroupEquality, [this.sortConfigs[0]])) {
      // add indexGroup to sortConfig
      this.sortConfigs[0].indexGroups.push(indexGroupNo)
    } else {
      // check if index merging is possible
      let indexMergingPossible = true
      for (const filterConfig of filterGroupEquality) {
        if (!this.hasMatchingIndex(this.collectionIndexes, [filterConfig], [this.sortConfigs[0]])) {
          indexMergingPossible = false
          break
        }
      }
      if (indexMergingPossible) {
        // add indexGroup to sortConfig
        this.sortConfigs[0].indexGroups.push(indexGroupNo)
      }
    }

    // build all possible index groups
    indexGroupNo = 2

    // map filter and sort config indices to flat indices (used later for the permutations)
    const indexMap: Map<number, ['filterConfig' | 'sortConfig', number]> = new Map()
    let flatIndex = 0
    for (let i = 0; i < this.filterConfigs.length; i++) {
      indexMap.set(flatIndex++, ['filterConfig', i])
    }
    // for (let i = 0; i < this.sortConfigs.length; i++) {
    //   indexMap.set(flatIndex++, ['sortConfig', i])
    // }
    // allow only first sort config
    indexMap.set(flatIndex++, ['sortConfig', 0])
    log(debug('Index map:'), indexMap)

    // get all possible combinations of the numbers (with each unique numbers and without order)
    let recursionCount = 0
    const permutations: number[][] = []

    // recursive function for generating all permutations
    function generatePermutations(base: number[], startIndex: number, lastIndex: number) {
      recursionCount++
      // prevent infinite loop (just in case)
      if (recursionCount > 1000) return

      for (let i = startIndex; i <= lastIndex; i++) {
        const p = Object.assign([], base) // copy array
        p.push(i)
        permutations.push(p)
        log(`[${recursionCount}]: ${p}`)
        if (i < lastIndex) {
          generatePermutations(p, i + 1, lastIndex)
        }
      }
    }

    // start with indices 0 and 1 (first combo is [0, 1]) and expand towards the last index
    generatePermutations([0], 1, indexMap.size - 1)

    // finally compose all possible filter/sort combos and build group if a Firestore index exists
    for (const permutation of permutations) {
      const tmpFilterConfigs: FilterConfigNew[] = []
      const tmpSortConfigs: SortConfig[] = []
      const tmpFilterIndices: number[] = []
      const tmpSortIndices: number[] = []
      for (const flatIndex of permutation) {
        const mappedConfig = indexMap.get(flatIndex)
        if (mappedConfig !== undefined) {
          if (mappedConfig[0] === 'filterConfig') {
            tmpFilterConfigs.push(this.filterConfigs[mappedConfig[1]])
            tmpFilterIndices.push(mappedConfig[1])
          } else if (mappedConfig[0] === 'sortConfig') {
            tmpSortConfigs.push(this.sortConfigs[mappedConfig[1]])
            tmpSortIndices.push(mappedConfig[1])
          }
        }
      }
      if (this.hasMatchingIndex(this.collectionIndexes, tmpFilterConfigs, tmpSortConfigs)) {
        logInfo(`Found matching Firestore index for index group ${indexGroupNo}`)

        // add index group to filter/sort configs
        for (const filterIndex of tmpFilterIndices) {
          this.filterConfigs[filterIndex].indexGroups.push(indexGroupNo)
        }
        for (const sortIndex of tmpSortIndices) {
          this.sortConfigs[sortIndex].indexGroups.push(indexGroupNo)
        }

        indexGroupNo++
      }
    }

    logGroupEnd() // initialize index groups
  }

  private applyFilterConfig(data: SnapshotDatas<T>, filterConfig: FilterConfigNew<T>) {
    log(debug('applyFilterConfig()'))

    const fieldPath = acessorObjectToString(filterConfig.fieldAccessor)

    // split path into field components (for nested structures)
    const fieldComponents = fieldPath.split('.')

    return data.filter((item) => {
      const filterValue: any = filterConfig.values
      let fieldValue: any = item

      // reach the specified field value iteratively
      for (const fieldComponent of fieldComponents) {
        fieldValue = fieldValue[fieldComponent]
        // check if field path is valid
        if (fieldValue === undefined) {
          throw new Error(`Undefined field "${fieldComponent}" in fieldPath "${fieldPath}"`)
        }
      }

      // assert that filter value is valid
      switch (filterConfig.opStr) {
        case 'in-range':
          // check if range is valid
          if (filterConfig.values[0] > filterConfig.values[1]) {
            throw new Error(
              `Invalid range in filter value. Lower range value is greater than the upper range value: ${filterConfig.values[0]} > ${filterConfig.values[1]}`
            )
          }
          break
        case 'in':
        case 'not-in':
        case 'array-contains-any':
          // filter value is an array by definition => nothing to do
          break
      }

      // assert that field value is valid
      switch (filterConfig.opStr) {
        case 'array-contains':
        case 'array-contains-any':
          if (!Array.isArray(fieldValue)) {
            throw new Error(
              `Field value is not an array and thus not compatible with operation '${filterConfig.opStr}' (fieldPath: '${fieldPath}')`
            )
          }
          break
        default:
          if (Array.isArray(fieldValue)) {
            throw new Error(
              `Field value is an array and thus not compatible with operation '${filterConfig.opStr}' (fieldPath: '${fieldPath}')`
            )
          }
      }

      // check if types can be compared
      if (
        !(Array.isArray(filterValue) && filterValue.length == 0) &&
        !(Array.isArray(fieldValue) && fieldValue.length == 0)
      ) {
        // get actual field/filter values to check types => if Array get first item
        const actualFieldValue = Array.isArray(fieldValue) ? fieldValue[0] : fieldValue
        const actualFilterValue = Array.isArray(filterValue) ? filterValue[0] : filterValue

        if (
          actualFieldValue !== undefined &&
          actualFieldValue !== null &&
          actualFilterValue !== undefined &&
          actualFilterValue !== null
        ) {
          // check if types match between filter value and field value
          if (typeof actualFilterValue !== typeof actualFieldValue) {
            throw new Error(
              `Type mismatch between filter and field value: ${
                actualFilterValue instanceof Date ? 'Date' : typeof actualFilterValue
              } != ${actualFieldValue instanceof Timestamp ? 'Timestamp' : typeof actualFieldValue}`
            )
          }

          // special case: Date/Timestamp (will not catched above since both of type 'object'!)
          if (actualFilterValue instanceof Date && !(actualFieldValue instanceof Timestamp)) {
            throw new Error(
              'Type mismatch between filter and field value: filter value is Date, but field value is not Timestamp'
            )
          } else if (actualFieldValue instanceof Timestamp && !(actualFilterValue instanceof Date)) {
            throw new Error(
              'Type mismatch between filter and field value: field value is Timestamp, but filter value is not Date'
            )
          }
        } else {
          // not able to check types => do nothing
        }

        // convert Date to Timestamp for comparison
        if (actualFilterValue instanceof Date) {
          log(debug('Filter value is Date => convert to Timestamp'))
          // filter value could be an "Array of Date" => cast all items
          for (let i = 0; i < filterValue.length; i++) {
            filterValue[i] = Timestamp.fromDate(filterValue[i])
            log(debug(`[${i}]: Converted filter value: `), filterValue[i])
          }
        }
      }

      // finally use the provided operation string to compare the field value to the filter value
      switch (filterConfig.opStr) {
        case '==':
          // special case: filter value may be an empty array
          if (Array.isArray(filterValue) && filterValue.length == 0)
            return Array.isArray(fieldValue) && fieldValue.length == 0
          else return fieldValue === filterValue[0]
        case '!=':
          // special case: filter value may be an empty array
          if (Array.isArray(filterValue) && filterValue.length == 0)
            return Array.isArray(fieldValue) && fieldValue.length == 0
          else return fieldValue !== filterValue[0]
        case '<':
          return fieldValue < filterValue[0]
        case '<=':
          return fieldValue <= filterValue[0]
        case '>':
          return fieldValue > filterValue[0]
        case '>=':
          return fieldValue >= filterValue[0]
        case 'in':
          return filterValue.includes(fieldValue)
        case 'not-in':
          return !filterValue.includes(fieldValue)
        case 'array-contains':
          return fieldValue.includes(filterValue[0])
        case 'array-contains-any':
          return filterValue.some((item: any) => fieldValue.includes(item))
        case 'in-range':
          return fieldValue >= filterValue[0] && fieldValue <= filterValue[1]
        default:
          // cast filterConfig to any to be able to handle the 'default' case (to catch possible bug)
          throw new Error(`Unsupported filter operation string: '${(filterConfig as any).opStr}'`)
      }
    })
  }

  private addSortConfigsToQuery(baseQuery: CollectionReference | Query, sortConfigs: SortConfig[]): Query {
    let query = baseQuery
    for (const sortConfig of this.sortConfigs) {
      query = fbQuery(query, orderBy(sortConfig.fieldPath, sortConfig.directionStr))
    }
    return query
  }

  private composeFirebaseQuery(baseQuery: CollectionReference | Query, filterConfig: FilterConfigNew<T>): Query[] {
    log(debug('composeFirebaseQuery()'))

    // error handling
    switch (filterConfig.opStr) {
      case 'in-range':
        // check if range is valid
        if (filterConfig.values[0] > filterConfig.values[1]) {
          throw new Error(
            `Invalid range in filter value. Lower range value is greater than the upper range value: ${filterConfig.values[0]} > ${filterConfig.values[1]}`
          )
        } else if (filterConfig.values[0] == filterConfig.values[1]) {
          // map it to "==" operation
          filterConfig = {
            fieldAccessor: filterConfig.fieldAccessor,
            isMandatory: filterConfig.isMandatory,
            indexGroups: filterConfig.indexGroups,
            opStr: '==',
            values: [filterConfig.values[0]]
          }
          log('The "in-range" filter is equal to a "==" filter. Using converted filter config:')
          log(filterConfig)
        }
        break
      case 'in':
      case 'not-in':
      case 'array-contains-any':
        // assuming that filter value is an array => check if empty
        if (filterConfig.values.length === 0) {
          throw new Error(`Empty array in filter value (opStr: '${filterConfig.opStr}')`)
        }
        break
    }

    // TODO: check type of field value? (e.g. if filter value is a string, field value must also be a string)

    const isDocumentID = 'id' in filterConfig.fieldAccessor
    const fieldPath = isDocumentID
      ? '__name__' // FieldPath.documentId()
      : acessorObjectToString(filterConfig.fieldAccessor)

    // if (typeof fieldPath === 'string' && Array.isArray(accessorStringToValue(filterConfig.fieldAccessor, fieldPath))) {
    //   if (filterConfig.values[0] === '_empty_') filterConfig.values = []
    // } else {
    //   if (filterConfig.values[0] === '_empty_') filterConfig.values = [null]
    // }

    log(
      debug(
        `Field path: ${fieldPath}, op: ${filterConfig.opStr}, values: ${filterConfig.values}, isArray: ${Array.isArray(
          filterConfig.values
        )}, length: ${filterConfig.values.length}`
      )
    )

    switch (filterConfig.opStr) {
      case '==':
      case '!=':
      case '<':
      case '<=':
      case '>':
      case '>=':
      case 'array-contains':
        // special case for empty array
        if (filterConfig.values[0] === null && filterConfig.opStr === 'array-contains') {
          return [fbQuery(baseQuery, where(fieldPath, '==', []))]
        }

        return [
          fbQuery(
            baseQuery,
            where(fieldPath, filterConfig.opStr, filterConfig.values.length > 0 ? filterConfig.values[0] : [])
          )
        ]
      case 'array-contains-any':
      case 'in':
      case 'not-in': {
        const queries: Query[] = []
        // if the values contains Null, filter them out and add a separate query for null values
        if ((filterConfig.values as any[]).includes(null)) {
          // filter out null
          filterConfig.values = (filterConfig.values as any[]).filter((v) => v !== null)
          queries.push(
            fbQuery(baseQuery, where(fieldPath, '==', filterConfig.opStr === 'array-contains-any' ? [] : null))
          )
        }

        if (filterConfig.values.length > FilterUtil.FIREBASE_MAX_COUNT_ARRAY_ITEMS) {
          // check firebase limitation
          log(`Splitting query to bypass the limit of ${FilterUtil.FIREBASE_MAX_COUNT_ARRAY_ITEMS} items`)
          for (let i = 0; i < filterConfig.values.length; i += FilterUtil.FIREBASE_MAX_COUNT_ARRAY_ITEMS) {
            const endIndex = Math.min(i + FilterUtil.FIREBASE_MAX_COUNT_ARRAY_ITEMS, filterConfig.values.length)
            const values = filterConfig.values.slice(i, endIndex)
            queries.push(fbQuery(baseQuery, where(fieldPath, filterConfig.opStr, values)))
            logGroup(debug(`- [${i},${endIndex}):`))
            log(`fieldAccessor: ${filterConfig.fieldAccessor}`)
            log(`fieldPath: ${fieldPath}`)
            log(`opStr:     ${filterConfig.opStr}`)
            log(`values:    ${values}`)
            logGroupEnd()
          }
        } else {
          queries.push(fbQuery(baseQuery, where(fieldPath, filterConfig.opStr, filterConfig.values)))
        }
        return queries
      }
      case 'in-range':
        return [
          fbQuery(
            baseQuery,
            where(fieldPath, '>=', filterConfig.values[0]),
            where(fieldPath, '<=', filterConfig.values[1])
          )
        ]
      default:
        // cast filterConfig to any to be able to handle the 'default' case (to catch possible bug)
        throw new Error(`Unsupported filter op: ${(filterConfig as any).opStr}`)
    }
  }

  private async getQueryCounts(queries: Query[][]): Promise<number[]> {
    // push snapshots as promise to parallelize the count get function call
    type SnapshotPromiseType = Promise<{
      count: number
    }>

    const snapshotPromises: SnapshotPromiseType[][] = []
    for (let i = 0; i < queries.length; i++) {
      snapshotPromises.push(
        queries[i].map(async (query) => {
          // const rsp = await getDocs(fbQuery(query, limit(this.maxQueryCount + 1)))
          const countSnap = await getCountFromServer(fbQuery(query, limit(this.maxQueryCount + 1)))
          return {
            // count: rsp.size
            count: countSnap.data().count
          }
        })
      )
    }

    logInfo('Wait for the count get queries to finish...')
    const snapshots = await Promise.all(snapshotPromises.map((p) => Promise.all(p)))

    // sum up the counts for each query group
    const counts: number[] = []
    for (let i = 0; i < snapshots.length; i++) {
      const allCounts = snapshots[i].map((s) => s.count)
      const totalCount = allCounts.reduce((sum, current) => sum + current)
      counts.push(totalCount)
    }

    return counts
  }

  private sortData(data: any[]) {
    logDebug('sortData()')

    if (this.sortConfigs.length == 0) {
      return
    }

    // DEBUG
    log(this.sortConfigs)

    // sort for all configs
    data.sort((item1: any, item2: any) => {
      let result: number = 0

      let fieldValue1: any
      let fieldValue2: any

      // check for all sort configs (with descending priority given by array order)
      for (const sortConfig of this.sortConfigs) {
        // split path into field components (for nested structures)
        const fieldComponents = sortConfig.fieldPath.split('.')

        // reach the specified field value iteratively
        fieldValue1 = item1
        fieldValue2 = item2

        for (const fieldComponent of fieldComponents) {
          fieldValue1 = fieldValue1[fieldComponent]
          fieldValue2 = fieldValue2[fieldComponent]
        }

        // check if field values are different (assuming default type: 'ascending')
        if (typeof fieldValue1 === 'string' && typeof fieldValue2 === 'string') {
          // use localeCompare for strings
          result = fieldValue1.localeCompare(fieldValue2, undefined, { numeric: true, sensitivity: 'base' })
          //log('string comparison: %s <=> %s = %d', fieldValue1, fieldValue2, result)
        } else if (
          typeof fieldValue1 === 'object' &&
          typeof fieldValue2 === 'object' &&
          fieldValue1 !== null &&
          fieldValue2 !== null &&
          'seconds' in fieldValue1 &&
          'nanoseconds' in fieldValue1 &&
          'seconds' in fieldValue2 &&
          'nanoseconds' in fieldValue2
        ) {
          // use Timestamp comparison for Timestamps
          if (fieldValue1.seconds > fieldValue2.seconds) result = 1
          else if (fieldValue1.seconds < fieldValue2.seconds) result = -1
          else if (fieldValue1.nanoseconds > fieldValue2.nanoseconds) result = 1
          else if (fieldValue1.nanoseconds < fieldValue2.nanoseconds) result = -1
          else result = 0
          //log('Timestamp comparison: %s <=> %s = %d', fieldValue1, fieldValue2, result)
        } else {
          const isNil = (value: any) => value === null || value === undefined

          // sort null values to the bottom when in asc order
          // and to the top when in desc order
          if (!isNil(fieldValue2) && isNil(fieldValue1)) result = -1
          else if (!isNil(fieldValue1) && isNil(fieldValue2)) result = 1
          else if (fieldValue1 > fieldValue2) result = 1
          else if (fieldValue1 < fieldValue2) result = -1
          else result = 0
          //log('non-string comparison: %s <=> %s = %d', fieldValue1, fieldValue2, result)
        }

        // if field values are equal => check next condition
        if (result === 0) continue

        // invert result if required
        if (sortConfig.directionStr === 'desc') {
          result *= -1
        }

        // if the result is clear (!=0) return
        return result
      }

      // if no result was returned within the loop, all checked fields should be equal
      return 0
    })
  }

  private async updateData() {
    logGroup('Update data')

    let directQuery = <Query>{}
    let mandatoryQuery = <Query>{}
    let minCustomQuery: Query[] = []

    // merge type for cases where multiple queries are required which must be merged locally
    // (i.e. for arrays with >10 items as filter value)
    // 'or' (default):  partial data will be joined as with a logical 'or' (avoiding duplicates)
    // 'and:            partial data will be joined as with a logical 'and' (must satisfy all subqueries)
    let mergeType: 'or' | 'and' = 'or'

    // callback function for the post processing in case of local filtering/sorting
    let localProcessingFn: (data: SnapshotDatas<T>) => SnapshotDatas<T>

    // special case: field 'id'
    for (const sortConfig of this.sortConfigs) {
      if (sortConfig.fieldPath === 'id') {
        log(debug('Sort by field "id" => override by "__name__"'))
        sortConfig.fieldPath = '__name__'
        log(sortConfig)
      }
    }

    // 1. only mandatory filters or no filters at all
    //   => no local filtering!
    //   => direct firebase query (check all limitations!)
    //   => limit query size (query.limit())
    //   => include sorting in firebase query (query.orderBy()), sorting only by one field possible(?)
    // 2. mandatory and custom filters (it should also work without any mandatory filters)
    //   => add all mandatory filters to 'mandatoryQuery' if present
    //   => find query with lowest count for custom filters (limit queries to MAX_QUERY_COUNT)
    //   => error if MAX_QUERY_COUNT exceeded
    //   => get data for query with lowest count and do filtering/sorting locally

    // check if mandatory and custom filters are present and also compose
    // the mandatory query consisting of the mandatory filters
    let hasMandatoryFilter = false
    let hasCustomFilter = false
    const mandatoryFilters: FilterConfigNew<T>[] = []
    const customFilters: FilterConfigNew<T>[] = []

    logGroup('Split filter configs (mandatory/custom)')
    for (let i = 0; i < this.filterConfigs.length; i++) {
      if (this.filterConfigs[i].isMandatory) {
        let tempQuery: Query[] = []
        // logging
        if (!hasMandatoryFilter) {
          log('Init firebase query from mandatory filter')
          // compose query from collection reference
          tempQuery = this.composeFirebaseQuery(this.collectionRef, this.filterConfigs[i])
        } else {
          log('Add mandatory filter')
          // compose query from existing query
          tempQuery = this.composeFirebaseQuery(mandatoryQuery, this.filterConfigs[i])
        }

        if (tempQuery.length > 1)
          throw new Error(
            `Multiple queries for mandatory filter are not allowed. Dont use "in" or "not-in" with >${FilterUtil.FIREBASE_MAX_COUNT_ARRAY_ITEMS} items`
          )

        mandatoryQuery = tempQuery[0]

        log(this.filterConfigs[i])

        mandatoryFilters.push(this.filterConfigs[i])
        hasMandatoryFilter = true
      } else {
        hasCustomFilter = true
        customFilters.push(this.filterConfigs[i])
      }
    }

    logGroup('Stats')

    logGroup(`Mandatory filters [${mandatoryFilters.length}]`)
    for (let i = 0; i < mandatoryFilters.length; i++) {
      const filterConfig = mandatoryFilters[i]
      logDebug(
        `[${i}]: ${acessorObjectToString(filterConfig.fieldAccessor)} ${filterConfig.opStr} ${filterConfig.values}`
      )
    }
    logGroupEnd()

    logGroup(`Custom filters [${customFilters.length}]`)
    for (let i = 0; i < customFilters.length; i++) {
      const filterConfig = customFilters[i]
      logDebug(
        `[${i}]: ${acessorObjectToString(filterConfig.fieldAccessor)} ${filterConfig.opStr} ${filterConfig.values}`
      )
    }
    logGroupEnd() // custom filters
    logGroupEnd() // stats
    logGroupEnd() // split filter configs

    // TODO: check if mandatory query has some data (count>0)?

    // process custom filters
    if (hasCustomFilter) {
      // first check if indexGroups exist for a direct firebase query

      // array of filter groups consisting of filterConfigs which can be queried together (same indexGroup)
      const filterGroups: FilterConfigNew<T>[][] = []
      // start with all custom filterConfigs and reduce after each composed filterGroup
      let tempFilterConfigs = customFilters

      logGroup('Search for composite filter groups (via indexGroups)')

      while (tempFilterConfigs.length > 0) {
        // map indexGroup number to filterConfig indices containing that indexGroup
        const indexGroupMap = new Map<number, number[]>()
        // sum of filterConfig indices for one indexGroup
        let bestRanking = 0
        // indexGroup with best ranking for building a query group of filterConfigs
        let selectedIndexGroup = -1
        // indices of the filterConfigs containing the selected indexGroup
        let selectedFilterIndices: number[] = []

        // map indexGroups and select the one with the best ranking
        for (let filterIndex = 0; filterIndex < tempFilterConfigs.length; filterIndex++) {
          const tempFilterConfig = tempFilterConfigs[filterIndex]
          // first check Firebase limitation
          switch (tempFilterConfig.opStr) {
            case 'in':
            case 'not-in':
            case 'array-contains-any':
              if (tempFilterConfig.values.length > 10) {
                // this is not supported by Firebase thus we can't use it for the direct query with a filter group
                log(debug(`Firebase query for opStr '${tempFilterConfig.opStr}' with >10 values not possible (skip)`))
                continue
              }
          }
          // add index of filterConfig to the map for each indexGroup
          for (const indexGroup of tempFilterConfig.indexGroups) {
            // add new entry for indexGroup if necessary
            if (!indexGroupMap.has(indexGroup)) {
              indexGroupMap.set(indexGroup, [])
            }
            // get array containing the indices
            const tmpFilterIndices = indexGroupMap.get(indexGroup)
            if (tmpFilterIndices !== undefined) {
              tmpFilterIndices.push(filterIndex)
              // update ranking and selection
              if (tmpFilterIndices.length > bestRanking) {
                bestRanking = tmpFilterIndices.length
                selectedIndexGroup = indexGroup
                selectedFilterIndices = tmpFilterIndices
              }
            }
          }
        }

        // Debug
        logGroup('Index map')
        log(indexGroupMap)
        log('Best ranking:', bestRanking)
        log('Selected index group:', selectedIndexGroup)
        log('Selected indices:', selectedFilterIndices)
        logGroupEnd()

        if (indexGroupMap.size === 0) {
          // if there are no index groups => just use the filterConfigs as is (one filter per filterGroup)
          for (const filterConfig of tempFilterConfigs) {
            filterGroups.push([filterConfig])
          }
          // nothing to do anymore => exit loop
          break
        } else {
          // push selected filters to the filter group
          filterGroups.push(tempFilterConfigs.filter((filterConfig, index) => selectedFilterIndices.includes(index)))
          // reduce the filterConfigs for next iteration
          tempFilterConfigs = tempFilterConfigs.filter((filterConfig, index) => !selectedFilterIndices.includes(index))

          logDebug('Reduced filter configs:')
          logDebug(tempFilterConfigs)
        }
      }

      // Debug
      logDebug('Filter groups:')
      logDebug(filterGroups)

      logGroupEnd() // search for composite filter groups

      // get all individual query counts (limit to maxQueryCount)
      logGroup(`Get query counts for each filter group (maxQueryCount: ${this.maxQueryCount})`)

      const customQueries: Query[][] = []
      for (let groupIndex = 0; groupIndex < filterGroups.length; groupIndex++) {
        const filterGroup = filterGroups[groupIndex]
        logGroup(`Composing query from filterGroup[${groupIndex}]`)

        // compose the first query/queries from the appropriate 'baseQuery'
        logInfo('Add filter to query:')
        logInfo(filterGroup[0])
        let tmpQueries = hasMandatoryFilter
          ? this.composeFirebaseQuery(mandatoryQuery, filterGroup[0])
          : this.composeFirebaseQuery(this.collectionRef, filterGroup[0])

        // only a filterGroup composed via indexGroups may contain multiple filters...
        for (let filterIndex = 1; filterIndex < filterGroup.length; filterIndex++) {
          // ... thus assume only one query per filter!
          // (multiple queries are only for a workaround of Firebase limitation of >10 values
          //  and not possible in filterGroups)
          log('Add filter to query:')
          log(filterGroup[filterIndex])
          tmpQueries = this.composeFirebaseQuery(tmpQueries[0], filterGroup[filterIndex])
        }

        log(debug('Debug: push query'))
        customQueries.push(tmpQueries)

        logGroupEnd() // Composing query ...
      }

      logGroupEnd() // Get query counts

      // logInfo('Wait for the count get queries to finish...')
      // const snapshots = await Promise.all(snapshotPromises.map((p) => Promise.all(p)))
      const counts = await this.getQueryCounts(customQueries)

      logGroup('Find query with minimum result count')
      // at least one query must be lower than the limit value (maxQueryCount + 1)
      let minCount = this.maxQueryCount + 1
      // index of filter with lowest count
      let indexMinCount = -1 // default: invalid index

      for (let i = 0; i < counts.length; i++) {
        // const allCounts = snapshots[i].map((s) => s.count)
        // const totalCount = allCounts.reduce((sum, current) => sum + current)
        logGroup(`filterGroups[${i}]:`)
        logDebug(filterGroups[i])
        log(`Snapshot result counts: ${counts[i]}`)

        if (counts[i] < minCount) {
          minCount = counts[i]
          indexMinCount = i
          logGroup('>>>>>>> UPDATE >>>>>>>')
          log('minCount:      ', minCount)
          log('indexMinCount: ', indexMinCount)
          //log('<<<<<<<<<<<<<<<<<<<<<<')
          logGroupEnd()
        }

        // REVIEW: special case (count=0)
        if (counts[i] == 0) {
          log(warning('Warning: Result count is 0 for custom filter config'))

          // get a random doc to check types
          const snapshot = await getDocs(fbQuery(hasMandatoryFilter ? mandatoryQuery : this.collectionRef, limit(1)))
          if (snapshot.empty) {
            log(warning('Warning: No documents in the collection!'))
            break
          }
          const data: SnapshotDatas<T> = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...(doc.data() as T),
            _local: { docPath: doc.ref.path }
          }))

          // Debug
          log(debug('Random data to check types:'))
          //this.callback?.(data)

          // get the filter value
          const filterValue = customFilters[i].values

          // get the field value
          const fieldComponents = acessorObjectToString(customFilters[i].fieldAccessor).split('.')
          let fieldValue: any = data[0]
          for (const fieldComponent of fieldComponents) {
            fieldValue = fieldValue[fieldComponent]

            // check if field path is valid
            if (fieldValue === undefined) {
              const msg = `Undefined field "${fieldComponent}" in fieldPath "${customFilters[i].fieldAccessor}"`
              log(error('Error: ' + msg))
              // TODO: throw?
              //throw new Error(msg)
              break
            }
          }

          // helper type and lambda function to get the type info of the values
          type TypeInfo = {
            itemType: string
            itemCount: number
            isArray: boolean
          }

          const getTypeInfoFn = (value: any, name: string): TypeInfo => {
            const isArray: boolean = Array.isArray(value)
            // TODO: check value.length > 0?
            const valueItem = isArray ? value[0] : value
            let valueType = `${typeof valueItem}`

            if (typeof valueItem === 'object') {
              if (valueItem instanceof Date) {
                valueType = Date.name
              } else if (valueItem instanceof Timestamp) {
                valueType = Timestamp.name
              }
            }

            const typeInfo: TypeInfo = { itemType: valueType, itemCount: isArray ? value.length : 1, isArray: isArray }

            log(`Type info for ${name} value '${value}':`)
            if (ENABLE_DEBUG_LOG) console.table(typeInfo)

            return typeInfo
          }

          // get filter value type
          const filterValueTypeInfo = getTypeInfoFn(filterValue, 'filter')

          // get field value type
          const fieldValueTypeInfo = getTypeInfoFn(fieldValue, 'field')

          // check if types are compatible
          if (filterValueTypeInfo.itemType != fieldValueTypeInfo.itemType) {
            log(
              warning('Warning: Value types do not match! (typeof(filterValue) = %s, typeof(fieldValue) = %s)'),
              filterValueTypeInfo.itemType,
              fieldValueTypeInfo.itemType
            )

            // TODO: report this issue (type mismatch) somehow to user of this instance (but how?)
          }

          // break the loop, makes no sense to go on if one of the filter configs has no result data...
          log('Skipping the other custom filters as the result count is 0 for current filter')
          break
        }

        logGroupEnd() // filterGroups
      }

      // print summary of the query count check
      logGroup('------- SUMMARY -------')
      logInfo('maxQueryCount:   ', this.maxQueryCount)
      logInfo('minCount:        ', minCount)
      logInfo('indexMinCount:   ', indexMinCount)
      logInfo('limit exceeded?: ', minCount > this.maxQueryCount)
      //logInfo('-----------------------')
      logGroupEnd() // summary
      logGroupEnd() // find query with minimum result count
      logGroupEnd() // get query counts for filter groups

      if (indexMinCount == -1) {
        throw new Error(`All queries for the custom filters exceed the max query count (${this.maxQueryCount})`)
      }

      // assign the custom query with minimum result count
      minCustomQuery = customQueries[indexMinCount]

      // select appropriate merge type for partial data
      if (minCustomQuery.length > 1 && customFilters[indexMinCount].opStr === 'not-in') {
        // for 'not-in' the partial data must be merged with an "and" operator
        mergeType = 'and'
      }

      // define the local processing callback function to call it later for each new data set
      // (see onSnapshot() call below)
      if (minCount > 0) {
        localProcessingFn = (data) => {
          logGroup('Local processing data')

          let resultData = data

          // get all custom filterConfigs except the one with the "minimum query" from the filterGroups
          const actualFilterConfigs = filterGroups
            .filter((filterGroup, i) => i != indexMinCount)
            .reduce((acc, val) => acc.concat(val), [])

          logInfo(`Apply all filterConfigs except the one(s) with the minimum query: ${actualFilterConfigs}`)

          // apply all filterConfigs
          actualFilterConfigs.forEach((filterConfig) => {
            logGroup(logInfo('Apply filter:'))
            log(filterConfig)
            logGroupEnd()

            resultData = this.applyFilterConfig(resultData, filterConfig)
            log(`Result count: ${resultData.length}`)

            // Debug: show result
            // this.callback?.(resultData)
          })

          // sort data in-place using the stored sortConfigs (member variable)
          log('Sort the result data')
          this.sortData(resultData)

          logGroupEnd() // local processing data

          return resultData
        }
      }
    }

    // if there are no custom filters => direct query must be used
    if (!hasCustomFilter) {
      const tempQuery = hasMandatoryFilter ? mandatoryQuery : this.collectionRef
      this.totalCount = (await this.getQueryCounts([[tempQuery]]))[0]

      // only mandatory filters: try to add the sort configs to the query (if any)
      if (hasMandatoryFilter) {
        logDebug('No custom filters => try to use direct query')

        // 1. check if the combination of filter/sort configs is valid
        // a) first check if there are multiple inequality filters
        const inequalityFilterConfigs = this.filterConfigs.filter((filterConfig) =>
          ['<', '<=', '!=', 'not-in', '>', '>='].includes(filterConfig.opStr)
        )
        if (inequalityFilterConfigs.length > 1) {
          throw new Error(
            `Invalid combination of filter configs: Multiple inequality filters are not supported (count: ${inequalityFilterConfigs.length})`
          )
        }
        // b) then check if there is an inequality filter and a sort config on another field
        if (inequalityFilterConfigs.length > 0 && this.sortConfigs.length > 0) {
          const inequalityFilterFieldPath = acessorObjectToString(inequalityFilterConfigs[0].fieldAccessor)
          const sortConfigFieldPath = this.sortConfigs[0].fieldPath
          if (inequalityFilterFieldPath !== sortConfigFieldPath) {
            // try to "fix" the query by adding the sort config for the field of the inequality filter
            // (this is a workaround for the limitation of Firestore)
            if (ENABLE_DEBUG_LOG) {
              console.warn(
                'Warning: Invalid combination of filter configs: Inequality filter and sort config on different fields!'
              )
              console.warn(
                `Workaround: Add sort config for field of inequality filter (fieldPath: ${inequalityFilterFieldPath})`
              )
            }
            // push sort config to the front of the array
            this.sortConfigs.unshift({
              fieldPath: inequalityFilterFieldPath,
              directionStr: 'asc',
              indexGroups: []
            })
          }
        }

        // 2. check if there is a matching index including the sort config(s) (if any)
        if (this.sortConfigs.length > 0) {
          // handle special case for "__name__" => remove sort configs for this field
          const sortConfigsToMatch = this.sortConfigs.filter((sortConfig) => sortConfig.fieldPath !== '__name__')
          if (this.hasMatchingIndex(this.collectionIndexes, this.filterConfigs, sortConfigsToMatch)) {
            log('Matching index found => use direct query with mandatory filters and sorting')
            directQuery = fbQuery(
              this.addSortConfigsToQuery(mandatoryQuery, this.sortConfigs),
              limit(this.paginationLimit)
            )
          } else {
            console.warn('Warning: No matching index found => use direct query with local sorting (if applicable)')
            // check if local sorting possible (must get all docs without violating the max query count!)
            if (this.totalCount <= this.maxQueryCount) {
              logInfo(`Direct query with mandatory filters possible (count: ${this.totalCount}) => use direct query`)
              directQuery = mandatoryQuery
              // assign local processing callback
              localProcessingFn = (data) => {
                logDebug('Local sorting data')
                this.sortData(data)
                return data
              }
            } else {
              // if not possible => execute query anyway so that the caller is informed about the missing index
              // and the corresponding link to create the index is generated
              logInfo(
                `Direct query with local sorting not possible (exceeds maxQueryCount: ${this.maxQueryCount}) => try direct query anyway`
              )
              directQuery = fbQuery(
                this.addSortConfigsToQuery(mandatoryQuery, this.sortConfigs),
                limit(this.paginationLimit)
              )
            }
          }
        } else {
          log('No sort config => use direct query with mandatory filters only')
          // limit data if no sort config present
          directQuery = fbQuery(mandatoryQuery, limit(this.paginationLimit))
        }
      } else {
        // no filters, just order (if required) and limit data
        logDebug('No mandatory filters => just use sort config (if any)')

        // check if there is a sort config
        if (this.sortConfigs.length > 0) {
          // just use the first sort config
          const sortConfig = this.sortConfigs[0]
          // handle "descending key-scan" issue
          if (sortConfig.directionStr === 'desc' && sortConfig.fieldPath === '__name__') {
            console.warn('Warning: Firestore does not support descending key scans! Using "ascending" instead')
            sortConfig.directionStr = 'asc'
          }
          directQuery = fbQuery(
            this.collectionRef,
            orderBy(sortConfig.fieldPath, sortConfig.directionStr),
            limit(this.paginationLimit)
          )
        } else {
          // just limit data if no sort config present
          directQuery = fbQuery(this.collectionRef, limit(this.paginationLimit))
        }
      }
    }

    // select query to be used (as an array to be consistent)
    const queries = hasCustomFilter ? minCustomQuery : [directQuery]

    // init snapshot data buffer and data complete flags
    this.snapshotDataBuffer = new Array<SnapshotDatas<T>>(queries.length)
    this.dataComplete = new Array<boolean>(queries.length).fill(false)

    logDebug('Initialize onSnapshot() callbacks for the queries')

    // define onSnapshot function for given query/queries
    ;(this.unbindHandles = queries.map((query, queryIndex) =>
      onSnapshot(query, (snapshot) => {
        logDebug(`Executing onSnapshot() callback of query[${queryIndex}]`)

        // set dataComplete flag for query index
        this.dataComplete[queryIndex] = true

        // check if all queries are complete
        const allComplete = this.dataComplete.every((complete) => complete)

        if (allComplete) {
          log('Snapshot data complete')

          // unsubscribe all listeners if parameter updateDataOnce is set
          if (this.updateDataOnce) {
            log('Unsubscribe all snapshot listeners (updateDataOnce: true)')
            this.unbindHandles.forEach((handle) => handle())
          }
        }

        // convert data to generic type
        const snapshotData: SnapshotDatas<T> = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as T),
          _local: { docPath: doc.ref.path }
        }))

        log(
          logInfo('Push snapshot data (queryIndex: %d, count: %d) into buffer[%d], dataComplete: %s'),
          queryIndex,
          snapshotData.length,
          queries.length,
          this.dataComplete
        )

        // store partial data in buffer
        this.snapshotDataBuffer[queryIndex] = snapshotData

        // DEBUG/TEST
        //this.callback?.(snapshotData)

        // process final data if all queries are complete
        if (allComplete) {
          // merge data
          let mergedData: typeof snapshotData = []
          if (mergeType === 'or') {
            log(debug('Merge data (mergeType: "or")'))
            mergedData = this.snapshotDataBuffer[0]
            for (let i = 1; i < this.snapshotDataBuffer.length; i++) {
              this.snapshotDataBuffer[i].forEach((snapshotDoc) => {
                if (!mergedData.some((mergedDoc) => mergedDoc.id === snapshotDoc.id)) {
                  mergedData.push(snapshotDoc)
                }
              })
            }
          } else if (mergeType === 'and') {
            log(debug('Merge data (mergeType: "and")'))
            for (let i = 0; i < this.snapshotDataBuffer[0].length; i++) {
              const doc = this.snapshotDataBuffer[0][i]
              let merge = true
              for (let j = 1; j < this.snapshotDataBuffer.length; j++) {
                merge = merge && this.snapshotDataBuffer[j].some((otherDoc) => doc.id === otherDoc.id)
                if (!merge) break
              }
              if (merge) {
                mergedData.push(doc)
              }
            }
          }

          log(debug(`Merged data (count: ${mergedData.length})`))

          // reset data
          // this.snapshotDataBuffer = []
          this.dataComplete = []

          // apply local filtering/sorting if applicable
          const finalData = localProcessingFn?.(mergedData) || mergedData

          // callback on final data after applying pagination limit
          this.callback?.(
            finalData.slice(0, this.paginationLimit),
            this.totalCount < 0 ? finalData.length : this.totalCount
          )
        }
      })
    )),
      (error: any) => {
        log(error('Error: ' + error))
      }

    logGroupEnd() // update data
  }
}
