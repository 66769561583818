
import { Component, Vue } from 'vue-property-decorator'

@Component({

})
export default class VFormLogin extends Vue {
  public formEmail = ''
  public formPassword = ''
  public formRememberMe = false

  public isLoading = false

  public async onLogin() {
    if (!this.formEmail || !this.formPassword)
      return
      
    this.isLoading = true

    await this.$auth.setPersistence(this.formRememberMe)

    await this.$auth.loginEmailPassword(this.formEmail, this.formPassword)
      .then(() => {
        this.formEmail = ''
        this.formPassword = ''
        this.formRememberMe = false
      })
      .catch((e) => this.$helpers.notification.Error('Auth failure. ' + e))
      .finally(() =>

        this.isLoading = false
      )

  }
}
