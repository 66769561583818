var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-icon',{attrs:{"icon":(_vm.ressourceType === 'pdf') ? 'file-pdf':
    (_vm.ressourceType === 'image') ? 'file-image':
    (_vm.ressourceType === 'link') ? 'link':
    (_vm.ressourceType === 'video') ? 'film':
    (_vm.ressourceType === 'other') ? 'file':
    (_vm.ressourceType === 'archive') ? 'file-archive':
    (_vm.ressourceType === 'dir') ? 'folder':
    'border-style',"size":"is-small"}})
}
var staticRenderFns = []

export { render, staticRenderFns }