
import { Component, Vue, Watch, PropSync, Prop } from 'vue-property-decorator'

import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'


import BackendConfigManager from '@/database/backendConfigManager'

import { BackendConfigDB } from '@/types/typeBackendConfig'

library.add(faCalendarDay)

@Component({
  components: { VFormConfigureSticker, VRecordMeta },
  props: {}
})
export default class VModalCreateAsidCodes extends Vue {

  public activeStep = 0


  @PropSync('active', { type: Boolean, required: true, default: () => false })
  public isModalRequestAsidCodesActive!: boolean

  @Watch('isModalRequestAsidCodesActive')
  private onTenantDocUpdate() {
    if (!this.isModalRequestAsidCodesActive) this.activeStep = 0
  }

  // @Prop({ type: Object, required: true })
  // readonly tenant!: TenantDB & hasDBid

  @Prop({ type: Object, required: true })
  readonly backendConfig!: BackendConfigDB

  public formBackendConfig!: BackendConfigDB

  @Watch('backendConfig', {immediate:true})
  private onBackendConfigUpdate() {
    this.formBackendConfig = this.backendConfig
    this.formSelectedCodeConfig = this.backendConfig.codes[0]
  }


  public formQuantity = 0

  public formSelectedCodeConfig = { ...BackendConfigManager.defaultDocDB.codes[0] }


}
