
import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator'
import { BaseElementDB, BaseGroupDB, ModuleType } from '../modules/typeModules'

import VRecordMeta from '@/components/VRecordMeta.vue'
import VModuleElementReferences from '@/components/VModuleElementReferences.vue'
import { ModuleManager } from '@/modules/moduleManager'
import { hasDBid } from '@/types/typeGeneral'
import { AppPreview } from './VBackendAppPreview.vue'
import { cloneObject } from '@/helpers/dataShapeUtil'

@Component({
  components: {
    VRecordMeta,
    VModuleElementReferences
  }
})
export default class VModuleElementBaseConfig extends Vue {
  @Model()
  public formBaseData!: BaseElementDB & hasDBid

  @Prop({ type: Boolean, required: false, default: () => false })
  public disabled!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public showOrder!: boolean

  @Prop({ type: Array, required: false, default: () => [] })
  private groups!: Array<hasDBid & BaseGroupDB>

  @Prop({ type: String, required: false, default: () => '' })
  private moduleType!: ModuleType

  @Prop({ type: Boolean, required: false, default: () => false })
  private disallowResetToRevision!: boolean

  @Prop({ type: String, required: false, default: 'element' })
  private elementNameString!: string

  @Prop({ type: String, required: false, default: 'Widget' })
  public widgetString!: string

  public get selectableFormGroups() {

    const selectedGroup = this.groups.find(g => g.id === this.formBaseData.public.groupID)

    return this.groups
      .filter(d => d.publishingState !== 'deleted')
      // filter same group type
      .filter(d => d.public.groupType === selectedGroup?.public.groupType)
      .map(el => ({
        key: el.name,
        value: el.id
      }))
  }

  get moduleClass() {
    return ModuleManager.getModuleClassByType(this.moduleType)
  }

  get documentPath() {
    return this.moduleClass.getElementDocDbReference(this.$auth.tenant.id, this.formBaseData.id).path
  }

  get documentPrivileges() {
    return this.moduleClass.authPrivileges
  }

  get groupName() {
    return this.selectableFormGroups.find(e => e.value == this.formBaseData.public.groupID)?.key || ''
  }

  get isGroupArchived() {
    return this.groups.find(e => e.id == this.formBaseData.public.groupID)?.publishingState === 'archived'
  }

  get isGroupDeleted() {
    return this.groups.find(e => e.id == this.formBaseData.public.groupID)?.publishingState === 'deleted'
  }

  public capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }


  public mounted() {
    // this.initIdentifierDefinitions()
  }

  @Watch('formBaseData', { immediate: true, deep: true })
  @Watch('groups', { immediate: false, deep: false })
  private onElementChange(old: any, newD: any) {
    // ci has its custom logic to show some preview groups
    // when previewing custom or script untrusted js might steal credentials
    if (this.moduleType !== 'Ci' && this.moduleType !== 'Script' && this.moduleType !== 'Custom')
      AppPreview.setElements(
        this.moduleType,
        [this.formBaseData],
        this.groups.find(g => g.id === this.formBaseData.public.groupID)
        || cloneObject({ ...this.moduleClass.groupDB, id: this.formBaseData.public.groupID }))

    if (this.moduleType === 'Data')
      AppPreview.setMode('references', false)
  }

}
