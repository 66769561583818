
import { Component, Vue, Prop } from 'vue-property-decorator'

import { faSearch, faBook, faTimes } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import VInputLocalizedFieldLocaleselectorView from '@/components/VInputLocalizedFieldLocaleselectorView.vue'
import ModuleFileFileIconComponent from '@/modules/file/Module_File_FileIcon_Component.vue'
import VPdfViewer from '../VPdfViewer.vue'
import { HelpRessouceDB } from '@/types/typeHelpRessource'
import { defaultRecordMeta } from '@/database/databaseSchema'
import { distinctObjects } from '@/database/dbHelper'
import HelpRessourceManager from '@/database/helpRessourceManager'
import { locale } from '@/types/typeI18n'
import { HELP_RESSOURCE_SECTIONS } from '@/businessLogic/constants'
import { hasDBid } from '@/types/typeGeneral'

library.add(faSearch, faBook, faTimes)

@Component({
  components: {
    ModuleFileFileIconComponent,
    VPdfViewer,
    VInputLocalizedFieldLocaleselectorView
  }
})
export default class VHelpCenterView extends Vue {


  @Prop({ type: String, required: false, default: () => 'All' })
  readonly section!: string

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly adminMode!: boolean

  public isLoading = false

  public selectedLocale: locale = 'en'

  public ressourceTypes = ['video', 'pdf', 'faq']
  public sectionAvailableOptions = HELP_RESSOURCE_SECTIONS
  public helpCategories = ['Tutorials', 'Manuals', 'FAQs']
  // public helpCategoriesFilterOptions = ['All', ...this.helpCategories]
  public helpCategoryFilter = 'All'
  public sectionFilter = 'All'
  public filterText = ''

  get helpCategoriesFilterOptions() {
    return ['All', ...distinctObjects(this.helpRessources.filter(r =>
      this.section === 'All'
      || r.section === this.section
    ).filter(r =>
      this.sectionFilter === 'All'
      || r.section === this.sectionFilter
    ).map(r => r.category), (s) => s)]
  }

  get sectionFilterOptions() {
    return ['All', ...distinctObjects(this.helpRessources.map(r => r.section), (s) => s)]
  }

  private helpRessources: (HelpRessouceDB & hasDBid)[] = [
    {
      id: '',
      category: 'Tutorials',
      order: 3,
      section: 'html',
      ressourceType: 'video',
      name: { locales: { en: 'help video' }, _ltType: true },
      url: { locales: { en: 'https://www.youtube-nocookie.com/embed/YjZ0uou_5-Y' }, _ltType: true },
      faqData: [

      ],
      _meta: defaultRecordMeta
    },
    // {
    //   category: 'FAQs',
    //   order: 2,
    //   section: 'html',
    //   ressourceType: 'faq',
    //   name: { locales: { en: 'FAQ' }, _ltType: true },
    //   { locales: { en:  url: '' }, _ltType: true },
    //   faqData: [
    //     {
    //       question: 'How to add a category',
    //       answer: 'do xyz <b>bold</b>'
    //     }, {
    //       question: 'How to add a category',
    //       answer: 'Excepturi omnis molestias tempore commodi ex id illum, animi repellat iste, repellendus recusandae inventore voluptatem porro libero iusto provident? Soluta autem in minima adipisci praesentium sapiente tenetur, cumque aspernatur iusto debitis commodi, hic perspiciatis voluptate laboriosam rerum quas. Quos iure doloribus sit nam iusto culpa minima officiis? Optio harum fugit incidunt ipsa architecto nobis ex blanditiis necessitatibus quod facere tenetur perspiciatis debitis sint neque sed, quos asperiores hic quidem nemo repellat numquam animi molestias deserunt nihil? Voluptas eligendi quis rem quibusdam ratione numquam, porro animi voluptate doloremque consectetur, doloribus, cumque in ab maxime ullam labore molestiae? Iure vitae sequi error.'
    //     }
    //   ],
    //   _meta: defaultRecordMeta
    // },
    {
      id: '',
      category: 'Manuals',
      order: 1,
      section: 'html',
      ressourceType: 'pdf',
      name: { locales: { en: 'manual.pdf' }, _ltType: true },
      url: { locales: { en: '/testFiles/compressed.tracemonkey-pldi-09.pdf' }, _ltType: true },
      faqData: [

      ],
      _meta: defaultRecordMeta
    }
  ]

  public async onUpdateElement(id: number) {
    // this.filteredHelpRessources[id];
    // await this.$firestoreBind('groups', FileModule.getGroupsDbReference(this.$auth.tenant.id))
    await HelpRessourceManager.update(this.filteredHelpRessources[id].id, this.$auth.userEmail, this.filteredHelpRessources[id])
      .catch(e => this.$helpers.notification.Error(e.toString()))
      .then(() => this.$helpers.notification.Success('element updated'))

  }

  public async onDeleteElement(id: number) {
    // this.filteredHelpRessources[id];
    // await this.$firestoreBind('groups', FileModule.getGroupsDbReference(this.$auth.tenant.id))
    await HelpRessourceManager.delete(this.filteredHelpRessources[id].id)
      .catch(e => this.$helpers.notification.Error(e.toString()))
      .then(() => this.$helpers.notification.Success('element updated'))

  }

  public async onAddElement() {
    await HelpRessourceManager.add(this.$auth.userEmail)
      .catch(e => this.$helpers.notification.Error(e.toString()))
      .then(() => this.$helpers.notification.Success('element added'))
  }


  public async mounted() {
    this.isLoading = true
    await this.$firestoreBind('helpRessources', HelpRessourceManager.getDbCollectionReference())
    this.isLoading = false
  }

  public get filteredHelpRessources() {
    return this.helpRessources.filter(r =>
      this.filterText === ''
      || this.$i18n.getLocalString(r.name, [this.selectedLocale]).toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase())
      || r.category.toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase())
      || r.ressourceType.toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase())
    ).filter(r =>
      this.helpCategoryFilter === 'All'
      || r.category === this.helpCategoryFilter
    ).filter(r =>
      this.section === 'All'
      || r.section === this.section
    ).filter(r =>
      this.sectionFilter === 'All'
      || r.section === this.sectionFilter
    ).filter(r =>
      this.adminMode
      || this.$i18n.getLocalString(r.name, [this.selectedLocale], 'none') !== 'not localized'
    ).sort((a, b) => a.order - b.order)
  }

}
