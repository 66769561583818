/**
 * Elements only exist in A but not in B
 * https://medium.com/better-programming/array-intersection-difference-and-union-with-javascript-es7-51d5ae1763b9
 *
 * @param a
 * @param b
 * @param comparison
 * @returns
 */
export function intersectOnlyInA<T>(a: T[], b: T[], comparison = (aEl: T, bEl: T) => aEl === bEl) {
  return a.filter((aEl) => !b.some((bEl) => comparison(aEl, bEl)))
}

/**
 *
 * @param a items to remove
 * @param b target
 * @param comparison
 * @returns
 */
export function removeAfromB<T>(a: T[], b: T[], comparison = (aEl: T, bEl: T) => aEl === bEl) {
  return b.filter((bEl) => !a.some((aEl) => comparison(aEl, bEl)))
}

/**
 * sort object by keys
 *
 * @param obj {a: 1, c:2, b:3}
 * @param dir 'asc
 * @returns {a: 1, b:3, c:2}
 */
export function sortObjectByKeys<T extends { [key: string]: any }>(obj: T, dir: 'asc' | 'desc' = 'asc') {
  const keys = Object.keys(obj).sort()

  if (dir === 'desc') keys.reverse()
  return keys.reduce((accumulator, currentValue) => {
    accumulator[currentValue] = obj[currentValue]
    return accumulator
  }, {} as any) as T
}

/**
 * true if any elements intersect
 *
 * @param a
 * @param b
 * @returns
 */
export function intersectSome<T>(a: T[], b: T[]) {
  return a.some((value) => b.includes(value))
}

/**
 *
 * @param a
 * @param b
 * @returns elements existsing on both arrays
 */
export function intersect<T>(a: T[], b: T[]) {
  return a.filter((value) => b.includes(value))
}

/**
 *
 * const a = [1,2,3,4,5,6]
 * getChunkedArray(a, 2) => [[1,2],[3,4],[5,6]]
 *
 * @param array [1,2,3,4,5,6]
 * @param size 2
 * @returns [[1,2],[3,4],[5,6]]
 */
export function getChunkedArray<T>(array: T[], size: number): Array<T[]> {
  if (array.length === 0) return []

  if (array.length <= size) return [array]

  return [array.slice(0, size), ...getChunkedArray(array.slice(size), size)]
}

/**
 * true if all elements of needle exists in haystack
 *
 * @param needle [1,2]
 * @param haystack [1,2,3,4]
 * @returns true
 */
export function intersectEvery<T>(needle: T[], haystack: T[]) {
  return needle.every((value) => haystack.includes(value))
}

/**
 * true arrays have identical content
 *
 * @param needle [1,2]
 * @param haystack [1,2]
 * @returns true
 */
export function identicalArray<T>(needle: T[], haystack: T[]) {
  return needle.every((value) => haystack.includes(value)) && needle.length === haystack.length
}

/**
 * true if duplicate elements exits
 *
 * @param array [1,2,2,3]
 * @returns true
 */
export function checkIfDuplicateExists(array: any[]) {
  return new Set(array).size !== array.length
}

/**
 * returns all duplicate values
 *
 * @param array [1,1,2,2,2,4]
 * @returns [1,1,2,2,2]
 */
export function getDuplicates(array: any[]) {
  return array.filter((e, i, a) => a.indexOf(e) !== i)
}

/**
 * removes duplicate elements from array
 *
 * @param a [1,1,2,2,2,4]
 * @returns [1,2,4]
 */
export function arrayUnique<T>(a: T[]) {
  return [...new Set(a)] as T[]
}

/**
 * removes duplicate elements from array of objects
 * 
 * @param array 
 * @param checkCallback 
 * @returns 
 */
export function objectArrayUnique<T>(array: T[], checkCallback: (a: T, b: T) => boolean) {
  return array.filter((value, index, self) => self.findIndex((t) => checkCallback(t, value)) === index)
}