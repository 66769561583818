import { ModuleType } from '@/modules/typeModules'

import { RouteConfig, RawLocation } from 'vue-router'
import BaseModule from '@/modules/baseModule'

import {
  ServiceModuleDB,
  ServiceElementDB,
  RequiredServicePrivileges,
  ServiceGroupDB,
  ServiceResponseIncidentDB,
  ServiceResponseIncidentItemDB
} from './typeServiceModule'

import { Database } from '@/database/databaseSchema'
import { TenantID } from '@/types/typeTenant'
import { createIncidentId } from './serviceModuleUtils'
import { DeepPartial, hasDBid, objectID } from '@/types/typeGeneral'
import { encodeEmailToKey } from '@/database/encodeEmailToKey'
import { query, where, getCountFromServer, getDocs, limit, orderBy } from 'firebase/firestore'
import { UserPrivilegeIdDB } from '@/types/typeUser'
import { objectArrayUnique } from '@/helpers/arrayHelper'

export default class ServiceModule extends BaseModule {
  public static type: ModuleType = 'Service'
  public static displayName = 'Service'
  public static color = '#1bd7ff'
  public static hasWidget = true
  public static description = 'Create a service widget'
  public static descriptionLong = 'Manage Service Tickets'

  public static routeNameListIncidents = 'module-service-incidents-list'
  public static routeNameIncidentsFilter = 'module-service-filter'
  public static routeNameSingleIncident = 'module-service-incident-single'
  public static routeNameConfigureModule = 'module-service-config'
  public static routeNameForms = 'module-service-forms'
  public static routeNameFormsGroup = 'module-service-forms-group'
  public static routeNameListForms = 'module-service-forms-list'

  public static authPrivileges: RequiredServicePrivileges = {
    r: ['service:read'],
    w: ['service:write'],
    view: ['service:view', 'service-config:view']
  }

  public static moduleDB: ServiceModuleDB = Database.defaultServiceModuleDB
  public static elementDB: ServiceElementDB = Database.defaultServiceElementDB
  public static groupDB: ServiceGroupDB = Database.defaultServiceGroupDB
  public static responseDB: ServiceResponseIncidentDB = Database.defaultServiceModuleResponseDB
  public static responseItemDB: ServiceResponseIncidentItemDB = Database.defaultServiceModuleResponseItemDB

  constructor() {
    super()
  }

  public static getElementsChannelDocs(tenantId: TenantID, includeDeleted = false, includeArchived = false) {
    return this.getDocsHelper<ServiceElementDB>(this.getElementsQuery(tenantId, includeDeleted, includeArchived))
  }

  public static async addIncident(
    tenantId: TenantID,
    authEmail: string,
    fields: DeepPartial<ServiceResponseIncidentDB>
  ) {
    const incidentDocId = await createIncidentId(this.getResponsesDbReference(tenantId), console.warn)

    return this.addDoc(this.getResponseDocDbReference(tenantId, incidentDocId), authEmail, fields, this.responseDB)
  }

  public static updateIncident(
    incidentID: objectID,
    tenantId: TenantID,
    authEmail: string,
    fields: DeepPartial<ServiceResponseIncidentDB>
  ) {
    return this.updateDoc<ServiceResponseIncidentDB>(
      this.getResponseDocDbReference(tenantId, incidentID),
      authEmail,
      fields
    )
  }

  /**
   *
   * @param filters
   * @param filterDatabaseDatatype
   * @param userEmail
   * @param tenantID
   * @returns {totalCount: number, unseenCount: number} // returns -1 if there are multiple array operators
   */
  public static async getCountForFilter(
    filters: { [key: string]: string | string[] },
    filterDatabaseDatatype: { [key: string]: 'string' | 'array' | 'date' | 'boolean' } = {},
    userEmail: string,
    tenantID: TenantID
  ) {
    let rspQuery = ServiceModule.getResponsesQueryV9(tenantID, false, false)

    let numberOfArrayOperators = 0

    for (const [filterPropertyAccessor, value] of Object.entries(filters)) {
      if (!filterPropertyAccessor) return { totalCount: 0, unseenCount: 0 }

      let queryOperator: '==' | '!=' | 'array-contains' | 'array-contains-any' =
        filterDatabaseDatatype[filterPropertyAccessor] === 'array'
          ? Array.isArray(value)
            ? ('array-contains-any' as const)
            : ('array-contains' as const)
          : ('==' as const)

      let queryValue: string | Date | any[] | boolean =
        filterDatabaseDatatype[filterPropertyAccessor] === 'date'
          ? Array.isArray(value)
            ? new Date(value[0])
            : new Date(value)
          : filterDatabaseDatatype[filterPropertyAccessor] === 'boolean'
          ? value === 'true'
            ? true
            : false
          : value

      // if queryValue is '_empty_', and operator is 'array' compare against empty array
      if (queryValue === '_empty_' && queryOperator === 'array-contains') {
        queryValue = []
        queryOperator = '=='
      }

      if (queryOperator === 'array-contains-any' || queryOperator === 'array-contains') numberOfArrayOperators++

      // if the datatype is boolean, invert the query operator to also catch docs which dont have the requested key
      // => does not work as non existing keys are not part of the index
      // if (filterDatabaseDatatype === 'boolean') {
      //   queryOperator = '!=' as const
      //   queryValue = !queryValue
      // }

      console.log(
        'queryValue',
        queryValue,
        'queryOperator',
        queryOperator,
        'filterPropertyAccessor',
        filterPropertyAccessor
      )

      rspQuery = query(rspQuery, where(filterPropertyAccessor, queryOperator, queryValue))

      console.debug(
        `query for filterproperty: ${filterPropertyAccessor} and value: ${value} and operator: ${queryOperator}`
      )
    }

    // if there are multiple array operators, firebase does not allow this
    if (numberOfArrayOperators > 1) {
      return { totalCount: -1, unseenCount: -1 }
    }

    const totalCount = (await getCountFromServer(rspQuery)).data().count

    const unseenQuery = query(rspQuery, where(`seenByUserID.${encodeEmailToKey(userEmail)}`, '==', false))
    const unseenCount = (await getCountFromServer(unseenQuery)).data().count

    //log
    console.debug(
      `totalCount: ${totalCount} for filterproperty: ${Object.keys(filters).join(',')} and value: ${Object.values(
        filters
      ).join(',')}`
    )

    return { unseenCount, totalCount }
  }

  public static async getIncidentDocsForSearchText(
    tenantID: TenantID,
    searchText: string,
    limitCount = 10
  ): Promise<(ServiceResponseIncidentDB & hasDBid)[]> {
    let text = searchText.trim()

    let incidentDocs: (ServiceResponseIncidentDB & hasDBid)[] = []

    // if no text is given, prefill with the latest updated tickets
    if (text === '') {
      const lastUpdatedIncidentsQuery = query(
        ServiceModule.getResponsesQueryV9(tenantID, true, true),
        orderBy('_dateLastActivity', 'desc'),
        limit(limitCount)
      )

      const lastUpdatedIncidentsSnap = await getDocs(lastUpdatedIncidentsQuery)

      incidentDocs = lastUpdatedIncidentsSnap.docs.map((doc) => ({
        ...(doc.data() as ServiceResponseIncidentDB & hasDBid),
        id: doc.id
      }))
    } else {
      const incidentTitleQuery = query(
        ServiceModule.getResponsesQueryV9(tenantID, true, true),
        where('public.title', '>=', text),
        where('public.title', '<=', text + '\uf8ff'),
        limit(limitCount)
      )

      text = text.toUpperCase()

      const incidentTitleUpperQuery = query(
        ServiceModule.getResponsesQueryV9(tenantID, true, true),
        where('public.title', '>=', text),
        where('public.title', '<=', text + '\uf8ff'),
        limit(limitCount)
      )

      text = text.toLowerCase()

      const incidentTitleLowerQuery = query(
        ServiceModule.getResponsesQueryV9(tenantID, true, true),
        where('public.title', '>=', text),
        where('public.title', '<=', text + '\uf8ff'),
        limit(limitCount)
      )

      // query for incident ids that start with the text
      const incidentIDQuery = query(
        ServiceModule.getResponsesQueryV9(tenantID, true, true),
        where('__name__', '>=', text),
        where('__name__', '<=', text + '\uf8ff'),
        limit(limitCount)
      )

      // uppercase first char of each word
      text = text
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

      const incidentTitleFirstCharUpperQuery = query(
        ServiceModule.getResponsesQueryV9(tenantID, true, true),
        where('public.title', '>=', text),
        where('public.title', '<=', text + '\uf8ff'),
        limit(limitCount)
      )

      const querySnapshots = [
        getDocs(incidentIDQuery),
        getDocs(incidentTitleQuery),
        getDocs(incidentTitleLowerQuery),
        getDocs(incidentTitleUpperQuery),
        getDocs(incidentTitleFirstCharUpperQuery)
      ]

      const snapshots = await Promise.all(querySnapshots)

      let allSnapshots = snapshots
        .map((snap) => snap.docs)
        .flat()
        .map((doc) => ({ ...(doc.data() as ServiceResponseIncidentDB & hasDBid), id: doc.id }))

      allSnapshots = objectArrayUnique(allSnapshots, (docA, docB) => docA.id === docB.id)

      incidentDocs = allSnapshots
    }

    return incidentDocs
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return [
      {
        to: { name: this.routeNameList },
        displayName: this.displayName,
        requiredPrivileges: ['service-config:view']
      },
      {
        to: { name: this.routeNameListIncidents },
        displayName: 'Tickets'
      }
    ]
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'module/service/incidents',
        name: this.routeNameListIncidents,
        component: () => import('./Backend_Module_Service_List_Incidents.vue'),
        redirect: { name: this.routeNameIncidentsFilter, query: { stats: 'true' } },
        meta: {
          label: 'Service',
          description: 'Manage your Service Tickets',
          breadcrumbs: false,
          isFullsize: true
        },
        props: true,
        children: [
          {
            path: 'filter',
            name: this.routeNameIncidentsFilter,
            component: () => import('./Backend_Module_Service_List_Incidents_Filter.vue'),
            meta: {
              label: 'Filter Tickets',
              description: `Manage your ${this.displayName} Widget`,
              isFullsize: true
            },
            props: true
          },
          {
            path: ':id',
            name: this.routeNameSingleIncident,
            component: () => import('./Backend_Module_Service_Incident_Single.vue'),
            meta: {
              label: 'Single Ticket',
              description: 'Manage your Ticket',
              breadcrumbs: false,
              isFullsize: true
            },
            props: (route) => ({ ...route.query, ...route.params }) // also applies query params as props
          }
        ]
      },
      {
        path: 'module/service',
        name: this.routeNameList,
        component: () => import('./Backend_Module_Service_List.vue'),
        meta: {
          label: 'Configure Service',
          description: 'Configure your Service Widgets',
          privileges: ['service-config:view'],
          breadcrumbs: false,
          isFullsize: true
        },
        children: [
          {
            path: 'config',
            name: this.routeNameConfigureModule,
            component: () => import('./Backend_Module_Service_Config.vue'),
            meta: {
              label: 'Configure Service',
              description: 'Configure your Service Tickets',
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          },

          {
            path: 'forms',
            name: this.routeNameListForms,
            // component: () => import('../form/Backend_Forms_Single.vue'),
            meta: {
              label: 'Configure Service',
              description: 'Manage your Form',
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          },
          {
            path: 'forms/:id',
            name: this.routeNameForms,
            component: () => import('../form/Backend_Forms_Single.vue'),
            meta: {
              label: 'Configure Service',
              description: 'Manage your Form',
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          },
          {
            path: 'forms/groups/:groupID',
            name: this.routeNameFormsGroup,
            component: () => import('../form/Backend_Forms_Group_Single.vue'),
            meta: {
              label: 'Configure Service',
              description: 'Manage your Form',
              isFullsize: true
            },
            props: true
          },
          {
            path: 'groups/:groupID',
            name: this.routeNameGroup,
            component: () => import('./Backend_Module_Service_Group_Single.vue'),
            meta: {
              label: `${this.displayName} Widget`,
              description: `Manage your ${this.displayName} Widget`,
              isFullsize: true
            },
            props: true
          },
          {
            path: ':id',
            name: this.routeNameElement,
            component: () => import('./Backend_Module_Service_Single.vue'),
            meta: {
              label: 'Configure Service',
              description: 'Manage your Service Widgets',
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          }
        ]
      }
    ]
  }
}
