
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import csv from 'papaparse'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileExport, faFileImport, faCog } from '@fortawesome/free-solid-svg-icons'

import download from '@/helpers/downloadHelper'
import { cloneObject, inlineArrayDimensionCSV, inlineDimension, reverseInlineArrayDimensionCSV, reverseInlineDimension } from '@/helpers/dataShapeUtil'
import { hasDBid } from '@/types/typeGeneral'
import firebase from 'firebase/compat/app'
import ComparisonResult from '@/types/typeComparisonResult'
import { accessorStringToValue, arrayGroupBy } from '@/database/dbHelper'
import { getChunkedArray } from '@/helpers/arrayHelper'
import db from '@/firebase'
import VModuleCompareData from './VModuleCompareData.vue'
import { BaseElementDB } from '@/modules/typeModules'
import { diff } from 'deep-diff'

library.add(faFileExport, faFileImport, faCog)

enum Formats {
  'csv' = 'CSV',
  'excel' = 'Excel'
}

type typeFormatterResturn = any

export type typeImportExportDefinitions<T> = {
  readOnly: boolean
  exportColumnName: string
  exportFormatter: (me: T) => typeFormatterResturn
  importFormatter?: (imp: typeFormatterResturn, me: T) => any | Promise<any>
}[]

export function convertToNullOrString(data: string | boolean | number | null) {
  const dataString = String(data)
  return (dataString === 'null' || dataString === 'undefined' || dataString === '') ? null : dataString
}

@Component({
  components: {},
  inheritAttrs: false,
  props: {
    formats: {
      type: Array,
      required: false,
      default: (): Array<keyof typeof Formats> => ['csv']
    },
    fileName: {
      type: String,
      required: false,
      default: () => 'data'
    },
    exportFormatter: { // legacy
      // a method that shall provide json data to be exported
      type: Function,
      required: false
    },
    importFormatter: { // legacy
      // a method that receives json data to be imported
      type: Function,
      required: false
    }
  }
})
export default class VImportExport extends Vue {
  public formFormats: Array<keyof typeof Formats> = []

  public formatsEnum = Formats
  public formFile = null

  @Prop({ type: Array, required: false, default: () => [] })
  readonly importExportDefinitions!: typeImportExportDefinitions<hasDBid>

  @Prop({ type: Array, required: false, default: () => [] })
  readonly exportDocs!: hasDBid[]

  @PropSync('isLoading', { type: Boolean, required: false, default: false })
  public isLoadingSync!: boolean

  @Prop({ type: Function, required: false })
  validateImportedData?: (importedData: Partial<hasDBid>[]) => void

  @Prop({ type: Function, required: false })
  getDoc?: (importedData: Partial<hasDBid>) => Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>

  @Prop({ type: Function, required: false })
  getDefaultDoc?: () => any

  @Prop({ type: Function, required: false })
  updateDocBatch?: (docId: string, docData: any, batch: firebase.firestore.WriteBatch) => firebase.firestore.WriteBatch

  @Prop({ type: Function, required: false })
  createDocBatch?: (docData: BaseElementDB, batch: firebase.firestore.WriteBatch) => firebase.firestore.WriteBatch

  private IMPORT_LIMIT = 2000


  public get accceptedFiletypes() {
    return ['.csv'] // todo use formats as basis for extensions
  }

  public READ_ONLY_SUFFIX = '_READONLY'

  // todo does not trigger when the same file is upladed again
  /**
   * --- EXPORTING ---
   * 1. click export
   * 2. call export formatter => {[key:string]: string|Date|number }[]
   *    {a.b.c: 14} => 'a.b.c': 14
   *    {a.d: 'OK'} => 'other': 'OK' // acessor may change
   * 3. call papaparse to create CSV or other export format
   * 
   * --- IMPORTING ---
   * 1. click import
   * 2. remove all readonly '_READONLY' fields
   * 3. call import formatter
   * 4. get all imported docs from DB 
   * 4.1 call export fromatter on them
   * 5. compare imported with current data and show changes modal
   * 6. process selected changes by reverting the key transformation while exporting
   * 7. update database entries
   */

  public async importData(importedData: hasDBid[]) {

    if (!this.getDoc)
      console.warn('getDoc not given')
    if (!this.validateImportedData)
      console.warn('validateImportedData not given')
    if (!this.getDefaultDoc)
      console.warn('getDefaultDoc not given')
    if (!this.updateDocBatch)
      console.warn('updateDocBatch not given')

    if (!this.getDoc
      || !this.validateImportedData
      || !this.getDefaultDoc
      || !this.updateDocBatch)
      return

    console.log(importedData)
    // rename identifiers key

    try {
      this.validateImportedData(importedData)
    } catch (error) {
      this.$helpers.notification.Error(error)
      return
    }

    // importing more than IMPORT_LIMIT docs is not supported
    if (importedData.length > this.IMPORT_LIMIT) {
      this.$helpers.notification.Error(`Importing more than ${this.IMPORT_LIMIT} documents is not supported. You tried to import ${importedData.length} documents. Please split your import into multiple files.`)
      return
    }

    // if ('categoryIDs' in importedData && 'categoryNames' in importedData) {
    //   this.$helpers.notification.Error('Only categoryIDs or categoryNames may be in the imported data. Remove the column you do not want to import')
    //   return
    // }


    // get all docs matching the imported importedData
    const promises: Array<Promise<firebase.firestore.DocumentSnapshot>> = []
    try {
      for (const importedDataset of importedData) {
        if (importedDataset.id.toLowerCase() !== 'new')
          promises.push(
            this.getDoc(importedDataset) // get doc may throw
          )
      }
    } catch (e) {
      this.$helpers.notification.Error(e + ' [E20231126]')
      return
    }

    // make every "new" entry unique by postfixing an incrementing number
    let i = 0
    importedData = importedData.map(impData => ({
      ...impData,
      id: (impData.id.toLowerCase() === 'new') ? 'new_' + ++i : impData.id
    }))

    try {

      this.isLoadingSync = true

      let processingInputToast = this.$buefy.toast.open({
        indefinite: true,
        message: 'processing input file ...',
        queue: false
        // type: 'is-info'
      })

      // if many docs, wait some time to show loading indicator
      if (importedData.length > 10)
        await new Promise(resolve => setTimeout(resolve, 1000))

      const dbDocs = (await Promise.all(promises))
        .map(doc => {
          console.log(doc)
          return doc
        })
        .filter(doc => doc.exists) // filter out non existend docs
        .map(d => {
          const data = d.data() as any
          const id = d.id
          return { id, ...data }
        })

      const dbDocsExportFormatted = this.applyExportDefinitions(dbDocs)

      processingInputToast.close()

      const confirmed = new Promise<ComparisonResult[]>((resolve, reject) => {
        this.$buefy.modal.open({
          props: {
            datasets: {
              oldData: dbDocsExportFormatted,
              newData: importedData
            }
          },
          customClass: 'import-modal',
          events: {
            confirmed: (checkedRows: Array<ComparisonResult>) => {
              resolve(checkedRows)
            },
            close: () => {
              reject()
            }
          },
          onCancel: () => {
            reject()
          },
          parent: this,
          component: VModuleCompareData,
          hasModalCard: true
        })
      })

      const applyImport = async (datasets: Array<ComparisonResult>) => {
        console.log(datasets)
        if (!this.getDoc
          || !this.validateImportedData || !this.getDefaultDoc || !this.updateDocBatch)
          return

        let savingToast = this.$buefy.toast.open({
          indefinite: true,
          message: 'importing data ...',
          queue: false
          // type: 'is-info'
        })

        try {
          this.isLoadingSync = true

          await this.$nextTick()

          // group changes by id for more efficient updates when more attributes change on one doc
          // {group: id, data: ComparisonResult}
          const changesGroupedByDocId = arrayGroupBy(datasets, (d) => d.id)

          const totalChanges = changesGroupedByDocId.length
          let currentChange = 0

          console.log('changesGroupedByDocId', changesGroupedByDocId)

          // group doc updates in sets of 400 to not reach the 500 docs per batch limit
          for (const chunksOfChangesByID of getChunkedArray(changesGroupedByDocId, 40)) {

            console.debug('changesGroupedByDocId', changesGroupedByDocId)

            currentChange += chunksOfChangesByID.length

            savingToast = this.$buefy.toast.open({
              indefinite: true,
              message: `importing data ${currentChange} / ${totalChanges} ... ${Math.round(currentChange / totalChanges * 100)}%`
              // type: 'is-info'
            })

            const batch = db.batch()

            for (const changePerID of chunksOfChangesByID) {


              const docID = changePerID.group
              const isNewDoc = docID.startsWith('new_')
              const currentDbDoc = isNewDoc ? this.getDefaultDoc() : dbDocs.find(doc => doc.id === docID)
              if (!currentDbDoc) {
                throw 'no doc found for id ' + docID
              }
              const updatedDbDoc = cloneObject(currentDbDoc)
              // todo create doc if id starts with new_
              // apply changes to doc
              for (const compResult of changePerID.data) {
                console.log(compResult)

                let importedDataReversedInlines = reverseInlineArrayDimensionCSV([{ [compResult.attribute]: compResult.newValue }])[0]
                // {a.b: 4} => {a:{b: 4}}
                console.log(importedDataReversedInlines)
                importedDataReversedInlines = reverseInlineDimension([importedDataReversedInlines])[0]


                const importExportDefinition = this.importExportDefinitions.find((def) => compResult.attribute.startsWith(def.exportColumnName))
                if (!(importExportDefinition && importExportDefinition.importFormatter)) {
                  throw 'no matching import formatter found for property ' + compResult.attribute
                }

                await importExportDefinition.importFormatter(importedDataReversedInlines[importExportDefinition.exportColumnName], updatedDbDoc)
              }

              // only update the changes between the current db doc and the imported data
              const changes = diff(currentDbDoc, updatedDbDoc)
              console.log(changes)

              if (changes?.length === 0) {
                console.log('no changes')
                continue
              }

              // copmile update instructions from changes
              const dbUpdateInstructions = changes?.reduce((prev, curr) => {
                const path = curr.path || []
                // if path contains a number, it is an array
                if (path.some(p => typeof p === 'number')) {
                  // remove last item from path, at it is the array index
                  path.pop()
                  // get the new value from the updated doc
                  const newValue = accessorStringToValue(updatedDbDoc, path.join('.'))
                  if (!Array.isArray(newValue))
                    throw 'expected array for path ' + path.join('.') + ' but got ' + typeof newValue + ' [20231028]'

                  prev[path.join('.')] = newValue
                  return prev
                } else if (curr.kind === 'A') {
                  const newValue = accessorStringToValue(updatedDbDoc, path.join('.'))
                  if (!Array.isArray(newValue))
                    throw 'expected array for path ' + path.join('.') + ' but got ' + typeof newValue + ' [20231028]'

                  prev[path.join('.')] = newValue
                  return prev
                } else if (curr.kind === 'E') {
                  prev[path.join('.')] = curr.rhs
                } else if (curr.kind === 'N') {
                  prev[path.join('.')] = curr.rhs
                } else if (curr.kind === 'D') {
                  prev[path.join('.')] = firebase.firestore.FieldValue.delete()
                }
                return prev
              }, {} as any)

              if (isNewDoc) {
                if (!this.createDocBatch) throw 'creating new documents using import is not supported for this dataset'
                this.createDocBatch(updatedDbDoc, batch)
              } else {
                this.updateDocBatch(docID, dbUpdateInstructions, batch)
              }
            }

            await batch
              .commit()
              .catch(e => {
                this.$helpers.notification.Error(`Error occured while updating objects: ${e.toString()}`)
              }).finally(() => {
                this.$emit('batch-updated')
              })


            // wait some time for firebase to update aggregated values, as there is a 1s continuous update limit
            await new Promise(resolve => setTimeout(resolve, 1000))
          }

          savingToast.close()
          this.$buefy.toast.open({
            indefinite: false,
            message: `successfully imported ${totalChanges} documents`,
            type: 'is-success'
          })

        } catch (e: any) {
          this.$helpers.notification.Error(e + ' [E20220129]')
          savingToast.close()
          return
        } finally {
          console.debug('setting isLoadingSync to false 1')
          this.isLoadingSync = false
          this.$emit('imported')
        }
      }

      const checkedRows = await confirmed
      await applyImport(checkedRows)

    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
      console.debug('setting isLoadingSync to false 2')
      this.isLoadingSync = false
    }
  }

  public onFileImport(file: File | Array<File>) {
    if (Array.isArray(file)) {
      console.warn('only one file may be selected')
      return
    }
    if (!('FileReader' in window)) {
      this.$helpers.notification.Error('Your browser does not support importing files.')
    }

    const reader = new FileReader()

    // Closure to capture the file information.
    reader.onload = (theFile => async (e: any) => {
      // todo parse csv/exel/... to json

      const csvParsingOptions = {
        delimiter: '',	// auto-detect
        newline: undefined,	// auto-detect
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        transformHeader: undefined,
        dynamicTyping: true,
        preview: 0,
        encoding: '',
        worker: false,
        comments: false as const,
        step: undefined,
        complete: undefined,
        error: undefined,
        download: false,
        downloadRequestHeaders: undefined,
        skipEmptyLines: 'greedy' as const,
        chunk: undefined,
        fastMode: undefined,
        beforeFirstChunk: undefined,
        withCredentials: undefined,
        transform: undefined,
        delimitersToGuess: [',', '\t', '|', ';', csv.RECORD_SEP, csv.UNIT_SEP]
      }

      const parseResult = csv.parse(e.target.result, csvParsingOptions)
      const jsonObj = (parseResult as any).data


      function nullPropsToEmpyString(obj: any) {
        Object.keys(obj).forEach(key => { if (obj[key] === null) obj[key] = '' })
      }


      const removeReadonlyKeys = (obj: any) => {
        Object.keys(obj).forEach(key => { if (key.includes(this.READ_ONLY_SUFFIX)) delete obj[key] })
      }

      if (Array.isArray(jsonObj)) {
        jsonObj.forEach(obj => {
          removeReadonlyKeys(obj)
          nullPropsToEmpyString(obj)
        })
      } else {
        removeReadonlyKeys(jsonObj)
        nullPropsToEmpyString(jsonObj)
      }

      console.log(parseResult)
      this.$props.importFormatter?.(jsonObj)
      await this.importData(jsonObj)

      this.formFile = null // reset file to be able to select new one
    })(file)

    // Read in the image file as a data URL.
    reader.readAsText(file)
  }


  private applyExportDefinitions(docs: hasDBid[]) {
    const exportData = docs.map(doc => ({
      id: doc.id,
      ...this.importExportDefinitions.reduce((prev, ie) => ({
        ...prev,
        [ie.exportColumnName + ((ie.readOnly) ? this.READ_ONLY_SUFFIX : '')]: ie.exportFormatter(doc)
      }), {})
    }))

    let modifiedExportData = inlineDimension<any>(exportData)
    modifiedExportData = inlineArrayDimensionCSV(modifiedExportData)

    return modifiedExportData
  }

  public onSelectExportMethod(format: keyof typeof Formats) {
    let data = []

    if (this.$props.exportFormatter)
      data = this.$props.exportFormatter(this.READ_ONLY_SUFFIX) // get data from export callback

    if (this.importExportDefinitions.length > 0)
      data = this.applyExportDefinitions(this.exportDocs)

    if (!Array.isArray(data)) {
      console.warn('export data is not of type Array', data)
      return
    }

    if (data.length == 0) {
      console.warn('provided data is empty')
      return
    }

    let columns: string[] = []
    data.forEach(
      row => Object.keys(row)
        .filter(k => !columns.includes(k))
        .forEach(k => {
          columns.push(k)
        })
    )

    // get parameter order from importExport Definitions
    const columnNamesOrder = this.importExportDefinitions.map(ied => ied.exportColumnName)

    // sort columans according to imExportDef. Take inlined props into account (identifer.i1/i2... must be sorted according to 'identifier' key)
    columns = columns.sort((a, b) =>
      columnNamesOrder.findIndex(cno => a.startsWith(cno)) -
      columnNamesOrder.findIndex(cno => b.startsWith(cno))
    )

    const csvUnparsingOptions = {
      quotes: false, //or array of booleans
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ',',
      header: true,
      newline: '\r\n',
      skipEmptyLines: false, //or 'greedy',
      columns //or array of strings
    }
    // https://github.com/zeMirco/json2csv
    switch (Formats[format]) {
      case Formats.csv:
        download(csv.unparse(data, csvUnparsingOptions), this.$props.fileName + '.csv', 'text/csv')
        break
      case Formats.excel:
        download(
          csv.unparse(data, csvUnparsingOptions), // todo excel support
          'data.xls',
          'application/msexcel'
        )
        break

      default:
        break
    }
  }

  @Watch('formats', { immediate: true })
  public formatsChanged(val: Array<keyof typeof Formats>, oldVal: Array<string>) {
    this.formFormats = val
  }
}
