
import { Component, Vue, Watch, Model, Prop } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft
} from '@fortawesome/free-solid-svg-icons'


import AsidManager from '@/database/asidManager'
import BackendConfigManager from '@/database/backendConfigManager'
import AdminManager from '@/database/adminManager'
import VEchoCode from '@/components/VEchoCode.vue'
import VInputColorpicker from '@/components/VInputColorpicker.vue'
import { CodeConfig } from '@/types/typeBackendConfig'

import VImageUploadModal from '@/components/image/VImageUploadModal.vue'
import VFormUploadFileView from '@/components/VFormUploadFileView.vue'
import TenantManager from '@/database/tenantManager'
import databaseSchema from '@/database/databaseSchema'


library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft)


@Component({
  components: {
    VEchoCode,
    VImageUploadModal,
    VFormUploadFileView,
    VInputColorpicker

  }
})
export default class BackendConfigureSticker extends Vue {

  public PRESELECTED_TEMPLATE_ID = 'ymyvCodeEchoCodeLogo'


  public AsidManager = AsidManager
  public formSampleAsid = AsidManager.createID('a')

  public isImageUploadModalActive = false
  public formFile: any = null

  public formPreviewWidth = false
  public formShowPrint = false
  public formSvgText = ''
  public formTemplateUrlSelector = this.PRESELECTED_TEMPLATE_ID
  public formTemplateUrlUpload = ''

  public isLoading = false

  get uploadPath() {
    return (this.$auth.tenant?.id)
      ? TenantManager.getConfigUploadPath(this.$auth.tenant.id)
      : AdminManager.getUploadPath('/codeTemplates')
  }

  get documentPath() {
    return (this.$auth.tenant?.id)
      ? databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__DOCUMENT_PATH__(this.$auth.tenant.id)
      : databaseSchema.COLLECTIONS.ADMIN.CODE_CONFIG.__DOCUMENT_PATH__()
  }

  public supportedFiletypes = ['image/svg+xml']
  public maxFileSize = 1024 * 1024


  public ERROR_CORRECTION_LEVELS = [
    { key: 'L', value: 'Low (7%)' },
    { key: 'M', value: 'Medium (15%)' },
    { key: 'Q', value: 'Quartile (25%)' },
    { key: 'H', value: 'High (30%)' }
  ]


  @Model('input', { type: Object, required: true, default: (): CodeConfig => ({ ...BackendConfigManager.defaultDocDB.codes[0] }) })
  public codeConfig!: CodeConfig

  @Prop({ type: Boolean, required: false, default: () => false })
  public disabled!: boolean

  @Prop({ type: String, required: false, default: () => '' })
  readonly baseUrl!: string

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly disableModifyWidth!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly disableModifyErrorCorrectionLevel!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly disableCustomLogo!: boolean

  @Watch('codeConfig', { immediate: true })
  private onCodeConfigChanged() {
    console.log('here')

    if (this.codeConfig.svgTemplateUrl === '') {
      this.formTemplateUrlSelector = this.PRESELECTED_TEMPLATE_ID
      this.formTemplateUrlUpload = ''
    } else if (!this.codeConfig.svgTemplateUrl.startsWith('http')) {

      this.formTemplateUrlSelector = this.codeConfig.svgTemplateUrl
      this.formTemplateUrlUpload = ''
    } else if (this.codeConfig.svgTemplateUrl.length > 0) {
      this.formTemplateUrlSelector = 'custom'
      this.formTemplateUrlUpload = this.codeConfig.svgTemplateUrl
    }

    this.onSelectedTemplateChanged()
  }

  @Watch('formTemplateUrlSelector')
  @Watch('formTemplateUrlUpload')
  private onSelectedTemplateChanged() {

    if (this.formTemplateUrlSelector === 'custom') {
      this.codeConfig.svgTemplateUrl = this.formTemplateUrlUpload
    } else {
      this.codeConfig.svgTemplateUrl = this.formTemplateUrlSelector
    }

  }

  public onValidateFile(file: File) {
    return true
  }


}
