
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'


import { ModuleType } from './typeModules'
import { ModuleManager } from './moduleManager'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLock, faEllipsisV, faEllipsisH, faChevronDown, faChevronRight, faAngleDoubleLeft, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons'
import BaseModule from './baseModule'

import VModalCategoryFilter from '@/components/VModalCategoryFilter.vue'

import VModuleMenuBody from './VModuleMenuBody.vue'
import { RouteConfig } from 'vue-router'


library.add(faLock, faTimes, faEllipsisV, faEllipsisH, faChevronDown, faChevronRight, faAngleDoubleLeft, faFilter)

@Component({
  components: {
    SlickList,
    SlickItem,
    VInputMultiCategorySelection,
    VModalCategoryFilter,
    VModuleMenuBody
  },
  directives: {
    handle: HandleDirective
  }
})
export default class VModuleMenu extends Vue {

  public isLoading = false


  @Prop({ type: String, required: false, default: () => '' })
  readonly label!: string

  @Prop({ type: String, required: false, default: () => '' })
  readonly description!: string

  @Prop({ type: String, required: false, default: () => '' })
  readonly moduleType!: ModuleType

  @Prop({ type: Function, required: false, default: (module: typeof BaseModule) => ({ name: module.routeNameList }) })
  readonly toListLink!: (module: typeof BaseModule) => RouteConfig


  public Module!: typeof BaseModule


  @Watch('moduleType', { immediate: true })
  public async onRouteChanged() {
    this.Module = ModuleManager.getModuleClassByType(this.moduleType)
  }

}
