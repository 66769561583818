
import { UserPrivilegeIdDB } from '@/types/typeUser'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({

})
export default class VPrivilegeNotification extends Vue {
  @Prop({ type: Array, required: true })
  public readonly requiredPrivileges!: UserPrivilegeIdDB[]

  @Prop({ type: String, default: '' })
  public readonly missingPrivilegesExplanation = ''

  public get missingPrivileges() {
    return this.requiredPrivileges.filter(privilege => !this.$auth.userHasPrivilege(privilege))
  }
}
