
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileImage, faFilePdf, faLink, faFile, faBorderStyle, faFilm, faFileArchive, faFolder } from '@fortawesome/free-solid-svg-icons'

library.add(faFileImage, faFilePdf, faFileArchive, faLink, faFile, faBorderStyle, faFilm, faFolder)

export default {
  props: {
    ressourceType: {
      type: String,
      required: true
    }
  }
}
