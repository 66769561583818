
import { Component, Vue } from 'vue-property-decorator'


import VInputCategorySelection from '@/components/VInputCategorySelection.vue'
import CategoryHelper, { CategoryFlattened } from '@/database/categoryHelper'

import { CategoryTree, CategoryCollection } from '@/types/typeCategory'


@Component({
  components: { VInputCategorySelection },
  props: {
    categoryID: {
      type: String,
      required: true,
      default: () => ''
    }
  }
})
export default class BackendCategoriesSingle extends Vue {
  // used for form controls

  // public formCategory!: CategoryItem = {  }

  public isLoading = false

  private categoriesDoc: CategoryCollection = this.$categories

  public get flattenedDataParentSelection() {
    const flattenedDataWithoutCurrentCategory: { [k: string]: CategoryFlattened } = {}
    for (const id in this.flattenedData) {

      if (id in this.flattenedData) {
        const element = this.flattenedData[id]
        let tempCat = element
        let tempIsBelowCurrentCategory = false
        while (tempCat.parentID !== '') {
          if (this.$props.categoryID === tempCat.id) {
            tempIsBelowCurrentCategory = true
            break
          }
          tempCat = tempCat.parentCategory as CategoryFlattened
        }
        if (!tempIsBelowCurrentCategory)
          flattenedDataWithoutCurrentCategory[element.id] = element
      }
    }
    // todo implement filtering to not be able to select the current category as parent
    //todo filer object: const { [this.$props.categoryID]: _, ...flattenedDataWithoutCurrentCategory } = this.flattenedData
    return flattenedDataWithoutCurrentCategory
  }

  public get flattenedData() {
    console.log(this.categoryDataTree)

    return CategoryHelper.flattenedDataAndAssParentReference(this.categoryDataTree)
  }


  get categoryDataTree(): CategoryTree {
    return CategoryHelper.buildCategoryTree(this.categoriesDoc)
  }

  public async created() {
    // Tenant().byCurrentUser().Categories().Doc() todo
    // this.isLoading = true
    // const catDocs: CategoryCollection = await this.$firestoreBind('categoriesDoc', await CategoryHelper.getCategoriesDoc(this.$auth.tenant.id))
    // this.isLoading = false
    // const tmpCat = catDocs[this.$props.categoryID]
    // if (tmpCat) {
    //   this.formCategory = tmpCat
    // }
  }

  public onParentCategorySelected() {
    // if (this.formCategory.parent === this.formCategory.id) {
    //   this.$helpers.notification.Error('Can not set Parent to current category')
    // }
  }

  public async onSaveForm() {
    // try {
    //   CategoryHelper.updateCategory(
    //     this.$auth.tenant.id,
    //     this.$props.categoryID,
    //     this.formCategory
    //   )
    //     .then(() => this.$helpers.notification.Success(`${this.formCategory.name} was udpated`))
    //     .catch((r) => this.$helpers.notification.Error(`${this.formCategory.name} could not be saved` + r.toString()))
    // } catch (e: any) {
    //   this.$helpers.notification.Error(e.toString())
    // }
  }

}
