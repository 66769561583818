import BaseModuleApp from '@/modules/baseModuleApp'
import { ModuleType } from '../typeModules'
import { RouteConfig } from 'vue-router'
import AppGroupLoading from '@/pages/app/App_Group_Loading.vue'

const App_Module_Protection = () => import(/* webpackPrefetch: true, webpackChunkName: "App_Module_Protection" */ './App_Module_Protection.vue')

export default class ProtectionModuleApp extends BaseModuleApp {
  public static type: ModuleType = 'Protection'

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'protection/:groupID',
        name: 'protection'
        //  component: App_Module_File_Single // todo async with import
      }
    ]
  }

  public static registerOverviewVue() {
    return () => ({
      component: App_Module_Protection(),
      loading: AppGroupLoading
    })
  }
}
