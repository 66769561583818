import BaseModuleApp from '@/modules/baseModuleApp'
import { ModuleType } from '../typeModules'

const App_Module_Ci = import(/* webpackChunkName: "App_Module_Ci" */ './App_Module_Ci.vue')

export default class CiModuleApp extends BaseModuleApp {
  public static type: ModuleType = 'Ci'

  public static registerOverviewVue() {
    //
  }

  public static registerBackgroundVue() {
    return () => App_Module_Ci
  }
}
