/**
 * functionality to create or modify colors
 */

import color from 'color'

export const getUniqueColorForString = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

export const getContrastColor = (hexcolor: string, opacity: number = 0.7) => {
  // if opacity is outside 0..1 range, log error and set to 1
  if (opacity < 0 || opacity > 1) {
    console.error('getContrastColor: opacity outside 0..1 range, setting to 1')
    opacity = 1
  }

  // if is dark, lighten the color, else darken it
  if (color(hexcolor).isDark()) {
    return color('white').alpha(opacity).hexa()
  } else {
    return color('black').alpha(opacity).hexa()
  }
}
