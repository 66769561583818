
import { Component, Vue, PropSync, Watch } from 'vue-property-decorator'
import firebase from 'firebase/compat/app'
import moment from 'moment'

import db from '@/firebase'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilter, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { acessorObjectToString } from '@/database/dbHelper'

library.add(faTimesCircle, faFilter)

@Component({
  components: {},
  inheritAttrs: false,
  props: {
    default: {
      type: Boolean,
      required: false,
      default: () => true
    }
  }
})
export default class VFilterDateDropdownView extends Vue {
  public isLoading = false
  public dropdownIsOpen = false

  public dateRange = [new Date, new Date]

  get formattedDateRange() {
    return this.dateRange.map(d => moment(d).format('YYYY.MM.DD'))
  }


  @PropSync('config', {
    type: Object, default: () => ({
      fieldName: '_meta.dateCreated',
      collectionPath: '',
      objAcessor: {},
      type: 'date',
      in: [],
      range: []
    })
  })
  public filterConfig!: {
    collectionPath: string
    objAcessor: any
    // options?: { value: string, title: any }[] todo directly specify options
    type: 'date'
    in: string[]
    range: (Date | string)[]
  }


  get propertyAcessorPath() {
    return acessorObjectToString(this.filterConfig.objAcessor)
  }

  @Watch('filterConfig', { immediate: true, deep: true })
  @Watch('filterSearchTerm', { immediate: false })
  @Watch('dropdownIsOpen', { immediate: false })
  private async onFilterConfigChanged() {
    if (!this.dropdownIsOpen) return

    function resolvePath(path: string, object: any) {
      return path.split('.').reduce((p, c) => p?.[c], object) || 'empty'
    }
    console.log('here')

    try {
      let query = db.collection(this.filterConfig.collectionPath)
        .limit(50)


      if (this.filterSearchTerm !== '') {
        if (this.propertyAcessorPath === 'id') {
          query = query.where(firebase.firestore.FieldPath.documentId(), '>=', this.filterSearchTerm)
            .where(firebase.firestore.FieldPath.documentId(), '<=', this.filterSearchTerm + '\uf8ff')
            .orderBy(firebase.firestore.FieldPath.documentId(), 'asc')
        } else {

          query = query.where(this.propertyAcessorPath, '>=', this.filterSearchTerm)
            .where(this.propertyAcessorPath, '<=', this.filterSearchTerm + '\uf8ff')
            .orderBy(this.propertyAcessorPath, 'asc')
        }
      }


      this.isLoading = true
      const data = await query.get()
      this.filterData = data.docs.map(d => {
        const data = { ...d.data(), id: d.id }
        return { __result__: resolvePath(this.propertyAcessorPath, data), ...data }
      }).map(d => {
        // for the checked rows to still work the object must be the same
        const checkedRow = this.filterCheckedRows.find(cr => cr.__result__ === d.__result__)
        return (checkedRow) ? checkedRow : d
      })
    } catch (e: any) {
      this.$helpers.notification.Error(e.toString())
    } finally {
      this.isLoading = false
    }

  }

  //#region filter

  public filterSearchTerm = ''

  public filterData: { [key: string]: any, __result__: string }[] = []

  public filterCheckedRows: { __result__: string, [key: string]: any }[] = []
  public filterColumns = [

    {
      field: '__result__',
      label: 'Result'
    }
  ]


  public onApplyFilter() {
    this.filterConfig.range = this.dateRange
      ; (this.$refs.dropdown as any).toggle()

  }
  public onResetFilter() {
    this.filterConfig.range = []
    this.filterCheckedRows = []
    if (this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }

  public onOpenFilter() {
    if (!this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }


  //#endregion filter
}
