
import { Component, Vue } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp, faSave, faTimes, faTrash
} from '@fortawesome/free-solid-svg-icons'


library.add(faArrowUp, faSave, faTimes, faTrash)

@Component({
  components: {}
})
export default class BackendHelpcenter extends Vue {


}
