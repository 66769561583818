

import { ModuleType, BaseElementDB } from '../modules/typeModules'
import VTagModuleElement from '@/components/VTagModuleElement.vue'
import { groupBy } from '../database/dbHelper'

export default {
  name: 'VTagModuleElements',
  components: { VTagModuleElement },
  props: {
    elements: {
      type: Array, // Module Element with id and type
      required: true
    },
    groupElementsByType: {
      type: Function,
      default(elements: Array<BaseElementDB & { type: ModuleType }>) {
        let tmpElementsByType: { [key: string]: typeof elements } = {}
        tmpElementsByType = groupBy(elements.filter(e => e.publishingState === 'published'), (el) => el.type)
        return tmpElementsByType
      }
    }
  }
}
