import { render, staticRenderFns } from "./VModalTOS.vue?vue&type=template&id=11e16fe3&"
import script from "./VModalTOS.vue?vue&type=script&lang=ts&"
export * from "./VModalTOS.vue?vue&type=script&lang=ts&"
import style0 from "./VModalTOS.vue?vue&type=style&index=0&id=11e16fe3&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports