
import { Component, Prop, Vue } from 'vue-property-decorator'

import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'


library.add(faCalendarDay)

@Component({
  components: { VFormConfigureSticker, VRecordMeta },
  props: {}
})
export default class VModalSaveChanges extends Vue {
 @Prop({ default: false }) readonly displaySaveOption!: boolean

}
