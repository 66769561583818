
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'

import { CategoryCollection, CategoryID } from '@/types/typeCategory'

import { groupBy } from '@/database/dbHelper'
import { BaseElementDB, BaseGroupDB, ModuleType, ElementID, PublishingState } from './typeModules'
import { SortingOrderHelper } from '../helpers/sortingOrderHelper'
import { ModuleManager } from './moduleManager'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLock, faEllipsisV, faEllipsisH, faChevronDown, faChevronRight, faAngleDoubleLeft, faFilter, faTimes, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import BaseModule from './baseModule'
import { RouteConfig } from 'vue-router'
import { asidID } from '@/types/typeAsid'

import { Dictionary, Location } from 'vue-router/types/router'
import { capitalize } from '@/helpers/stringHelper'
import { hasDBid, objectID } from '@/types/typeGeneral'
import CategoryHelper from '@/database/categoryHelper'
import { intersectSome } from '@/helpers/arrayHelper'
import VModalCategoryFilter from '@/components/VModalCategoryFilter.vue'


library.add(faLock, faTimes, faEllipsisV, faEllipsisH, faChevronDown, faChevronRight, faAngleDoubleLeft, faFilter, faFileInvoice, faTimes)

@Component({
  components: {
    SlickList,
    SlickItem,
    VInputMultiCategorySelection,
    VModalCategoryFilter
  },
  directives: {
    handle: HandleDirective
  }
})
export default class VModuleMenuBody extends Vue {

  public isLoading = false

  @Prop({ type: Array, required: false, default: () => [] })
  readonly newElementAsidPresets!: asidID[]

  @Prop({ type: Object, required: false, default: () => ({}) })
  readonly newElementIdentifierPresets!: any

  @Prop({ type: Boolean, required: false, default: false })
  readonly filterElements!: boolean

  // set "filterElements" to true for the filterByElements to be taken into account
  @Prop({ type: Array, required: false, default: () => [] })
  readonly filterByElements!: string[]

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly hideGroups!: boolean

  @Prop({ type: String, required: false, default: () => '' })
  readonly moduleType!: ModuleType

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly hideHeader!: boolean

  @Prop({ type: Function, required: false, default: (module: typeof BaseModule) => ({ name: module.routeNameList }) })
  readonly toListLink!: () => RouteConfig

  @Prop({ type: Function, required: false, default: (id: string, groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget' = 'group-type_widget') => ({ name: module.routeNameElement, params: { id, groupID, groupType } }) })
  readonly toSingleElementLink!: (id: string, groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget') => Location

  /** keeps the same hash */
  public toSingleElementLinkKeepHash(id: string, groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget' = 'group-type_widget') {
    const rawLink = this.toSingleElementLink(id, groupID, module, groupType)
    rawLink.hash = this.$route.hash
    return rawLink
  }

  @Prop({ type: Function, required: false, default: (groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget') => ({ name: module.routeNameGroup, params: { groupID, groupType } }) })
  readonly toSingleGroupLink!: (groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget') => Location

  /** keeps the same hash */
  public toSingleGroupLinkKeepHash(groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget' = 'group-type_widget') {
    const rawLink = this.toSingleGroupLink(groupID, module, groupType)
    rawLink.hash = this.$route.hash
    return rawLink
  }

  @Prop({ type: String, required: false, default: () => 'group-type_widget' })
  readonly groupType!: 'group-type_widget' | 'group-type_group'

  // @Prop({ type: Array, required: false, default: () => [] })
  readonly groups: Array<hasDBid & BaseGroupDB> = []
  // @Prop({ type: Array, required: false, default: () => [] })
  readonly moduleElements: Array<hasDBid & BaseElementDB> = []
  // @Prop({ type: Object, required: false, default: () => ({}) })
  readonly categoriesDoc: CategoryCollection = this.$categories


  @Prop({ type: String, required: false, default: () => '' })
  readonly addWidgetText!: string

  @Prop({ type: String, required: false, default: () => 'Widget' })
  readonly widgetText!: string

  public Module!: typeof BaseModule


  public isFilterModalActive = false


  @Watch('moduleType', { immediate: true })
  public async onModuleTypeChanges() {
    this.Module = ModuleManager.getModuleClassByType(this.moduleType)
    this.isLoading = true
    try {
      await this.$firestoreBind('moduleElements', this.Module.getElementsDbReference(this.$auth.tenant.id), { wait: true })
      await this.$firestoreBind('groups', this.Module.getGroupsDbReference(this.$auth.tenant.id), { wait: true })
      this.onRouteChanged()
    } catch (error: any) {
      this.$helpers.notification.Error('You may be missing permissions to read this data. ' + error.toString())
    } finally {
      this.isLoading = false
    }
  }

  public showArchivedElements = []
  public toggleShowArchivedElementsForGroupIndex(i: number) {
    this.$set(this.showArchivedElements, i, !this.showArchivedElements[i])
  }

  public getSortedCategoryReferenceValues(moduleElement: BaseElementDB) {
    return Object.entries(moduleElement.reference.categoryIDs)
      .sort((a, b) => a[0].localeCompare(b[0], undefined, { numeric: true, sensitivity: 'base' })).map(d => d[1])
  }


  get hasArchivedElements(): boolean[] {
    return this.elementsByGroups.map(G =>
      G.displayGroups.some(dg =>
        dg.elements.some(el => ['archived', 'deleted'].includes(el.publishingState))
      )
    )
  }

  public showDeletedArchivedGroups = false

  get hasDeletedArchivedGroups() {
    return this.groups
      // filter by groupType
      .filter(g => g.public.groupType === this.groupType)
      .some(g => ['deleted', 'archived'].includes(g.publishingState))
  }

  get hasData() {
    return this.groups.length > 0
    // && Object.keys(this.categories).length > 0
  }

  @Watch('$route', { immediate: true })
  public onRouteChanged() {
    // check if the route matches any of the avaibale groups or elements

    // get the groupID from the route
    const groupID = this.$route.params.groupID

    // get the elementID from the route
    const elementID = this.$route.params.id

    let group = null

    if (groupID) {
      group = this.groups.find(g => g.id === groupID)
    } else if (elementID) {
      const activeElement = this.moduleElements.find(e => e.id === elementID)

      if (!activeElement) return

      // check if the group of the element is the right type
      const activeElementGroupID = activeElement.public.groupID

      group = this.groups.find(g => g.id === activeElementGroupID)
    }

    if (!group) return

    this.$emit('route-active', group.public.groupType === this.groupType)
  }


  public displayGroupCollapsed(moduleGroupID: objectID, groupName: string) {
    return this.$localSettings.modules.collapsedDisplayGroups.includes(`${this.moduleType}_${moduleGroupID}_${groupName}`)
  }

  public onCollapseAllDisplayGroups() {
    this.elementsByGroups.forEach(eg => {
      eg.displayGroups.forEach(dg => {
        if (!this.displayGroupCollapsed(eg.id, dg.name))
          this.$localSettings.modules.collapsedDisplayGroups.push(`${this.moduleType}_${eg.id}_${dg.name}`)
      })
    })
  }

  public onExpandAllDisplayGroups() {
    this.elementsByGroups.forEach(eg => {
      eg.displayGroups.forEach(dg => {
        const index = this.$localSettings.modules.collapsedDisplayGroups.indexOf(`${this.moduleType}_${eg.id}_${dg.name}`)
        if (index >= 0)
          this.$localSettings.modules.collapsedDisplayGroups.splice(index, 1)
      })
    })
  }

  public onToggleCollapsedDisplayGroup(moduleGroupID: objectID, groupName: number) {
    const index = this.$localSettings.modules.collapsedDisplayGroups.indexOf(`${this.moduleType}_${moduleGroupID}_${groupName}`)

    if (index >= 0) {
      this.$localSettings.modules.collapsedDisplayGroups.splice(index, 1)
    } else {
      this.$localSettings.modules.collapsedDisplayGroups.push(`${this.moduleType}_${moduleGroupID}_${groupName}`)
    }
  }

  public groupCollapsed(groupID: objectID) {
    return this.$localSettings.modules.collapsedGroups.includes(`${this.moduleType}_${groupID}`)
  }

  public onCollapseAllWidgets() {
    if (this.hideGroups) return
    this.groups.forEach(g => {
      if (!this.groupCollapsed(g.id))
        this.$localSettings.modules.collapsedGroups.push(`${this.moduleType}_${g.id}`)
    })
  }

  public onExpandAllWidgets() {
    this.groups.forEach(g => {
      const index = this.$localSettings.modules.collapsedGroups.indexOf(`${this.moduleType}_${g.id}`)
      if (index >= 0)
        this.$localSettings.modules.collapsedGroups.splice(index, 1)
    })
  }

  public onToggleCollapsedGroup(groupID: objectID) {
    const index = this.$localSettings.modules.collapsedGroups.indexOf(`${this.moduleType}_${groupID}`)

    if (index >= 0) {
      this.$localSettings.modules.collapsedGroups.splice(index, 1)
    } else {
      this.$localSettings.modules.collapsedGroups.push(`${this.moduleType}_${groupID}`)
    }
  }

  public getCategoryName(catID: CategoryID) {
    return (this.categoriesDoc[catID] && this.categoriesDoc[catID].name) || 'category not found'
  }

  public async onAddElement(groupID: ElementID) {
    // todo disable and emit instead
    // this.$emit('add-element', groupID)
    const query: Dictionary<string | (string | null)[] | null | undefined> = {}

    if (this.newElementAsidPresets.length > 0)
      query['elRefPresetAsid'] = this.newElementAsidPresets

    if (Object.keys(this.newElementIdentifierPresets).length > 0)
      query['elRefPresetIdentifier'] = Object.keys(this.newElementIdentifierPresets).map(idKey => `${idKey}__-__${this.newElementIdentifierPresets[idKey]}`)

    const toSingleElementLink = this.toSingleElementLink('new', groupID, this.Module, this.groupType)

    await this.$router.push({
      ...toSingleElementLink,
      query
    })
  }

  public async onChangePublishElements(groupID: ElementID, targetState: PublishingState) {
    const verbWords = {
      published: 'publish',
      draft: 'draft',
      deleted: 'deleted',
      archived: 'archive'
    }

    const pastWords = {
      published: 'published',
      draft: 'drafted',
      deleted: 'deleted',
      archived: 'archived'
    }

    try {
      const elements = this.elementsByGroups
        .find(g => g.id === groupID)?.displayGroups?.flatMap(dg => dg.elements)
        .filter(e => !['deleted', 'archived'].includes(e.publishingState))
        .filter(e => e.publishingState !== targetState)
        || []
      if (elements?.length === 0) throw 'no matching elements to publish'

      await new Promise((res, rej) => this.$buefy.dialog.confirm({
        title: `${capitalize(verbWords[targetState])} Elements`,
        message: `Are you sure you want to <b>${verbWords[targetState]} all ${elements?.length}</b> elements?`,
        confirmText: `${capitalize(verbWords[targetState])} ${elements?.length} Elements`,
        type: 'is-success',
        hasIcon: false,
        onConfirm: res,
        onCancel: () => rej(`changing state to ${verbWords[targetState]} canceled`)
      }))

      this.isLoading = true
      for (const el of elements) {
        await this.Module.updateElement(this.$auth.tenant.id, this.$auth.userEmail, el.id, { publishingState: targetState })
      }
      this.$helpers.notification.Success(`${elements?.length} Elements have been ${pastWords[targetState]}`)

    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
    } finally {
      this.isLoading = false
    }
  }

  public async onAddGroup() {
    // todo disable and emit instead
    // this.$emit('add-group', groupID)
    if (this.hideGroups) {
      await this.onAddElement(this.Module.defaultGroupID)
    } else {
      const toSingleGroupLink = this.toSingleGroupLink('new', this.Module, this.groupType)
      await this.$router.push(toSingleGroupLink)
    }
  }

  public onCopyElement(moduleElementId: objectID) {
    this.Module.copyElement(this.$auth.tenant.id, this.$auth.userEmail, moduleElementId)
      .then(async (d) => {
        this.$helpers.notification.Success('Element copied')
        await this.$router.push({ name: this.Module.routeNameElement, params: { id: d.id } })
      })
      .catch((e: any) => this.$helpers.notification.Error('[20210702] Error copying element: ' + e))
  }

  public async onOrderChanged(
    { event, newIndex, oldIndex }: { event: any, newIndex: number, oldIndex: number },
    elements: Array<BaseElementDB & hasDBid>
  ) {
    if (newIndex === oldIndex) return

    this.isLoading = true
    SortingOrderHelper.manageOrder(
      elements,
      oldIndex,
      newIndex,
      async (el, order) => {
        console.log('updating', el, order)

        return this.Module
          .updateElement(this.$auth.tenant.id, this.$auth.userEmail, el.id, { public: { order: order } })

      },
      (el) => el.public.order,
      (el, order) => el.public.order = order)
      .then(() => this.$helpers.notification.Success('Order updated'))
      .catch((e) => this.$helpers.notification.Error('Error updating Order ' + e.toString()))
      .finally(() => this.isLoading = false)

    console.log(this.moduleElements.map((el) => el.public.groupID))
    console.log(this.moduleElements.map((el) => el.id))

    // this.moduleElements = this.moduleElements.sort((a, b) => a.public.order - b.public.order)
  }

  public isDisplayGroupsActive = false

  /**
   * Elements are grouped by widget (group) and displayGroup
   * [
   *  {
   *   name : 'widget name',
   *   displayGroups: [
   *     {
   *       name: 'displa group Name'
   *       elements: []
   *     }
   *   ]
   *  }
   * ]
   */
  get elementsByGroups(): Array<hasDBid & BaseGroupDB & {
    displayGroups: { name: string, elements: (hasDBid & BaseElementDB)[] }[]
  }> {
    const elsByGroup = groupBy(this.moduleElements, (el) => el.public.groupID)

    const tmpElByGroup = []
    const lcFilter = this.$localSettings.modules.filter.toLowerCase()

    const filterCategories = CategoryHelper.getFilteredCategories(
      this.$categories,
      this.$localSettings.modules.filters.categories,
      this.$auth.user?.visibleCategories || [],
      this.$localSettings.modules.filters.categoriesIncludeParentCats,
      this.$localSettings.modules.filters.categoriesIncludeChildCats
    )

    //todo filter by comma
    for (const group of this.groups) {

      if (group.public.groupType !== this.groupType) continue

      /**
       * Element A v2 versionBaseElementId A v0
       *  Element A v1 versionBaseElementId: A v0
       *  Element A v0 versionBaseElementId: null
       * Element B
       * Element C
       * ----
       * Archived Element
       */

      const elements = (elsByGroup[group.id] || [])
        .sort((a, b) => {
          // sort archived and deleted elements to bottom preserving their order
          let orderA = a.public.order
          let orderB = b.public.order

          if (a.publishingState == 'archived') orderA += 100000
          if (b.publishingState == 'archived') orderB += 100000
          if (a.publishingState == 'deleted') orderA += 10000000
          if (b.publishingState == 'deleted') orderB += 10000000

          return orderA - orderB || a.id.localeCompare(b.id, undefined, { numeric: true, sensitivity: 'base' }) // if order is the same, fallback to id based sorting
        })
        .filter(el => (!this.filterElements) || this.filterByElements.includes(el.id))
        .filter((el) => (
          lcFilter.length == 0
          || el.name.toLowerCase().includes(lcFilter)
          || Object.values(el.reference.categoryIDs)
            .flat()
            .map(cid => this.getCategoryName(cid))
            .some(cName => cName.toLowerCase().includes(lcFilter))
          || Object.values(el.reference.identifierValues)
            .map(id => (id + '').toLowerCase())
            .some(id => id.includes(lcFilter))
          || el.id.toLowerCase().includes(lcFilter)
        ))
        .filter(el => (
          filterCategories.length == 0
          || intersectSome(Object.values(el.reference.categoryIDs)
            .flat(), filterCategories)
        ))

      // group versions together {Av0:[Av2,Av0,Av1]}
      // const data = Object.values(groupBy(elements, (el) => el.versionBaseElementId || el.id))
      // // console.log(data)

      // elements = data.map(versions => versions.sort((a, b) => (b.version || 0) - (a.version || 0))) // sort versions by version number
      //   .flat()
      // versionBaseElementId

      let groupByProp = (el: hasDBid & BaseElementDB) => 'group'

      this.isDisplayGroupsActive = false
      if (this.$localSettings.modules.groupedBy !== '') this.isDisplayGroupsActive = true

      switch (this.$localSettings.modules.groupedBy) {
        case 'name':
          groupByProp = (el: hasDBid & BaseElementDB) => {
            const groupElements = el.name.split('/')
            return groupElements.length > 1 ? groupElements[0] : 'other'
          }
          break

        case 'categories':
          groupByProp = (el: hasDBid & BaseElementDB) =>
            Object.values(el.reference.categoryIDs)
              .flat()
              .map(cid => this.$getCategoryName(cid)).sort().join(', ')
          break

        case 'identifier':
          groupByProp = (el: hasDBid & BaseElementDB) => {
            const identifierValues = Object.values(el.reference.identifierValues).flatMap(iv => iv).filter(iv => !!iv).sort()
            return identifierValues.length > 0 ? identifierValues.join(', ') : 'no identifiers'
          }

          break

        default:
          break
      }

      const elsByDisplayGroup = groupBy(elements, groupByProp)

      tmpElByGroup.push({
        ...group,
        id: group.id,
        displayGroups: Object.entries(elsByDisplayGroup).map(([groupName, elements]) => ({ name: groupName, elements }))
      })
    }

    tmpElByGroup.sort((a, b) => a.public.order - b.public.order || a.id.localeCompare(b.id, undefined, { numeric: true, sensitivity: 'base' }))
    return tmpElByGroup
  }

}
