
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

library.add(faExclamationTriangle)

interface MenuNotification {
  count: number
  text: string
}

export interface MenuNotifications {
  info: MenuNotification
  warning: MenuNotification
  error: MenuNotification
}


@Component
export default class VNotificationCounts extends Vue {
  @Prop({ type: Object, required: true })
  public menuNotifications!: MenuNotifications
}

