
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faTrash
} from '@fortawesome/free-solid-svg-icons'


import VRecordMeta from '@/components/VRecordMeta.vue'

import { cloneObject } from '@/helpers/dataShapeUtil'
import { CategoryEntryDefinition, CategoryEntryDefinitionObject } from '@/types/typeBackendConfig'
import { accessorStringToValue, assignValueBasedOnAccessorString } from '@/database/dbHelper'
import VInputMultiCategorySelection from './VInputMultiCategorySelection.vue'
import { ROOT_CATEGORY_ID } from '@/businessLogic/sharedConstants'

library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faTrash)

@Component({
  components: {
    SlickList,
    SlickItem,
    VRecordMeta,
    VInputMultiCategorySelection
  },
  directives: {
    handle: HandleDirective
  }
})
export default class VFormCategoryEntryDefinition extends Vue {

  @Model('update', { type: Object }) readonly categoryDefinitionObject!: CategoryEntryDefinitionObject

  @Prop({ type: Boolean, default: () => false }) readonly showIdentifierWarning!: boolean

  public categoryDefinitionObjectLocal: CategoryEntryDefinitionObject = { ...this.categoryDefinitionObject }

  /** name suggestions based on title and removed special chars */
  public categoryDefinitionNameSuggestions: { [key: string]: string } = {}

  @Watch('categoryDefinitionObject', { deep: true, immediate: true })
  private oncategoryDefinitionChange() {
    this.categoryDefinitionObjectLocal = cloneObject(this.categoryDefinitionObject)
  }

  public accessorStringToValue(obj: any, acessor: string) {
    return accessorStringToValue(obj, acessor)
  }

  public assignValueBasedOnAccessorString(obj: any, acessor: string, value: any) {
    assignValueBasedOnAccessorString(obj, acessor, value)
  }


  // #region categoryDefinition

  public editedElement: keyof CategoryEntryDefinitionObject | null = null

  public resetElement(elementID: keyof CategoryEntryDefinitionObject) {
    this.categoryDefinitionObjectLocal[elementID].title = ''
    this.categoryDefinitionObjectLocal[elementID].validator = {
      pivotCategory: '',
      constraint: 'categoryEntryConstraint_anyNode',
      minCount: 0,
      maxCount: 10
    }

    this.stopEditElement(elementID)

    // toast
    this.$helpers.notification.InfoToast('Element reset to default values')
  }

  public toggleEditElement(elementID: keyof CategoryEntryDefinitionObject) {
    // stop editing if there is currently an element being edited
    if (this.editedElement === elementID) {
      this.stopEditElement(this.editedElement)
    } else if (this.editedElement !== null) {
      this.stopEditElement(this.editedElement)

      // if stopping was indeed successful, start editing the new element
      if (this.editedElement === null) {
        this.editedElement = elementID
      }
    } else {
      this.editedElement = elementID
    }
  }

  // fn to be used from external to save the local data
  public saveLocal() {
    if (this.editedElement !== null) {
      this.stopEditElement(this.editedElement)
    }
    return this.errorMessage
  }

  public errorMessage = ''

  public stopEditElement(element: keyof CategoryEntryDefinitionObject) {
    console.debug('stopEditElement', element)

    // check that atleast one title is set
    const anyTitleSet = Object.entries(this.categoryDefinitionObjectLocal).some(([key, value]) => value.title !== '')
    if (!anyTitleSet) {
      this.errorMessage = 'At least one title must be set'
      return
    }

    Object.entries(this.categoryDefinitionObjectLocal).forEach(([key, value]) => {
      if (value.title && !value.validator.pivotCategory) {
        // if a title is set but no pivot category is set, set the pivot category to ROOT_CATEGORY_ID
        value.validator.pivotCategory = ROOT_CATEGORY_ID
        // toast
        this.$helpers.notification.InfoToast('Category branch set to root category')
      } else if (!value.title && value.validator.pivotCategory) {
        // if no title is set, remove the pivot category
        value.validator.pivotCategory = ''
        // toast
        this.$helpers.notification.InfoToast('Category branch removed as no title is set')
      }
    })

    this.errorMessage = ''
    this.editedElement = null
    this.$emit('update', this.categoryDefinitionObjectLocal)
  }

  get valueTypeName() {
    return 'category'
  }


  public CATEGORY_ENTRY_DEFINITON_CONSTRAINT: { key: CategoryEntryDefinition['validator']['constraint'], value: string }[] = [
    { key: 'categoryEntryConstraint_leafNode', value: 'Leaf Category' },
    // { key: 'categoryEntryConstraint_parentNode', value: 'Parent Node' },
    { key: 'categoryEntryConstraint_anyNode', value: 'Any Category' }
  ]

  public categoryDefinitionTableColumns = [
    //   {
    //   field: '__key__',
    //   label: 'Data ID',
    //   visible: true,
    //   type: 'text'
    //   // tooltip: 'The data ID is used '
    // },
    {
      field: 'title',
      label: 'Title',
      visible: true,
      type: 'text',
      tooltip: 'The title is used for displaying purposes and as a column header in the list codes view'
    }, {
      field: 'validator.pivotCategory',
      label: 'Category Branch',
      visible: true,
      type: 'text',
      tooltip: 'A subset of the categories to be used for this entry definition. It includes all categories below the selected category, including the selected category itself.'
    },
    {
      field: 'validator.constraint',
      label: 'Constraint',
      visible: true,
      type: 'dropdown',
      options: this.CATEGORY_ENTRY_DEFINITON_CONSTRAINT.map(({ key, value }) => [key, value]).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
    }, {
      field: 'validator.minCount',
      label: 'Min',
      type: 'number',
      tooltip: 'The minimum number of categories that must be selected'
    },
    {
      field: 'validator.maxCount',
      label: 'Max',
      type: 'number',
      tooltip: 'The maximum number of categories that can be selected'
    }]
  // #endregion categoryDefinition

}
