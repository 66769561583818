import BaseModuleApp from '@/modules/baseModuleApp'
import { ModuleType } from '../typeModules'


export default class I18nModuleApp extends BaseModuleApp {
  public static type: ModuleType = 'I18n'

  public static registerBackgroundVue() {
    return () => import('./App_Module_I18n.vue')
  }
}
