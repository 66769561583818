import { VueConstructor } from 'vue'

import { BaseAuth, AuthUser } from './baseAuthUtil'

import databaseSchema from '@/database/databaseSchema'

class AdminAuth extends BaseAuth {
  protected onUserChanged(user: AuthUser | null) {
    console.log('userchaged')

    if (!user) {
      this.state.tenant = null
      this.state.user = null
    } else {
      // Vue.set(this, 'user', U)
      // this.user = Object.assign({}, this.user, U)
      const userMail = user.email
      this.state.user = { ...databaseSchema.COLLECTIONS.TENANTS.USERS.__EMPTY_DOC__, email: userMail, name: user.name } // set user last to make sure other props are set
    }
    return Promise.resolve()
  }

  // in admin the user has all privileges
  get userPrivileges() {
    return [...Object.values(this.dbPrivileges), ...Object.values(this.privileges)]
  }
}

// declare module 'vue/types/vue' {
//   interface Vue {
//     $adminAuth: AdminAuth
//   }
// }

export default {
  install(Vue: VueConstructor, options: any) {
    Vue.prototype.$auth = new AdminAuth(Vue)
  }
}
