
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Timestamp } from '@/firebase'


import VFormAddress from '@/components/VFormAddress.vue'
import VInputUserRolesTags from '@/components/VInputUserRolesTags.vue'
import VInputMultiUserManagementView from '@/components/VInputMultiUserManagementView.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay, faPlus, faMinus, faEnvelope, faTag } from '@fortawesome/free-solid-svg-icons'


import { Address, TenantDB, Terms } from '@/types/typeTenant'


import { ModuleManager } from '@/modules/moduleManager'

import { ModuleType } from '@/modules/typeModules'
import { plans } from '../../businessLogic/plans'
import TenantManager from '../../database/tenantManager'
import TosManager from '@/database/tosManager'
import { Plans, PlanType, PlanDB, avaiableFeatures } from '@/types/typePlan'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { cloneObject } from '@/helpers/dataShapeUtil'

import BackendConfigManager from '@/database/backendConfigManager'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import { CategoryCollection } from '@/types/typeCategory'
import CategoryHelper from '@/database/categoryHelper'


library.add(faCalendarDay, faPlus, faMinus, faEnvelope, faTag, faCalendarDay)


@Component({
  components: {
    VFormAddress,
    VInputUserRolesTags,
    VInputMultiUserManagementView

  }
})
export default class AdminTenantSingle extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {

  @Prop({ required: true }) public id!: string

  public isLoading: boolean = false

  // used for form controls
  public formPlanExpires = new Date()

  public formAvailablePlans: Array<{ key: string, value: string }> = Object.keys(plans).map(p => ({
    key: p,
    value: plans[p as keyof Plans]
  })) // Enum to string array

  private tenant = cloneObject(TenantManager.defaultDocDB)
  public plan = cloneObject(TenantManager.defaultPlanDB)
  public formAdminData = cloneObject(TenantManager.defaultAdminDataDB)
  public formBackendConfig = cloneObject(BackendConfigManager.defaultDocDB)

  public categoriesCollection: CategoryCollection = {}

  public get formTenantNumber() {
    return (this.tenant._number > 0) ? TenantManager.formatPaddedNumber(this.tenant._number) : ''
  }


  public onCopyBillingToShipping() {
    this.tenant.masterData.shippingAddress = { ...this.tenant.masterData.billingAddress }
  }

  public formActiveModules: ModuleType[] = []
  public formAvailableModules = ModuleManager.availableModules
  public formAvailableFeatures: avaiableFeatures[] = ['deepl']

  @Watch('tenant.masterData.billingAddress', { immediate: true })
  public addressChanged(val: Address, oldVal: Address) {
    console.log('tenant.masterData.billingAddress changed')
  }

  private filterText = ''
  public setFilterText(text: string) {
    this.filterText = text
  }

  public get formFilteredActiveModules() {
    return this.formAvailableModules
      .filter((option: ModuleType) => !this.formActiveModules.includes(option)) // remove alrdy selected roles
      .filter((option: string) => {
        return option.toLowerCase()
          .indexOf(this.filterText.toLowerCase()) >= 0
      })
  }

  public get formFilteredAvailableFeatures() {
    return this.formAvailableFeatures
      .filter((option: avaiableFeatures) => !this.plan.activeFeatures.includes(option)) // remove alrdy selected roles
      .filter((option: string) => {
        return option.toLowerCase()
          .indexOf(this.filterText.toLowerCase()) >= 0
      })
  }

  public async createNewTenantNumber() {
    const MIN_NUMBER = 2000
    const MAX_NUMBER = 3000

    const field: keyof TenantDB = '_number'
    const highestTenantNumberDoc = await TenantManager.getDbCollectionReference()
      .orderBy(field).where(field, '<', 3000)
      .where(field, '>', 2000).limitToLast(1).get()

    let highestDocNumber = highestTenantNumberDoc.size > 0
      ? (highestTenantNumberDoc.docs[0].data() as TenantDB)._number : 0

    if (highestDocNumber > MAX_NUMBER) this.$helpers.notification.Error(`Tenant number range ${MIN_NUMBER}..${MAX_NUMBER} exceeded. Doc with max tenant number exists`)
    if (highestDocNumber < MIN_NUMBER) highestDocNumber += MIN_NUMBER

    this.tenant._number = highestDocNumber + 1
  }


  public async checkUniqueTenantNumber() {

    const field: keyof TenantDB = '_number'
    const sameNumberTenantDocSnap = await TenantManager.getDbCollectionReference()
      .where(field, '==', this.tenant._number).limit(2).get()

    if (!sameNumberTenantDocSnap.empty) {
      let sameNumberTenantDocs = sameNumberTenantDocSnap.docs.map(d => (d.data() as TenantDB))

      //tenant found tenant has other ID but same _number
      if (sameNumberTenantDocSnap.docs.length > 1 // more than one doc with the same number
        || sameNumberTenantDocSnap.docs[0].id !== this.$props.id)
        throw `Another tenant "${sameNumberTenantDocs.map(d => d.name).join(',')}" exists with the same tenant number "${this.tenant._number}"`
    }

  }

  public async $save() {
    // validation
    let isValid = true
    if (this.tenant.name.length < 2) {
      this.$helpers.notification.Warn('Tenant name to short')
      isValid = false
    }
    if (!isValid) return

    this.isLoading = true


    let tenantID: string = this.$props.id
    if (this.$props.id === 'new') {
      try {
        await this.checkUniqueTenantNumber()

        tenantID = (await TenantManager.add(this.$auth.userEmail)).id

        this.$helpers.notification.Success('Tenant created')
      } catch (e: any) {
        this.$helpers.notification.Error('Error creating Tenant ' + e)
      }
    } else {
      (this.$refs.userRef as any).save()
    }

    try {
      await this.checkUniqueTenantNumber()

      // handle Module activation changes
      const activatedModules = (await ModuleManager.getActivatedModules(tenantID)).map(M => M.type)
      const activeOnlyOnDB = activatedModules.filter(x => !this.formActiveModules.includes(x))
      await ModuleManager.deactivateModules(tenantID, this.$auth.userEmail, activeOnlyOnDB)
      const activeOnlyInForm = this.formActiveModules.filter(x => !activatedModules.includes(x))
      await ModuleManager.activateModules(tenantID, this.$auth.userEmail, activeOnlyInForm)


      // await timeout(2000)
      await TenantManager.updatePlan(tenantID, this.$auth.userEmail, {
        type: this.plan.type as PlanType,
        activeFeatures: this.plan.activeFeatures,
        customCodeRangePrefix: this.plan.customCodeRangePrefix,
        expires: Timestamp.fromDate(this.formPlanExpires) as any,
        availableAsidSlots: this.plan.availableAsidSlots
      })
      console.log('updatePlan')
      // await timeout(2000)
      await TenantManager.updateAdminData(tenantID, this.$auth.userEmail, {
        ...this.formAdminData
      })
      console.log('updateAdminData')

      // await timeout(2000)
      await TenantManager.update(tenantID, this.$auth.userEmail, { ...this.tenant })
      console.log('update')

      await BackendConfigManager.update(tenantID, this.$auth.userEmail, this.formBackendConfig)

      this.$helpers.notification.Success('Tenant saved')
      if (this.$props.id === 'new')
        await this.$router.push({ name: 'tenant-single', params: { id: tenantID } })

    } catch (e: any) {
      this.$helpers.notification.Error('Error updating Tenant: ' + e)
    } finally {

      this.isLoading = false
    }

  }

  public formRemoveTenant() {
    this.$buefy.dialog.confirm({
      title: 'Deleting account',
      message: `Are you sure you want to <b>delete ${this.tenant.name} </b> account and all linked ECHO CODES? This action cannot be undone.`,
      confirmText: 'Delete Account',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {

        this.isLoading = true

        TenantManager.deleteTenantAndLinkedAsids(this.id)
          .then(async (result) => {
            this.$helpers.notification.Success({ message: 'Delete success: ' + JSON.stringify(result), duration: 2000 })
            await this.$router.push({ name: 'tenant-list' })
          })
          .catch((err) => {
            this.$helpers.notification.Error(`Delete failed, see console, ${err}`)
          }).finally(() => this.isLoading = false)
      }
    })

  }

  //#region Terms

  public showTermsPreview = false
  public showOdpPreview = false

  get termsAccepted() {
    return TosManager.areTOSAccepted(this.tenant.terms, 'terms')
  }

  get odpAccepted() {
    return TosManager.areTOSAccepted(this.tenant.odp, 'odp')
  }

  public isAcceptTermsModalActive = false

  public async onAcceptDefaultTerms() { await this.onAcceptDefaultTermsHelper(this.tenant.terms, 'terms', false) }
  public async onAcceptDefaultOdp() { await this.onAcceptDefaultTermsHelper(this.tenant.odp, 'odp', false) }

  public async onAcceptCustomTerms() { await this.onAcceptDefaultTermsHelper(this.tenant.terms, 'terms', true) }
  public async onAcceptCustomOdp() { await this.onAcceptDefaultTermsHelper(this.tenant.odp, 'odp', true) }

  private async onAcceptDefaultTermsHelper(terms: Terms, type: 'odp' | 'terms', custom: boolean) {
    try {

      if (!custom) {

        if (terms.customTermsText !== '')
          await new Promise((res, rej) => this.$buefy.dialog.confirm({
            title: 'Custom Terms will be removed',
            message: 'Accepting the default terms removes the custom terms specified for this tenant.',
            cancelText: 'Cancel',
            confirmText: 'Remove Custom terms',
            type: 'is-danger',
            onConfirm: res,
            onCancel: () => rej('canceled')
          }))

        terms.customTermsText = ''
      } else {
        if (!terms.customTermsText) {
          terms.customTermsText = 'Custom Terms Accepted'
        }
      }

      terms.acceptedTermsHash = ''

      const acceptorEmail: string = await new Promise((res, rej) => this.$buefy.dialog.prompt({
        message: 'Accepted by this Email Address',
        inputAttrs: {
          placeholder: 'Email',
          maxlength: 300,
          type: 'email',
          value: this.tenant.masterData.email
        },
        cancelText: 'Cancel',
        confirmText: 'OK',
        trapFocus: true,
        onConfirm: (value) => {
          res(value)
        },
        onCancel: () => rej('canceled')
      }))

      const acceptedDate: Date = await new Promise((res, rej) => this.$buefy.dialog.prompt({
        message: 'Accepted Date',
        inputAttrs: {
          placeholder: 'YYYY-MM-DD',
          maxlength: 50,
          type: 'date',
          value: new Date().toLocaleDateString('en-CA')
        },
        trapFocus: true,
        onConfirm: (value) => {
          res(new Date(value))
        },
        onCancel: () => rej('canceled')
      }))

      this.isLoading = true

      await this.$save()

      await TosManager.acceptTerms(this.$props.id, this.$auth.userEmail, terms, type, acceptorEmail, acceptedDate)


    } catch (e: any) {
      this.$helpers.notification.Error(JSON.stringify(e))
    } finally {
      this.isLoading = false
    }
  }


  //#endregion Terms

  private unsubscribeSnapshot?: SnapshotUnbindHandle = undefined

  @Watch('id', { immediate: true })
  public async init() {
    this.isLoading = true

    let tmpPlan = { ...TenantManager.defaultPlanDB }

    if (this.$props.id !== 'new') {

      try {


        await this.$bindSnapshot('tenant', TenantManager.getDbDocReference(this.$props.id))


        const planDoc = await TenantManager.getDbPlanDocReference(this.$props.id).get()
        const planData = planDoc.data() as PlanDB
        if (!planDoc.exists || !planData) {
          this.$helpers.notification.Error(`plan ${this.$props.id} not found`)
          return
        }

        await this.$bindSnapshot('formAdminData', TenantManager.getDbAdminDataDocReference(this.$props.id))
        await this.$bindSnapshot('formBackendConfig', BackendConfigManager.getDbDocReference(this.$props.id))


        tmpPlan = this.$helpers.merge(tmpPlan, planData)
        tmpPlan.expires = planData.expires.toDate()

        // this.tenant.terms.dateAccepted = this.tenant.terms.dateAccepted.toDate()
        // this.tenant.terms.acceptCustomTermsUntil = this.tenant.terms.acceptCustomTermsUntil.toDate()

        // fetch categories
        this.categoriesCollection = await CategoryHelper.getCategoriesCollection(this.$props.id)

      } catch (e: any) {
        this.$helpers.notification.Error(e.toString())
      }
    } else {
      this.tenant = cloneObject(TenantManager.defaultDocDB)
      await this.createNewTenantNumber()
      this.plan = cloneObject(TenantManager.defaultPlanDB)
      this.formAdminData = cloneObject(TenantManager.defaultAdminDataDB)
      this.formBackendConfig = cloneObject(BackendConfigManager.defaultDocDB)
    }

    this.plan = tmpPlan


    this.formActiveModules = (this.$props.id !== 'new') ? (await ModuleManager.getActivatedModules(this.$props.id)).map(M => M.type) : []

    this.isLoading = false

  }

  public beforeDestroy() {
    if (this.unsubscribeSnapshot) this.unsubscribeSnapshot()
  }
}
