import { NotificationProgrammatic as Notification, ToastProgrammatic as Toast } from 'buefy'
import { BNoticeConfig, BNotificationConfig } from 'buefy/types/components'
import * as Sentry from '@sentry/vue'

const baseConfig: Partial<BNotificationConfig> = {
  duration: 3000,
  position: 'is-top-right',
  hasIcon: false
}

const baseConfigToast: Partial<BNoticeConfig> = {
  duration: 3000,
  position: 'is-top'
}

function isBNotificationConfig(obj: any): obj is BNotificationConfig {
  return obj.message !== undefined && !obj.code && !obj.stack // try to differentiate from other error object
}

// error Objects do not have enumerable keys. Use this workaround
// https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
export const stringifyError = function (
  err: any,
  replacer: (key: any, value: any) => any = (key, value) => (typeof value === 'function' ? 'function' : value),
  space?: string
) {
  let plainObject: any = {}

  if (typeof err === 'object')
    // dont deconstruct if e.g. string
    Object.getOwnPropertyNames(err).forEach(function (key) {
      plainObject[key] = err[key]
    })
  else plainObject = err

  return JSON.stringify(plainObject, replacer, space)
}
const notifications = {
  //todo add silent option and error logging
  Error: (options: BNotificationConfig | any) => {
    console.error(options)

    Sentry.captureException(options)

    if (!isBNotificationConfig(options) && typeof options !== 'string') {
      options = stringifyError(options)
    }

    Notification.open({
      ...baseConfig,
      type: 'is-danger',
      duration: 8000,
      pauseOnHover: true,
      ...(typeof options === 'string' ? { message: options } : options)
    })
  },

  ErrorToast: (options: BNoticeConfig | any) => {
    console.error(options)

    Sentry.captureException(options)

    if (!isBNotificationConfig(options) && typeof options !== 'string') {
      options = stringifyError(options)
    }

    Toast.open({
      ...baseConfigToast,
      type: 'is-danger',
      pauseOnHover: true,
      ...(typeof options === 'string' ? { message: options } : options)
    })
  },

  Success: (options: BNotificationConfig | string) => {
    Notification.open({
      ...baseConfig,
      type: 'is-success',
      pauseOnHover: true,
      ...(typeof options === 'string' ? { message: options } : options)
    })
  },

  SuccessToast: (options: BNoticeConfig | string) => {
    Toast.open({
      ...baseConfigToast,
      type: 'is-success',
      pauseOnHover: true,
      ...(typeof options === 'string' ? { message: options } : options)
    })
  },

  Info: (options: BNotificationConfig | string) => {
    Notification.open({
      ...baseConfig,
      type: 'is-info',
      pauseOnHover: true,
      ...(typeof options === 'string' ? { message: options } : options)
    })
  },

  InfoToast: (options: BNoticeConfig | string) => {
    return Toast.open({
      ...baseConfigToast,
      type: 'is-info',
      pauseOnHover: true,
      ...(typeof options === 'string' ? { message: options } : options)
    })
  },

  Warn: (options: BNotificationConfig | string) => {
    console.warn(options)

    return Notification.open({
      ...baseConfig,
      duration: 5000,
      type: 'is-warning',
      pauseOnHover: true,
      ...(typeof options === 'string' ? { message: options } : options)
    })
  },

  WarnToast: (options: BNoticeConfig | string) => {
    console.warn(options)

    return Toast.open({
      ...baseConfigToast,
      duration: 5000,
      type: 'is-warning',
      pauseOnHover: true,
      ...(typeof options === 'string' ? { message: options } : options)
    })
  }
}

export default notifications

export const handlePromiseError = function (promise: Promise<unknown> | (() => Promise<unknown>)) {
  if (typeof promise === 'function') {
    promise = promise()
  }
  promise.catch((err) => {
    notifications.Error(err)
  })
}

export const unpromisify = handlePromiseError
