
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPause } from '@fortawesome/free-solid-svg-icons'
import { Component, ModelSync, Vue } from 'vue-property-decorator'

library.add(faPause)

@Component({})
export default class VButtonToggleLiveUpdate extends Vue {
  @ModelSync('value', 'input', { type: Boolean })
  public liveUpdate!: boolean
}
