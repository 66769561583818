
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileDownload, faFilePdf, faPrint } from '@fortawesome/free-solid-svg-icons'
import print from '@/helpers/printUtil'

library.add(faPrint, faFilePdf, faFileDownload)

@Component({})
export default class VPrintButton extends Vue {

  @Prop({ type: [Object, HTMLElement], required: false, default: undefined }) printContainerRef!: HTMLElement | Vue
  @Prop({ type: Boolean, required: false, default: false }) resizetoDinA4!: boolean
  @Prop({ type: String, required: false, default: 'export.pdf' }) fileName!: string
  @Prop({ type: Boolean, required: false, default: true }) printOnly!: boolean
  @Prop({ type: Boolean, required: false, default: false }) pdfOnly!: boolean

  public isLoading = false

  public async onPrintClick() {
    this.isLoading = true
    const printElement = (this.$props.printContainerRef) ? (this.$props.printContainerRef.$el || this.$props.printContainerRef) as HTMLElement : window.document.body
    try {
      await print(printElement, this.$props.resizetoDinA4)
    } catch (error) {
      console.error(error)
      this.$helpers.notification.Error('Error printing: ' + error)
    }
    this.isLoading = false
  }

  public async onExportPdfClick() {
    this.isLoading = true

    // wait 1s to ensure background is hidden
    await new Promise(resolve => setTimeout(resolve, 1000))

    const $exportEl = (this.$props.printContainerRef) ? (this.$props.printContainerRef.$el || this.$props.printContainerRef) as HTMLElement : window.document.body
    const exportMargin = 0 // results in pagebreaks within elements
    const exportPadding = 10
    const initialPadding = $exportEl.style.padding

    // add padding to element to ensure the element is not cut off
    $exportEl.style.padding = exportPadding + 'mm'

    // add print class to element to hide all elements that should not be printed
    $exportEl.classList.add('print')

    // if resizetoDinA4 is true, we need to resize the print container to A4 size before exporting
    if (this.$props.resizetoDinA4) {
      $exportEl.style.width = 210 - (exportMargin * 2) + 'mm'

      // wait for next tick to ensure the element is resized
      await this.$nextTick()

      // wait 2 seconds to ensure the element is resized
      await new Promise(resolve => setTimeout(resolve, 2000))
    }

    const sanatizeFileName = (fileName: string) => {
      return fileName.replace(/[^a-z0-9]/gi, '_').toLowerCase()
    }

    try {
      // lazy import to reduce bundle size
      const html2pdf = (await import('html2pdf.js')).default
      await html2pdf()
        .set({
          image: { type: 'jpeg', quality: 0.94 },
          margin: exportMargin,
          html2canvas: { letterRendering: 1, allowTaint: false, useCORS: true, scale: 1 },
          // jsPDF: { userPassword: '1234' }, not working
          pagebreak: {
            mode: ['avoid-all']
            // before: ['.pageBreak'],
            // after: ['.breakAfter'],
            // avoid: ['.breakInsideAvoid']
          }
        })
        .from($exportEl)
        .save(sanatizeFileName(this.fileName))
        .thenExternal()

    } catch (error: any) {
      console.error(error)
      this.$helpers.notification.Error('Error exporting to PDF: ' + error)
    }

    // remove print class from element
    $exportEl.classList.remove('print')

    // reset padding
    $exportEl.style.padding = initialPadding

    // restore original width
    if (this.$props.resizetoDinA4) {
      $exportEl.style.width = 'auto'
    }

    this.isLoading = false
  }
}
