
import { Component, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEdit, faSearch, faSyncAlt
} from '@fortawesome/free-solid-svg-icons'

import VPaginationMixin from '../../components/mixins/VPaginateMixin.vue'
import { mixins } from 'vue-class-component'
import { EmailDB } from '@/types/typeEmail'
import db from '@/firebase'
import { typedPartialUpdatePayload, typedWhereV9 } from '@/database/dbHelper'
import TenantManager from '@/database/tenantManager'
import { DataCache } from '@/helpers/dataCache'
import { TenantDB } from '@/types/typeTenant'
import { hasDBid } from '@/types/typeGeneral'
import databaseSchema from '@/database/databaseSchema'
import VEditorHtml from '@/components/global/VEditorHtml.vue'
import sanitize from 'sanitize-html'
import { FilterConfigNew } from '@/database/filterUtil'
import { collection, getCountFromServer } from 'firebase/firestore'
import { MenuNotifications } from '@/components/global/VNotificationCounts.vue'

library.add(faEdit, faSearch, faSyncAlt)


export async function getMenuNotification(): Promise<MenuNotifications> {
  // get not sent emails

  const emailsErrorStateQuery = typedWhereV9(
    collection(db, databaseSchema.COLLECTIONS.EMAILS.__COLLECTION_PATH__()),
    { delivery: { state: 'ERROR' } }, '==', 'ERROR'
  )

  const emailsErrorStateQueryCount = (await getCountFromServer(emailsErrorStateQuery)).data().count

  return {
    info: { count: 0, text: '' },
    warning: { count: 0, text: '' },
    error: { count: emailsErrorStateQueryCount, text: 'Emails with error state' }
  }
}

@Component({
  components: {
    VEditorHtml
  }
})
export default class AdminEmailsList extends mixins<VPaginationMixin<EmailDB>>(VPaginationMixin) {
  public pagination_sortDirection: 'asc' | 'desc' = 'desc'
  public pagination_sortField: string = '_meta.dateCreated'
  public pagination_perPage = 10
  public pagination_liveUpdateOnFirstPage = true

  protected pagination_collectionReference = db.collection(databaseSchema.COLLECTIONS.EMAILS.__COLLECTION_PATH__())

  public formFilterByNotSent = false

  public sanitizeHtmlConfig: sanitize.IOptions = {
    allowedTags: ['div'],
    allowedAttributes: {
      div: ['style']
    },
    allowedStyles: {
      div: {
        'background-color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'padding': [/^\d+(px|em|rem|%)$/],
        'border-radius': [/^\d+\.\d+(px|em|rem|%)$/]
      }
    }
  }

  @Watch('formFilterByNotSent')
  public onChangeFormFilterByNotSent() {
    this.pagination_getData(true)
  }


  protected pagination_filter(): FilterConfigNew<EmailDB>[] {
    return (this.formFilterByNotSent)
      ? [{
        fieldAccessor: { delivery: { state: 'SUCCESS' } },
        opStr: '!=',
        values: ['SUCCESS'],
        indexGroups: [],
        isMandatory: true
      }]
      : []
  }

  private dataCacheTenant = new DataCache<TenantDB & hasDBid>(async (key) => {
    return await TenantManager.get(key)
  })

  public async onRetry(row: EmailDB & hasDBid) {
    console.log(row)
    // set is retrying
    this.$set(row, 'isRetrying', true)

    // update email
    try {
      await this.pagination_collectionReference.doc(row.id).update(
        typedPartialUpdatePayload<EmailDB>({
          delivery: {
            state: 'RETRY'
          }
        })
      )

    } catch (error) {
      this.$helpers.notification.Error(`Error updating email: ${error}`)
    } finally {
      this.$set(row, 'isRetrying', false)
    }
  }

  protected async pagination_foreachDoc(doc: EmailDB & hasDBid & { _local: { docPath: string } }) {
    // console.log(doc._docRef?.parent?.parent?.id)

    if (doc.tenantID) {

      this.dataCacheTenant.get(doc.tenantID).then((d) => {
        this.$set(doc, 'tenantName', d.name)
        this.$set(doc, 'tenantNumber', d._number)
      }).catch((e) => {
        this.$helpers.notification.Error(`Error getting tenant: ${e}`)
      })


    } else {
      this.$set(doc, 'tenantID', 'not assigned') // use 0 for proper sorting
      this.$set(doc, 'tenantNumber', 0)
    }
  }

  public created() {
    this.pagination_getData()
  }

}
