
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TenantDB } from '../types/typeTenant'
import TenantManager from '../database/tenantManager'
import { hasDBid } from '@/types/typeGeneral'

@Component({

})
export default class VFormLogin extends Vue {
  public selectedTenant: TenantDB & hasDBid | null = null

  @Prop({ type: Array, required: true, default: () => [] })
  readonly tenants!: Array<TenantDB & hasDBid>

  public onClose() {
    (this.$parent as any).close()
  }

  public formatPadded(number: number) {
    return TenantManager.formatPaddedNumber(number)
  }

  public async onSelect() {
    this.$emit('selected', this.selectedTenant)
    this.onClose()
  }
}
