
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'


import CategoryHelper from '@/database/categoryHelper'
// import the component
import Treeselect from '@riophae/vue-treeselect'
import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { CategoryTree, CategoryCollection, CategoryID } from '@/types/typeCategory'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faTimes)

@Component({
  components: {
    Treeselect
  },
  model: {
    prop: 'selectedCategoryIDs',
    event: 'selected'
  }
})
export default class VInputMultiCategorySelection extends Vue {

  @Model('selected', { type: Array, required: true }) readonly selectedCategoryIDs!: CategoryID[]

  @Prop({ type: Object, required: true }) readonly categoriesDoc!: CategoryCollection
  @Prop({ type: Array, required: false, default: () => [] }) readonly enabledCategoryIDs!: CategoryID[]
  @Prop({ type: Boolean, required: false, default: false }) readonly hideRoot!: boolean
  @Prop({ type: Boolean, required: false, default: true }) readonly multiple!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly disableBranchNodes!: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly showCloseButton!: boolean

  public categoryTree: CategoryTree[] = []
  public defaultOptions: CategoryTree[] = []
  public formSelectedIds: CategoryID[] = []
  public beforeMessage = ''

  private ENTRY_LIMIT = 500

  get isAsyncSearch() {
    return Object.keys(this.categoriesDoc).length > this.ENTRY_LIMIT
  }

  public normalizer(node: CategoryTree) {
    const tmpNode = {
      id: node.id,
      label: node.name,
      children: (node.children && node.children.length > 0) ? node.children : undefined, // to remove the child prop when empty
      isDefaultExpanded: this.isAsyncSearch
    }
    return {
      ...tmpNode // to remove the child prop when empty
    }
  }

  public loadOptions({ action, searchQuery, callback }: any) {

    console.log('loadOptions', action, searchQuery, callback)
    if (action === ASYNC_SEARCH) {

      // if less than 500 categories are loaded ignore async search
      if (!this.isAsyncSearch) {
        return
      }

      const filteredCategories = Object.fromEntries(
        Object.entries(this.categoriesDoc).filter(([key, value]) => {
          return searchQuery.length === 0 || value.name.toLowerCase().includes(searchQuery.toLowerCase())
        })
      )

      let filteredCategoriesLimited = filteredCategories

      // limit results to ENTRY_LIMIT
      if (Object.keys(filteredCategories).length > this.ENTRY_LIMIT) {
        filteredCategoriesLimited = Object.fromEntries(
          Object.entries(filteredCategories).slice(0, this.ENTRY_LIMIT)
        )
      }
      // include also all parents of the found categories
      const filteredCategoriesWithParents = CategoryHelper.getAllParentCategoriesCollection(Object.keys(filteredCategoriesLimited), this.categoriesDoc)

      const categoryTree = CategoryHelper.buildCategoryTree(filteredCategoriesWithParents, this.enabledCategoryIDs)
      callback(null, categoryTree.children)
    }

  }

  public onInputChange(selectedIDs: CategoryID[]) {
    // for some reason when flat is false, the treeselect emits twice and the second time with an array containing also the instance id

    console.log('onInputChange', selectedIDs)
    if (Array.isArray(selectedIDs) && selectedIDs.length > 0 && this.multiple === false)
      return
    console.log('onInputChange after', selectedIDs)

    // make sure we always emit an array, even if multiple is false
    if (!Array.isArray(selectedIDs))
      selectedIDs = [selectedIDs]

    this.$emit('selected', selectedIDs)
  }

  public onSearchChange(searchText: string) {
    if (this.isAsyncSearch) {
      this.beforeMessage = ''

      const filteredCategories = Object.fromEntries(
        Object.entries(this.categoriesDoc).filter(([key, value]) => {
          return searchText.length === 0 || value.name.toLowerCase().includes(searchText.toLowerCase())
        })
      )

      // limit results to ENTRY_LIMIT
      if (Object.keys(filteredCategories).length > this.ENTRY_LIMIT) {
        this.beforeMessage = `The result list is limited to ${this.ENTRY_LIMIT} categories. Please refine your search.`

      }
      if (searchText.length === 0) {
        this.beforeMessage = `More than ${this.ENTRY_LIMIT} categories found. Start typing to view results.`
      }
    }
  }

  // todo see AppAdress on how its done
  @Watch('selectedCategoryIDs', { immediate: true })
  onselectedCategoryIDChanged(val: string[]) {
    this.formSelectedIds = val

    // if more than 500 categories are loaded, fallback to async search for performance reasons
    if (this.isAsyncSearch) {
      // only set the already selected categories
      const selectedCatsTree = CategoryHelper.getAllParentCategoriesCollection(this.selectedCategoryIDs, this.categoriesDoc)
      this.defaultOptions = CategoryHelper.buildCategoryTree(selectedCatsTree, this.enabledCategoryIDs).children

      this.beforeMessage = `More than ${this.ENTRY_LIMIT} categories found. Start typing to view results.`
      return
    }
  }

  @Watch('categoriesDoc', { immediate: true, deep: true })
  onCategoriesDocChangedChanged(doc: CategoryCollection) {
    // if more than 500 categories are loaded, fallback to async search for performance reasons
    if (this.isAsyncSearch) {
      // only set the already selected categories
      const selectedCatsTree = CategoryHelper.getAllParentCategoriesCollection(this.selectedCategoryIDs, this.categoriesDoc)
      this.defaultOptions = CategoryHelper.buildCategoryTree(selectedCatsTree, this.enabledCategoryIDs).children

      this.beforeMessage = `More than ${this.ENTRY_LIMIT} categories found. Start typing to view results.`
      return
    }

    if (this.hideRoot) {
      this.categoryTree = CategoryHelper.buildCategoryTree(doc, this.enabledCategoryIDs).children[0].children
    } else {
      this.categoryTree = CategoryHelper.buildCategoryTree(doc, this.enabledCategoryIDs).children
    }
  }
}
