
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({

})
export default class VFormRegisterView extends Vue {
  public formRegistrationCode = ''
  public formRegistrationCodeDisabled = false
  public formEmail = ''
  public formEmailDisabled = false
  public formPassword = ''
  public formPassword2 = ''
  public formRememberMe = false

  public isLoading = false
  public formCompany = ''

  public onClose() {
    console.log('on close')

    if (this.$parent && (this.$parent as any).close)
      (this.$parent as any).close()
  }

  get hasErrorPasswordMissmatch() {
    return this.formPassword != this.formPassword2
  }

  get hasErrorRegistrationCode() {
    return !this.$auth.validateRegistrationCode(this.formRegistrationCode, this.formEmail, this.formCompany)
  }

  public async onRegister() {
    if (!this.formEmail || !this.formPassword || this.hasErrorPasswordMissmatch || this.hasErrorRegistrationCode)
      return

    this.isLoading = true
    await this.$auth.registerEmailPassword(this.formEmail, this.formPassword)
      .then(async () => {
        this.formEmail = ''
        this.formPassword = ''
        this.formRememberMe = false
        this.onClose()
        await this.$router.push({ name: 'backend' })
      })
      .catch((e) => this.$helpers.notification.Error('Auth failure. ' + e))
      .finally(() => {
        this.isLoading = false
      })
  }

  @Watch('$route', { immediate: true })
  public onRouteChanged() {
    this.formRegistrationCode = this.$route.query.registrationcode as string || ''
    this.formEmail = this.$route.query.email as string || ''
    this.formCompany = this.$route.query.companyname as string || ''

    if (this.formRegistrationCode)
      this.formRegistrationCodeDisabled = true
    if (this.formEmail)
      this.formEmailDisabled = true
  }
}
