
import { library } from '@fortawesome/fontawesome-svg-core'
import {  faFlask } from '@fortawesome/free-solid-svg-icons'

library.add(faFlask)

export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'is-dark'
    },
    text: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: false,
      default: 'is-top'
    }
  }
}
