
import VPaginationMixin, { FilterConfig } from '@/components/mixins/VPaginateMixin.vue'
import VButtonToggleLiveUpdate from '@/components/VButtonToggleLiveUpdate.vue'
import VFilterCategoriesDropdownView from '@/components/VFilterCategoriesDropdownView.vue'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import VFilterDropdownView from '@/components/VFilterDropdownView.vue'
import VImportExport, { typeImportExportDefinitions } from '@/components/VImportExport.vue'
import { accessorStringToValue, acessorObjectToString, assignValueBasedOnAccessorString } from '@/database/dbHelper'
import { firebase } from '@/firebase'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { BaseElementDB } from '@/modules/typeModules'
import { BaseDB } from '@/types/typeBase'
import { DeepPartial, hasDBid, objectID } from '@/types/typeGeneral'

import { mixins } from 'vue-class-component'
import { Component, Emit, Prop, PropSync, Watch } from 'vue-property-decorator'
import { FilterConfigNew } from '@/database/filterUtil'
import { getContrastColor } from '@/helpers/colorHelper'
import VTableColumnsDropdown from './VTableColumnsDropdown.vue'
import VImageUploadModal from '@/components/image/VImageUploadModal.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { diff } from 'deep-diff'
import { identicalArray } from '@/helpers/arrayHelper'

library.add(faSearch)

export interface TableColumnDefinition<T = any> {
  // provide either field or fieldAccessor (e.g. 'data.name' or {data:{name:''}})
  field?: string
  fieldAccessor?: DeepPartial<T>

  // label used in table header
  label: string

  // tooltip to shown on the label
  tooltip?: string

  // column is numeric
  numeric: boolean

  // column is searchable
  searchable: boolean

  // column is sortable
  sortable: boolean

  // formats the columns value
  formatter?: (value: any) => any

  // display type. If not set, the value is displayed as text
  display?: 'taglist' | 'tag' | 'colored-taglist' | 'image'

  // column is editable
  editable?: boolean

  // table header class
  headerClass?: string

  // table cell class
  cellClass?: string

  // column is centered
  centered?: boolean

  // column is part of a column group, which can be expanded
  columnGroup?: string

  // values not supplied by the user, but are automatcially set
  _derived?: {
    // column is a special column which shows a chevron to expand the column group
    isColumnGroupColumn?: boolean
  }
}

@Component({
  components: {
    VButtonToggleLiveUpdate,
    VFilterCategoriesDropdownView,
    VInputMultiCategorySelection,
    VFilterDropdownView,
    VImportExport,
    VTableColumnsDropdown,
    VImageUploadModal
  }
})
export default class VTable extends mixins<VPaginationMixin<any>>(VPaginationMixin) {

  //#region import/export

  @Prop({ type: Array, required: false, default: () => [] })
  public importExport_importExportDefinitions!: typeImportExportDefinitions<any>

  @Prop({ type: Function, required: false })
  public importExport_validateImportedData?: (importedData: Partial<hasDBid>[]) => void

  @Prop({ type: Function, required: false })
  public importExport_getDoc?: (importedData: Partial<hasDBid>) => Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>

  @Prop({ type: Function, required: false })
  public importExport_getDefaultDoc?: () => BaseDB

  @Prop({ type: Function, required: false })
  public importExport_updateDocBatch?: (docId: string, docData: any, batch: firebase.firestore.WriteBatch) => firebase.firestore.WriteBatch

  @Prop({ type: Function, required: false })
  public importExport_createDocBatch?: (docData: BaseElementDB, batch: firebase.firestore.WriteBatch) => firebase.firestore.WriteBatch

  //#endregion import/export

  // #region filter url query

  private updateFilterConfigFromUrlOnce = true


  @Watch('pagination_sortField')
  @Watch('pagination_sortDirection')
  private async convertSortFieldAndDirectionToQueryUrl() {
    console.debug('convertSortFieldAndDirectionToQueryUrl')

    // query object
    const newUrlQuery = {
      ...this.$route.query
    }

    // set sort field and direction
    if (this.pagination_sortField && this.pagination_sortField !== 'id')
      newUrlQuery.sortField = this.pagination_sortField

    if (this.pagination_sortDirection)
      newUrlQuery.sortDirection = this.pagination_sortDirection


    // avoid redunant navigation
    const queryChanges = diff(this.$route.query, newUrlQuery)
    if (!queryChanges)
      return

    await this.$router.replace({ query: newUrlQuery, hash: this.$route.hash })
  }


  @Watch('sortFieldSync', { immediate: true })
  @Watch('sortDirectionSync')
  private convertQueryUrlToSortField() {
    console.debug('convertQueryUrlToSortField')

    // query object
    const query = this.$route.query

    // get sort field from query
    const sortField = query.sortField
    const sortDirection = query.sortDirection as 'asc' | 'desc'

    if (['asc', 'desc'].includes(sortDirection))
      this.pagination_sortDirection = sortDirection as 'asc' | 'desc'

    if (typeof sortField === 'string')
      this.pagination_sortField = sortField
  }


  @Watch('pagination_filterConfig', { deep: true })
  private async convertFilterToQueryUrl() {
    console.debug('convertFilterToQueryUrl')

    if (this.filterConfig.length === 0) {
      console.log('no filter config')
      return
    }

    // query object
    const newUrlQuery = {
      ...this.$route.query,
      ...this.pagination_filterConfig.reduce((acc, filter) => {
        const field = acessorObjectToString(filter.fieldAccesor)
        const value = filter.in

        acc[field] = (value.length === 0 || value[0] === '') ? undefined : value

        // if array with length 0, convert to literal value
        if (acc[field] && acc[field].length === 1)
          acc[field] = acc[field][0]

        return acc
      }, {} as { [key: string]: any })
    }

    // filter out all undefined values, since they do not appear in the url, but would result in an url update
    const newQueryWithoutUndefined = Object.fromEntries(Object.entries(newUrlQuery).filter(([_, v]) => v !== undefined))

    // avoid redunant navigation
    const queryChanges = diff(this.$route.query, newQueryWithoutUndefined)
    if (!queryChanges)
      return
    // debugger

    await this.$router.replace({ query: newUrlQuery })
  }

  @Watch('filterConfig', { immediate: true, deep: true })
  @Watch('$route')
  private convertQueryUrlToFilter(oldValue?: FilterConfig<any>[], newValue?: FilterConfig<any>[]) {
    console.debug('convertQueryUrlToFilter')

    this.convertQueryUrlToSortField()

    // query object
    const query = this.$route.query

    // convert query to filter
    const newFilterConfig = cloneObject(this.filterConfig).map(filter => {
      const field = acessorObjectToString(filter.fieldAccesor)
      let queryValue: (string | boolean | null) | (string | boolean | null)[] = query[field]

      if (!Array.isArray(queryValue))
        queryValue = [queryValue]

      queryValue = queryValue.filter((v): v is string => v !== null && v !== undefined)
        .map(v => {
          if (v === 'false')
            return false
          else if (v === 'true')
            return true
          else if (v === 'null')
            return null
          else
            return v
        })

      // if the value in the filterconfig changed, use the new value
      const oldFilterConfigValue = Array.isArray(oldValue) ? oldValue?.find(f => acessorObjectToString(f.fieldAccesor) === field)?.in : undefined
      const newFilterConfigValue = Array.isArray(newValue) ? newValue?.find(f => acessorObjectToString(f.fieldAccesor) === field)?.in : undefined
      // console.debug('oldFilterConfigValue', oldFilterConfigValue)
      // console.debug('newFilterConfigValue', newFilterConfigValue)

      if (Array.isArray(oldFilterConfigValue)
        && Array.isArray(newFilterConfigValue)
        && !identicalArray(oldFilterConfigValue as any[], newFilterConfigValue as any[])
      ) {
        // filter.in = queryValue as string[]
        console.debug('external filter config changed')
      } else if (queryValue.length === 0 && filter.in.length > 0) { // if value is given in filterConfig, but not in query, use the filterConfig value
        // use filterConfig queryValue
        // filter.in = filter.in
      } else {
        // use query queryValue
        filter.in = queryValue as string[]
      }

      return filter
    })

    // // get sort field from query
    // const sortField = query.sortField
    // const sortDirection = query.sortDirection as 'asc' | 'desc'

    // if (['asc', 'desc'].includes(sortDirection))
    //   this.pagination_sortDirection = sortDirection as 'asc' | 'desc'

    // if (this.filterConfig.find(f => acessorObjectToString(f.fieldAccesor) === sortField) && typeof sortField === 'string')
    //   this.pagination_sortField = sortField

    // avoid redunant navigation
    const filterChanges = diff(this.pagination_filterConfig, newFilterConfig)
    if (!filterChanges)
      return

    this.pagination_filterConfig = newFilterConfig
  }

  // #endregion filter url query

  //#region editing
  public localData: { [key: string]: { editing: boolean } } = {}

  public toggleEditElement(id: objectID) {
    if (!(id in this.localData))
      this.$set(this.localData, id, {
        editing: false
      })

    this.localData[id].editing = !this.localData[id].editing
  }

  public endEditElement(id: objectID) {
    if (id in this.localData)
      this.localData[id].editing = false
  }

  private isUpdateLoading = false

  public async updateElement(id: string) {
    try {
      this.isUpdateLoading = true
      await this.updateElementCallback?.(id)
      this.endEditElement(id)
    } catch (error) {
      this.$helpers.notification.Error('Error updating Element [20221203]: ' + error)
    } finally {
      this.isUpdateLoading = false
    }
  }
  //#endregion editing


  @Prop({ type: Array, required: true })
  public readonly columnDefinition!: TableColumnDefinition[]

  /** includes colum groups */
  public localColumnDefinition: (TableColumnDefinition & { field: string, columnGroup: string })[] = []

  @Prop({ type: Object, required: true })
  public readonly collectionReference!: any

  public pagination_collectionReference = this.collectionReference

  @Watch('collectionReference', { immediate: true })
  private onChange_collectionReference() {
    this.refreshData(true)
  }


  @Prop({ type: Array, required: true })
  public readonly filterConfig!: FilterConfig<any>[]

  // @Watch('filterConfig', { immediate: true, deep: true })
  // private onFilterConfigChanged() {
  //   this.pagination_filterConfig = cloneObject(this.filterConfig)
  // }

  @Prop({ type: Function, required: false })
  public readonly deleteActionCallback?: (rowID: string) => void

  @Prop({ type: Function, required: false })
  public readonly updateElementCallback?: (rowID: string) => void

  @Prop({ type: Function, required: false, default: () => [] })
  public readonly queryFilter!: () => FilterConfigNew[]

  public pagination_filter = this.queryFilter

  @Prop({ type: Function, required: false, default: (data: any) => data })
  public readonly localDocsFilter!: (docs: (any & hasDBid)[]) => (any & hasDBid)[]

  public pagination_localDocsFilter = this.localDocsFilter

  //   protected pagination_filter(query: firebase.firestore.Query<firebase.firestore.DocumentData>) {
  //   query = typedWhere<BaseResponseDB>(query, { publishingState: 'deleted' }, 'not-in', ['deleted'])
  //   query = query.orderBy('publishingState')
  //   return typedWhere<BaseResponseDB>(query, { elementID: '' }, '==', this.moduleElement.id)
  // }

  @PropSync('sortFieldAccessor', { type: String, required: true })
  public sortFieldSync!: string

  @Watch('pagination_sortField')
  private onChange_sortField() {
    this.sortFieldSync = this.pagination_sortField
  }

  @Watch('sortFieldSync', { immediate: true })
  private onChange_sortFieldSync() {
    this.pagination_sortField = this.sortFieldSync
  }

  @PropSync('sortDirection', { type: String, required: true })
  public sortDirectionSync!: 'asc' | 'desc'

  @Watch('pagination_sortDirection', { immediate: false })
  private onChange_sortDirection() {
    this.sortDirectionSync = this.pagination_sortDirection
  }

  @Watch('sortDirectionSync', { immediate: true })
  private onChange_sortDirectionSync() {
    this.pagination_sortDirection = this.sortDirectionSync
  }

  @PropSync('itemsPerPage', { type: Number, required: false, default: 20 })
  public perPageSync!: number

  @Watch('pagination_perPage', { immediate: false })
  private onChange_perPage() {
    this.perPageSync = this.pagination_perPage
  }

  @Watch('perPageSync', { immediate: true })
  private onChange_perPageSync() {
    this.pagination_perPage = this.perPageSync
  }

  @PropSync('liveUpdateOnFirstPage', { type: Boolean, required: true })
  public liveUpdateOnFirstPageSync!: boolean

  @Watch('pagination_liveUpdateOnFirstPage')
  private onChange_liveUpdateOnFirstPage() {
    this.liveUpdateOnFirstPageSync = this.pagination_liveUpdateOnFirstPage
  }

  @Watch('liveUpdateOnFirstPageSync', { immediate: true })
  private onChange_liveUpdateOnFirstPageSync() {
    this.pagination_liveUpdateOnFirstPage = this.liveUpdateOnFirstPageSync
  }

  @PropSync('selectedRowId', { type: String, required: false })
  public selectedRowIdSync!: string

  get localSelectedRow() {
    return this.pagination_paginatedData.find(d => d.id === this.selectedRowIdSync)
  }

  set localSelectedRow(row: any) {
    this.selectedRowIdSync = row.id || ''
  }

  // #region image upload
  // uploadPath prop
  @Prop({ type: String, required: false, default: () => '' }) readonly uploadPath!: string

  public isImageUploadModalActive = false

  // #endregion image upload


  public getTextColor(hexcolor: string) {
    return getContrastColor(hexcolor)
  }

  public importExport_isLoading = false

  get isAnyLoading() {
    const anyLoading = this.pagination_isPaginationLoading
      || this.importExport_isLoading
      || this.isUpdateLoading
    this.$emit('loading', anyLoading)
    return anyLoading
  }

  public expandedColumnGroups: string[] = []

  @Watch('columnDefinition', { immediate: true })
  onUpdateColumnGroups() {
    // insert a 'special' column for each column group which shows a chevron to expand this group
    const processedColumnGroups: string[] = []

    // copy to not modify the prop
    const columnDefinition = cloneObject(this.columnDefinition)

    const targetColumnDef: (TableColumnDefinition & { field: string, columnGroup: string })[] = []

    for (let index = 0; index < columnDefinition.length; index++) {
      const colummDef = columnDefinition[index] as (TableColumnDefinition & { field: string, columnGroup: string })

      // get either field or fieldAccessor
      const field = colummDef.field || acessorObjectToString(colummDef.fieldAccessor || {})

      colummDef.field = field

      colummDef._derived = colummDef._derived || {}

      colummDef.columnGroup = colummDef.columnGroup || ''

      // process column groups
      if (colummDef.columnGroup && !processedColumnGroups.includes(colummDef.columnGroup)) {
        processedColumnGroups.push(colummDef.columnGroup)

        console.debug('column group', colummDef.columnGroup, 'found at index', index)

        targetColumnDef.push({
          ...colummDef,
          field: colummDef.columnGroup,
          label: colummDef.columnGroup,
          numeric: false,
          searchable: false,
          sortable: false,
          editable: false,
          _derived: {
            ...colummDef._derived,
            isColumnGroupColumn: true
          }
        })
      }

      targetColumnDef.push(colummDef)
    }

    this.localColumnDefinition = targetColumnDef

  }

  get hiddenColumns() {
    if (!(this.$route.path in this.$localSettings.table.hiddenColumns)) {
      this.$set(this.$localSettings.table.hiddenColumns, this.$route.path, [])
    }

    return this.$localSettings.table.hiddenColumns[this.$route.path]
  }

  set hiddenColumns(hiddenColumns: string[]) {
    this.$localSettings.table.hiddenColumns[this.$route.path] = hiddenColumns
  }

  public dropdownPosition(column: TableColumnDefinition) {
    // open dropdown to the left if the column is at the right end of the table
    const COLUMNS_FROM_RIGHT_END = 3
    const isLastColumn = (this.localColumnDefinition.length - this.localColumnDefinition.map(c => c.label).indexOf(column.label)) < COLUMNS_FROM_RIGHT_END

    return isLastColumn ? 'is-bottom-left' : 'is-bottom-right'
  }

  public toggleShowColumnGroup(groupName: string) {
    // toggle group name in array
    const index = this.expandedColumnGroups.indexOf(groupName)

    if (index === -1) {
      this.expandedColumnGroups.push(groupName)
    } else {
      this.expandedColumnGroups.splice(index, 1)
    }
  }

  created() {
    this.pagination_getData(true)
  }

  /**
   * can be called from parent component to force update data. e.g. after bulk update
   */
  public refreshData(configChanged = false) {
    this.updateFilterConfigFromUrlOnce = true

    if (!this.pagination_liveUpdateActive || configChanged)
      this.pagination_getData(true)
  }

  public accessorStringToValue(obj: any, acessor: string) {
    return accessorStringToValue(obj, acessor)
  }

  public assignValueBasedOnAccessorString(obj: any, acessor: string, value: any) {
    assignValueBasedOnAccessorString(obj, acessor, value)
  }


  // used as key to recreate table on column change
  public get columnsHash() {
    return this.columnDefinition.map(c => c.field).join('')
  }

  @Watch('pagination_paginatedData', { immediate: true })
  @Emit('table-data')
  onPagination_paginatedDataChange() {
    return this.pagination_paginatedData
  }

  @Watch('pagination_checkedRows', { immediate: true })
  @Emit('checked-rows')
  onPagination_checkedRowsChange() {
    return this.pagination_checkedRows
  }

  /**
 * a custom sort is required since by default null values are always sorted to the end
 * this sort is kinda borrowd from buefy 9.x but the isNil direction is reversed
 */
  public customSort(a: any, b: any, isAsc: boolean, key: string) {
    const isNil = (value: any) => value === null || value === undefined

    function getValueByPath(obj: any, path: string) {
      let value = path.split('.').reduce(function (o, i) {
        return o ? o[i] : null
      }, obj)
      return value
    }

    // Get nested values from objects
    let newA = getValueByPath(a, key)
    let newB = getValueByPath(b, key)
    // sort boolean type
    if (typeof newA === 'boolean' && typeof newB === 'boolean') {
      return isAsc ? Number(newA) - Number(newB) : Number(newB) - Number(newA)
    }
    // sort null values to the bottom when in asc order
    // and to the top when in desc order
    if (!isNil(newB) && isNil(newA)) return !isAsc ? 1 : -1
    if (!isNil(newA) && isNil(newB)) return !isAsc ? -1 : 1
    if (newA === newB) return 0

    newA = (typeof newA === 'string')
      ? newA.toUpperCase()
      : newA
    newB = (typeof newB === 'string')
      ? newB.toUpperCase()
      : newB

    return isAsc
      ? newA > newB ? 1 : -1
      : newA > newB ? -1 : 1

  }
}
