
import { Component, Watch } from 'vue-property-decorator'
import db, { increment, serverTimestamp } from '@/firebase'
import firebase from 'firebase/compat/app'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faTrash,
  faQrcode,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons'

import VImportExport from '@/components/VImportExport.vue'
import VEchoCode from '@/components/VEchoCode.vue'
import VModuleCompareData from '@/components/VModuleCompareData.vue'
import VFilterDropdownView from '@/components/VFilterDropdownView.vue'
import VFilterDateDropdownView from '@/components/VFilterDateDropdownView.vue'


import { AsidDB, asidState, isAssetAttributeKey, isIdentifierKey } from '@/types/typeAsid'
import ComparisonResult from '@/types/typeComparisonResult'

import AsidManager from '@/database/asidManager'
import { TenantDB } from '../../types/typeTenant'
import { mixins } from 'vue-class-component'
import VPaginationMixin from '../../components/mixins/VPaginateMixin.vue'
import TenantManager from '../../database/tenantManager'

import BackendConfigManager from '@/database/backendConfigManager'
import { BackendConfigDB } from '@/types/typeBackendConfig'

import JSZip from 'jszip'
import { downloadBlob } from '@/helpers/downloadHelper'
import databaseSchema, { asidStates } from '@/database/databaseSchema'

import { cloneObject, deepCompare } from '@/helpers/dataShapeUtil'
import { hasDBid } from '@/types/typeGeneral'
import { DataCache } from '@/helpers/dataCache'
import RecordMetaHelper from '@/database/recordMetaHelper'
import { DataElementDB, DataGroupDB, isDataKey } from '@/modules/data/typeDataModule'
import DataModule from '@/modules/data/dataModule'
import { ModuleManager } from '@/modules/moduleManager'
import { CategoryCollection } from '@/types/typeCategory'
import CategoryHelper from '@/database/categoryHelper'
import VAdminCreateAsidModal from './components/VAdminCreateAsidModal.vue'
import VDocSelectionView from './components/VDocSelectionView.vue'
import { getChunkedArray } from '@/helpers/arrayHelper'
import BatchManager from '@/database/batchManager'
import { BatchDB } from '@/types/typeBatch'


library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faTrash, faQrcode, faChevronLeft)

@Component({
  components: {
    VImportExport,
    VEchoCode,
    VFilterDropdownView,
    VFilterDateDropdownView,
    VAdminCreateAsidModal,
    VDocSelectionView
  },
  props: {
    filterByBatchID: {
      type: String,
      required: false
    }
  }
})
export default class AdminASIDList extends mixins<VPaginationMixin<AsidDB>>(VPaginationMixin) {

  public showCreateAsidsModal = false

  public onAsidsCreated() {
    this.getData(true)
  }

  public assignBatchID = ''

  public async onAssignBatch(add = true) {
    this.isLoading = true


    try {
      if (!this.assignBatchID) throw 'no batch was selected'

      for (const chunkOfAsids of getChunkedArray(this.pagination_checkedRows, 400)) {

        const batch = db.batch()

        for (const asid of chunkOfAsids) {
          if (add) {
            AsidManager.updateBatch(asid.id, this.$auth.userEmail, { batchIDs: firebase.firestore.FieldValue.arrayUnion(this.assignBatchID) as any }, batch)
          } else {
            AsidManager.updateBatch(asid.id, this.$auth.userEmail, { batchIDs: (this.assignBatchID) ? [this.assignBatchID] : [] }, batch)
          }
        }
        this.$helpers.notification.Success('Chunk Asid codes batches assigned')

        batch
          .commit()
          .then(d => {
            this.$helpers.notification.Success(`${this.pagination_checkedRows.length} Asid codes batches assigned`)
            // this.updatepagination_CheckedRows()
          })
          .catch(e => {
            this.$helpers.notification.Error(`Error occured while assigning batches to Asid codes: ${e.toString()}`)
          })
      }

      this.$helpers.notification.Success(`Updated state to ${this.formState} on ${this.pagination_checkedRows.length} codes`)
    } catch (e: any) {
      this.$helpers.notification.Error(`Error occured while updating state on Asid codes: ${e.toString()}`)
    } finally {
      await this.getData(true)
      this.isLoading = false
    }

  }

  public pagination_sortDirection: 'asc' | 'desc' = 'desc'
  public pagination_sortField: string = 'id'
  public pagination_perPage = 20

  public pagination_checkedRows: Array<AsidDB & hasDBid & { tenantName: string, tenantNumber: string, baseUrl: string, dataElement?: DataElementDB }> = []
  public isLoading = false
  public pagination_paginatedData: Array<AsidDB & hasDBid & { tenantName: string, tenantNumber: string, baseUrl: string, dataElement?: DataElementDB }> = []

  protected pagination_collectionReference = AsidManager.getDbCollectionReference()

  public asidStates = asidStates

  public onSort(field: string, order: 'asc' | 'desc') {
    console.log(field, order)

    this.pagination_sortField = field
    this.pagination_sortDirection = order
  }

  public onPageChange(page: number = 1) {
    this.pagination_currentPage = page
  }

  public formatPadded(number: number) {
    return TenantManager.formatPaddedNumber(number)
  }

  get anyLoading() {
    return this.isLoading || this.pagination_isPaginationLoading
  }

  public selectedFilterTenant: (TenantDB & hasDBid) | null = null
  public selectedFilterTenantName = ''
  public selectedFilterASID = ''


  @Watch('selectedFilterTenant')
  private onSelectedFilterTenantChanged() {
    this.pagination_filterConfig.find(fc => {
      if (fc.tableColumnField === 'tenantName') {
        fc.in = (this.selectedFilterTenant?.id) ? [this.selectedFilterTenant.id] : []
      }
    })
  }

  @Watch('$route', { immediate: true })
  private onChangeRoute() {
    if (this.$props.filterByBatchID)
      this.pagination_filterConfig.find(fc => {
        if (fc.tableColumnField === 'batchNames') {
          fc.in = [this.$props.filterByBatchID]
        }
      })
  }

  public pagination_filterConfig = [{
    fieldAccesor: { batchName: '' },
    collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
    objAcessor: { batchName: '' },
    type: 'exact' as const,
    in: [] as string[],
    range: [],
    notBackendSortable: false
  }, {
    fieldAccesor: { id: '' },
    collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
    objAcessor: { id: '' },
    type: 'exact' as const,
    in: [] as string[],
    range: [],
    notBackendSortable: false
  }, {
    fieldAccesor: { tenantID: '' },
    collectionPath: databaseSchema.COLLECTIONS.TENANTS.__COLLECTION_PATH__(),
    objAcessor: { id: '' },
    type: 'exact' as const,
    in: [] as string[],
    range: [],
    notBackendSortable: false
  }, {
    fieldAccesor: { tenantID: '' } as AsidDB,
    tableColumnField: 'tenantName',
    collectionPath: databaseSchema.COLLECTIONS.TENANTS.__COLLECTION_PATH__(),
    objAcessor: { id: '' } as TenantDB & hasDBid,
    objDisplayAcessor: { name: '' } as TenantDB,
    type: 'exact' as const,
    in: [] as string[],
    range: [],
    notBackendSortable: false
  }, {
    fieldAccesor: { tenantID: '' } as AsidDB,
    tableColumnField: 'tenantNumber',
    collectionPath: databaseSchema.COLLECTIONS.TENANTS.__COLLECTION_PATH__(),
    objAcessor: { id: '' } as TenantDB & hasDBid,
    objDisplayAcessor: { _number: 0 } as TenantDB,
    type: 'exact' as const,
    in: [] as string[],
    range: [],
    notBackendSortable: false
  }, {
    fieldAccesor: { _meta: { dateCreated: '' } } as AsidDB & hasDBid,
    collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
    objAcessor: { _meta: { dateCreated: '' } } as AsidDB & hasDBid,
    type: 'date' as const,
    in: [] as string[],
    range: [],
    notBackendSortable: false
  }, {
    fieldAccesor: { batchIDs: [''] } as AsidDB,
    tableColumnField: 'batchNames',
    collectionPath: databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__COLLECTION_PATH__(),
    objAcessor: { id: '' } as BatchDB & hasDBid,
    objDisplayAcessor: { name: '' } as BatchDB,
    type: 'exact' as const,
    in: [] as string[],
    range: [],
    notBackendSortable: false
  }
  ]


  // public getFilterConfig(field: string) {
  //   return this.filterConfig.find(f =>
  //     (f.tableColumnField)
  //       ? f.tableColumnField === field
  //       : acessorObjectToString(f.fieldAccesor) === field
  //   )
  // }


  // protected filterByConfig(query: firebase.firestore.Query<firebase.firestore.DocumentData>) {
  //   console.log('custom filter')

  //   let hasMultipleForIn = false
  //   let hasMultipleRange = false
  //   let hasIDMultipleRange = false
  //   let error = false
  //   for (const fc of this.filterConfig) {
  //     if (fc.in.length > 1) {
  //       if (hasMultipleForIn) {
  //         error = true
  //         this.$helpers.notification.Error('Setting multiple filters with more than one item is currently not supported')
  //         break
  //       }
  //       hasMultipleForIn = true
  //     }
  //     if (fc.range.length > 0 || ('id' in fc.fieldAccesor && fc.in.length > 0)) {
  //       if ('id' in fc.fieldAccesor && fc.in.length > 0) hasIDMultipleRange = true
  //       if (hasMultipleRange) {
  //         error = true
  //         if (hasIDMultipleRange) {
  //           this.$helpers.notification.Error('Setting range filters and ID filters is currently not supported')
  //         } else {
  //           this.$helpers.notification.Error('Setting multiple range filters is currently not supported')
  //         }
  //         break
  //       }
  //       hasMultipleRange = true
  //     }
  //   }

  //   if (error) return query

  //   this.filterConfig.forEach(filter => {
  //     const propertyAcessorPath = ('id' in filter.fieldAccesor) ?
  //       firebase.firestore.FieldPath.documentId() :
  //       acessorObjectToString(filter.fieldAccesor)

  //     if (filter.range.length > 0) {

  //       const filterMin = filter.range[0]
  //       let filterMax = (filter.range[1] || filter.range[0]) + '\uf8ff'

  //       if (filter.type === 'date')
  //         filterMax = filter.range[1] || filter.range[0]

  //       query = query
  //         .where(propertyAcessorPath, '>=', filterMin)
  //         .where(propertyAcessorPath, '<=', filterMax)

  //       // it is already ordered by the sort field, so dont order again
  //       if (this.pagination_sortField !== propertyAcessorPath)
  //         query = query.orderBy(propertyAcessorPath)

  //     } else if (filter.in.length === 1) {
  //       query = query
  //         .where(propertyAcessorPath, '==', filter.in.map(f => f === 'empty' ? '' : f)[0])
  //     } else if (filter.in.length > 1) {
  //       query = query
  //         .where(propertyAcessorPath, 'in', filter.in.map(f => f === 'empty' ? '' : f))
  //     }
  //   })

  //   return query
  // }

  // protected pagination_filter(query: firebase.firestore.Query<firebase.firestore.DocumentData>) {
  //   return this.filterByConfig(query)
  // }

  private dataCacheTenant = new DataCache<TenantDB & hasDBid>(async (key) => {
    return await TenantManager.get(key)
  })

  private dataCacheBatch = new DataCache<BatchDB & hasDBid>(async (key) => {
    return await BatchManager.get(key)
  })

  private dataCacheBackendConfig = new DataCache<BackendConfigDB & hasDBid>(async (key) => {
    return await BackendConfigManager.get(key)
  })

  private dataCacheDataElement = new DataCache<Array<DataElementDB & hasDBid>>(async (key) => {
    return await DataModule.getElementsDocs<DataElementDB & hasDBid>(key)
  })

  private dataCacheDataGroup = new DataCache(async (key) => {
    return await DataModule.getGroups<DataGroupDB & hasDBid>(key)
  })

  private dataCacheCategories = new DataCache<CategoryCollection>(async (key) => {
    return await CategoryHelper.getCategoriesCollection(key)
  })

  // used to identify if some data is still loading and the export must wait for all to complete
  private isAdditionalRowPropsLoading = 0
  protected async pagination_foreachDoc(doc: AsidDB & hasDBid & { batchNames?: string[] }) {
    if (doc.tenantID) {

      // TenantManager.get(doc.tenantID).then(cDoc => {
      //   if (cDoc)
      //     this.$set(doc, 'tenantName', cDoc.name)
      // })

      this.dataCacheTenant.get(doc.tenantID)
        .then((d) => {
          this.$set(doc, 'tenantName', d.name)
          this.$set(doc, 'tenantNumber', d._number)
        }).catch(() => {
          this.$set(doc, 'tenantName', 'not found')
          this.$set(doc, 'tenantNumber', 'not found')
        })

      this.isAdditionalRowPropsLoading++
      this.dataCacheBackendConfig.get(doc.tenantID)
        .then((d) => {
          this.$set(doc, 'baseUrl', d.asid.baseUrl)
          this.$set(doc, 'backendConfig', d)
        }).catch(() => {
          this.$set(doc, 'baseUrl', 'not found')
        }).finally(() => {
          this.isAdditionalRowPropsLoading--
        })

      this.isAdditionalRowPropsLoading++
      this.dataCacheDataElement.get(doc.tenantID)
        .then(async (d) => {
          // get the date Element with the
          let dataElements: any[] = []
          if (d.length > 0)
            dataElements = ModuleManager.filterElementsMatchingReferences(d,
              await this.dataCacheCategories.get(doc.tenantID || ''),
              doc.categoryIDs,
              doc.id,
              doc.identifierValue
            )
          this.$set(doc, 'dataElement', dataElements[0] || [])
        }).catch(() => {
          this.$set(doc, 'dataElement', 'not found')
        }).finally(() => {
          this.isAdditionalRowPropsLoading--
        })

    } else {
      this.$set(doc, 'tenantID', 'not assigned') // use 0 for proper sorting
      this.$set(doc, 'backendConfig', cloneObject(BackendConfigManager.defaultDocDB))
    }

    doc.batchIDs.filter(b => b).forEach(bid => {
      this.isAdditionalRowPropsLoading++
      this.dataCacheBatch.get(bid).then((d) => {
        if (!('batchNames' in doc)) {
          doc.batchNames = [d.name]
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          doc.batchNames = [...doc.batchNames!, d.name]
        }
        this.$set(doc, 'batchNames', doc.batchNames)
      }).catch(() => {
        this.$set(doc, 'batchNames', ['not found'])
      }).finally(() => {
        this.isAdditionalRowPropsLoading--
      })
    })
  }

  /**
   * checked rows references are lost after updating the underlying objects
   * thus they have to be updated by id
   */
  @Watch('pagination_paginatedData')
  private updatepagination_CheckedRows() {
    this.pagination_checkedRows = this.pagination_checkedRows.map(
      asid => this.pagination_paginatedData.find(q => q.id === asid.id)
    )
      .filter((q): q is AsidDB & hasDBid & { tenantName: string, tenantNumber: string, baseUrl: string, dataElement?: DataElementDB } => !!q)
  }

  public onReserveCheckedAsidCodes() {
    this.reserveAsidCodes(this.pagination_checkedRows)
  }

  public isFetchingTenants = false
  public selectedTenant: (TenantDB & hasDBid) | null = null
  public tenants: Array<TenantDB & hasDBid> = []
  public isTenantSelectionModalactive = false
  public isAssigningCodes = false

  public onGetTenantData(searchString: string) {
    this.isFetchingTenants = true
    this.tenants = []

    const capitalize = (s: string) => {
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    Promise.all([
      db.collection('Tenants').where('name', '>=', searchString).where('name', '<=', searchString + '\uf8ff').limit(10).get().then((data) => {
        return data
      }),
      db.collection('Tenants').where('name', '>=', searchString.toLowerCase()).where('name', '<=', searchString.toLowerCase() + '\uf8ff').limit(10).get().then((data) => {
        return data
      }),
      db.collection('Tenants').where('name', '>=', searchString.toUpperCase()).where('name', '<=', searchString.toUpperCase() + '\uf8ff').limit(10).get().then((data) => {
        return data
      }),
      db.collection('Tenants').where('name', '>=', capitalize(searchString)).where('name', '<=', capitalize(searchString) + '\uf8ff').limit(10).get().then((data) => {
        return data
      }),
      db.collection('Tenants').where('_number', '==', +searchString).limit(5).get().then((data) => {
        return data
      })
    ]).then((datas) => {

      this.tenants = datas.flatMap(data => data.docs.map(doc => ({ id: doc.id, ...doc.data() as TenantDB })), [] as Array<TenantDB & hasDBid>) // combine results
        .reduce((acc, comp) => { // make unique
          if (!acc.find(C => C.id === comp.id)) acc.push(comp)
          return acc
        }, [] as Array<TenantDB & hasDBid>)
    })
      .finally(() => this.isFetchingTenants = false)


    // db.collection('Tenants').where('name', '>=', searchString.toLowerCase()).limit(10).get().then((data) => {
    //   this.tenants = [...this.tenants, ...data.docs.map(doc => ({ id: doc.id, ...doc.data() as TenantDB }))]
    //   this.isFetchingTenants = false
    // })
  }

  private assignAsidCodes(asids: Array<AsidDB & hasDBid>) {

    if (!this.selectedTenant) {
      this.$helpers.notification.Warn('No Codes selected')
      return
    }

    this.isAssigningCodes = true

    AsidManager.assignAsids(this.selectedTenant.id, this.$auth.userEmail, asids.map(a => a.id))
      .then(d => {
        this.$helpers.notification.Success(`${asids.length} Asid codes Assigned`)
        this.getData(true)
      })
      .catch(e => {
        this.$helpers.notification.Error(`Error occured while assigning Asid codes: ${e.toString()}`)
      }).finally(() => {
        this.isAssigningCodes = false
        this.isTenantSelectionModalactive = false
      })
  }

  public onAssignCheckedAsidCodes() {
    this.assignAsidCodes(this.pagination_checkedRows)
  }

  public formState: asidState = 'generated'
  public async onSetState() {
    this.isLoading = true
    try {
      for (const asid of this.pagination_checkedRows) {
        await AsidManager.update(asid.id, this.$auth.userEmail, { state: this.formState })
      }
      this.$helpers.notification.Success(`Updated state to ${this.formState} on ${this.pagination_checkedRows.length} codes`)
    } catch (e: any) {
      this.$helpers.notification.Error(`Error occured while updating state on Asid codes: ${e.toString()}`)
    } finally {
      await this.getData(true)
      this.isLoading = false
    }
  }

  // public formNote = ''
  // public async onSetNote() {
  //   this.isLoading = true
  //   try {
  //     for (const asid of this.pagination_checkedRows) {
  //       await AsidManager.update(asid.id, this.$auth.userEmail, { : this.formState })
  //     }
  //     this.$helpers.notification.Success(`Updated state to ${this.formState} on ${this.pagination_checkedRows.length} codes`)
  //   } catch (e: any) {
  //     this.$helpers.notification.Error(`Error occured while updating state on Asid codes: ${e.toString()}`)
  //   } finally {
  //     await this.getData(true)
  //     this.isLoading = false
  //   }
  // }

  public onRemoveCheckedAsidCodes() {
    this.$buefy.dialog.confirm({
      title: 'Deleting Asids',
      message: `Are you sure you want to <b>delete ${this.pagination_checkedRows.length
        } Asid codes? This action cannot be undone.`,
      confirmText: 'Delete Asid',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        this.removeAsidCodes(this.pagination_checkedRows)
      }
    })
  }

  public async onDownloadAsidCodes(qrOnly = false) {
    if (this.isAdditionalRowPropsLoading > 0) {
      this.$helpers.notification.Error('not all data has been loaded, wait a few seconds and try again :(')
      return
    }

    this.isLoading = true

    for (const asid of this.pagination_checkedRows) {
      if (!deepCompare(this.pagination_checkedRows[0].codeConfig, asid.codeConfig)) {
        this.$helpers.notification.Warn('not all exposted codes share the same code template. This might be a mistake')
        break
      }
    }


    const zip = new JSZip()
    const folder = zip.folder('echo-codes')

    if (!folder) {
      throw 'zip download is not supported by your browser'
    }


    // tenants to get the base url
    const tenantBackendConfigPerTenant: { [key: string]: BackendConfigDB & hasDBid } = {}
    const codeTemplateSVGCache: { [key: string]: string } = {}

    let index = 0
    for (const asid of this.pagination_checkedRows) {
      let baseUrl = ''
      let backendConfig!: BackendConfigDB
      if (asid.tenantID && asid.tenantID !== 'not assigned') {
        if (!(asid.tenantID in tenantBackendConfigPerTenant)) {
          tenantBackendConfigPerTenant[asid.tenantID] = await BackendConfigManager.get(asid.tenantID)
        }

        backendConfig = tenantBackendConfigPerTenant[asid.tenantID]
        baseUrl = tenantBackendConfigPerTenant[asid.tenantID].asid.baseUrl
      }

      if (!(asid.codeConfig.svgTemplateUrl in codeTemplateSVGCache)) {
        codeTemplateSVGCache[asid.codeConfig.svgTemplateUrl] = await AsidManager.getCodeTemplateSVGTextFromUrl(asid.codeConfig.svgTemplateUrl)
      }

      const svgTemplateText = codeTemplateSVGCache[asid.codeConfig.svgTemplateUrl]

      let svgString = ''
      if (qrOnly) {
        svgString = AsidManager.getQrCodeSvg(asid.id, baseUrl, asid.codeConfig.errorCorrectionLevel)
      } else {

        // create object with identifier, attribute names
        const identifierNameeKeyedObject = backendConfig ? Object.keys(asid.identifierValue).reduce((acc, key) => {
          acc[backendConfig.asid.identifierDefinition[key as isIdentifierKey].name] = asid.identifierValue[key as isIdentifierKey]
          return acc
        }, {} as any) : {}

        const attributeNameKeyedObject = backendConfig ? Object.keys(asid.assetAttributeValue).reduce((acc, key) => {
          acc[backendConfig.asid.assetAttributeDefinitions[key as isAssetAttributeKey].name] = asid.assetAttributeValue[key as isAssetAttributeKey]
          return acc
        }, {} as any) : {}

        this.isAdditionalRowPropsLoading++
        const dataGroupsForTenant = await this.dataCacheDataGroup.get(asid.tenantID || '')

        // convert data.i2 to data.name where name is retrieved from the dataDefinition
        const dataNameKeyedObject = Object.keys(asid.dataElement?.data || {}).reduce((acc, key) => {
          // get the dataDefinition
          const dataDefinition = dataGroupsForTenant.find(dg => dg.id === asid.dataElement?.public.groupID)?.dataDefinition
          const dataDefinitionEntry = Object.entries(dataDefinition || {}).find(([ddefKey, ddef]) => ddefKey === key)
          if (dataDefinitionEntry && asid.dataElement && asid.dataElement.data)
            acc[dataDefinitionEntry[1].name] = asid.dataElement?.data[key as isDataKey]
          return acc
        }, {} as any)


        svgString = (await AsidManager.getCodeSVG(
          asid.id,
          baseUrl,
          svgTemplateText,
          asid.codeConfig.errorCorrectionLevel,
          [asid.codeConfig.customText, asid.codeConfig.customText2],
          asid.codeConfig.logoUrl,
          asid.codeConfig.color,
          true,
          {
            identifier: {
              ...identifierNameeKeyedObject,
              ...asid.identifierValue
            },
            attribute: {
              ...attributeNameKeyedObject,
              ...asid.assetAttributeValue
            },
            data: {
              ...dataNameKeyedObject,
              ...(asid.dataElement) && { ...asid.dataElement.data }
            }

          },
          asid.categoryIDs,
          undefined,
          backendConfig
        )).innerHTML
      }

      folder.file(`asid_${++index}_${asid.id}.svg`, svgString)
      // download(svgString, `asid-${asid.id}.svg`, 'image/svg+xml')
    }
    await folder.generateAsync({ type: 'blob' })
      .then(content =>
        downloadBlob(content, `${this.pagination_checkedRows.length}_${qrOnly ? 'QR' : 'echo'}-codes_${this.pagination_checkedRows[0].batchName.replaceAll('.', '-').replaceAll(' ', '_')}`)
      )

    this.isLoading = false
  }

  private reserveAsidCodes(asids: Array<AsidDB & hasDBid>) {
    const batch = db.batch()

    for (const asid of asids) {
      console.log(asid)

      const asidRef = AsidManager.getDbDocReference(asid.id)
      const asidUpdate: Partial<AsidDB> = {
        state: 'printing'
      }
      batch.set(asidRef, asidUpdate, { merge: true })
    }

    batch
      .commit()
      .then(d => {
        this.$helpers.notification.Success(`${asids.length} Asid codes reserved`)
        // this.updatepagination_CheckedRows()
      })
      .catch(e => {
        this.$helpers.notification.Success(`Error occured while reserving Asid codes: ${e.toString()}`)
      })
  }

  private removeAsidCodes(asids: Array<AsidDB & hasDBid>) {

    try {
      this.isLoading = true
      const batch = db.batch()

      for (const asid of asids) {
        if (asid.activated || asid.tenantID || asid.batchIDs.length !== 0) {
          throw 'can not delete Asid which are assigned to a tenant or a batch'
        }

        const asidRef = AsidManager.getDbDocReference(asid.id)
        batch.delete(asidRef)
      }

      batch
        .commit()
        .then(async d => {
          this.$helpers.notification.Success(`${asids.length} Asids deleted!`)
          await this.getData(true)
          this.updatepagination_CheckedRows()
        })
        .catch(e => {
          this.$helpers.notification.Error(`Error occured while deleting Asid codes: ${e.toString()}`)
        })


    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
    } finally {
      this.isLoading = false
    }
  }


  public exportAsid(): Array<any> {
    return this.pagination_checkedRows.map(r => ({
      id: r.id,
      'tenantID_READONLY': r.tenantID,
      'tenantName_READONLY': r.tenantName,
      batchName: r.batchName,
      state: r.state
    })) // add id, since its not enumerable
  }

  public importAsid(importedData: Array<Partial<AsidDB & hasDBid>>) {
    const promises: Array<Promise<firebase.firestore.DocumentSnapshot>> = []

    console.log(importedData)

    // get all docs matching the imported importedData
    for (const importedDataset of importedData) {
      promises.push(
        AsidManager.getDbCollectionReference()
          .doc(importedDataset.id)
          .get()
      )
    }
    //todo progress indicator
    Promise.all(promises)
      .then(docs => {
        console.table([docs, importedData])

        const databaseDataset: Array<AsidDB & hasDBid> = docs.map(d => {
          const data = d.data() as AsidDB
          const id = d.id
          return { id, ...data }
        })

        this.$buefy.modal.open({
          props: {
            datasets: {
              oldData: databaseDataset,
              newData: importedData
            }
          },
          events: {
            confirmed: (datasets: Array<ComparisonResult>) => {
              console.log(datasets)

              const batch = db.batch()

              for (const compResult of datasets) {
                console.log(compResult)

                const asidRef = AsidManager.getDbDocReference(compResult.id)
                const asidUpdate: { [key: string]: any } = {
                  ...RecordMetaHelper.getUpdateMetaInstructions(serverTimestamp, increment, this.$auth.userEmail)
                }
                asidUpdate[compResult.attribute] = compResult.newValue // todo check if this works for multiple updated props at once
                batch.update(asidRef, asidUpdate)
              }

              batch
                .commit()
                .then(d => {
                  this.$helpers.notification.Success(`${datasets.length} Asid codes Updated`)
                  this.getData(true)
                })
                .catch(e => {
                  this.$helpers.notification.Success(`Error occured while updating Asid codes: ${e.toString()}`)
                })
            }
          },
          parent: this,
          component: VModuleCompareData,
          hasModalCard: true
        })
      })
      .catch(e => {
        this.$helpers.notification.Error(`Error occured while importing Asid codes: ${e.toString()}`)
      })
  }

  public mounted() {
    this.getData()
  }

}
