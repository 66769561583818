
import { Component, Vue } from 'vue-property-decorator'

@Component({

})
export default class VFormRegisterView extends Vue {


  public isLoading = false


  public async onDelete() {

    this.$buefy.dialog.confirm({
      title: 'Deleting User',
      message: `Are you sure you want to <b>delete user ${this.$auth.userEmail}</b>? This action cannot be made undone.`,
      confirmText: 'Delete User',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        this.isLoading = true
        await this.$auth.deleteUser()
          .then(() => {
            this.$helpers.notification.Error('User removed.')
          })
          .catch((e) => this.$helpers.notification.Error('Could not remove user. ' + e))
        this.isLoading = false
      }
    })


  }
}
