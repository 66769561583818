
import { Component } from 'vue-property-decorator'


import VInputLocalizedText from '@/components/VInputLocalizedText.vue'
import VInputLocalizedEditor from '@/components/editor/VInputEditorLocalized.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'

import TenantManager from '../../database/tenantManager'
import databaseSchema from '@/database/databaseSchema'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'


@Component({
  components: {
    VInputLocalizedEditor,
    VInputLocalizedText,
    VRecordMeta
  }
})
export default class BackendConfigLegal extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {


  public isLoading = true

  private appConfig = { ...TenantManager.defaultAppConfigDB }

  get documentPath() {
    return TenantManager.getDbAppConfigDocReference(this.$auth.tenant.id).path
  }

  get documentPrivileges() {
    return databaseSchema.COLLECTIONS.TENANTS.DATA.APP_CONFIG.__PRIVILEGES__
  }

  public async initialise() {
    this.isLoading = true
    await this.$bindSnapshot('appConfig', TenantManager.getDbAppConfigDocReference(this.$auth.tenant.id))
    this.isLoading = false
  }

  // @Watch('tenant')
  // onComapynChanged(){
  //   this.appConfig = merge(this.tenant.public.config.appConfig, TenantManager.defaultDocDB.public.config.appConfig)
  // }

  public async $save() {
    try {
      this.isLoading = true

      await TenantManager.updateAppConfig(this.$auth.tenant.id, this.$auth.userEmail, this.appConfig)
      this.$helpers.notification.Success('Data updated')
    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading = false
    }
  }

  async created() {
    await this.initialise()
  }


}
