
import { Component, Vue } from 'vue-property-decorator'
import VFormLogin from '@/components/VFormLoginModalView.vue'
import VFormRegisterView from '@/components/VFormRegisterView.vue'
import Avatar from 'vue-avatar'
import { copyDebugInformationToClipboard } from '@/helpers/debugInfoHelper'
import VModalCategoryFilter from '@/components/VModalCategoryFilter.vue'
import moment from 'moment'

@Component({
  components: {
    VFormLogin,
    VFormRegisterView,
    Avatar,
    VModalCategoryFilter
  }
})
export default class TheMainMenu extends Vue {

  public isFilterModalActive = false
  public isLoading = false


  public isLoginComponentModalActive: boolean = false
  public isRegistrationComponentModalActive: boolean = false

  public link = {
    app: `${process.env.VUE_APP_URL_PROTOCOL}${process.env.VUE_APP_APP_BASE_URL}`,
    backend: `${process.env.VUE_APP_URL_PROTOCOL}${process.env.VUE_APP_BACKEND_BASE_URL}`,
    admin: `${process.env.VUE_APP_URL_PROTOCOL}${process.env.VUE_APP_ADMIN_BASE_URL}`
  }

  public env = process.env.VUE_APP_FIREBASE_ENV

  public appVersion = process.env.VUE_APP_VERSION || '0'
  public appBuildHash = (+(process.env.VUE_APP_BUILD_TIME || 0)).toString(36)
  public appBuildTime = moment(+(process.env.VUE_APP_BUILD_TIME || 0)).format('YYYY-MM-DD HH:mm:ss')

  get isBackend() {
    return process.env.VUE_APP_PAGE === 'backend'
  }

  public isMenuOpen = false

  public async onClickChangeTenant() {
    this.$localSettingsGlobal.login.selectedTenantID = ''
    // if not on the landing page, redirect to landing page
    if (this.$router.currentRoute.name !== 'start') {
      this.isLoading = true
      // toast the tenant change and redirect to landing page
      this.$buefy.toast.open({ message: 'Chaning Tenant...' })
      await this.$router.push({ name: 'start' })
    }
    this.$router.go(0)
  }

  public toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen
  }

  public onCopyDebugInfoClicked() {
    // add tenantid query parameter to the url
    const urlWithTenantID = new URL(window.location.href)
    urlWithTenantID.searchParams.set('tenant-id', this.$auth.tenantID)

    const successfull = copyDebugInformationToClipboard({
      tenant: this.$auth.tenantName + ' - ' + this.$auth.tenantID,
      tenantUrl: urlWithTenantID.href,
      user: this.$auth.userName + ' - ' + this.$auth.user?.email,
      appBuildTime: this.appVersion + ' - ' + this.appBuildTime,
      appBuildHash: this.appVersion + ' - ' + this.appBuildHash
    })

    if (successfull) {
      this.$helpers.notification.Info('Debug information copied to clipboard')
    } else {
      this.$helpers.notification.Error('Error occured while trying to copy debug information to clipboard')
    }
  }
}
