
import { Component, Vue } from 'vue-property-decorator'


import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faTrash,
  faQrcode,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons'

import databaseSchema from '@/database/databaseSchema'
import AdminNotificationManager from '@/database/adminNotificationManager'


library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faTrash, faQrcode, faChevronLeft)

@Component({
  components: {}
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class AdminMockdata extends Vue {


  public isLoading = false

  public globalNotification = databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__EMPTY_DOC__
  public globalNotificationActive = false

  public async onUpdateNotification() {
    AdminNotificationManager.updateAdminNotification(this.$auth.userEmail, this.globalNotification)
      .catch((e) => this.$helpers.notification.Error(e))
  }

  public mounted() {

    AdminNotificationManager.onSnapshot((notification) => {
      this.globalNotification = notification


    }, (e) => console.error(e))
  }
}
