import Vue from 'vue'
import Router, { Route } from 'vue-router'

import Backend from '@/pages/backend/Backend.vue'

import BackendDashboard from '@/pages/backend/Backend_Dashboard.vue'
import BackendCategoriesList from '@/pages/backend/Backend_Categories_List.vue'
import BackendCategoriesSingle from '@/pages/backend/Backend_Categories_Single.vue'
import BackendAsidList from '@/pages/backend/Backend_Asid_List.vue'
import BackendActivateAsid from '@/pages/backend/Backend_ActivateAsid.vue'
import BackendAsidSingle from '@/pages/backend/Backend_Asid_Single.vue'

import BackendConfig from '@/pages/backend/Backend_Config_List.vue'

import BackendHelpcenter from '@/pages/backend/Backend_Helpcenter.vue'
import BackendStart from '@/pages/backend/Backend_Start.vue'

import BackendUserSingle from '@/pages/backend/Backend_User_Single.vue'

import BackendPageNotFound from '@/pages/backend/Backend_PageNotFound.vue'

import BackendModulesList from '@/pages/backend/Backend_Modules_List.vue'
import BackendWidgetsList from '@/pages/backend/Backend_Widgets_List.vue'

import { ModuleManager } from '@/modules/moduleManager'

import { configChildRoutes } from './routerConfigChildRoutes'
import { HELP_RESSOURCE_SECTIONS } from '@/businessLogic/constants'
import { viewPrivileges } from '@/helpers/privileges'

Vue.use(Router)

type HelpSectionType = typeof HELP_RESSOURCE_SECTIONS[number]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'is-active is-active--exact',
  linkActiveClass: 'is-active child-is-active',
  routes: [
    {
      path: '/register',
      name: 'register',
      component: () => import(/* webpackChunkName: "register" */ '@/pages/backend/Backend_Register.vue')
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import(/* webpackChunkName: "auth" */ '@/pages/backend/Backend_Auth.vue')
    },
    {
      path: '/tos',
      name: 'tos',
      meta: {
        label: 'Terms Of Service / Allgemeine Geschäftsbedingungen',
        description: ''
      },
      component: () => import(/* webpackChunkName: "tos" */ '@/pages/backend/Backend_Tos.vue')
    },
    {
      path: '/tools',
      name: 'tools',
      meta: {
        label: 'Tools',
        description: ''
      },
      component: () => import(/* webpackChunkName: "tos" */ '@/pages/backend/Backend_Tools.vue')
    },

    {
      path: '/odp',
      name: 'odp',
      component: () => import(/* webpackChunkName: "odp" */ '@/pages/backend/Backend_Odp.vue')
    },
    // {
    //   path: 'login',
    //   name: 'login',
    //   component: BackendLogin
    // },
    {
      path: '/reset',
      name: 'reset',
      component: () => import(/* webpackChunkName: "reset" */ '@/pages/backend/Backend_ResetPw.vue')
    },
    { path: '/', redirect: { name: 'start' } },
    {
      path: '/',
      name: 'backend',
      component: Backend,
      children: [
        ...ModuleManager.getRoutes(),
        {
          path: 'manage-categories',
          name: 'manage-categories',
          component: BackendCategoriesList,
          meta: {
            label: 'Categories',
            privileges: [viewPrivileges.CATEGORY_MANAGE_VIEW],
            description: 'Manage Categories',
            helpSection: 'Categories' as HelpSectionType
          },
          children: [
            {
              path: ':categoryID',
              name: 'categories-single',
              component: BackendCategoriesSingle,
              meta: {
                label: 'Category',
                description: 'Manage a Category',
                helpSection: 'Categories' as HelpSectionType
              },
              props: true
            }
          ]
        },
        {
          path: 'manage-echo-codes',
          name: 'manage-asids',
          component: BackendAsidList,
          meta: {
            label: 'ECHO Codes',
            privileges: [viewPrivileges.CODE_LIST_VIEW],
            description: 'Manage your ECHO Codes. Each code represents an asset.',
            helpSection: 'List-Code' as HelpSectionType
          }
        },
        {
          path: 'settings',
          name: 'settings-overview',
          component: BackendConfig,
          meta: {
            label: 'Configuration',
            privileges: [
              viewPrivileges.APP_CONFIG_VIEW,
              viewPrivileges.BACKEND_CONFIG_VIEW,
              viewPrivileges.USER_MANAGE_VIEW
            ],
            description: 'Configure',
            helpSection: 'Settings' as HelpSectionType
          },
          children: configChildRoutes
        },
        {
          path: 'manage-modules',
          name: 'manage-modules',
          component: BackendModulesList,
          props: true,
          meta: {
            label: 'Modules',
            privileges: [viewPrivileges.MODULES_MANAGE_VIEW],
            description:
              'Overview of all modules in your ECHO PRM Backend. You can also access the Modules from the sidemenu.',
            helpSection: 'Manage-Modules' as HelpSectionType
          }
        },
        {
          path: 'manage-widgets',
          name: 'manage-widgets',
          component: BackendWidgetsList,
          props: true,
          meta: {
            label: 'Widgets',
            showPreview: true,
            privileges: [viewPrivileges.WIDGETS_MANAGE_VIEW],
            description:
              'You can define the order of the different Widgets as they will be displayed in the App. You can also add new Widgets at certain positions.',
            // description: 'Order and Create Widgets'
            helpSection: 'Order-Widgets' as HelpSectionType
          }
        },

        {
          path: 'start',
          name: 'start',
          component: BackendStart,
          props: true,
          meta: {
            label: 'Welcome',
            description: 'This is the ECHO PRM backend, select the respective functionalities from the left menu.'
          }
        },

        {
          path: 'user',
          name: 'user-single',
          component: BackendUserSingle,
          props: true,
          meta: {
            label: 'User',
            description: 'Manage your User'
          }
        },
        {
          path: 'activate-echo-code/:asid?',
          name: 'activate-asid',
          component: BackendActivateAsid,
          meta: {
            label: 'Activate ECHO Code',
            privileges: [viewPrivileges.CODE_ACTIVATE_VIEW],
            description: 'Activate and modify the category and identifier assignment of your ECHO Code',
            helpSection: 'Activate-Code' as HelpSectionType
          },
          props: true
        },
        {
          path: 'echo-code/:asid',
          name: 'asid-single',
          component: BackendAsidSingle,
          meta: {
            label: 'View ECHO Asset',
            showPreview: true,
            privileges: [viewPrivileges.CODE_LIST_VIEW, viewPrivileges.CODE_ACTIVATE_VIEW],
            description: 'Activate and modify the category and identifier assignment of your ECHO Asset',
            helpSection: 'Activate-Code' as HelpSectionType
          },
          props: (route) => ({ ...route.query, ...route.params }) // also applies query params as props
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: BackendDashboard,
          meta: {
            label: 'Dashboard',
            privileges: [viewPrivileges.DASHBOARD_VIEW],
            // description:'Manage some tabular data'
            helpSection: 'Dashboard' as HelpSectionType
          }
        },
        {
          path: 'helpcenter',
          name: 'helpcenter',
          component: BackendHelpcenter,
          meta: {
            label: 'Help Center',
            description: 'View all help ressources.'
          }
        },
        { path: '*', component: BackendPageNotFound }
      ]
    }
    // {
    //   path: '/feedback',
    //   name: 'feedback',
    //   // route level code-splitting
    //   // this generates a separate chunk (feedback.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "feedback" */ './views/Feedback.vue')
    // }
  ]
})

router.beforeEach(async (to: Route, from: Route, next: any) => {
  document.title = process.env.VUE_APP_TITLE_PREFIX + (to.meta.label || 'ECHO PRM Backend')
  next()
  // is done in VUE to not have to redirect but show a prompt instead
  // if (to.meta?.privileges?.length > 0) {
  //   await backendAuth.waitForUser()
  //   if (backendAuth.user && !backendAuth.userHasPrivilege(to.meta?.privileges)) {
  //     next({
  //       name: 'insufficient',
  //       // path: '/unauthorized',
  //       query: {
  //         requiredPrivileges: to.meta?.privileges
  //       }
  //     })
  //   } else {
  //     next()
  //   }
  // } else {
  //   next()
  // }
})

export default router
