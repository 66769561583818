
import { acessorObjectToDatatype, acessorObjectToString } from '@/database/dbHelper'
import { firebase } from '@/firebase'
import { DeepPartial, hasDBid } from '@/types/typeGeneral'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { FilterConfigNew, FilterUtil, SnapshotData, SnapshotDatas, SortConfig } from '@/database/filterUtil'
import { handlePromiseError } from '@/helpers/notificationHelper'

export type FilterConfig<T = any> = {
  fieldAccesor: DeepPartial<T> // of the filtered dataset
  collectionPath?: string // of the filter dropdown
  objAcessor: any // of the filter dropdown - for use in the filtered dataset
  objDisplayAcessor?: any // of the filter dropdown - for displaying
  options?: any[] // hardcoded options of the filter dropdown instead of a db query
  hideEmptyOption?: boolean
  tableColumnField?: string
  queryFilter?: (query: firebase.firestore.Query<firebase.firestore.DocumentData>) => firebase.firestore.Query<firebase.firestore.DocumentData>
  type: 'exact' | 'date' | 'categories' | 'exact-number'
  in: string[] | boolean[]
  range: (Date | string)[]
  presets?: any[] // presets are not changeable by the user
  notBackendSortable: boolean
  displayLeft?: boolean
  emptyValue?: any
  hideRange?: boolean
}

// todo add vComponent for basic sortable table including view
@Component({

})
export default class VPaginationMixin<DbDataType> extends Vue {
  public pagination_sortDirection: 'asc' | 'desc' = 'asc'
  public pagination_sortField: string = 'id'

  public pagination_perPage = 20
  public pagination_currentPage = 1
  public pagination_isPaginationLoading = false

  public pagination_liveUpdateOnFirstPage = false

  public pagination_paginatedData: Array<DbDataType & hasDBid> = []

  public pagination_checkedRows: Array<DbDataType & hasDBid> = []

  public pagination_totalItemsEstimation = this.pagination_perPage + 1


  protected notBackendSortable = false
  private paginationSnapshotUnbindHandle?: () => void

  public get pagination_liveUpdateActive() {
    console.log('pagination_liveUpdateActive', this.pagination_liveUpdateOnFirstPage && this.pagination_currentPage === 1)
    console.log('pagination_liveUpdateOnFirstPage', this.pagination_liveUpdateOnFirstPage)
    console.log('pagination_currentPage', this.pagination_currentPage)

    return this.pagination_liveUpdateOnFirstPage && this.pagination_currentPage === 1
  }

  public pagination_filterConfig: FilterConfig<DbDataType>[] = []

  public pagination_onSort(field: string, order: 'asc' | 'desc') {
    // console.log(field, order)

    this.pagination_sortField = field
    this.pagination_sortDirection = order
  }

  public pagination_onPageChange(page: number = 1) {
    this.pagination_currentPage = page
  }

  @Watch('pagination_sortField')
  @Watch('pagination_sortDirection')
  public onSortChange() {
    console.log('onSortChange', this.pagination_sortField, this.pagination_sortDirection)
    this.getData(false, true)
      .catch(e => this.$helpers.notification.Error(e))
    // await this.getData()
  }

  @Watch('pagination_perPage')
  @Watch('pagination_currentPage')
  public onpagination_perPageChange() {
    console.log('onpagination_perPageChange', this.pagination_perPage, this.pagination_currentPage)
    this.getData() // todo when current page gets updated after pagination_perPage, many items may be loaded
      .catch(e => this.$helpers.notification.Error(e))
  }

  @Watch('pagination_liveUpdateActive') // if changing from page > 1 to 1 we need to reset to be live
  public onChangeLiveUpdateActive() {
    // dont reset when pagination_liveUpdateOnFirstPage is deactivated manually to keep the already loaded data
    if (this.pagination_liveUpdateActive || this.pagination_currentPage !== 1) {

      // dont update if is still loading
      if (!this.pagination_isPaginationLoading) {
        console.log('pagination_liveUpdateActive', this.pagination_liveUpdateActive)
        this.getData(true, false) // todo when current page gets updated after pagination_perPage, many items may be loaded
          .catch(e => this.$helpers.notification.Error(e))
      }
    }
  }

  @Watch('pagination_liveUpdateOnFirstPage')
  public onliveUpdateOnFirstPageChange() {
    // on falling edge, just discard the listener, to keep the data
    if (!this.pagination_liveUpdateOnFirstPage) {
      this.paginationSnapshotUnbindHandle?.()
    }
    // await this.getData(true, false) // todo when current page gets updated after pagination_perPage, many items may be loaded
  }

  @Watch('pagination_filterConfig', { deep: true })
  private onPaginationFilterConfigChanged() {
    this.getData(true)
      .catch(e => this.$helpers.notification.Error(e))
  }

  @Watch('pagination_checkedRows')
  public onCheckdRowsChanged() {
    if (this.pagination_checkedRows.length > 0)
      this.pagination_liveUpdateOnFirstPage = false
  }

  private lastPageDocRef: any | null = null
  protected pagination_allLoaded = false
  protected pagination_collectionReference: firebase.firestore.CollectionReference = null as any
  protected pagination_collectionGroupName: string = ''
  // may be used to force update components
  protected pagination_updateCounter = 0

  // callback called for each doc retrieved from db
  protected async pagination_foreachDoc(doc: SnapshotData<DbDataType>) {
    //
  }

  // callback called to filter data 
  // filter does only work when grouped by the filter property. 
  // It does not really work in combination with pagination. -> only filter locally if all elements are loaded
  protected pagination_filter(): FilterConfigNew<DbDataType>[] {
    return []
  }

  protected async pagination_getData(reset = false, sortChange = false) {
    console.log('pagination_getData', reset, sortChange)
    return this.getData(reset, sortChange)
  }

  // if an update is pending, we dont want to start a new one. wait for the current one to finish then rerun with the new config
  private pagination_updatePending = {
    updatePending: false,
    reset: false,
    sortChange: false
  }

  protected async getData(reset = false, sortChange = false) {

    // const PAGINATION_LIMIT = 20
    const MAX_QUERY_COUNT = 5000

    // convert this.pagination_filterConfig to FilterConfigNew
    let filterConfigNew: FilterConfigNew[] = []

    // convert this.pagination_filterConfig to FilterConfigNew
    filterConfigNew = this.pagination_filterConfig
      .map(fc => {
        // const propertyAcessorPath = acessorObjectToString(fc.fieldAccesor)
        const datatype = acessorObjectToDatatype(fc.fieldAccesor)
        //create opStr
        let opStr = ''
        if (fc?.range?.length || 0 > 0) {
          opStr = 'in-range'
        } else if (fc.in.length === 1) {
          if (datatype === 'array')
            opStr = 'array-contains'
          else
            opStr = '=='
        } else if (fc.in.length > 1) {
          if (datatype === 'array')
            opStr = 'array-contains-any'
          else
            opStr = 'in'
        }

        // create values
        let values = fc.in || fc.range || []

        // handle _empty_ value filter
        switch (opStr) {
          case '==':
            values = values.map(f => f === '_empty_' ? ('emptyValue' in fc) ? fc.emptyValue : null : f)
            break
          case 'in':
            values = values.flatMap(f => f === '_empty_' ? ('emptyValue' in fc) ? fc.emptyValue : ['', null] : f)
            opStr = 'in'
            break
          case 'array-contains':
            values = values[0] === '_empty_' ? ('emptyValue' in fc) ? [fc.emptyValue] : [null] : values
            break
          case 'in-range':
            console.error('in-range not implemented yet')
            break
          case 'array-contains-any':
            values = values.flatMap(f => f === '_empty_' ? ('emptyValue' in fc) ? fc.emptyValue : ['', null] : f)
            break
        }

        // if the filterconfig has presets, allow the filter only if its a subset of presets, otherwise use the presets
        if (fc.presets && fc.presets.length > 0) {
          if (fc.in.length > 0) {
            if ((fc.in as any[]).every(f => (fc.presets as any[]).includes(f))) {
              // use the filter
            } else {
              // use the presets
              values = fc.presets
              if (datatype === 'array')
                opStr = 'array-contains-any'
              else
                opStr = 'in'
            }
          } else if (fc.range.length > 0) {
            // todo
          } else {
            // use the presets
            values = fc.presets
            if (datatype === 'array')
              opStr = 'array-contains-any'
            else
              opStr = 'in'
          }
        }

        return {
          fieldAccessor: fc.fieldAccesor,
          opStr: opStr as any,
          values,
          isMandatory: false,
          indexGroups: [] // dummy
        }
      }).filter(fc => fc.opStr !== '')

    // handle _empty_ value filter
    // filterConfigNew.map((fc)=>{


    //   if (typeof propertyAcessorPath === 'string'
    //     && Array.isArray(accessorStringToValue(filter.fieldAccesor, propertyAcessorPath))) {
    //     if (filter.in[0] === '_empty_') {
    //       query = query
    //         .where(propertyAcessorPath, '==', ('emptyValue' in filter) ? filter.emptyValue : [])
    //     } else {
    //       query = query
    //         .where(propertyAcessorPath, 'array-contains', filter.in[0])
    //     }
    //   } else {
    //     query = query
    //       .where(propertyAcessorPath, '==', filter.in.map(f => f === '_empty_' ? ('emptyValue' in filter) ? filter.emptyValue : null : f)[0])
    //   }
    // } else if (filter.in.length > 1) {

    //   if (isDocumentID)
    //     this.pagination_sortField = 'id'

    //   if (typeof propertyAcessorPath === 'string'
    //     && Array.isArray(accessorStringToValue(filter.fieldAccesor, propertyAcessorPath))) {
    //     query = query
    //       .where(propertyAcessorPath, 'array-contains-any', filter.in.map(f => f === '_empty_' ? ('emptyValue' in filter) ? filter.emptyValue : null : f))
    //   } else {
    //     query = query
    //       .where(propertyAcessorPath, 'in', filter.in.flatMap(f => f === '_empty_' ? ('emptyValue' in filter) ? filter.emptyValue : ['', null] : f))
    //   }
    // })

    const mandatoryFilters = this.pagination_filter()

    filterConfigNew = [...filterConfigNew, ...mandatoryFilters]

    let sampleFilterConfig: FilterConfigNew<DbDataType>[] = [
      {
        fieldAccessor: 'open',
        opStr: '==',
        values: ['open'],
        isMandatory: true,
        indexGroups: [] // dummy
      }
      // {
      //   fieldPath: 'age',
      //   opStr: 'in-range',
      //   values: [20, 40],
      //   isMandatory: false,
      //   indexGroups: [1] // dummy
      // },
      // {
      //   fieldPath: 'favoriteFruits',
      //   opStr: 'array-contains-any',
      //   values: ['apple', 'orange', 'apricot', 'melon', 'watermelon', 'peach', 'kiwi', 'cherry', 'strawberry', 'banana'],
      //   isMandatory: false,
      //   indexGroups: [1] // dummy
      // },
      // {
      //   fieldPath: 'country',
      //   opStr: 'not-in',
      //   values: ['Turkey', 'Germany', 'China', 'Spain'],
      //   isMandatory: false,
      //   indexGroups: [3] // dummy
      // },
      // {
      //   fieldPath: '_meta.dateUpdated',
      //   opStr: 'in-range',
      //   values: [new Date(2022, 6, 1), new Date(2023, 2, 31)],
      //   //values: [100, 130],  // 0 results
      //   //values: ['20', '30'], // incompatible type
      //   isMandatory: false,
      //   indexGroups: [4] // dummy
      // },
      // {
      //   fieldPath: 'firstName',
      //   opStr: 'in',
      //   values: ['Max', 'Adam', 'Thomas', 'Kevin', 'Michael', 'Toby', 'Robert'],
      //   isMandatory: false,
      //   indexGroups: [4] // dummy
      // },
      // {
      //   fieldPath: 'age',
      //   opStr: '!=',
      //   values: [30],
      //   isMandatory: false,
      //   indexGroups: [] // dummy
      // },
      // {
      //   fieldPath: 'country',
      //   opStr: '>=',
      //   values: ['C'],
      //   isMandatory: false,
      //   indexGroups: [] // dummy
      // }
    ]

    const sampleSortConfig: SortConfig[] = [
      {
        fieldPath: this.pagination_sortField,
        directionStr: this.pagination_sortDirection,
        indexGroups: [] // dummy
      }
      // {
      //   fieldPath: 'firstName',
      //   directionStr: 'asc'
      // },
      // // {
      // //     fieldPath: 'company',
      // //     directionStr: 'asc'
      // // },
      // {
      //   fieldPath: 'age',
      //   directionStr: 'desc'
      // },
      // {
      //   fieldPath: '_meta.dateUpdated',
      //   directionStr: 'desc'
      // }
    ]

    sampleFilterConfig = filterConfigNew

    console.log('filterConfigNew', filterConfigNew)

    if (!this.pagination_collectionReference) console.warn('pagination_collectionReference not set in VPaginationMixin')

    if (this.pagination_isPaginationLoading) {
      // set flag that one update is pending
      console.log('update pending')

      this.pagination_updatePending = {
        updatePending: true,
        reset,
        sortChange
      }
      return
    }

    if (this.pagination_liveUpdateActive) reset = true

    if (reset) { // dont empty data to be able to keep selected items // todo may use partial reactive update? check performance
      this.pagination_currentPage = 1
      this.pagination_allLoaded = false
    }

    if (this.pagination_allLoaded) return

    console.log('updating paginated data')
    console.log({
      reset,
      sortChange,
      pagination_currentPage: this.pagination_currentPage,
      pagination_perPage: this.pagination_perPage,
      pagination_isPaginationLoading: this.pagination_isPaginationLoading,
      pagination_paginatedData: this.pagination_paginatedData,
      pagination_totalItemsEstimation: this.pagination_totalItemsEstimation,
      pagination_liveUpdateActive: this.pagination_liveUpdateActive,
      pagination_liveUpdateOnFirstPage: this.pagination_liveUpdateOnFirstPage,
      pagination_allLoaded: this.pagination_allLoaded,
      pagination_updatePending: this.pagination_updatePending.updatePending
    })


    const itemsToBeLoaded = this.pagination_currentPage * +this.pagination_perPage

    const limit = (reset || sortChange) ? itemsToBeLoaded : itemsToBeLoaded - this.pagination_paginatedData.length
    if (limit <= 0) return // items already loaded

    this.pagination_isPaginationLoading = true


    const parseResponse = async (data: SnapshotDatas<DbDataType>, totalCount: number) => {

      // if another update is pending, dont parse the response, but rerun the update
      if (this.pagination_updatePending.updatePending) {
        this.pagination_updatePending.updatePending = false
        this.pagination_isPaginationLoading = false
        await this.getData(this.pagination_updatePending.reset, this.pagination_updatePending.sortChange)
        return
      }

      console.log(data)

      // this.lastPageDocRef = data.docs[data.docs.length - 1]

      let docs = data
      // let docs: (DbDataType & hasDBid & { _docRef?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> })[] = data.docs.map(doc => ({ id: doc.id, ...doc.data() as DbDataType, _docRef: doc.ref }))

      // docs.forEach(this.pagination_foreachDoc)
      for (const doc of docs) {
        await this.pagination_foreachDoc(doc)
      }

      // remove docrref
      // docs.forEach(d => {
      //   delete d._docRef
      // })

      const docsLengthBeforeFilter = docs.length
      docs = this.pagination_localDocsFilter(docs)
      const numberOfDocsFiltered = docsLengthBeforeFilter - docs.length

      // todo likely will only just override all the time or atleast merge with the current data to keep the state of the table
      // if (!(reset || sortChange)) { // reset will be true when liveUpdateActive
      //   this.pagination_paginatedData = [...this.pagination_paginatedData, ...docs]
      // } else {
      this.pagination_paginatedData = docs
      // set checked rows to new items
      this.pagination_checkedRows = this.pagination_checkedRows.map(row => this.pagination_paginatedData.find(q => q.id === row.id)).filter((q): q is (DbDataType & hasDBid) => !!q)
      // }

      //this.pagination_totalItemsEstimation = this.pagination_paginatedData.length + 1 + numberOfDocsFiltered
      this.pagination_totalItemsEstimation = totalCount
      // TODO
      // const itemsToBeLoaded = 20
      if (this.pagination_paginatedData.length + numberOfDocsFiltered < itemsToBeLoaded) {
        this.pagination_allLoaded = true
        this.pagination_totalItemsEstimation -= 1
      }

      this.pagination_updateCounter++
      this.pagination_isPaginationLoading = false
    }

    // check referencing
    if (this.pagination_collectionGroupName !== '' && this.pagination_collectionReference !== null) {
      console.error('pagination_collectionGroupName and pagination_collectionReference are both set. Only one can be set at a time')
    }

    const referenceType: 'collectionGroup' | 'collection' = this.pagination_collectionGroupName !== '' ? 'collectionGroup' : 'collection'

    const referencePathOrName = referenceType === 'collectionGroup' ? this.pagination_collectionGroupName : this.pagination_collectionReference.path

    const filterUtil = new FilterUtil<DbDataType>(referencePathOrName, referenceType, MAX_QUERY_COUNT)

    // todo if liveupdate is false, clear the previous query
    this.paginationSnapshotUnbindHandle?.()
    this.paginationSnapshotUnbindHandle = filterUtil.onSnapshot((userData, totalCount) => {
      handlePromiseError(parseResponse(userData, totalCount))
      console.log('---')
    })

    try {
      // may be called multiple times with different configs
      // throws if a config is invalid or too many docs ('narrow your filter')
      // await, since it may perform some db queries to validate the filterconfig
      await filterUtil.updateConfig(sampleFilterConfig, sampleSortConfig, itemsToBeLoaded)
      //await new Promise((f) => setTimeout(f, 500))
      //await filterUtil.updateConfig([], [])
    } catch (error) {
      const msg = error instanceof Error ? error.message : String(error)
      console.error('Error:', msg)
    }


  }

  // protected async getData2(reset = false, sortChange = false) {

  //   if (!this.pagination_collectionReference) console.warn('pagination_collectionReference not set in VPaginationMixin')

  //   if (this.pagination_isPaginationLoading) {
  //     // set flag that one update is pending
  //     console.log('update pending')

  //     this.pagination_updatePending = {
  //       updatePending: true,
  //       reset,
  //       sortChange
  //     }
  //     return
  //   }

  //   if (this.pagination_liveUpdateActive) reset = true

  //   if (reset) { // dont empty data to be able to keep selected items // todo may use partial reactive update? check performance
  //     this.pagination_currentPage = 1
  //     this.pagination_allLoaded = false
  //   }

  //   if (this.pagination_allLoaded) return

  //   console.log('updating paginated data')
  //   console.log({
  //     reset,
  //     sortChange,
  //     pagination_currentPage: this.pagination_currentPage,
  //     pagination_perPage: this.pagination_perPage,
  //     pagination_isPaginationLoading: this.pagination_isPaginationLoading,
  //     pagination_paginatedData: this.pagination_paginatedData,
  //     pagination_totalItemsEstimation: this.pagination_totalItemsEstimation,
  //     pagination_liveUpdateActive: this.pagination_liveUpdateActive,
  //     pagination_liveUpdateOnFirstPage: this.pagination_liveUpdateOnFirstPage,
  //     pagination_allLoaded: this.pagination_allLoaded,
  //     pagination_updatePending: this.pagination_updatePending.updatePending
  //   })


  //   const itemsToBeLoaded = this.pagination_currentPage * +this.pagination_perPage

  //   const limit = (reset || sortChange) ? itemsToBeLoaded : itemsToBeLoaded - this.pagination_paginatedData.length
  //   if (limit <= 0) return // items already loaded

  //   let query = this.pagination_collectionReference.limit(limit)

  //   query = this.pagination_filterByConfig(this.pagination_filter(query))

  //   if (this.pagination_sortField !== 'id' && !this.notBackendSortable) {
  //     console.log('sorting by', this.pagination_sortField, this.pagination_sortDirection)
  //     query = query.orderBy(this.pagination_sortField, this.pagination_sortDirection)
  //   }

  //   if (!(reset || sortChange) && this.lastPageDocRef) // already data loaded    
  //     query = query.startAfter(this.lastPageDocRef)


  //   this.pagination_isPaginationLoading = true

  //   const parseResponse = (data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {

  //     // if another update is pending, dont parse the response, but rerun the update
  //     if (this.pagination_updatePending.updatePending) {
  //       this.pagination_updatePending.updatePending = false
  //       this.pagination_isPaginationLoading = false
  //       this.getData(this.pagination_updatePending.reset, this.pagination_updatePending.sortChange)
  //       return
  //     }

  //     console.log(data)

  //     this.lastPageDocRef = data.docs[data.docs.length - 1]

  //     let docs: (DbDataType & hasDBid & { _docRef?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> })[] = data.docs.map(doc => ({ id: doc.id, ...doc.data() as DbDataType, _docRef: doc.ref }))

  //     docs.forEach(this.pagination_foreachDoc)

  //     // remove docrref
  //     docs.forEach(d => {
  //       delete d._docRef
  //     })

  //     const docsLengthBeforeFilter = docs.length
  //     docs = this.pagination_localDocsFilter(docs)
  //     const numberOfDocsFiltered = docsLengthBeforeFilter - docs.length

  //     if (!(reset || sortChange)) { // reset will be true when liveUpdateActive
  //       this.pagination_paginatedData = [...this.pagination_paginatedData, ...docs]
  //     } else {
  //       this.pagination_paginatedData = docs

  //       // set checked rows to new items
  //       this.pagination_checkedRows = this.pagination_checkedRows.map(row => this.pagination_paginatedData.find(q => q.id === row.id)).filter((q): q is (DbDataType & hasDBid) => !!q)
  //     }

  //     this.pagination_totalItemsEstimation = this.pagination_paginatedData.length + 1 + numberOfDocsFiltered

  //     if (this.pagination_paginatedData.length + numberOfDocsFiltered < itemsToBeLoaded) {
  //       this.pagination_allLoaded = true
  //       this.pagination_totalItemsEstimation -= 1
  //     }

  //     this.pagination_updateCounter++
  //     this.pagination_isPaginationLoading = false
  //   }

  //   // unbind if snapshot exists
  //   this.paginationSnapshotUnbindHandle?.()

  //   if (this.pagination_liveUpdateActive) {
  //     this.paginationSnapshotUnbindHandle = query.onSnapshot((s) => {
  //       parseResponse(s)
  //     }, e => {
  //       this.$helpers.notification.Error(e.message.toString())
  //       this.pagination_isPaginationLoading = false
  //     })
  //     return Promise.resolve()
  //   } else {
  //     return await query.get().then(
  //       parseResponse
  //     ).catch(e => this.$helpers.notification.Error(e.toString()))
  //   }

  // }

  protected pagination_localDocsFilter(docs: SnapshotDatas<DbDataType>) {
    return docs
  }


  // private pagination_filterByConfig(query: firebase.firestore.Query<firebase.firestore.DocumentData>) {
  //   console.log('custom filter')

  //   // log pagination_filterConfig
  //   console.log(this.pagination_filterConfig)

  //   let hasMultipleForIn = false
  //   let hasMultipleRange = false
  //   let hasIDMultipleRange = false
  //   let error = false
  //   for (const fc of this.pagination_filterConfig) {
  //     if (fc.in.length > 1) {
  //       if (fc.in.length > 10) {
  //         error = true
  //         this.$helpers.notification.Error('Filtering by more than 10 elements is currently not supported')
  //         break
  //       }
  //       if (hasMultipleForIn) {
  //         error = true
  //         this.$helpers.notification.Error('Setting multiple filters with more than one item is currently not supported')
  //         break
  //       }
  //       hasMultipleForIn = true
  //     }
  //     if (fc.range.length > 0 || ('id' in fc.fieldAccesor && fc.in.length > 0)) {
  //       if ('id' in fc.fieldAccesor && fc.in.length > 0) hasIDMultipleRange = true
  //       if (hasMultipleRange) {
  //         error = true
  //         if (hasIDMultipleRange) {
  //           this.$helpers.notification.Error('Setting range filters and ID filters is currently not supported')
  //         } else {
  //           this.$helpers.notification.Error('Setting multiple range filters is currently not supported')
  //         }
  //         break
  //       }
  //       hasMultipleRange = true
  //     }
  //   }

  //   if (error) return query

  //   let notBackendSortable = false

  //   this.pagination_filterConfig.forEach(filter => {
  //     const isDocumentID = ('id' in filter.fieldAccesor)
  //     const propertyAcessorPath = isDocumentID ?
  //       firebase.firestore.FieldPath.documentId() :
  //       acessorObjectToString(filter.fieldAccesor)

  //     if (filter.range.length > 0 || filter.in.length === 1 || filter.in.length > 1)
  //       notBackendSortable = filter.notBackendSortable || notBackendSortable

  //     if (filter.range.length > 0) {

  //       const filterMin = filter.range[0]
  //       const filterMax = (filter.type === 'date') ?
  //         filter.range[1] as Date || filter.range[0] as Date :
  //         (filter.range[1] || filter.range[0]) + '\uf8ff'


  //       query = query
  //         .where(propertyAcessorPath, '>=', filterMin)
  //         .where(propertyAcessorPath, '<=', filterMax)

  //       // it is already ordered by the sort field, so dont order again
  //       if (this.pagination_sortField !== propertyAcessorPath)
  //         query = query.orderBy(propertyAcessorPath)


  //     } else if (filter.in.length === 1) {
  //       if (isDocumentID)
  //         this.pagination_sortField = 'id'

  //       if (typeof propertyAcessorPath === 'string'
  //         && Array.isArray(accessorStringToValue(filter.fieldAccesor, propertyAcessorPath))) {
  //         if (filter.in[0] === '_empty_') {
  //           query = query
  //             .where(propertyAcessorPath, '==', ('emptyValue' in filter) ? filter.emptyValue : [])
  //         } else {
  //           query = query
  //             .where(propertyAcessorPath, 'array-contains', filter.in[0])
  //         }
  //       } else {
  //         query = query
  //           .where(propertyAcessorPath, '==', filter.in.map(f => f === '_empty_' ? ('emptyValue' in filter) ? filter.emptyValue : null : f)[0])
  //       }
  //     } else if (filter.in.length > 1) {

  //       if (isDocumentID)
  //         this.pagination_sortField = 'id'

  //       if (typeof propertyAcessorPath === 'string'
  //         && Array.isArray(accessorStringToValue(filter.fieldAccesor, propertyAcessorPath))) {
  //         query = query
  //           .where(propertyAcessorPath, 'array-contains-any', filter.in.map(f => f === '_empty_' ? ('emptyValue' in filter) ? filter.emptyValue : null : f))
  //       } else {
  //         query = query
  //           .where(propertyAcessorPath, 'in', filter.in.flatMap(f => f === '_empty_' ? ('emptyValue' in filter) ? filter.emptyValue : ['', null] : f))
  //       }
  //     }
  //   })

  //   this.notBackendSortable = notBackendSortable


  //   return query
  // }

  public pagination_getFilterConfig(field: string) {
    return this.pagination_filterConfig.find(f =>
      (f.tableColumnField)
        ? f.tableColumnField === field
        : acessorObjectToString(f.fieldAccesor) === field
    )
    // return this.pagination_filterConfig.find(f => acessorObjectToString(f.fieldAccesor) === field)
  }

  public beforeDestroy() {
    this.paginationSnapshotUnbindHandle?.()
  }

}
