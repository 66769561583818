
import { Component, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEdit, faSearch
} from '@fortawesome/free-solid-svg-icons'

import VPaginationMixin from '../../components/mixins/VPaginateMixin.vue'
import { mixins } from 'vue-class-component'
import UserManager from '@/database/userManager'
import VInputUserRolesTags from '@/components/VInputUserRolesTags.vue'
import { UserReferenceDB } from '@/types/typeUser'
import db from '@/firebase'
import { dbPrivileges } from '@/helpers/privileges'
import TenantManager from '@/database/tenantManager'
import { DataCache } from '@/helpers/dataCache'
import { TenantDB } from '@/types/typeTenant'
import { hasDBid } from '@/types/typeGeneral'
import VImportExport, { typeImportExportDefinitions } from '@/components/VImportExport.vue'
import { FilterConfigNew } from '@/database/filterUtil'

library.add(faEdit, faSearch)

@Component({
  components: { VInputUserRolesTags, VImportExport }
})
export default class AdminUsersList extends mixins<VPaginationMixin<UserReferenceDB>>(VPaginationMixin) {
  public pagination_sortDirection: 'asc' | 'desc' = 'desc'
  public pagination_sortField: string = 'email'
  public pagination_perPage = 10

  protected pagination_collectionGroupName = UserManager.getCollectionGroupName()

  public formFilterByTosAcceptPriv = false

  @Watch('formFilterByTosAcceptPriv')
  public onChangeFormFilterByTosAcceptPriv() {
    this.pagination_getData(true)
  }


  protected pagination_filter(): FilterConfigNew<UserReferenceDB>[] {
    /**
     *     return (this.formFilterByTosAcceptPriv)
      ? typedWhere<UserReferenceDB>(query, { privileges: [] }, 'array-contains', dbPrivileges.TOS_ACCEPT)
      : query
     */
    return (this.formFilterByTosAcceptPriv)
      ? [{
        fieldAccessor: { privileges: [] },
        opStr: 'array-contains',
        values: [dbPrivileges.TOS_ACCEPT],
        indexGroups: [],
        isMandatory: false
      }]
      : []
  }


  private dataCacheTenant = new DataCache<TenantDB & hasDBid>(async (key) => {
    return await TenantManager.get(key)
  })

  protected async pagination_foreachDoc(doc: UserReferenceDB & hasDBid & { _local: { docPath: string } }) {
    console.log(doc._local.docPath)

    if (db.doc(doc._local.docPath)?.parent?.parent?.id) {

      this.dataCacheTenant.get(db.doc(doc._local.docPath)?.parent?.parent?.id || '').then((d) => {
        this.$set(doc, 'tenantID', d.id)
        this.$set(doc, 'tenantName', d.name)
        this.$set(doc, 'tenantNumber', d._number)
      }).catch((e) => {
        this.$helpers.notification.Error('Error loading tenant data: ' + String(e))
      })

    } else {
      this.$set(doc, 'tenantID', 'not assigned') // use 0 for proper sorting
      this.$set(doc, 'tenantName', 'not assigned')
      this.$set(doc, 'tenantNumber', 0)
    }
    this.$set(doc, 'canAcceptTerms', doc.privileges.includes(dbPrivileges.TOS_ACCEPT).toString())
    this.$set(doc, 'isActive', doc.active.toString())
  }

  public created() {
    this.pagination_getData()
  }

  public importExport_importExportDefinitions: typeImportExportDefinitions<any> = [
    {
      readOnly: true,
      exportColumnName: 'Name',
      exportFormatter: (d: UserReferenceDB) => d.name
    }, {
      readOnly: true,
      exportColumnName: 'Email',
      exportFormatter: (d: UserReferenceDB) => d.email
    }, {
      readOnly: true,
      exportColumnName: 'DateCreated',
      exportFormatter: (d: UserReferenceDB) => d._meta.dateCreated.toDate().toString()
    }, {
      readOnly: true,
      exportColumnName: 'tenantID',
      exportFormatter: (d: any) => d.tenantID
    }, {
      readOnly: true,
      exportColumnName: 'tenantName',
      exportFormatter: (d: any) => d.tenantName
    }, {
      readOnly: true,
      exportColumnName: 'tenantNumber',
      exportFormatter: (d: any) => d.tenantNumber
    }, {
      readOnly: true,
      exportColumnName: 'canAcceptTerms',
      exportFormatter: (d: any) => d.canAcceptTerms
    }, {
      readOnly: true,
      exportColumnName: 'Privileges',
      exportFormatter: (d: UserReferenceDB) => d.privileges
    }
  ]
}
