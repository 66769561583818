import { observableDiff, applyChange, diff, applyDiff } from 'deep-diff'
import Transformer from '@/helpers/transformer'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import deepmerge from 'deepmerge'
import dayjs from 'dayjs'
import { DeepPartial, hasDBid } from '@/types/typeGeneral'
import firebase from 'firebase/compat/app'
import { CollectionReference, query, Query, where } from 'firebase/firestore'
import { BaseElementDB, BaseElementPublicData } from '@/modules/typeModules'
import db from '@/firebase'

const ENABLE_LOG = false

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Like Object.fromEntries but preserves the key order
 *
 * @param entries - [['a',1],['b',2]]
 * @returns - {a:1,b:2}
 */
export function objectFromEntriesOrdered<T>(entries: [string, T][]) {
  const obj: { [k: string]: T } = {}
  for (const [key, value] of entries) {
    obj[key] = value
  }
  return obj
}

/**
 *
 * @param object - {a:{b:'C'}}
 * @returns 'a.b'
 */
export function acessorObjectToString(object: any) {
  return Object.keys(Transformer.flatten(object, '.'))[0] //{a:{b:'C'}} => 'a.b':'C'
}

/**
 *
 * @param object - {a:{b:'C'}}
 * @param acessorString - 'a.b'
 * @returns 'C'
 */
export function accessorStringToValue(object: any, acessorString: string) {
  return acessorString.split('.').reduce((prev, curr) => prev[curr], object)
}

/**
 * @param object  - {a:{b:'C'}}
 * @returns 'C'
 */
export function acessorObjectToValue(object: any) {
  return accessorStringToValue(object, acessorObjectToString(object))
}

/**
 * @param object  - {a:{b:'C'}}
 * @returns 'string' | 'number' | 'boolean' | 'object' | 'undefined' | 'array'
 */
export function acessorObjectToDatatype(object: any) {
  const value = acessorObjectToValue(object)
  if (Array.isArray(value)) return 'array'
  return typeof value
}

/**
 * @param accessorObject - {a:{b:''}}
 * @param targetObject - {a:{b:'C'},d:123}
 * @returns 'C'
 */
export function acessorObjectToObjectValue(accessorObject: any, targetObject: any) {
  return accessorStringToValue(targetObject, acessorObjectToString(accessorObject))
}

/**
 * Sets a value of nested key string descriptor inside a Object.
 * It changes the passed object.
 * Ex:
 *    let obj = {a: {b:{c:'initial'}}}
 *    setNestedKey(obj, ['a', 'b', 'c'], 'changed-value')
 *    assert(obj === {a: {b:{c:'changed-value'}}})
 *
 * @param {[Object]} obj   Object to set the nested key
 * @param {[Array]} path  An array to describe the path(Ex: ['a', 'b', 'c'])
 * @param {[Object]} value Any value
 */
export const setNestedKey = (obj: any, path: string[], value: any) => {
  if (path.length === 1) {
    obj[path as any] = value
  } else {
    setNestedKey(obj[path[0]], path.slice(1), value)
  }
}

export const getNestedKey = (obj: any, path: string[]): any => {
  if (path.length === 1) {
    return obj[path as any]
  } else {
    return getNestedKey(obj[path[0]], path.slice(1))
  }
}

/**
 *
 * @param object  - {a:{b:'C'}}
 * @param acessorString - 'a.b'
 * @param value - '3'
 *
 * => {a:{b:3}}
 */
export function assignValueBasedOnAccessorString(object: any, acessorString: string, value: any) {
  setNestedKey(object, acessorString.split('.'), value)
}

export const uniqueID = () => {
  // use firebase id generator as it seems robust
  return db.collection('dummy').doc().id
}

export function groupBy<T extends { [key: string]: any }>(data: T[], acessor: (obj: T) => string) {
  return data.reduce(
    (previous, current, i, a, k = acessor(current)) => ((previous[k] || (previous[k] = [])).push(current), previous),
    {} as { [key: string]: T[] }
  )
}

export function timeSeriesPreparationHelper<T extends { [key: string]: any }>(
  data: T[],
  dateAcessor: (obj: T) => Date
) {
  // group by date and count occurences
  const groupedData = groupBy(data, (a) => dayjs(dateAcessor(a)).format('MM/DD/YYYY') || '')

  return Object.keys(groupedData).map((group) => {
    return {
      x: group,
      y: groupedData[group].length
    }
  })
}

export function distinctObjects<T>(data: T[], accessor: (obj: T) => string) {
  const response: T[] = []
  data.forEach((el, i) => {
    // make unique
    if (!response.some((E) => accessor(E) === accessor(el))) {
      response.push(el)
    }
  })

  return response
}

export function arrayGroupBy<T extends { [key: string]: any }>(data: T[], acessor: (obj: T) => string) {
  //{a:[],b:[]} => [{group:'a',data:[]}]
  const groups = groupBy(data, acessor)
  return Object.keys(groups).map((group) => ({ group, data: groups[group] }))
}

export function typedPartialUpdatePayload<T>(updateData: DeepPartial<T>) {
  return Transformer.flatten(updateData, '.')
}

// vue fire overrides existing objects when an obj update arrives, this resets the ui state.
// instead only update the changed props
export function applySelectiveReactiveUpdate<T>(
  snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
  documents: Array<T & hasDBid>
) {
  // this is not reactve!!
  // const newDocs = snapshot.docs.map(d => ({
  //   ...(d.data() as T),
  //   id: d.id
  // }))

  //     observableDiff(documents, newDocs, (diff: any) => {
  //       applyChange(documents, newDocs, diff)
  //     })

  snapshot.docChanges().forEach((change) => {
    const { newIndex, oldIndex, doc, type } = change
    const newDoc = {
      ...(doc.data() as T),
      id: doc.id
    }

    if (type === 'added') {
      documents.splice(newIndex, 0, newDoc)
    } else if (type === 'modified') {
      // !!! dont replace array element but update modified prop to keep ui state
      observableDiff(documents[oldIndex], newDoc, (diff: any) => {
        applyChange(documents[oldIndex], newDoc, diff)
      })
    } else if (type === 'removed') {
      documents.splice(oldIndex, 1)
    }
  })
}

export function applySelectiveReactiveUpdateOneDoc<T>(targetDoc: T, newDoc: T) {
  // data not changed by the user (DB = DBlocal) is updated with new data
  //if (!memoryDoc) memoryDoc = newDoc

  const arrayEquals = (a: any[], b: any[]) => a.length === b.length && a.every((v, i) => v === b[i])

  // const userChanged = diff(this.formElementDB, targetDoc)
  const dbChanges = diff(targetDoc, newDoc)
  if (ENABLE_LOG) console.log('hereeee-----')

  // all changes in respect to last snapshot (DB <> newDoc) will be overidden
  // remove all db changes that are also user changes
  if (dbChanges) {
    applyDiff(targetDoc, newDoc, (a, b, diff) => {
      if (ENABLE_LOG) console.log(diff)

      const changeInDbInRespectToPreviousSnapshot = dbChanges.some((dbDiff) =>
        arrayEquals(dbDiff.path || [], diff.path || [])
      )
      return changeInDbInRespectToPreviousSnapshot
    })
  }

  return targetDoc
}

/**
 *
 * @param acessor '{a: {b: 3}}'
 * @param value 42
 *
 * @returns '{'a.b': 42}'
 */
export function typedPartialUpdatePayloadValue<T>(acessor: DeepPartial<T>, value: any) {
  const acessorString = acessorObjectToString(acessor)
  return { [acessorString]: value }
}

export function typedWhere<T>(
  query: firebase.firestore.Query<firebase.firestore.DocumentData>,
  path: DeepPartial<T>,
  op: firebase.firestore.WhereFilterOp,
  value: any
) {
  let acessorString = acessorObjectToString(path)
  acessorString = acessorString === 'id' ? '__name__' : acessorString
  return query.where(acessorString, op, value)
}

export function typedWhereV9<T>(
  dbQuery: Query<firebase.firestore.DocumentData> | CollectionReference<firebase.firestore.DocumentData>,
  path: DeepPartial<T>,
  op: firebase.firestore.WhereFilterOp,
  value: any
) {
  let acessorString = acessorObjectToString(path)
  acessorString = acessorString === 'id' ? '__name__' : acessorString
  return query(dbQuery, where(acessorString, op, value))
}

export function typedWhereExpressionV9<T>(path: DeepPartial<T>, op: firebase.firestore.WhereFilterOp, value: any) {
  let acessorString = acessorObjectToString(path)
  acessorString = acessorString === 'id' ? '__name__' : acessorString
  return where(acessorString, op, value)
}

export function typedWhereSartsWith<T>(
  query: firebase.firestore.Query<firebase.firestore.DocumentData>,
  path: DeepPartial<T>,
  value: any
) {
  let acessorString = acessorObjectToString(path)

  // firebase.firestore.FieldPath.documentId()
  acessorString = acessorString === 'id' ? '__name__' : acessorString
  return query.where(acessorString, '>=', value).where(acessorString, '<=', value + '\uf8ff')
  // collectionRef.where('name', '>=', queryText).where('name', '<=', queryText+ '\uf8ff').
}

export function typedOrderBy<T>(
  query: firebase.firestore.Query<firebase.firestore.DocumentData>,
  path: DeepPartial<T>,
  dir: firebase.firestore.OrderByDirection = 'asc'
) {
  const propAcessorMap = Transformer.flatten(path, '.')
  return query.orderBy(Object.keys(propAcessorMap)[0], dir)
}

// export function typedDocRef(db: firebase.firestore.Firestore, dbDefinition: dbDefinition) {
//   db.doc(dbDefinition.)
// }

// typedDocRef(dba, databaseSchema.COLLECTIONS.TENANTS.DATA).get(id)
// typedDocRef(dba, databaseSchema.COLLECTIONS.TENANTS.DATA).set(id, data)
// typedDocRef(dba, databaseSchema.COLLECTIONS.TENANTS.DATA).update(id, data)
// typedDocRef(dba, databaseSchema.COLLECTIONS.TENANTS.DATA).update(id, data)

//   transaction.update(CategoryHelper.getCategoriesDoc(tenantId), {
//   [`${cId}._computed.linkedAsids`]: firebase.firestore.FieldValue.increment(-1)
// })
// for combining parallel executions cbs into one on snapshot cb
// only emits after all cb have been fired atleast once
export class ParallelCallbackHelper<T> {
  constructor() {
    // do stuff
  }

  private idCounter = 0

  private proxyCount = 0
  private proxyResults: Array<T> = []
  private proxyHasResults: boolean[] = []

  private listeners: Array<{
    id: number
    once: boolean
    cb: (e: Array<T>) => any
  }> = []

  public get listenersCount() {
    return this.listeners.length
  }

  private emitSnapshot(id?: number) {
    if (ENABLE_LOG) console.log('emitting snapshot')

    for (const listener of this.listeners) {
      if (ENABLE_LOG) console.log('listener')
      if (id === undefined || id === listener.id) {
        listener.cb(this.proxyResults)

        if (listener.once) this.removeListener(listener.id)
      }
    }
  }

  public removeAllListeners(id?: number) {
    if (ENABLE_LOG) console.log('removing all listeners snapshot')

    for (const listener of this.listeners) {
      if (ENABLE_LOG) console.log('listener')
      if (id === undefined || id === listener.id) {
        this.removeListener(listener.id)
      }
    }
  }

  public cbProxy<Args extends any[]>(cb: (...args: Args) => T) {
    const tmpCount = this.proxyCount
    this.proxyHasResults.push(false)
    this.proxyCount++

    if (ENABLE_LOG) console.log('added proxy')

    return (
      (count) =>
      (...args: Args) => {
        if (ENABLE_LOG) console.log('proxy called')

        this.proxyResults[tmpCount] = cb(...args)
        this.proxyHasResults[tmpCount] = true
        if (this.proxyHasResults.every((b) => b)) {
          this.emitSnapshot()
        }
      }
    )(tmpCount)
  }

  private removeListener(id: number) {
    this.listeners = this.listeners.filter((L) => L.id !== id)
    // todo if no listeners, also dispose all proxied listeners
  }

  public onSnapshot(cb: (cbResult: Array<T>) => any, once = false): SnapshotUnbindHandle {
    const id = ++this.idCounter
    this.listeners.push({ id, cb, once })
    if (ENABLE_LOG) console.log('listener id ', id)
    if (ENABLE_LOG) console.log('listener lenth ', this.listeners.length)
    if (this.proxyHasResults.every((b) => b)) {
      this.emitSnapshot(id)
    }

    return ((id) => () => {
      if (ENABLE_LOG) console.log('remove listener')

      this.removeListener(id)
    })(this.idCounter)
  }
}
/*SnapshotChain()
        .onSt((data, cb) => {
          return this.onSnapshotActivatedModuleClasses(cb)
        })
        .onSt((d, cb) => {
          // will be newly created when the first chainlink changes

          const cbh = new CallbackHelper()
          d.forEach(M => {
            db.collection('Tenants')
              .doc(tenantID)
              .collection('Modules')
              .doc(M.public.type)
              .collection('Elements')
              .onSnapshot(
                cbh.cbProxy((snapshot: firebase.firestore.QuerySnapshot) => {
                  return snapshot.docs.map(D => ({
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    type: D.ref.parent.parent!.id as ModuleType,
                    ...(D.data() as BaseElementDB),
                    id: D.id
                  }))
                }),
                errCb ? errCb : e => notificationHelper.Error(`Error fetching Module Elements: ${e.toString()}`)
              )
          })
          return cbh.onSnapshot(cb)
        })
        .onSt(data => cb(data.flat()))*/

type SnapshotCb = (data: any) => void
type SnapInitFunc = (data: any, cb: SnapshotCb) => SnapshotUnbindHandle
type SnapshotSequenceEl = {
  initFunc: SnapInitFunc
  instance: SnapshotUnbindHandle | null
}

// chain dependant snapshots. Update will caus the descendats to also be reinitiated.
// on snapshot update -> dispose descendants & reinit
export class SnapshotSequence {
  constructor() {
    // do stuff
  }
  private snapshotChainElements: SnapshotSequenceEl[] = []

  private snapshotListener: ((data: any) => void) | null = null

  public onSnapshot(cb: (data: any) => void): SnapshotUnbindHandle {
    if (this.snapshotListener) {
      throw 'SnapshotSequence listener is already set'
    }
    this.snapshotListener = cb

    //init chain - call cbs
    this.snapshotChainElements[0].instance = this.snapshotChainElements[0].initFunc(null, this.handleChainResponse(0))

    return () => {
      // dispose all
      for (const el of this.snapshotChainElements) {
        el.instance?.()
        if (ENABLE_LOG) console.log('disposing')
      }
      this.snapshotChainElements = []
      this.snapshotListener = null
    }
  }

  private handleChainResponse = (index: number) => (data: any) => {
    // (re)init al next element in chain
    if (ENABLE_LOG) console.log('rsp called', index)

    if (index >= this.snapshotChainElements.length - 1) {
      // last chan element
      this.snapshotListener && this.snapshotListener(data)
      if (ENABLE_LOG) console.log('last element', index)
    } else {
      // handle next element
      // dispose first
      const inst = this.snapshotChainElements[index + 1].instance
      if (inst !== null) inst()

      // init
      this.snapshotChainElements[index + 1].instance = this.snapshotChainElements[index + 1].initFunc(
        data,
        this.handleChainResponse(index + 1)
      )
    }
  }

  public addSnapShotSeq(SnapInitF: SnapInitFunc) {
    const index = this.snapshotChainElements.length

    if (ENABLE_LOG) console.log('snapshot added', index)

    this.snapshotChainElements.push({
      initFunc: SnapInitF,
      instance: null
    })

    return this
  }

  // private initEl(index: number) {
  // dispose first
  // if (this.snapshotChainElements[index].instance)
  //this.snapshotChainElements[index].instance();
  // }
}

type SnapParInitFunc = (cb: SnapshotCb, errCb: SnapshotCb) => SnapshotUnbindHandle
type SnapshotParEl = {
  hasData: boolean
  data: any
  instance?: SnapshotUnbindHandle
}

export class SnapshotParallel {
  constructor(waitForAllResponses = true, debugName = '') {
    this.waitForAllResponses = waitForAllResponses
    this.debugName = debugName
  }

  private waitForAllResponses = true
  private debugName = ''
  private snapshotParElements: SnapshotParEl[] = []
  private snapshotListener: ((data: any[]) => void) | null = null
  private errCbListener = (err: any) => console.error(err)

  public onSnapshot(cb: (data: any[]) => void, errCb: (data: any) => void): SnapshotUnbindHandle {
    if (this.snapshotListener) {
      throw this.debugName + 'SnapshotParallel listener is already set'
    }
    this.snapshotListener = cb
    this.errCbListener = errCb

    this.emitData()
    return () => {
      // dispose all
      for (const el of this.snapshotParElements) {
        el.instance?.()
        if (ENABLE_LOG) console.log(this.debugName, ': SnapshotParallel disposing')
      }
      this.snapshotParElements = []
      this.snapshotListener = null
    }
  }

  private emitData() {
    if (this.snapshotParElements.every((pe) => pe.hasData) && this.waitForAllResponses) {
      // all snapshots triggered, emit data
      this.snapshotListener && this.snapshotListener(this.snapshotParElements.map((pe) => pe.data))
    }
  }

  public addSnapShotPar(SnapInitF: SnapParInitFunc) {
    const index = this.snapshotParElements.length

    if (ENABLE_LOG) console.log(this.debugName, ': SnapshotParallel snapshot added', index)

    const onResponse = (index: number) => (data: any) => {
      // (re)init al next element in chain
      if (ENABLE_LOG) console.log(this.debugName, ': SnapshotParallel par rsp called', index)

      this.snapshotParElements[index].data = data
      this.snapshotParElements[index].hasData = true

      this.emitData()
    }

    this.snapshotParElements.push({
      data: null,
      hasData: false
    })

    // set instance later, since it might immediately invoke onResponse
    this.snapshotParElements[index].instance = SnapInitF(onResponse(index), this.errCbListener)

    return this
  }
}

// allow multiple onSnapshot listeners for one input
// remove the input snapshot if all listeners are removed and keepAlive is false
export class SnapshotMultiListener<T> {
  constructor(keepAliveWhenAllListenersRemoved = false) {
    this.keepAlive = keepAliveWhenAllListenersRemoved
  }

  public debugName = ''

  private listeners: Array<{
    id: number
    once: boolean
    cb: (d: T) => void
  }> = []

  private idCounter = 0
  private keepAlive = false
  private dataCache: T | undefined
  private dataReceived = false
  private SnapshotUnbindHandle: SnapshotUnbindHandle | null = null

  private removeListener(id: number) {
    this.listeners = this.listeners.filter((L) => L.id !== id)

    if (ENABLE_LOG)
      console.log(
        this.debugName,
        'removing listener - active listeners',
        this.listeners.length,
        'keepAlive',
        this.keepAlive
      )
    if (this.listeners.length == 0 && !this.keepAlive) {
      if (ENABLE_LOG) console.log(this.debugName, 'disposing snapshot')

      this.SnapshotUnbindHandle?.()
      this.SnapshotUnbindHandle = null
      this.dataReceived = false
      this.dataCache = undefined
    }
  }

  public isInitialized() {
    return this.SnapshotUnbindHandle !== null
  }

  public setInputSnapshot(snapShot: (cb: (d: T) => void) => SnapshotUnbindHandle) {
    if (this.SnapshotUnbindHandle) {
      throw 'SnapshotMultiListener listener is already set'
    }

    if (ENABLE_LOG) console.log(this.debugName, '- multi input snap set')
    this.SnapshotUnbindHandle = snapShot((d: T) => {
      if (ENABLE_LOG) console.log(this.debugName, '- multi input snap called')
      this.dataCache = d
      this.dataReceived = true
      this.emitSnapshot()
    })
  }

  private emitSnapshot(id?: number) {
    if (!this.dataReceived || !this.dataCache) {
      if (ENABLE_LOG) console.log('no data received yet')
      return
    }

    if (ENABLE_LOG) console.log(this.debugName, 'emitting snapshot')

    for (const listener of this.listeners) {
      if (id === undefined || id === listener.id) {
        listener.cb(this.dataCache)

        if (listener.once) this.removeListener(listener.id)
      }
    }
  }

  public onSnapshot(cb: (data: T) => void, once = false): SnapshotUnbindHandle {
    const id = ++this.idCounter
    this.listeners.push({ id, cb, once })
    if (ENABLE_LOG) console.log(this.debugName, 'listener id ', id)
    if (ENABLE_LOG) console.log(this.debugName, 'listener lenth ', this.listeners.length)

    this.emitSnapshot(id)
    return ((id) => () => {
      if (ENABLE_LOG) console.log(this.debugName, 'remove listener')

      this.removeListener(id)
    })(this.idCounter)
  }
}

/**
 * hydrates db data with default data. Usefull when schema changes
 */
export function merge<T>(dbData: T, defaultData: T) {
  dbData = dbData || ({} as T)
  return deepmerge<T>(deepmerge<T>({}, defaultData, { clone: true }), dbData)
}

/**
 * Sorts module elements by order and id
 *
 * @param a
 * @param b
 * @param dir
 * @returns
 */
export function sortElements<T extends BaseElementDB>(a: T & hasDBid, b: T & hasDBid, dir: 'asc' | 'desc' = 'asc') {
  const orderA = a.public.order
  const orderB = b.public.order

  return orderA - orderB || a.id.localeCompare(b.id, undefined, { numeric: true, sensitivity: 'base' }) // if order is the same, fallback to id based sorting
}

export function sortElementsPublicData<T extends BaseElementPublicData>(
  a: T & hasDBid,
  b: T & hasDBid,
  dir: 'asc' | 'desc' = 'asc'
) {
  const orderA = a.order
  const orderB = b.order

  return orderA - orderB || a.id.localeCompare(b.id, undefined, { numeric: true, sensitivity: 'base' }) // if order is the same, fallback to id based sorting
}
