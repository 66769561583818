import { ModuleType } from '@/modules/typeModules'

import { RouteConfig, RawLocation } from 'vue-router'
import BaseModule from '@/modules/baseModule'

import { CiModuleDB, CiElementDB, RequiredCiPrivileges, CiGroupDB } from './typeCiModule'

import {
  defaultCiModuleDB,
  defaultCiElementDB,
  defaultCiGroupDB
} from '@/database/databaseSchema'
import { UserPrivilegeIdDB } from '@/types/typeUser'

export default class CiModule extends BaseModule {
  public static type: ModuleType = 'Ci'
  public static displayName = 'Design'
  public static color = '#418cec'
  public static hasWidget = false
  public static description = 'Customize the Theme to match your branding.'

  public static authPrivileges: RequiredCiPrivileges = {
    r: ['ci:read'],
    w: ['ci:write'],
    view: ['ci:view']
  }

  public static moduleDB: CiModuleDB = defaultCiModuleDB
  public static elementDB: CiElementDB = defaultCiElementDB
  public static groupDB: CiGroupDB = defaultCiGroupDB

  constructor() {
    super()
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return [
      {
        to: { name: 'module-ci-list' },
        displayName: this.displayName
      }
    ]
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'module/ci',
        name: this.routeNameList,
        component: () => import('./Backend_Module_Ci_List.vue'),
        meta: {
          label: this.displayName,
          description: 'Manage your Designs',
          breadcrumbs: false,
          privileges: this.authPrivileges.view,
          isFullsize: true
        },
        children: [
          {
            path: ':id',
            name: this.routeNameElement,
            component: () => import('./Backend_Module_Ci_Single.vue'),
            meta: {
              label: this.displayName,
              description: 'Manage your Design',
              privileges: this.authPrivileges.view,
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          }
        ]
      }
    ]
  }
}
