
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Address } from '@/types/typeTenant'

@Component({
  components: {},
  model: {
    prop: 'address',
    event: 'input'
  },
  props: {
    address: {
      type: Object,
      required: true
    }
  }
})
export default class VFormAddress extends Vue {
  // needed for proper reactivity
  public formAddress: Address = {} as Address


  @Watch('address', { immediate: true })
  public addressChanged(val: Address, oldVal: Address) {
    this.formAddress = { ...this.$props.address }
  }

  public valueChanged() {
    this.$emit('input', this.formAddress)
  }
}
