
import { Component, Vue, Prop, PropSync, Watch, Model } from 'vue-property-decorator'
import { Cropper } from 'vue-advanced-cropper'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUndo, faRedo, faUpload, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'

import StorageManager from '@/helpers/StorageManager'
import { uniqueID } from '@/database/dbHelper'

library.add(faUndo, faRedo, faUpload, faExpandArrowsAlt)

/**
 * infers the type of image descriptor provided, which is
 * - local_url: data stored on firestore
 * - external_url: data stored somewhere else
 * - variable: url stored in a {{variable}}
 * - '': no valid data, or data incomplete
 */
export function inferImgDescriptorType(imgDescriptor: string) {
  const URL_REGEX: RegExp = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi
  const FIREBASE_URL_REGEX: RegExp = /http(s)?:(.+)echoprm(.*)backendUpload\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gi
  // regex to match {{variable}} or {{variable.property}} or {{variable.Some_Name-o_something}} or {{ identifier.non-exist_ant }}
  const VARIABLE_REGEX: RegExp = /{{.*}}/gi

  console.log('inferImgDescriptorType')

  if (imgDescriptor.match(FIREBASE_URL_REGEX)) {
    return 'local_url'
  } else if (imgDescriptor.match(VARIABLE_REGEX)) {
    return 'variable'
  } else if (imgDescriptor.match(URL_REGEX)) {
    return 'external_url'
  }
  return ''
}

/**
 * Add or update an ImagesDescriptor (local_url, external_url, {{variable}})
 * 
 * If an ImageDescriptor is already given via v-model, its type cant be changed
 * If no url is given, the user may input either a variable, or url
 */
@Component({
  components: {
    Cropper
  }
})
export default class VImageUploadModal extends Vue {
  @Model('input', { type: String, required: false, default: () => '' })
  public url!: string

  @PropSync('active', { type: Boolean, required: true, default: () => false })
  public isImageUploadModalActive!: boolean

  @Prop({ type: String, required: false, default: () => '' })
  readonly namePrefix!: string

  @Prop({ type: String, required: true, default: () => '' })
  readonly uploadPath!: string

  @Prop({ type: String, required: false, default: () => 'infer' })
  readonly targetFormat!: typeof this.ALLOWED_FILE_FORMATS[number] | 'infer'

  @Prop({ type: Number, required: false, default: () => 1024 * 1024 }) // 1 MB
  readonly maxFilesize!: number

  @Prop({ type: Number, required: false, default: () => 1000 })
  readonly maxImageWidth!: number

  @Prop({ type: Number, required: false, default: () => 1000 })
  readonly maxImageHeight!: number

  @Prop({ type: String, required: false, default: () => '' })
  readonly uploaderDocumentPath!: string

  @Prop({ type: Boolean, required: false, default: () => true })
  readonly allowVariable!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly disableRemoveImage!: boolean

  private ALLOWED_FILE_FORMATS = ['image/bmp', 'image/gif', 'image/jpeg', 'image/pjpeg', 'image/png', 'image/svg+xml', 'image/svg'] as const
  public dropedImageFormat: typeof this.ALLOWED_FILE_FORMATS[number] | 'infer' = 'infer'

  public modalIsLoading = false
  public imageModalUploading = false
  public imageModalProgress = 0

  /** image data in the canvas */
  public cropperImage = ''


  @Watch('isImageUploadModalActive')
  @Watch('url', { immediate: true })
  public onUrlChange() {
    this.cropperImage = ''
    this.formImgUrlOrVar = ''

    if (!this.isImageUploadModalActive)
      return

    this.externalDataType = inferImgDescriptorType(this.url)

    // initialize cropper image to recrop
    if (this.externalDataType === 'local_url')
      this.cropperImage = this.url
    else
      this.formImgUrlOrVar = this.url

    // this.onMaximizeImage()
  }

  // public cropperImage = 'https://images.pexels.com/photos/580012/pexels-photo-580012.jpeg'
  public onImageDropped(file: File) {
    // create a new FileReader to read this image and convert to base64 format
    const reader = new FileReader()
    // Define a callback function to run, when FileReader finishes its job
    reader.onload = (e) => {
      // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
      // Read image as base64 and set to imageData
      if (e.target) this.cropperImage = e.target.result as string
      //this.isInput = true
    }
    // Start the reader job - read file as a data url (base64 format)
    reader.readAsDataURL(file)

    this.dropedImageFormat = file.type as typeof this.ALLOWED_FILE_FORMATS[number]
  }

  /** user defined external url or {{variable}} */
  public formImgUrlOrVar = ''

  /** data type of the img descriptor */
  public get localDataType(): 'external_url' | 'local_url' | 'variable' | '' {
    if (this.externalDataType === 'local_url' || this.cropperImage !== '') return 'local_url'

    return inferImgDescriptorType(this.formImgUrlOrVar)
  }

  /** data type of the img descriptor, which is based on the provided v-model */
  public externalDataType: 'external_url' | 'local_url' | 'variable' | '' = ''

  public dataTypeName = ''
  public message = ''
  public type = ''

  get imageTypeName() {
    switch (this.localDataType) {
      case 'external_url':
        return 'External Url'
      case 'local_url':
        return 'Image'
      case 'variable':
        return 'Image Variable'
      default:
        return ''
    }
  }

  public defaultFullSize({ imageWidth, imageHeight }: { imageWidth: number, imageHeight: number }) {
    return {
      width: imageWidth,
      height: imageHeight
    }
  }

  public onMaximizeImage() {
    // wait for next tick, so that the cropper is initialized
    this.$nextTick(() => {
      if (this.cropperImage !== '')
        (this.$refs.cropper as any).setCoordinates((coordinates: any, imageSize: any) => ({
          width: imageSize.width,
          height: imageSize.height
        }))
    })
  }

  public onRotateImage() {
    const image = document.createElement('img')
    image.crossOrigin = 'anonymous'
    image.src = this.cropperImage
    image.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      if (!ctx) return

      if (image.width > image.height) {
        canvas.width = image.height
        canvas.height = image.width
        ctx.translate(image.height, image.width / image.height)
      } else {
        canvas.height = image.width
        canvas.width = image.height
        ctx.translate(image.height, image.width / image.height)
      }
      ctx.rotate(Math.PI / 2)
      ctx.drawImage(image, 0, 0)
      this.cropperImage = canvas.toDataURL()
    }
  }

  @Watch('formImgUrlOrVar', { immediate: true })
  private resetValidateFormInput() {
    // if is some valid format, remove the error message
    if (this.localDataType !== '') {
      this.type = ''
      this.message = ''
    }
  }

  public onRemoveImage() {
    this.$emit('input', '')
    this.$emit('url', '')
  }

  public async onSave() {
    if (this.localDataType === 'local_url') {
      try {
        await this.uploadCroppedImage()
      } catch (e: unknown) {
        this.$helpers.notification.Error(e)
      }
    } else {
      if (this.localDataType === '') {
        this.type = 'is-danger'
        this.message = 'please enter a valid url or variable'
      } else {
        this.$emit('input', this.formImgUrlOrVar)
        this.$emit('url', this.formImgUrlOrVar)

        this.cropperImage = ''
        this.formImgUrlOrVar = ''
        this.isImageUploadModalActive = false
      }
    }
  }

  public get isNotCroppable() {
    const imageFormat = this.getImageFormat()
    return imageFormat === 'image/svg+xml' || imageFormat === 'image/svg'
  }

  private getImageFormat() {
    // dataUrl = "data:image/png;base64,abcdefghijklm.."
    console.log(this.cropperImage)

    if (this.cropperImage.startsWith('data:')) {
      return (this.cropperImage.substring(this.cropperImage.indexOf(':') + 1, this.cropperImage.indexOf(';')) || 'infer') as typeof this.ALLOWED_FILE_FORMATS[number] | 'infer'
    } else {
      return 'image/' + this.cropperImage.split('.').pop() as typeof this.ALLOWED_FILE_FORMATS[number] | 'infer'
    }
  }

  public async uploadCroppedImage() {
    let dontModifyGif = false

    let targetFormat: typeof this.ALLOWED_FILE_FORMATS[number] = 'image/png'
    // if targetformat is not set, infer it from the image
    if (this.targetFormat === 'infer') {
      if (this.dropedImageFormat !== 'infer')
        targetFormat = this.dropedImageFormat
      else {
        const imageFormat = this.getImageFormat()
        if (imageFormat !== 'infer')
          targetFormat = imageFormat
      }
    }

    if (this.getImageFormat() === 'image/gif')
      dontModifyGif = await new Promise((res, rej) =>
        this.$buefy.dialog.confirm({
          message: 'You input a gif image. Do you want to keep the animation (if there is any)?',

          cancelText: 'No, not animated',
          confirmText: 'Yes, is animated (can not crop)',
          trapFocus: true,
          onConfirm: () => res(true),
          onCancel: () => res(false)
        })
      )

    const isSVG = targetFormat === 'image/svg+xml' || targetFormat === 'image/svg'

    if (dontModifyGif) {
      this.onMaximizeImage()
      targetFormat = 'image/gif'
    }

    let fileBlob: Blob | null = null
    if (dontModifyGif || isSVG) {
      fileBlob = await (await fetch(this.cropperImage)).blob()
    } else {
      const { canvas }: { canvas: HTMLCanvasElement } = (this.$refs.cropper as any).getResult()
      fileBlob = await new Promise<Blob | null>((res, rej) =>
        canvas.toBlob((fileBlob) => res(fileBlob), targetFormat)
      )
    }

    const imageUpload = async (file: File, cb: (formImgUrlOrVar: string) => void, dontModify = false) => {
      try {
        this.modalIsLoading = true
        this.imageModalProgress = 0

        const imageUrl = await StorageManager.uploadImage(
          this.$auth.userEmail,
          file,
          this.uploadPath,
          (progress) => this.imageModalProgress = progress,
          (warning: string) => this.$helpers.notification.Warn(warning),
          targetFormat,
          this.ALLOWED_FILE_FORMATS,
          this.uploaderDocumentPath,
          this.maxFilesize,
          this.maxImageWidth,
          this.maxImageHeight,
          dontModify
        )

        cb(imageUrl)
      } catch (e: any) {
        console.error(e)
        this.$helpers.notification.Error(e.toString())

        this.modalIsLoading = false
        this.imageModalUploading = false
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger'
        })
      } finally {
        this.modalIsLoading = false
        this.imageModalUploading = false
        this.imageModalProgress = 100
      }
    }


    const fileName = `${this.namePrefix}image-${uniqueID()}.${targetFormat.split('/')[1]}`
    if (fileBlob)
      await imageUpload(
        new File([fileBlob], fileName, { type: targetFormat }),
        (url) => {
          if (this.isImageUploadModalActive) {
            this.$emit('url', url)
            this.$emit('input', url)

            this.isImageUploadModalActive = false
            this.cropperImage = ''
            this.formImgUrlOrVar = ''
            //this.isInput = false

          }
        },
        dontModifyGif || isSVG
      )
  }


}
