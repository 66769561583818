import { ALL_MODULE_TYPE_NAMES } from '@/modules/typeModules'

export const ACCEPT_TOS_UNTIL = new Date('2024-07-31')

export const HELP_RESSOURCE_SECTIONS = [
  ...ALL_MODULE_TYPE_NAMES,
  'Manage-Modules',
  'Settings',
  'Overview',
  'Activate-Code',
  'List-Code',
  'Order-Widgets',
  'Categories',
  'Dashboard',
  'FormResponse'
] as const

// per slot you get so many interactions
export const INTERACTION_ASID_SLOT_MULTIPLIER = 500 

// subscription slot interaction count
export const INTERACTION_ASID_SLOT_MULTIPLIER_SUBSCRIPTION = 4000