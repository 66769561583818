import BaseModuleApp from '@/modules/baseModuleApp'
import { ModuleType } from '../typeModules'

export default class ScriptModuleApp extends BaseModuleApp {
  public static type: ModuleType = 'Script'

  public static registerBackgroundVue() {
    return () => import(/* webpackPrefetch: true, webpackChunkName: "App_Module_Script" */ './App_Module_Script.vue')
  }
}
