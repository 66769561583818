
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'


import TosManager from '@/database/tosManager'
import { Terms } from '@/types/typeTenant'

@Component({})
export default class VModalTOS extends Vue {

  @Prop({ required: true })
  public terms!: Terms

  @Prop({ required: true })
  public type!: 'terms' | 'odp'

  get accepted() {
    return TosManager.areTOSAccepted(this.terms, this.type)
  }

  get termsHtml() {
    return TosManager.getTermsHtml(this.terms, this.type)
  }

  get termsName() {
    return this.type === 'terms' ? 'Terms Of Service / AGB' : 'Order Data Processing'
  }

  public onClose() {
    this.$emit('closed')
      ; (this.$parent as any).close()
  }

  public onAccept() {
    if (this.formAccepted) this.$emit('accepted')

      ; (this.$parent as any).close()
  }

  public formAccepted = false
  public reachedBottom = false

  @Watch('accepted', { immediate: true })
  onAcceptedChanged() {
    this.formAccepted = this.accepted
  }

  get daysToAcceptTOS() {
    return TosManager.daysUntilToAcceptTOS(this.terms)
  }

  public scrolledToBottom(event: any) {
    const el = event.srcElement
    // console.log(el.scrollTop + ' ' + el.scrollHeight + ' ' + el.clientHeight)
    if (!this.reachedBottom) {
      if (el.scrollTop >= (el.scrollHeight - el.clientHeight) - 10) {
        this.reachedBottom = true
      }
    }
  }

}
