import { render, staticRenderFns } from "./Backend_Config_Legal.vue?vue&type=template&id=f29cd156&"
import script from "./Backend_Config_Legal.vue?vue&type=script&lang=ts&"
export * from "./Backend_Config_Legal.vue?vue&type=script&lang=ts&"
import style0 from "./Backend_Config_Legal.vue?vue&type=style&index=0&id=f29cd156&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports