/**
 * copied from https://github.com/sudodoki/copy-to-clipboard/blob/main/index.js
 * and improved with modern clipboard apis //stackoverflow.com/questions/60217202/copy-text-to-clipboard-now-that-execcommandcopy-is-obsolete
 */

export function copy(
  text: string,
  options: {
    debug: boolean
    message?: string
    format?: 'text/html' | 'text/plain'
    onCopy?: (data: DataTransfer | null) => void
  } = { debug: false, message: 'Copy to clipboard: #{key}, Enter' }
) {
  const debug = options.debug || false

  let success = false

  const tryLegacyCopy = () => {
    let message, range, selection, mark

    try {
      range = document.createRange()
      selection = document.getSelection()

      mark = document.createElement('span')
      mark.textContent = text
      // avoid screen readers from reading out loud the text
      mark.ariaHidden = 'true'
      // reset user styles for span element
      mark.style.all = 'unset'
      // prevents scrolling to the end of the page
      mark.style.position = 'fixed'
      ;(mark.style as any).top = 0
      mark.style.clip = 'rect(0, 0, 0, 0)'
      // used to preserve spaces and line breaks
      mark.style.whiteSpace = 'pre'
      // do not inherit user-select (it may be `none`)
      mark.style.webkitUserSelect = 'text'
      ;(mark.style as any).MozUserSelect = 'text'
      ;(mark.style as any).msUserSelect = 'text'
      mark.style.userSelect = 'text'
      mark.addEventListener('copy', function (e) {
        e.stopPropagation()
        if (options.format) {
          e.preventDefault()
          if (typeof e.clipboardData === 'undefined') {
            const clipboardToIE11Formatting = {
              'text/plain': 'Text',
              'text/html': 'Url',
              default: 'Text'
            }

            // IE 11
            debug && console.warn('unable to use e.clipboardData')
            debug && console.warn('trying IE specific stuff')
            ;(window as any).clipboardData.clearData()
            const format = clipboardToIE11Formatting[options.format] || clipboardToIE11Formatting['default']
            ;(window as any).clipboardData.setData(format, text)
          } else {
            // all other browsers
            e.clipboardData?.clearData()
            e.clipboardData?.setData(options.format, text)
          }
        }
        if (options.onCopy) {
          e.preventDefault()
          options.onCopy(e.clipboardData)
        }
      })

      document.body.appendChild(mark)

      range.selectNodeContents(mark)
      selection?.addRange(range)

      const successful = document.execCommand('copy')
      if (!successful) {
        throw new Error('copy command was unsuccessful')
      }
      success = true
    } catch (err) {
      debug && console.error('unable to copy using execCommand: ', err)
      debug && console.warn('trying IE specific stuff')
      try {
        (window as any).clipboardData.setData(options.format || 'text', text)
        options.onCopy && options.onCopy((window as any).clipboardData)
        success = true
      } catch (err) {
        debug && console.error('unable to copy using clipboardData: ', err)
        debug && console.error('falling back to prompt')

        const format = (message: string) => {
          const copyKey = (/mac os x/i.test(navigator.userAgent) ? '⌘' : 'Ctrl') + '+C'
          return message.replace(/#{\s*key\s*}/g, copyKey)
        }

        message = format(options.message || '')
        window.prompt(message, text)
      }
    } finally {
      if (selection) {
        if (typeof selection.removeRange == 'function') {
          range && selection.removeRange(range)
        } else {
          selection.removeAllRanges()
        }
      }

      if (mark) {
        document.body.removeChild(mark)
      }
    }
  }

  if (!navigator.clipboard) {
    debug && console.log('using legacy copy')
    tryLegacyCopy()
  } else {
    debug && console.log('using modern copy')
    success = true
    navigator.clipboard
      .writeText(text)
      .then(function () {
        // settin success here does not wprk due to the async nature of the new api
        // however making it async man not work in the old way, since legacy api requires the api be triggered immediately?
        // this is still the case using promises though => investiaget if making whole func async works
      })
      .catch(function (e) {
        console.error(e)
        tryLegacyCopy()
        success = false
      })
  }

  return success
}
