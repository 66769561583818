export default async function print(printElement: HTMLElement, resize: boolean = false) {
  // printed page padding in mm
  const pagePadding = resize ? 5 : 0

  // resize html elemnt to fit a din a4 paper
  if (resize) printElement.style.width = 210 - 2 * pagePadding + 'mm'

  // wait 2 second for the element to resize
  await new Promise((resolve) => setTimeout(resolve, 1000))

  const doc = printElement.ownerDocument
  const prtHtml = printElement.outerHTML

  // get width of the print element
  // const windowWidth = printElement.offsetWidth + 100
  const windowWidth = 1200

  // Get all stylesheets HTML
  let stylesHtml = ''
  for (const node of [...doc.querySelectorAll('link[rel="stylesheet"], style')]) {
    stylesHtml += node.outerHTML
  }

  // Open the print window
  const WinPrint = window.open('', '', `left=0,top=0,width=${windowWidth},height=900,toolbar=0,scrollbars=0,status=0`)

  if (!WinPrint) throw 'Direct printing is not supported on your device. Try using your systems native print functionality'

  WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
    <style>
      body {
        margin: ${pagePadding}mm;
      }

    </style>
  </head>
  <body class="print">
    ${prtHtml}
  </body>
</html>`)

  WinPrint.document.close()
  WinPrint.focus()

  // print after 1 seconds
  setTimeout(() => {
    WinPrint.print()

    //close after 2 seconds
    setTimeout(() => {
      WinPrint.close()
    }, 2000)
  }, 1000)

  // reset the width of the element
  printElement.style.width = 'auto'
}
