import notification from './notificationHelper'
import merge from 'deepmerge'
import localStorage from './localstorage'

import { VueConstructor } from 'vue'

export const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[]

declare module 'vue/types/vue' {
  interface Vue {
    $helpers: {
      notification: typeof notification
      merge: typeof merge
      localStorage: typeof localStorage
    }
  }
}

export default {
  install(Vue: VueConstructor, options: any) {
    // Vue.helpers = helpers
    Vue.prototype.$helpers = {
      notification,
      merge,
      localStorage
    }
  }
}
