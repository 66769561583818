
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import sanitizeHtml, { IOptions } from 'sanitize-html'
import { merge } from '@/database/dbHelper'


@Component
export default class VEditorHtml extends Vue {
  @Prop({ type: String, required: true })
  public html!: string

  @Prop({ type: Object, required: false, default: ()=>({}) })
  public sanitizeHtmlConfig!: IOptions

  public get sanitizedHtml() {

    const allowedHtml = merge(
      this.sanitizeHtmlConfig,
      {
        allowedTags: [
          'b', 'i', 's', 'u', 'em', 'strong',
          'a', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'br', 'img', 'iframe',
          'ul', 'ol', 'li', 'table', 'td', 'tr', 'th', 'tbody', 'colgroup', 'hr', 'span'
        ],
        allowedAttributes: {
          'a': ['href', 'target'],
          'img': ['src', 'alt', 'title', 'width'],
          'iframe': ['src'],
          'td': ['colspan'],
          '*': ['style'],
          'table': ['width', 'border', 'cellspacing', 'cellpadding']
        },
        allowedStyles: {
          '*': {
            // Match HEX and RGB
            'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
            'text-align': [/^left$/, /^right$/, /^center$/],
            // Match any number with px, em, or %
            'font-size': [/^\d+(?:px|em|%)$/]
          }
        },
        allowedClasses: {
          'a': ['button']
        },
        allowedIframeHostnames: ['www.youtube.com']
      })

    return sanitizeHtml(this.html, allowedHtml)
  }

}


