import { TenantID } from '@/types/typeTenant'

const baseBackendUploadPath = (tenantId: TenantID) => `tenants/${tenantId}/backendUpload`

export const getBackendUploadPathModule = (tenantId: TenantID, module = 'default') => {
  return `${baseBackendUploadPath(tenantId)}/module/${module}`
}

export const getBackendUploadPath = (tenantId: TenantID, path = '') => {
  return `${baseBackendUploadPath(tenantId)}${path}`
}

export const getAdminUploadPath = (path = '') => {
  return `admin${path}`
}

export const getappUploadPathModule = (tenantId: TenantID, module = 'default') => {
  return `tenants/${tenantId}/appUpload/module/${module}`
}

export const PUBLIC_APP_UPLOAD_EMAIL_STRING = 'public-upload'

export const UPLOAD_PATHS = {
  ASID_ASH_ATTRIBUTES: (tenantID: TenantID) => getBackendUploadPath(tenantID, '/asid/assetAttributes'),
  ASID_IDENTIFIERS: (tenantID: TenantID) => getBackendUploadPath(tenantID, '/asid/identifiers')
}
