import {
  defaultExpandedWidgetDesignDefinition,
  defaultTileWidgetDesignDefinition,
  defaultWidgetDesignDefinition
} from '../database/databaseSchema'
import { CustomModuleAppData, CustomGroupDB } from '@/modules/custom/typeCustomModule'
import { DataElementDB, DataGroupDB, isDataKey } from '@/modules/data/typeDataModule'
import {
  ProtectionElementDB,
  ProtectionModuleAppData,
  ProtectionElementPublicData,
  ProtectionGroupDB,
  ProtectionElementPrivateDataDB
} from '@/modules/protection/typeProtectionModule'
import {
  BaseElementDB,
  BaseGroupDB,
  BaseGroupPublicData,
  BaseModuleDB,
  BaseModuleGroupAppData,
  BaseModulePublicData,
  ModuleType
} from '@/modules/typeModules'
import { asidID, IdentifierValue, identifierValueType, isAssetAttributeKey, isIdentifierKey } from '@/types/typeAsid'
import { BackendConfigDB } from '@/types/typeBackendConfig'
import { CategoryCollection, CategoryID, CategoryItem } from '@/types/typeCategory'
import { hasDBid } from '@/types/typeGeneral'
import { moduleOrder } from './general'

import { assetAttributeValue } from '@/types/typeAsid'
import { ServiceElementPublicData } from '@/modules/service/typeServiceModule'

export type LogContext = {
  moduleType?: ModuleType
  groupID?: string
  elementID?: string
  responseID?: string
  responseItemID?: string
  asidID?: string
  tenantID?: string
  // if not part of the module system
  documetType?: string
  documentPath?: string
  documentID?: string

  scope?: string

  data?: any
}

export type ExtendedLogContext = LogContext & {
  'logging.googleapis.com/labels': {
    function_name: string
    execution_id: string
  }
}

export type ContextLogger = (message: string, context: LogContext, ...args: unknown[]) => void

export type InjectedLogging = {
  error: ContextLogger
  // is an error for the user, but warning for the system
  userError: ContextLogger
  warn: ContextLogger
  info: ContextLogger
  debug: ContextLogger
}

const groupBy = <
  T extends {
    [key: string]: any
  }
>(
  data: T[],
  acessor: (obj: T) => string
) => {
  return data.reduce(
    (previous, current, i, a, k = acessor(current)) => ((previous[k] || (previous[k] = [])).push(current), previous),
    {} as {
      [key: string]: T[]
    }
  )
}

const GROUP_AND_TYPE_SEPARATOR = '...._-_....'

export function getAllPublishedParentCategories(startCategoryNodes: CategoryID[], allCategories: CategoryCollection) {
  const allParentCategories: Map<CategoryID, CategoryItem> = new Map()

  function traverseUp(categoryID: CategoryID) {
    if (allCategories[categoryID]?.publishingState === 'published')
      allParentCategories.set(categoryID, allCategories[categoryID])

    if (allCategories[categoryID]?.parentID) traverseUp(allCategories[categoryID].parentID)
  }

  startCategoryNodes.forEach((C) => traverseUp(C))

  return allParentCategories
}

export function getAllPublishedChildCategories(childCategories: CategoryID[], allCategories: CategoryCollection) {
  const allChildCategories: Map<CategoryID, CategoryItem> = new Map()

  function traverseDown(childCategoryIds: CategoryID[]) {
    childCategoryIds.forEach((cId) => {
      if (cId in allCategories && allCategories[cId].publishingState === 'published') {
        allChildCategories.set(cId, allCategories[cId])
        traverseDown(Object.keys(allCategories).filter((key) => allCategories[key].parentID === cId))
      }
    })
  }
  traverseDown(childCategories)

  return allChildCategories
}

// converts Map<CategoryID, CategoryItem> to an array ob objects wich includes the id of the category itself and the categoryItem
export function mapToCategoryIDArray(categoryMap: Map<CategoryID, CategoryItem>) {
  return Array.from(categoryMap.entries()).map(([id, categoryItem]) => ({ id, ...categoryItem }))
}

export function categoryNameToID(categoryName: string, allCategories: CategoryCollection) {
  return Object.keys(allCategories).find((key) => allCategories[key].name === categoryName)
}

export type AsidReference = {
  categoryIDs: CategoryID[]
  asidID: asidID
  identifierValue: IdentifierValue
}

export function filterElementsMatchingReferences<T extends BaseElementDB>(
  elements: T[],
  categoriesMap: Map<string, CategoryItem>,
  { categoryIDs, asidID, identifierValue }: AsidReference,
  logger: InjectedLogging = {
    error: console.error,
    userError: console.warn,
    warn: console.warn,
    info: console.info,
    debug: console.debug
  }
) {
  return elements
    .filter((el) => el.reference.asidIDs.every((elAsidRef) => !elAsidRef || asidID === elAsidRef))
    .filter((el) =>
      // every identifier on the element must be present in the reference
      Object.entries(el.reference.identifierValues).every(
        ([elIdentKey, elIdentValues]: [string, identifierValueType[]]) =>
          elIdentValues.every((iv) => !iv) || // either there is no identifier reference on the element
          elIdentValues.some((identValue) => identValue === identifierValue[elIdentKey as isIdentifierKey]) // or the identifier is present on the reference
      )
    )
    .filter((el) =>
      Object.values(el.reference.categoryIDs).every(
        (elCatRefs) =>
          elCatRefs.every((iv) => !iv) || // either there is no category reference on the element
          elCatRefs.some((elCatRef) => categoriesMap.has(elCatRef)) // or the category is present on the cat tree map
      )
    )
    .filter(
      // filter out elements with no references
      (el) =>
        !(
          Object.values(el.reference.categoryIDs).every((elCatRefs) => elCatRefs.every((iv) => !iv)) &&
          Object.entries(el.reference.identifierValues).every(
            ([elIdentKey, elIdentValues]: [string, identifierValueType[]]) => elIdentValues.every((iv) => !iv)
          ) &&
          Object.values(el.reference.asidIDs).every((elAsidRef) => !elAsidRef)
        )
    )
    .sort((a, b) => a.public.order - b.public.order)
}

export function createAppData(
  modules: Array<BaseModuleDB>,
  groupsAndType: Array<BaseGroupDB & hasDBid & { type: ModuleType }>,
  elementsAndType: Array<BaseElementDB & hasDBid & { type: ModuleType }>,
  backendConfigDoc: BackendConfigDB,
  accessKeys: string[],
  asidReference: AsidReference,
  assetAttributeValue: assetAttributeValue,
  categoryCollenction: CategoryCollection,
  protectionUnlockCb: (
    group: ProtectionGroupDB &
      hasDBid & {
        type: ModuleType
      },
    me: ProtectionElementPrivateDataDB & {
      public: ProtectionElementPublicData
    } & BaseElementDB &
      hasDBid,
    state: 'failed' | 'unlocked'
  ) => void,
  ignoreReferenceFilter = false,
  logger: InjectedLogging = {
    error: console.error,
    userError: console.warn,
    warn: console.warn,
    info: console.info,
    debug: console.debug
  }
) {
  // wrap the logger to issue every log only once, by keeping track of the logged messages
  const loggedMessages: string[] = []
  const loggerWrapper = (logger: InjectedLogging) => {
    const wrapLogger =
      (contextLogger: ContextLogger) =>
      (message: string, context: LogContext, ...args: any[]) => {
        const logMessage = `${message} ${JSON.stringify(context)} ${JSON.stringify(args)}`
        if (!loggedMessages.includes(logMessage)) {
          loggedMessages.push(logMessage)
          contextLogger(message, context, ...args)
        }
      }
    return {
      error: wrapLogger(logger.error),
      userError: wrapLogger(logger.userError),
      warn: wrapLogger(logger.warn),
      info: wrapLogger(logger.info),
      debug: wrapLogger(logger.debug)
    }
  }

  logger = loggerWrapper(logger)

  logger.debug('start compiling appData', { asidID: asidReference.asidID })
  let appData: BaseModuleGroupAppData[] = []

  const categoriesMap: Map<string, CategoryItem> = getAllPublishedParentCategories(
    asidReference.categoryIDs,
    categoryCollenction
  )
  if (!ignoreReferenceFilter)
    elementsAndType = filterElementsMatchingReferences(elementsAndType, categoriesMap, asidReference, logger)

  appData = compileAppData(modules, groupsAndType, elementsAndType, logger)

  appData = appDataCustomModule(appData, groupsAndType, categoriesMap, asidReference, logger)

  appData = appDataI18nModule(appData, modules, logger)
  // get backendconfig for identifier definition and interpolation

  appData = variableInterpolation(
    appData,
    groupsAndType,
    elementsAndType,
    backendConfigDoc,
    asidReference,
    assetAttributeValue,
    logger
  )

  appData = appDataProtectionModule(
    appData,
    groupsAndType,
    elementsAndType,
    accessKeys,
    asidReference,
    protectionUnlockCb,
    logger
  )

  // validation
  appDataValidation(elementsAndType, appData, logger)

  logger.debug('finished compiling appData', { asidID: asidReference.asidID }, { appData })

  return appData
}

export function compileAppData(
  modules: Array<BaseModuleDB>,
  groupsAndType: Array<BaseGroupDB & hasDBid & { type: ModuleType }>,
  elementsAndType: Array<BaseElementDB & hasDBid & { type: ModuleType }>,
  logger: InjectedLogging
) {
  const moduleElementsByGroupAndType = groupBy(
    elementsAndType,
    (me) => `${me.public.groupID}${GROUP_AND_TYPE_SEPARATOR}${me.type}`
  )

  const groupsGroupedByIDAndType = groupBy(groupsAndType, (g) => `${g.id}${GROUP_AND_TYPE_SEPARATOR}${g.type}`)

  const modulePublicDataByType: {
    [key: string]: BaseModulePublicData
  } = {}

  modules.forEach((d) => (modulePublicDataByType[d.public.type] = d.public))

  // #region compile moduleGroupsAppData
  let appData: BaseModuleGroupAppData[] = []
  for (const groupAndType in moduleElementsByGroupAndType) {
    const type = groupAndType.split(GROUP_AND_TYPE_SEPARATOR)[1]
    if (groupAndType in groupsGroupedByIDAndType) {
      const groupFromDB = groupsGroupedByIDAndType[groupAndType][0]
      const group: BaseGroupPublicData & hasDBid = {
        ...groupFromDB.public,
        id: groupFromDB.id
      }
      const elements = moduleElementsByGroupAndType[groupAndType]
      const appDataItem = {
        group,
        elements: elements
          .map((E) => ({
            id: E.id,
            ...E.public
          }))
          .sort(
            (a, b) => a.order - b.order || a.id.localeCompare(b.id, undefined, { numeric: true, sensitivity: 'base' })
          ), // if order is the same, fallback to id based sorting
        public: {
          ...modulePublicDataByType[type]
        } // dont reference the object here, since changing the type later (protection) changes the reference
      }

      appData.push(appDataItem)
    }
  }

  // sort by order
  appData = appData.sort(
    // if the order is qual, sort by the initial module order, if equal, sort by group id
    (a, b) =>
      a.group.order - b.group.order ||
      (moduleOrder[a.public.type] || 0) - (moduleOrder[b.public.type] || 0) ||
      a.group.id.localeCompare(b.group.id, undefined, { numeric: true, sensitivity: 'base' })
  )

  return appData
}

/** https://stackoverflow.com/a/9204218/787464 */
const escapeJson = function (str: string) {
  return str
    .replace(/[\\]/g, '\\\\')
    .replace(/["]/g, '\\"')
    .replace(/[/]/g, '\\/')
    .replace(/[\b]/g, '\\b')
    .replace(/[\f]/g, '\\f')
    .replace(/[\n]/g, '\\n')
    .replace(/[\r]/g, '\\r')
    .replace(/[\t]/g, '\\t')
}

/** https://stackoverflow.com/a/6234804/787464 */
// show html tags as output
function escapeHtml(unsafe: string) {
  // if not a string return it
  if (typeof unsafe !== 'string') return unsafe
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

function unEscapeHtml(escapedValue: string) {
  // if not a string return it
  if (typeof escapedValue !== 'string') return escapedValue
  return (
    escapedValue
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      // eslint-disable-next-line quotes
      .replace(/&#039;/g, "'")
  )
}

// parse the substitutionString into variable, filter and args
// e.g. "identifier.i1|formatList(['col1', 'col2'], 'start', 'separator', 'end')" into {variable: "identifier.i1", filter: "formatList", args: ["['col1', 'col2']", "'start'", "'separator'", "'end'"]
export function parseSubstitutionString(str: string, logContext: LogContext, logger: InjectedLogging) {
  // const regex = /^([\w$.-_]+)(?:\|(\w+)(?:\((.*?)\))?)?$/
  // added letter and mark, to match umlaute
  const regex = /^([\p{Letter}\p{Mark}$.-_]+)(?:\|([\p{Letter}\p{Mark}]+)(?:\((.*?)\))?)?$/iu

  const match = str.match(regex)
  if (match) {
    const [fullMatch, variableName, filterName, argString] = match
    let args: any[] = []

    if (argString) {
      // split the argString into an array of args using JSON.parse
      // e.g. "['col1', 'col2'], 'start', 'separator', 'end']" into [['col1', 'col2'], 'start', 'separator', 'end']
      // json parse does not work with single quotes, so replace them with double quotes
      try {
        args = JSON.parse(`[${unEscapeHtml(argString).replace(/'/g, '"')}]`)
      } catch (e) {
        logger.userError(`error parsing args for "${str}", argString: "${argString}"`, logContext, e)
      }
    }

    return {
      variableName,
      filterName,
      args,
      fullMatch
    }
  }
  return null
}

export function variableInterpolation(
  appData: BaseModuleGroupAppData[],
  groupsAndType: Array<BaseGroupDB & hasDBid & { type: ModuleType }>,
  elementsAndType: Array<BaseElementDB & hasDBid & { type: ModuleType }>,
  backendConfigDoc: BackendConfigDB,
  { categoryIDs, asidID, identifierValue }: AsidReference,
  assetAttributeValue: assetAttributeValue,
  logger: InjectedLogging
) {
  // #region interpolate identifiers and data elements

  // get Data elements and group
  const dataModuleElements = elementsAndType
    .filter((me) => me.type === 'Data')
    .sort((a, b) => a.public.order - b.public.order) as unknown as (DataElementDB & hasDBid)[]

  const interpolateMustache = (
    text: string,
    variables: { [key: string]: string | number | null | object | string[] | boolean },
    logContext: LogContext
  ) => {
    return text.replace(/{{([^{}]*)}}/g, (a, substitutionString) => {
      // substitutionString may contain a filter "identifier.i1|escape" or "identifier.i1|formatList(['col1', 'col2'], 'start', 'separator', 'end')'])"
      // split it into variableName, filterName and arguments
      const match = parseSubstitutionString(substitutionString.trim(), logContext, logger)
      if (!match) {
        logger.userError(`no match for substitutionString "${substitutionString}"`, logContext)
        return ''
      }
      const { variableName, filterName, args } = match

      let value = variableName in variables && variables[variableName] ? variables[variableName] : ''

      if (!(variableName in variables) && filterName !== 'default') {
        logger.userError(
          `variable "{{${variableName}}}" not found. Check the spelling and if the variable is available for this element. If this variable may be empty, use the default filter to set a fallback value. "{{${variableName}|default('defaultValue')}}"`,
          logContext,
          logger
        )
      } else if (!value && filterName !== 'default') {
        logger.warn(
          `no value for variable "{{${variableName}}}" found. Check the spelling and if the variable is available for this element. If this variable may be empty, use the default filter to set a fallback value. "{{${variableName}|default('defaultValue')}}"`,
          logContext,
          logger
        )
      }

      if (!value) value = ''

      // if value is a number, convert to string
      if (typeof value === 'number') value = value.toString()

      // apply filter
      if (filterName)
        switch (filterName.toLowerCase()) {
          /**
           * default filter
           * if value is empty, return default value
           *
           * default(defaultValue: string)
           */
          case 'default':
            // if value is not a string, log and return empty string
            if (!value) {
              const [defaultValue] = args

              if (!defaultValue) {
                logger.userError(
                  `no default value for variable "{{${variableName}|default('defaultValue')}}" given. Provide a default value as argument. "${args.join(
                    ','
                  )}" was given`,
                  logContext,
                  logger
                )
              }

              value = defaultValue || ''
            }
            break

          /**
           * escape string for use as html
           */
          case 'htmlescape':
            // if value is not a string, log and return empty string
            if (typeof value !== 'string') {
              logger.userError(
                `value for variable "${variableName}" is not a string, got ${typeof value} "${value.toString()}"`,
                logContext
              )
              value = ''
              break
            }

            value = escapeHtml(value)
            break

          /**
           * convert array to string with start, separator and end
           *
           * formatlist(columnNames: string[], start: string, separator: string, end: string)
           * <start><value><separator><value><separator><value><end>
           * e.g. formatlist(['col1', 'col2'], 'start', 'separator', 'end') => startcol1separatorcol2end
           *
           */
          case 'formatlist': {
            // if value is not an array, log and return empty string
            if (!Array.isArray(value)) {
              // logger.error(
              //   `value for variable "${variableName}" is not an array, got ${typeof value} "${value.toString()}"`,
              //   logContext
              // )
              value = ''
              break
            }

            const [columns, start, separator, end] = args

            // validate that all args are present and that columns is an array
            if (
              columns === undefined ||
              start === undefined ||
              separator === undefined ||
              end === undefined ||
              !Array.isArray(columns)
            ) {
              // log what is missing
              logger.userError(
                `missing args for formatList(), variable "${variableName}", columns: ${columns}, start: ${start}, separator: ${separator}, end: ${end}`,
                logContext
              )

              value = ''
              break
            }

            // filter the values array to only entries where any of the columns exist as key will be kept
            value = value.filter((row) => columns.some((colName) => colName in row)) as any[]

            const rows = (value as any[]).map((obj) => {
              return columns.map((col) => obj[col]).join(separator)
            })

            value = start + rows.join(separator) + end

            break
          }

          /**
           * convert array to html table
           *
           * formattable(columnNames: string[], headingTitles: string[], htmlEscpae: boolean)
           * <table><tbody><tr><td><headingTitle></td><td><headingTitle></td></tr><tr><td><value></td><td><value></td></tr></tbody></table>
           *
           */
          case 'formattable': {
            // if value is not an array, log and return empty string
            if (!Array.isArray(value)) {
              // unknown if this is an error as there might be no data variables and this is valid
              // logger.error(
              //   `value for variable "${variableName}" is not an array, got ${typeof value} "${value.toString()}"`,
              //   logContext
              // )
              value = ''
              break
            }

            const [columns, headingTitles, htmlescape] = args

            // validate that all args are present and that columns and headingTitle is an array
            if (!columns || !headingTitles || !Array.isArray(columns) || !Array.isArray(headingTitles)) {
              // log what is missing
              logger.userError(
                `missing args for formatTable(), variable "${variableName}", columns: ${columns}, headingTitles: ${headingTitles}`,
                logContext
              )
              value = ''
              break
            }

            let rows = [headingTitles.map((title) => `<p><strong>${title}</strong></p>`).join('</td><td>')]

            const doEscapeHtml = htmlescape === true

            const getColumnValue = (row: any, colName: string) => {
              if (row[colName] === undefined) return ''
              if (doEscapeHtml) return escapeHtml(row[colName])
              return row[colName]
            }

            // filter the values array to only entries where any of the columns exist as key will be kept
            value = value.filter((row) => columns.some((colName) => colName in row)) as any[]

            rows = [
              ...rows,
              ...(value as any[]).map((row) => {
                return columns.map((colName) => `<p>${getColumnValue(row, colName)}</p>`).join('</td><td>')
              })
            ]

            value = '<table><tbody><tr><td>' + rows.join('</td></tr><tr><td>') + '</td></tr></tbody></table>'

            break
          }

          default:
            // error that filter was not found
            logger.userError(`filter "${filterName}" not found`, logContext)
        }

      // if value is not a string, convert to string
      if (typeof value !== 'string') {
        // logger.userError(
        //   `value for variable "${variableName}" is not a string, got ${typeof value} "${value.toString()}"`,
        //   logContext
        // )
        value = JSON.stringify(value)
      }

      const escapedValue = escapeJson(value)
      return escapedValue
    })
  }

  const dataModuleVariables: { [key: string]: any } = {}

  ;(groupsAndType.filter((g) => g.type === 'Data') as unknown as (DataGroupDB & hasDBid)[]).forEach((group) => {
    // iterate dataModuleElements and add them to dataModuleVariables according to the name defined in the dataDefinition
    dataModuleElements
      .filter((me) => me.public.groupID === group.id)
      .forEach((me) => {
        // convert element data keyed by i1, i2, i3 to key by dataDefinition name
        const dataKeyedByName = Object.entries(me.data).reduce((acc, [key, value]) => {
          const dataDef = group.dataDefinition[key as isDataKey]

          if (!dataDef || value === null || value === '' || value === undefined) return acc

          if (dataDef.name) acc[dataDef.name] = value

          // the variable will only be set if it is not already present on the variable map.
          // this results in only the first variable beeing used if multiple variables have the same name
          if (!(`data.${dataDef.name}` in dataModuleVariables)) {
            dataModuleVariables[`data.${dataDef.name}`] = value
          }

          return acc
        }, {} as { [key: string]: any })

        // add all data elements to the dataModuleVariables datas key
        if (!('datas' in dataModuleVariables)) dataModuleVariables['datas'] = []

        dataModuleVariables['datas'].push(dataKeyedByName)
      })
  })

  const variables = {
    // i1, i2, i3: any
    ...identifierValue,
    // identifier.i1, identifier.i2, identifier.i3: any
    ...Object.fromEntries(Object.entries(identifierValue).map(([key, value]) => ['identifier.' + key, value])),
    // identifier.serial_number, identifier.serial_number, identifier.serial_number: any
    ...Object.fromEntries(
      Object.entries(identifierValue).map(([key, value]) => [
        'identifier.' + backendConfigDoc.asid.identifierDefinition[key as isIdentifierKey].name || '__',
        value
      ])
    ),
    // serial_number, serial_number, serial_number: any
    ...Object.fromEntries(
      Object.entries(identifierValue).map(([key, value]) => [
        backendConfigDoc.asid.identifierDefinition[key as isIdentifierKey].name || '__',
        value
      ])
    ),
    ...Object.fromEntries(
      Object.entries(assetAttributeValue).map(([key, value]) => [
        'attribute.' + backendConfigDoc.asid.assetAttributeDefinitions[key as isAssetAttributeKey].name || '__',
        value
      ])
    ),
    'app.echoID': asidID,
    ...dataModuleVariables
  }

  // interpolate variables
  appData = appData
    .map((appData) => {
      const logContext: LogContext = {
        moduleType: appData.public.type,
        scope: 'variable interpolate'
      }
      // todo split parsing into indicidual elements to provide better logging
      /*
       * {{ identifier.i3 }} identifier.<identKey>
       * {{ identifier.serial_number }} identifier.<identName>
       * {{ app.echoID }}
       * {{ data.columnName }}
       **/
      const doInterpolateIdentifiersAndData = true
      if (doInterpolateIdentifiersAndData) {
        try {
          // interpolate public, group and element data separately for better error reporting
          appData.public = JSON.parse(interpolateMustache(JSON.stringify(appData.public), variables, { ...logContext }))
          appData.group = JSON.parse(
            interpolateMustache(JSON.stringify(appData.group), variables, { ...logContext, groupID: appData.group.id })
          )
          appData.elements = appData.elements.map((el) =>
            JSON.parse(
              interpolateMustache(JSON.stringify(el), variables, {
                ...logContext,
                elementID: el.id,
                groupID: appData.group.id
              })
            )
          )
        } catch (e) {
          logger.userError(`error interpolating mustache: ${e}`, logContext)
        }
      }

      return appData
    })
    .filter((mad) => mad.public.type !== 'Data') // dont expose data module to the app
  // #endregion interpolate identifiers and data elements

  // #endregion compile moduleGroupsAppData

  return appData
}

export function appDataCustomModule(
  appData: BaseModuleGroupAppData[],
  groupsAndType: Array<BaseGroupDB & hasDBid & { type: ModuleType }>,
  categoriesMap: Map<string, CategoryItem>,
  { categoryIDs, asidID, identifierValue }: AsidReference,
  logger: InjectedLogging
) {
  //#region custom module

  function filterObjectProperties(object: any, props: string[]) {
    return Object.keys(object)
      .filter((key) => props.includes(key))
      .reduce((obj, key) => {
        obj[key] = object[key]
        return obj
      }, {} as any)
  }

  const groupsGroupedByIDAndType = groupBy(groupsAndType, (g) => `${g.id}${GROUP_AND_TYPE_SEPARATOR}${g.type}`)

  appData = appData.map((data) => {
    if (data.public.type !== 'Custom') {
      return data
    } else {
      const customModAppData = data as CustomModuleAppData
      const groupData: CustomGroupDB = groupsGroupedByIDAndType[
        [customModAppData.group.id, customModAppData.public.type].join(GROUP_AND_TYPE_SEPARATOR)
      ][0] as unknown as CustomGroupDB

      const publishedIdentifierKeys = groupData.publishedIdentifiers
      const publishedCategories = groupData.publishCategories

      const identifiers_ = filterObjectProperties(identifierValue, publishedIdentifierKeys)
      const directCategoryNames_ = publishedCategories
        ? categoryIDs.map(
            (cid) =>
              (
                categoriesMap.get(cid) || {
                  name: ''
                }
              ).name
          )
        : []
      const allCategoryNames_ = publishedCategories ? Array.from(categoriesMap).map(([cid, val]) => val.name) : []

      return {
        ...customModAppData,
        group: {
          ...customModAppData.group,
          identifiers_,
          directCategoryNames_,
          allCategoryNames_
        }
      }
    }
  })

  //#endregion custom module

  return appData
}

export function appDataProtectionModule(
  appData: BaseModuleGroupAppData[],
  groupsAndType: Array<BaseGroupDB & hasDBid & { type: ModuleType }>,
  elementsAndType: Array<BaseElementDB & hasDBid & { type: ModuleType }>,
  accessKeys: string[],
  { categoryIDs, asidID, identifierValue }: AsidReference,
  protectionUnlockCb: (
    group: ProtectionGroupDB &
      hasDBid & {
        type: ModuleType
      },
    me: ProtectionElementPrivateDataDB & {
      public: ProtectionElementPublicData
    } & BaseElementDB &
      hasDBid,
    state: 'failed' | 'unlocked'
  ) => void,
  logger: InjectedLogging
) {
  const moduleElementsByGroupAndType = groupBy(
    elementsAndType,
    (me) => `${me.public.groupID}${GROUP_AND_TYPE_SEPARATOR}${me.type}`
  )

  const groupsGroupedByIDAndType = groupBy(groupsAndType, (g) => `${g.id}${GROUP_AND_TYPE_SEPARATOR}${g.type}`)

  // #region protection
  /*
   * Protection
   * Acess keys are used to grant access to ressources. They either match an ASID identifier or a static password
   * 1. get all groups with active protection
   * 2. if >0 exist, traverse up from the ASID categories and match with the first (or all?) protectionElement for each branch
   * 3. replace the groups elements with a protection element, if no access keys given otherwise
   * 4. check all accessKeys against all protection mechanisms and grant access if all match
   *
   * */

  // filter out all elements used for Protection Module
  const protectionAppData = appData.filter((data) => data.public.type === 'Protection') // contains array of protection groups. in practice only default group should exist => update: user defined groups
  appData = appData.filter((data) => data.public.type !== 'Protection')

  // get the module elements including private data
  const protectionModuleElements = protectionAppData
    .map(
      (ad) =>
        moduleElementsByGroupAndType[`${ad.group.id}${GROUP_AND_TYPE_SEPARATOR}${ad.public.type}`] as unknown as Array<
          ProtectionElementDB & hasDBid
        >
    )
    .flat()

  // go over all appData
  appData = appData.map((ad) => {
    // check if it is protected
    const group = groupsGroupedByIDAndType[`${ad.group.id}${GROUP_AND_TYPE_SEPARATOR}${ad.public.type}`]?.[0]

    if (group && group.protectionGroupID !== '') {
      // is protected - match agains provided keys

      // get all protection elements of the protection group selected in this group/widget
      const relevantProtectionElements = protectionModuleElements.filter(
        (pme) => pme.public.groupID === group.protectionGroupID
      )

      // check if all required protections are matched by a key
      const protectionsNoMatch = relevantProtectionElements.filter((protection) => {
        if (
          protection.mechanism.identifier !== '' && // identifier is defined
          identifierValue[protection.mechanism.identifier] && // asid has this identifier
          accessKeys.some(
            (key) => protection.mechanism.identifier !== '' && key === identifierValue[protection.mechanism.identifier]
          )
        )
          return false // a key matches the identifier

        if (
          protection.mechanism.password !== '' && // mechanism is password
          accessKeys.some((key) => key === protection.mechanism.password)
        )
          return false // a key matches the password

        // no mechanism matches
        return true
      })

      // no macthing protection was found
      if (relevantProtectionElements.length === 0) {
        logger.userError(
          `Protection Module: no protection element was defined for the ${group.type} widget "${group.name}" - ${ad.group.id}. This means the element can not be unlocked`,
          {
            moduleType: group.type,
            groupID: ad.group.id,
            scope: 'protection'
          }
        )
        // no protection was parametrized, but protection was enabled on group
        const protectionElement: ProtectionElementPublicData & hasDBid = {
          id: 'no-id-for-protection-element',
          groupID: '',
          order: 0,
          pwHint: {
            _ltType: true,
            locales: {
              en: 'ERROR: no password was set to access this data. Please ask the admin of this website.',
              de: 'ERROR: Es wurde kein Passwort zum Zugriff auf diese Daten festgelegt. Bitte wenden Sie sich an den Webseitenbetreiber.'
            }
          }
        }
        ad.elements = [protectionElement]
        ;(ad as ProtectionModuleAppData).group.originalType_ = ad.public.type
        ad.public.type = 'Protection'
      } else if (protectionsNoMatch.length > 0) {
        // not all matching keys were provided
        // report only if number of keys matches protections to prevent reporting failes if multiple protections are required, but only one provided
        if ([...new Set(accessKeys)].length > relevantProtectionElements.length - protectionsNoMatch.length) {
          protectionsNoMatch.forEach((me) => {
            protectionUnlockCb(group, me, 'failed')
          })
        }

        // change appData to protection type and remove all moduleElements
        ad.elements = protectionAppData
          .map((ad) => ad.elements)
          .flat() // change elements with one protection element
          .filter(
            (el) => protectionsNoMatch.some((me) => me.id === el.id) // get all protection elements that do not match
          )
          .slice(0, 1) // only send one password request to the client
        ;(ad as ProtectionModuleAppData).group.originalType_ = ad.public.type
        ad.public.type = 'Protection'
      } else if (relevantProtectionElements.length > 0) {
        // if all protections were unlocked, report it
        relevantProtectionElements.forEach((me) => {
          protectionUnlockCb(group, me, 'unlocked')
        })
      }
    }

    return ad
  })

  // #endregion protection

  return appData
}

export function appDataValidation(
  elementsAndType: Array<BaseElementDB & hasDBid & { type: ModuleType }>,
  appData: BaseModuleGroupAppData[],
  logger: InjectedLogging
) {
  // #region service module
  // validate that the form group for the required start form is available
  const serviceModuleChannelElements: (ServiceElementPublicData & hasDBid)[] = appData
    .filter((ad) => ad.public.type === 'Service')
    .flatMap((ad) => ad.elements as (ServiceElementPublicData & hasDBid)[])

  function getElementNameFromID(id: string) {
    const element = elementsAndType.find((el) => el.id === id)
    if (!element) return ''
    return element.name
  }

  serviceModuleChannelElements
    .filter((channel) => channel.startFormGroupID !== '')
    .forEach((channel) => {
      // check that the requested form exists
      const formGroupExists = appData
        .filter((ad) => ad.public.type === 'Form')
        .some((ad) => ad.group.id === channel.startFormGroupID)

      if (!formGroupExists)
        logger.userError(
          `Service Module: on channel "${getElementNameFromID(channel.id)}" the requested start form id "${
            channel.startFormGroupID
          }" does not exist. Please check if the form has published elements and the referencing is correct`,
          {
            moduleType: 'Service',
            groupID: channel.groupID,
            elementID: channel.id,
            scope: 'validation'
          }
        )
    })

  serviceModuleChannelElements
    .filter((channel) => channel.closeFormGroupID !== '')
    .forEach((channel) => {
      // check if the close form exists
      const closeFormGroupExists = appData
        .filter((ad) => ad.public.type === 'Form')
        .some((ad) => ad.group.id === channel.closeFormGroupID)

      if (!closeFormGroupExists)
        logger.userError(
          `Service Module: on channel "${getElementNameFromID(channel.id)}" the requested close form id "${
            channel.closeFormGroupID
          }" does not exist. Please check if the form has published elements and the referencing is correct`,
          {
            moduleType: 'Service',
            groupID: channel.groupID,
            elementID: channel.id,
            scope: 'validation'
          }
        )
    })
  // #endregion service module
}

export function appDataI18nModule(
  appData: BaseModuleGroupAppData[],
  modules: Array<BaseModuleDB>,
  logger: InjectedLogging
) {
  const modulePublicDataByType: {
    [key: string]: BaseModulePublicData
  } = {}

  modules.forEach((d) => (modulePublicDataByType[d.public.type] = d.public))

  // i18n module does not have elements nor groups. Current logic only pushes modules with active elements to client
  if (modulePublicDataByType['I18n']) {
    appData.push({
      group: {
        id: 'default-group',
        groupType: 'group-type_widget',
        display: {
          displayType: 'inline',
          imageUrl: { locales: { default: '' }, _ltType: true },
          tintMode: 'partial',
          design: {
            ...defaultWidgetDesignDefinition,
            ...defaultExpandedWidgetDesignDefinition,
            ...defaultTileWidgetDesignDefinition
          }
        },
        title: { locales: { default: '' }, _ltType: true },
        subtitle: { locales: { default: '' }, _ltType: true },
        description: { locales: { default: '' }, _ltType: true },
        order: -99999
      },
      elements: [],
      public: modulePublicDataByType['I18n']
    })
  }

  return appData
}
