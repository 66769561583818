const Transformer = {
  flatten: (object: any[] | any, separator = '.', flattenArray = false): { [key: string]: string | number } => {
    const regex = flattenArray ? /Array|Object/ : /Object/
    const fireBaseInternalTypes = ['t', 'FieldValue', 'FieldValueImpl', 'GeoPoint', 'Timestamp', 'DocumentReference']
    // t is used for firebases own types
    if (object && regex.test(object.constructor.name) && !fireBaseInternalTypes.includes(object.constructor.name)) {
      const r: any = {}
      ;(function f(object, path?: string) {
        Object.keys(object).forEach((key) =>
          object[key] && regex.test(object[key].constructor.name) && Object.keys(object[key]).length > 0
            ? f(object[key], path ? `${path}${separator}${key}` : key)
            : (r[path ? `${path}${separator}${key}` : key] = object[key])
        )
      })(object)
      return r
    }
    return {}
  },

  unflatten: (object: any, separator = '.') => {
    if (object && /Object/.test(object.constructor.name)) {
      const r = {}
      Object.keys(object).forEach(k =>
        k
          .split(separator)
          .reduce(
            (p, c, i, a) => (i === a.length - 1 ? ((p as any)[c] = object[k]) : ((p as any)[c] = (p as any)[c] || {})),
            r
          )
      )
      return r
    }
    return object
  }
}

export default Transformer
