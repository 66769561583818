
import VButtonToggleLiveUpdate from '@/components/VButtonToggleLiveUpdate.vue'
import VEchoCode from '@/components/VEchoCode.vue'
import VFilterCategoriesDropdownView from '@/components/VFilterCategoriesDropdownView.vue'
import VFilterDateDropdownView from '@/components/VFilterDateDropdownView.vue'
import VFilterDropdownView from '@/components/VFilterDropdownView.vue'
import VImportExport, { convertToNullOrString, typeImportExportDefinitions } from '@/components/VImportExport.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'
import VTagModuleElements from '@/components/VTagModuleElements.vue'
import databaseSchema from '@/database/databaseSchema'
import { AsidDB, asidID, assetAttributeValueType, identifierValueType, isAssetAttributeKey, isIdentifierKey } from '@/types/typeAsid'
import { CategoryID } from '@/types/typeCategory'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { DeepPartial, hasDBid } from '@/types/typeGeneral'
import { library } from '@fortawesome/fontawesome-svg-core'
import VTable, { TableColumnDefinition } from '@/components/VTable.vue'
import {
  faAngleLeft, faAngleRight, faArrowUp,
  faChevronRight, faClipboardList, faEllipsisH, faMars, faReplyAll, faStream, faSync, faVenus
} from '@fortawesome/free-solid-svg-icons'
import firebase from 'firebase/compat/app'
import { Component, Watch } from 'vue-property-decorator'
import AsidManager from '../../database/asidManager'
import { merge, typedWhere } from '../../database/dbHelper'
import { ModuleManager } from '../../modules/moduleManager'
import { BaseElementDB, hasModuleType, ModuleType, PublishingState } from '../../modules/typeModules'
import moment from 'moment'
import CategoryHelper from '@/database/categoryHelper'
import { FilterConfigNew } from '@/database/filterUtil'
import { DataElementDB, DataGroupDB, isDataKey } from '@/modules/data/typeDataModule'
import DataModule from '@/modules/data/dataModule'
import { DataCache } from '@/helpers/dataCache'
import { FilterConfig } from '@/components/mixins/VPaginateMixin.vue'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { CategoryEntryDefinition, CategoryEntryDefinitionObject } from '@/types/typeBackendConfig'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import { arrayUnique } from '@/helpers/arrayHelper'
import VPrivilegeNotification from '@/components/VPrivilegeNotification.vue'
import { ROOT_CATEGORY_ID } from '@/businessLogic/sharedConstants'


library.add(faArrowUp, faStream, faReplyAll, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faSync, faEllipsisH, faClipboardList)

@Component({
  components: {
    VTagModuleElements,
    VImportExport,
    VRecordMeta,
    VEchoCode,
    VFilterDropdownView,
    VFilterCategoriesDropdownView,
    VFilterDateDropdownView,
    VButtonToggleLiveUpdate,
    VTable,
    VPrivilegeNotification
  }
})
export default class BackendAsidList extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {

  public table_currentPage = 1
  public table_sortField = this.$localSettings.asidlist.sortField
  public table_sortDirection = this.$localSettings.asidlist.sortDirection
  public table_filterConfig: FilterConfig<AsidDB>[] = []

  public table_checkedRows: any[] = []
  public table_tableData: (AsidDB & hasDBid)[] = []
  public table_isLoading = false

  public debug(row: any) {
    console.log(row)
  }

  @Watch('table_sortField')
  private onChangePaginationSortField() {
    this.$localSettings.asidlist.sortField = this.table_sortField
  }

  @Watch('table_sortDirection')
  private onChangePaginationSortDirection() {
    this.$localSettings.asidlist.sortDirection = this.table_sortDirection
  }

  public getDataByDefinitionKeys(asidDB: AsidDB & hasDBid) {
    // {varName1: 12, varNameFromOtherElement: '23'}
    let combinesDataElements: { [key: string]: any } = {}

    const dataElements = (this.elementsByAsid[asidDB.id]
      ?.filter((e) => e.type === 'Data') as Array<DataElementDB & hasDBid & hasModuleType>)
      ?.sort((a, b) => b.public.order - a.public.order) || []

    // combine all data elements data values into one object
    dataElements.forEach(dataElement => {
      const dataGroup = this.dataGroupsByID[dataElement.public.groupID]
      if (dataGroup) {
        Object.entries(dataGroup.dataDefinition).map(([key, def]) => {
          if (def.name)
            combinesDataElements[def.name] = dataElement.data[key as isDataKey]
        })
      }
    })

    return combinesDataElements
  }

  // @Watch('pagination_filterConfig', { deep: true })
  // private onChangePaginationFilterConfig() {
  //   // todo
  //   this.$localSettings.asidlist.filter.categories = this.pagination_filterConfig.find(fc => fc.type === 'categories')?.in || []
  // }

  public table_liveUpdateOnFirstPage = true


  mounted() {
    //
  }

  public isLoading = false
  public doShowArchivedAsids = false
  public elementsByAsid: { [key: string]: (BaseElementDB & hasDBid & hasModuleType)[] } = {}
  public dataGroupsByID: { [key: string]: (DataGroupDB & hasDBid & hasModuleType) } = {}


  public table_perPage = 20
  public table_collectionReference = AsidManager.getDbCollectionReference()

  private branchCategoryCache: { [definitionKey: string]: CategoryID[] } = {}

  public get table_tableColumns() {
    const columnDef: TableColumnDefinition<AsidDB>[] = [
      // {
      //   field: 'asidID',
      //   label: 'ECHO CODE',
      //   numeric: false,
      //   searchable: true,
      //   sortable: true
      // },
      {
        field: 'id',
        label: 'ID',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: false,
        editable: false,
        cellClass: 'asid-column'
      }, {
        field: 'activated',
        label: 'Activated',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: true,
        sortable: true,
        editable: false,
        centered: true
      },
      {
        field: 'dateActivated',
        label: 'Date Activated',
        formatter: (d: any) => d ? moment(d.toDate()).format('YYYY.MM.DD - HH:mm') : 'none',
        numeric: false,
        searchable: true,
        sortable: true
      },
      {
        field: 'categoryIDs',
        label: 'Categories',
        formatter: (catIDs: CategoryID[]) => catIDs.map(id => this.$getCategoryName(id)),
        numeric: false,
        searchable: true,
        sortable: false,
        display: 'taglist',

        editable: true
        // headerClass: 'is-element-reference',
        // cellClass: 'is-element-reference'
        // columnGroup: 'References'
      },

      // categoryDefinitions
      ...Object.entries(this.$backendConfig.asid.categoryDefinitions || {})
        .filter(([definitionKey, definition]: [string, CategoryEntryDefinition]) => definition.title)
        // dont show category definitions with ROOT_CATEGORY_ID as pivot, as they are already covered by the categoryIDs column
        .filter(([definitionKey, definition]: [string, CategoryEntryDefinition]) => definition.validator.pivotCategory !== ROOT_CATEGORY_ID)
        // sort by key
        .sort(([keyA, a]: [string, CategoryEntryDefinition], [keyB, b]: [string, CategoryEntryDefinition]) => a.order - b.order || keyA.localeCompare(keyB, undefined, { numeric: true, sensitivity: 'base' }))
        .map(([definitionKey, definition]: [string, CategoryEntryDefinition]) => ({
          field: 'categoryIDs',
          label: definition.title,
          numeric: false,
          searchable: false,
          sortable: false,
          editable: false,
          display: 'taglist' as const,
          formatter: (catIDs: CategoryID[]) => {
            const filteredCatIDsForEntryDef = CategoryHelper.filterCategoryIDsByEntryDefinition(
              cloneObject(this.$categories),
              this.$backendConfig.asid.categoryDefinitions,
              definitionKey as keyof CategoryEntryDefinitionObject,
              catIDs,
              this.branchCategoryCache
            )
            return filteredCatIDsForEntryDef.map(id => this.$getCategoryName(id))
          }

          // headerClass: 'is-element-reference',
          // cellClass: 'is-element-reference'
          // columnGroup: 'References'
        })),


      ...Object.entries(this.$backendConfig.asid.identifierDefinition || {})
        .filter(([definitionKey, definition]) => (definition.title || definition.name))
        // sort by order and key
        .sort(([keyA, a], [keyB, b]) => a.order - b.order || keyA.localeCompare(keyB, undefined, { numeric: true, sensitivity: 'base' }))
        // filter by visible categories
        .filter(([definitionKey, definition]) => {
          const filteredCategories = CategoryHelper.getFilteredCategories(
            this.$categories,
            this.$localSettings.modules.filters.categories,
            this.$auth.user?.visibleCategories || [],
            this.$localSettings.modules.filters.categoriesIncludeParentCats,
            this.$localSettings.modules.filters.categoriesIncludeChildCats
          )

          if (filteredCategories.length === 0) return true

          if (definition.categories.length === 0) return true

          return definition.categories.some(catID => filteredCategories.includes(catID))
        })
        .map(([definitionKey, definition]) => ({
          field: `identifierValue.${definitionKey}`,
          label: (definition.title || definition.name),
          numeric: definition.datatype === 'number',
          searchable: ((+definitionKey.substring(1)) <= 6), // allow everything to be sorted 
          sortable: ((+definitionKey.substring(1)) <= 3),// only allow sort up to index d3 for index permutation reasons (alrdy 60 composiet indexes)
          editable: true,
          // display image if image otherwise tag
          display: definition.datatype === 'image' ? 'image' as const : 'tag' as const

          // headerClass: 'is-element-reference',
          // cellClass: 'is-element-reference'
          // columnGroup: 'References'
        })),

      ...Object.entries(this.$backendConfig.asid.assetAttributeDefinitions || {})
        .filter(([definitionKey, definition]) => (definition.title || definition.name))
        // sort by order and key
        .sort(([keyA, a], [keyB, b]) => a.order - b.order || keyA.localeCompare(keyB, undefined, { numeric: true, sensitivity: 'base' }))
        // filter by visible categories
        .filter(([definitionKey, definition]) => {
          const filteredCategories = CategoryHelper.getFilteredCategories(
            this.$categories,
            this.$localSettings.modules.filters.categories,
            this.$auth.user?.visibleCategories || [],
            this.$localSettings.modules.filters.categoriesIncludeParentCats,
            this.$localSettings.modules.filters.categoriesIncludeChildCats
          )

          if (filteredCategories.length === 0) return true

          if (definition.categories.length === 0) return true

          return definition.categories.some(catID => filteredCategories.includes(catID))
        })
        .map(([definitionKey, definition]) => ({
          field: `assetAttributeValue.${definitionKey}`,
          label: (definition.title || definition.name),
          numeric: definition.datatype === 'number',
          // formatter to handle boolean values
          // formatter: (value: any) => {
          //   if (definition.datatype === 'boolean') {
          //     return value === true ? 'yes' : value === false ? 'no' : ''
          //   }
          //   return value
          // },
          searchable: ((+definitionKey.substring(1)) <= 6), // allow everything to be sorted 
          sortable: ((+definitionKey.substring(1)) <= 3),// only allow sort up to index d3 for index permutation reasons (alrdy 60 composiet indexes)
          editable: true,
          display: definition.datatype === 'image' ? 'image' as const : 'tag' as const

          // headerClass: 'is-element-reference',
          // cellClass: 'is-element-reference'
          // columnGroup: 'References'
        })),

      {
        field: '_computed.responseCountPerModule',
        label: 'Interaction Count',
        tooltip: 'An interaction is counted every time a user interacts with an ECHO Code. This includes the initial view of the ECHO Code, as well as every time a user opens a file or submits a form response.',
        formatter: (responseCountPerModule: number) => Object.values(responseCountPerModule).reduce((a, b) => a + b, 0),
        numeric: false,
        searchable: false,
        sortable: false
      }, {
        field: '_computed.pageviews',
        label: 'View Count',

        numeric: false,
        searchable: true,
        sortable: true
      }
    ]

    return columnDef
  }

  public async onSetArchiveAsid(asidID: asidID, archive = false) {

    this.isLoading = true

    try {
      if (archive) {
        this.$buefy.dialog.confirm({
          title: 'Archiving ECHO Code',
          message: `Archiving the ECHO Code "${asidID}" will also archive all ${Object.values(this.table_tableData.find(e => e.id === asidID)?._computed.responseCountPerModule || { a: 0 }).reduce((a, b) => a + b)} responses assigned to this Code.`,
          confirmText: 'Archive ECHO Code',
          // type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            await AsidManager.update(asidID, this.$auth.userEmail, { publishingState: archive ? 'archived' : 'published' })
          }
        })
      } else {
        await AsidManager.update(asidID, this.$auth.userEmail, { publishingState: archive ? 'archived' : 'published' })
      }
    } catch (error: any) {
      this.$helpers.notification.Error('error changing publishing state [20220220]: ' + error.toString())
    } finally {
      this.isLoading = false
    }

  }

  // @Watch('doShowArchivedAsids')
  // private onChangeDoShowArchivedAsids() {
  //   (this.$refs as any).VTable.refreshData(true)
  // }

  // protected table_localDocsFilter(docs: (AsidDB & hasDBid)[]) {
  //   return docs.filter(d => this.doShowArchivedAsids || d.publishingState !== 'archived')
  // }

  // when doShowArchivedAsids changes, change the filter config accordingly
  @Watch('doShowArchivedAsids', { immediate: true })
  private onChangeDoShowArchivedAsidsFilterConfig() {
    const clonedFilterConfig = cloneObject(this.table_filterConfig)
    // find publishing state filter
    const filterConfig = clonedFilterConfig.find(fc => fc.fieldAccesor.publishingState)

    // if not found, return
    if (!filterConfig) return

    // set the filter value according to the doShowArchivedAsids value
    filterConfig.presets = this.doShowArchivedAsids ? ['published', 'archived'] : ['published']

    this.table_filterConfig = clonedFilterConfig

    if ((this.$refs as any).VTable)
      (this.$refs as any).VTable.refreshData(true)
  }

  //#region RecordMeta

  get documentPrivileges() {
    return merge(databaseSchema.COLLECTIONS.ASID.__PRIVILEGES__,
      { r: databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__PRIVILEGES__.r, w: [] })
  }

  //#endregion RecordMeta

  protected notBackendSortable = false

  get anyLoading() {
    return this.isLoading || this.table_isLoading
  }


  public table_queryFilter(): FilterConfigNew<AsidDB>[] {
    //  return typedWhere<AsidDB>(query, { tenantID: '' }, '==', this.$auth.tenant.id)
    return [
      {
        fieldAccessor: { tenantID: '' },
        opStr: '==',
        values: [this.$auth.tenant.id],
        indexGroups: [],
        isMandatory: true
      }
    ]
  }


  public getModuleColorByType(moduleType: ModuleType) {
    return ModuleManager.getModuleClassByType(moduleType).color
  }


  private dataCacheGroup = new DataCache<DataGroupDB & hasDBid>(async (groupID) => {
    return await DataModule.getGroup<DataGroupDB>(this.$auth.tenantID, groupID)
  })

  public async onDetailsOpen(row: any) {
    // instead of a listener, only query those values when the details are opened, as also statistic changes on the elements would cause a rerende
    this.$unbindHandle(await ModuleManager.onSnapshotElementsForAsid(row.id, this.$auth.tenant.id, undefined, { includeDeleted: false, debugName: 'asid list' }, (elements) => {
      console.log('setting', this.elementsByAsid)

      this.$set(this.elementsByAsid, row.id, elements)

      // get all groups for data elements
      const dataElements = elements.filter(e => e.type === 'Data')

      const groupIDs = arrayUnique(dataElements.map(e => e.public.groupID))

      groupIDs.forEach(groupID => {
        this.dataCacheGroup.get(groupID).then((d) => {
          this.$set(this.dataGroupsByID, groupID, d)
        }, (e) => {
          this.$helpers.notification.Error('error loading group data [202202292]: ' + e.toString())
        })
      })

    }, true))

  }

  private unsubscribeSnapshot?: SnapshotUnbindHandle = undefined

  public activated = 0
  public availableAsidSlots = 0
  public totalUsedInteractions = 0
  public availableInteractions = 0
  private onActivatedAsidsHandle?: SnapshotUnbindHandle = undefined

  public async created() {
    this.isLoading = true

    this.branchCategoryCache = {}

    // await this.$bind('asids', AsidManager.getDbCollectionReference().where('Tenant', '==', tenant.id))
    // await this.$bind('categoriesDoc', CategoryHelper.getCategoriesDoc(this.$auth.tenant.id))
    if (this.$auth.userHasPrivilege('config:read'))
      this.onActivatedAsidsHandle = AsidManager.onPlanData(this.$auth.tenantID, (data) => {
        this.activated = data._computed.activatedAsids
        this.availableAsidSlots = data.availableAsidSlots
        this.totalUsedInteractions = data._computed.totalUsedInteractions
        this.availableInteractions = AsidManager.getAvailableInteractionsCount(data)
      }, (e) => {
        this.$helpers.notification.Error('error loading plan data [20230321]: ' + e.toString())
      })

    this.setFilterConfig()
    this.onChangeGlobalCategoryFilter()
    this.isLoading = false
  }

  public setFilterConfig() {
    this.table_filterConfig = [
      // publishing state filter
      {
        fieldAccesor: { publishingState: 'published' },
        objAcessor: { value: '' },
        objDisplayAcessor: { title: '' },
        options: [{ title: 'Published', value: 'published' }, { title: 'Archived', value: 'archived' }],
        hideEmptyOption: true,
        type: 'exact' as const,
        in: [],
        presets: ['published'],
        range: [],
        notBackendSortable: false
      },
      {
        fieldAccesor: { id: '' } as Partial<AsidDB>,
        collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
        objAcessor: { id: '' },
        queryFilter: (query: firebase.firestore.Query<firebase.firestore.DocumentData>) => typedWhere<AsidDB>(query, { tenantID: '' }, '==', this.$auth.tenant.id),
        type: 'exact' as const,
        in: [],
        range: [],
        notBackendSortable: false
      }, {
        fieldAccesor: { activated: true },
        objAcessor: { value: '' },
        objDisplayAcessor: { title: '' },
        options: [{ title: 'Yes', value: true }, { title: 'No', value: false }],
        hideEmptyOption: true,
        type: 'exact' as const,
        in: [],
        range: [],
        notBackendSortable: false
      }, {
        fieldAccesor: { categoryIDs: [] },
        collectionPath: '',
        objAcessor: { categoryIDs: [] },
        type: 'categories' as const,
        // in: this.getCategoryFilter(),
        in: [],
        presets: this.getCategoryFilterPreset(),
        range: [],
        notBackendSortable: false
      }, {
        fieldAccesor: { _computed: { pageviews: 0 } } as AsidDB,
        collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
        queryFilter: (query: firebase.firestore.Query<firebase.firestore.DocumentData>) => typedWhere<AsidDB>(query, { tenantID: '' }, '==', this.$auth.tenant.id),
        objAcessor: { _computed: { pageviews: 0 } } as AsidDB,
        type: 'exact-number' as const,
        in: [],
        range: [],
        notBackendSortable: false
        // only first 3 (allow filter for 6) identifers have full sort option. are filterable though
      }, ...Object.entries(this.$backendConfig.asid.identifierDefinition)
        // .filter(([key, def]) => +key[1] <= 5)
        .map(([identifierKey, identDef]) => {
          return {
            fieldAccesor: { identifierValue: { [identifierKey]: '' } } as DeepPartial<AsidDB & hasDBid>,
            queryFilter: (query: firebase.firestore.Query<firebase.firestore.DocumentData>) => typedWhere<AsidDB>(query, { tenantID: '' }, '==', this.$auth.tenant.id),
            collectionPath: databaseSchema.COLLECTIONS.ASID.__COLLECTION_PATH__(),
            objAcessor: { identifierValue: { [identifierKey]: '' } } as DeepPartial<AsidDB & hasDBid>,
            // if the dataype is a number, use 'exact-number' filter type
            type: identDef.datatype === 'number' ? 'exact-number' as const : 'exact' as const,
            in: [],
            range: [],
            notBackendSortable: false,
            // if the datatype is a number, use set hideRange to true
            hideRange: identDef.datatype === 'number'
          }
        })
    ]
  }

  @Watch('$localSettings.modules.filters.categories', { immediate: true, deep: true })
  @Watch('$localSettings.modules.filters.categoriesIncludeChildCats')
  @Watch('$localSettings.modules.filters.categoriesIncludeParentCats')
  @Watch('$categories', { deep: true })
  private onChangeGlobalCategoryFilter() {
    const catConfigIndex = this.table_filterConfig.findIndex(fc => fc.type === 'categories')

    if (catConfigIndex >= 0) {
      (this.table_filterConfig[catConfigIndex].presets as string[]) = this.getCategoryFilterPreset()
    }
  }

  private getCategoryFilter() {
    // if no local filter is set, use the global one
    if (this.$localSettings.asidlist.filter.categories.length === 0) {
      const filterInputCagegories = [...this.$localSettings.modules.filters.categories]

      // add parent categories if configured in local settings
      if (this.$localSettings.modules.filters.categoriesIncludeParentCats) {
        const parentCats = CategoryHelper.getAllParentCategoriesArray(this.$localSettings.modules.filters.categories, this.$categories)
        filterInputCagegories.push(...parentCats)
      }

      // add child categories if configured in local settings
      if (this.$localSettings.modules.filters.categoriesIncludeChildCats) {
        const childCats = CategoryHelper.getAllChildCategoriesArray(this.$localSettings.modules.filters.categories, this.$categories)
        filterInputCagegories.push(...childCats)
      }

      console.log('filterInputCagegories', filterInputCagegories)

      return filterInputCagegories
    } else {
      return this.$localSettings.asidlist.filter.categories
    }
  }

  private getCategoryFilterPreset() {
    return CategoryHelper.getFilteredCategories(
      this.$categories,
      this.$localSettings.modules.filters.categories,
      this.$auth.user?.visibleCategories || [],
      this.$localSettings.modules.filters.categoriesIncludeParentCats,
      this.$localSettings.modules.filters.categoriesIncludeChildCats
    )
  }

  public beforeDestroy() {
    if (this.unsubscribeSnapshot) this.unsubscribeSnapshot()
    if (this.onActivatedAsidsHandle) this.onActivatedAsidsHandle()
  }


  //#region import/export

  /**
   * convert doc property to string or object or array
   * exportFormatter(doc): object|string|[]
   * 
   * apply exported doc property to doc
   * importFormatter(import: object|string|[], doc) 
   */
  public importExport_importExportDefinitions: typeImportExportDefinitions<AsidDB & hasDBid> = [
    {
      readOnly: false,
      exportColumnName: 'CategoryNames',
      exportFormatter: (me) => me.categoryIDs.map(cid => this.$getCategoryName(cid)),
      importFormatter: (imp: string[], me) => me.categoryIDs = imp.filter(catName => catName).map(catName => this.$getCategoryID(catName))
    }, {
      readOnly: true,
      exportColumnName: 'Activated',
      exportFormatter: (me) => me.activated,
      importFormatter: (imp: string, me) => me.activated = !!imp
    },
    {
      readOnly: false,
      exportColumnName: 'Identifiers',
      exportFormatter: (me) => Object.fromEntries(
        Object.entries(this.$backendConfig.asid.identifierDefinition)
          .filter(([key, value]) => value.name)
          .map(([key, value]) => [value.name, me.identifierValue[key as isIdentifierKey]])
      ),
      importFormatter: (imp: {
        [k: string]: identifierValueType
      }, me) => {
        Object.entries(this.$backendConfig.asid.identifierDefinition)
          .filter(([key, identDef]) => identDef.name)
          .forEach(([key, identDef]) => {
            if (identDef.name in imp)
              me.identifierValue[key as isIdentifierKey] = convertToNullOrString(imp[identDef.name])
          })
      }
    },
    // assetAttributes
    {
      readOnly: false,
      exportColumnName: 'AssetAttributes',
      exportFormatter: (me) => Object.fromEntries(
        Object.entries(this.$backendConfig.asid.assetAttributeDefinitions)
          .filter(([key, value]) => value.name)
          .map(([key, value]) => [value.name, me.assetAttributeValue[key as isAssetAttributeKey]])
      ),
      importFormatter: (imp: {
        [k: string]: assetAttributeValueType
      }, me) => {
        Object.entries(this.$backendConfig.asid.assetAttributeDefinitions)
          .filter(([key, identDef]) => identDef.name)
          .forEach(([key, identDef]) => {
            if (identDef.name in imp)
              me.assetAttributeValue[key as isAssetAttributeKey] = convertToNullOrString(imp[identDef.name])
          })
      }
    },
    {
      readOnly: false,
      exportColumnName: 'PublishingState',
      exportFormatter: (me) => me.publishingState,
      importFormatter: (imp: string, me) => {
        const isPublishingState = (string: any): string is PublishingState =>
          ['draft', 'published', 'archived', 'deleted'].includes(string)

        if (!isPublishingState(imp)) {
          throw `Valid values for publishingState are ('draft' , 'published' , 'archived' , 'deleted'). The supplied value was ${imp}`
        }

        me.publishingState = imp
      }
    }, {
      readOnly: true,
      exportColumnName: 'DateActivated',
      exportFormatter: (me) => me.dateActivated ? me.dateActivated.toDate().toString() : 'not activated'
    }, {
      readOnly: true,
      exportColumnName: 'DateUpdated',
      exportFormatter: (me) => me._meta.dateUpdated.toDate().toString()
    }, {
      readOnly: true,
      exportColumnName: 'FormResponses',
      exportFormatter: (me) => me._computed.responseCountPerModule.form
    }, {
      readOnly: true,
      exportColumnName: 'FileResponses',
      exportFormatter: (me) => me._computed.responseCountPerModule.file
    }, {
      readOnly: true,
      exportColumnName: 'ViewCount',
      exportFormatter: (me) => me._computed.pageviews
    }, {
      readOnly: true,
      exportColumnName: 'Echo Code Url',
      exportFormatter: (me) => AsidManager.createLink(me.id, this.$backendConfig.asid.baseUrl || undefined)
    }
  ]


  public importExport_validateImportedData(importedData: Partial<hasDBid>[]) {
    // if ('categoryIDs' in importedData && 'categoryNames' in importedData)
    // throw 'Only categoryIDs or categoryNames may be in the imported data. Remove the column you do not want to import'
  }

  public importExport_getDoc(importedData: Partial<hasDBid>) {
    return AsidManager.getDbDocReference(importedData.id || '').get()
  }

  public importExport_getDefaultDoc() {
    return AsidManager.defaultDocDB
  }

  public importExport_updateDocBatch(docId: string, docData: any, batch: firebase.firestore.WriteBatch) {
    return AsidManager.updateBatch(docId, this.$auth.userEmail, docData, batch)
  }

  //#endregion import/export

}
