
import { Component, Vue } from 'vue-property-decorator'

import { ModuleManager } from '@/modules/moduleManager'
import color from 'color'
import BaseModule from '@/modules/baseModule'
import VRecordMeta from '@/components/VRecordMeta.vue'


import { RequiredDocPrivileges } from '@/types/typeRequiredPrivileges'

@Component({
  components: {
    VRecordMeta
  }
})
export default class BackendModulesList extends Vue {
  public modules: typeof ModuleManager.moduleClasses = []


  public isLoading = false

  //#region RecordMeta
  get documentPrivileges(): RequiredDocPrivileges {
    return { r: this.modules.flatMap(m => m.authPrivileges.r), w: [] }
  }
  //#endregion RecordMeta

  public getTileStyle(module: typeof BaseModule) {
    return {
      backgroundImage: `linear-gradient( 155deg, ${module.color} 10%, ${color(module.color).rotate(-20).lighten(.1)} 100%)`,
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }
  public getTileColor(module: typeof BaseModule) {
    return {
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }

  get widgetModules() {
    return this.modules.filter(M => M.hasWidget)
  }

  get nonWidgetModules() {
    return this.modules.filter(M => !M.hasWidget)
  }

  private getActivatedModulesSnapshotHandle: (() => void) | undefined
  public async created() {
    this.isLoading = true
    this.getActivatedModulesSnapshotHandle = ModuleManager.onSnapshotActivatedModuleClasses(this.$auth.tenant.id, undefined, (modules) => {
      console.log('ouou')
      this.modules = modules
      this.isLoading = false
    }, (e) => {/** */ }, 'sideMenu')
  }

  beforeDestroy() {
    console.info('beforeDestroy')
    this.getActivatedModulesSnapshotHandle && this.getActivatedModulesSnapshotHandle()
  }
}
