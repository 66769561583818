import db from '@/firebase'

import BaseManager from './baseManager'

import databaseSchema from './databaseSchema'
import { HelpRessouceDB } from '@/types/typeHelpRessource'
import { objectID } from '@/types/typeGeneral'

export default class HelpRessourceManager extends BaseManager {
  public static defaultDocDB: HelpRessouceDB = databaseSchema.COLLECTIONS.HELPRESSOURCES.__EMPTY_DOC__

  public static add(authEmail: string) {
    return this.addDoc(this.getDbCollectionReference(), authEmail, this.defaultDocDB, this.defaultDocDB)
  }

  public static update(objectId: objectID, authEmail: string, data: HelpRessouceDB) {
    return this.updateDoc(this.getDbDocReference(objectId), authEmail, data)
  }

  public static delete(objectId: objectID) {
    return this.getDbDocReference(objectId).delete()
  }

  public static getDbDocReference(objectId: objectID) {
    return db.doc(databaseSchema.COLLECTIONS.HELPRESSOURCES.__DOCUMENT_PATH__(objectId))
  }

  public static getDbCollectionReference() {
    return db.collection(databaseSchema.COLLECTIONS.HELPRESSOURCES.__COLLECTION_PATH__())
  }
}
