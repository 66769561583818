import BaseModuleApp from '@/modules/baseModuleApp'
import { ModuleType } from '../typeModules'
import { RouteConfig } from 'vue-router'
import AppGroupLoading from '@/pages/app/App_Group_Loading.vue'

const App_Module_Custom_Overview = () =>
  import(/* webpackPrefetch: true, webpackChunkName: "App_Module_Custom_Overview" */ './App_Module_Custom_Overview.vue')

export default class CustomModuleApp extends BaseModuleApp {
  public static type: ModuleType = 'Custom'

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'custom/:groupID',
        name: 'custom',
        component: App_Module_Custom_Overview
      }
    ]
  }

  public static registerOverviewVue() {
    return () => ({
      component: App_Module_Custom_Overview(),
      loading: AppGroupLoading
    })
  }
}
