import { groupBy } from '@/database/dbHelper'
import { BaseResponseDB, ModuleType } from '@/modules/typeModules'
import { hasDBid } from '@/types/typeGeneral'
import moment from 'moment'
import { sortObjectByKeys } from './arrayHelper'
import { cloneObject } from './dataShapeUtil'

export default class ResponseViewHelper {
  public static prepareResponses<T extends (BaseResponseDB & hasDBid)[]>(responses: T, moduleTye: ModuleType) {
    return responses
      .filter((rsp) => rsp.publishingState !== 'deleted')
      .map((r) => ({
        type: moduleTye,
        date: moment(r._meta.dateCreated.toDate()).unix(),
        ...cloneObject(r),
        id: r.id
      }))
  }

  /**
   * prepareAndGroupResponses
   */
  public static groupAndSortResponses<T extends (BaseResponseDB & hasDBid & { date: number, type: ModuleType })[]>(
    allResponses: T,
    MERGE_TIME_DELTA = 10*60
  ) {
    const rsps = cloneObject(allResponses)

    const rspsByDate = sortObjectByKeys(
      groupBy(rsps, (o) => `${o.date}`),
      'desc'
    )

    const rspsByDateGroupedAndOrdered: typeof rspsByDate = {}
    // const rspsByDateGroupedAndOrdered: { [key: string]: { [key: string]: BaseResponseDB } } = {}
    Object.keys(rspsByDate).forEach((date) => {
      // merge close dates
      // 1, 23, 33, 50, 53, 58, 70 => 1, 23, 33, 50-58, 70
      const mergeTimeDelta = MERGE_TIME_DELTA
      for (const mergedTimes of Object.keys(rspsByDateGroupedAndOrdered)) {
        let timeRange = mergedTimes.split('-')
        if (timeRange.length === 1) timeRange.push(timeRange[0])
        if (timeRange.some((t) => Math.abs(+t - +date) < mergeTimeDelta)) {
          timeRange.push(date)
          timeRange = timeRange.sort()
          // merge current dataset with existing dataset
          rspsByDateGroupedAndOrdered[`${timeRange[0]}-${timeRange[timeRange.length - 1]}`] = [
            ...rspsByDate[date],
            ...rspsByDateGroupedAndOrdered[mergedTimes]
          ]
          delete rspsByDateGroupedAndOrdered[mergedTimes]
          return
        }
      }
      rspsByDateGroupedAndOrdered[`${date}-${date}`] = rspsByDate[date]
    })
    console.log('aaaaaa')

    const rspsByDateAndSession: { [key: string]: typeof rspsByDate } = {}

    Object.keys(sortObjectByKeys(rspsByDateGroupedAndOrdered, 'desc')).forEach((date) => {
      // format <12345-12346> => 12:56 - 12:57
      const dateRange = date.split('-')
      let dateString = moment.unix(+dateRange[0]).format('DD.MM.YYYY - HH:mm')
      if (dateRange[0] !== dateRange[1]) dateString += moment.unix(+dateRange[1]).format('-HH:mm')

      rspsByDateAndSession[dateString] = groupBy(rspsByDateGroupedAndOrdered[date], (e) => e.responseSession)
    })

    return rspsByDateAndSession
  }
}
