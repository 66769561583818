
import { Component, Vue, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle, faEye, faEyeSlash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


import TheMainMenu from '@/components/TheMainMenu.vue'
import TheSideMenu from '@/components/TheSideMenu.vue'
import VFormLoginView from '@/components/VFormLoginView.vue'
import VFormRegisterView from '@/components/VFormRegisterView.vue'
import VBackendAppPreviewSplitpane from '@/components/VBackendAppPreviewSplitpane.vue'
import AdminNotificationManager from '@/database/adminNotificationManager'
import databaseSchema from '@/database/databaseSchema'

import * as Sentry from '@sentry/browser'
import VEditorHtml from '@/components/global/VEditorHtml.vue'
import initGlobals from '@/modules/_globals/allGlobals'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'

library.add(faExclamationCircle, faExclamationTriangle, faEye, faEyeSlash) // used in forms with error


Vue.filter('truncate', function (text: string = '', stop: number = 0, clamp: string) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '…' : '')
})
Vue.filter('truncateMid', function (text: string = '', length: number, clamp: string = '...') {
  return length < text.length
    ? text.substring(0, length / 2) + clamp + text.substring(text.length - length / 2, text.length)
    : text
})


@Component({
  components: {
    TheMainMenu,
    TheSideMenu,
    VFormRegisterView,
    VBackendAppPreviewSplitpane,
    VFormLoginView,
    VEditorHtml
  }
})
export default class Backend extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public isHelpCenterComponentModalActive = false
  public isLoading: boolean = true

  get route() {
    return this.$route
  }
  get auth() {
    return this.$auth
  }

  get insufficientPermission() {
    return this.$route.meta?.privileges?.length > 0
      && this.$auth.user
      && !this.$auth.userHasSomePrivilege(this.$route.meta?.privileges)
  }

  public globalNotification = databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__EMPTY_DOC__.backendNotification
  public globalNotificationActive = false

  @Watch('$auth.tenant')
  private onTenantChange() {
    Sentry.setTag('tenant_number', this.$auth.tenant._number)
    Sentry.setTag('tenant_name', this.$auth.tenantName)
    Sentry.setTag('tenant_id', this.$auth.tenantID)
  }

  public mounted() {
    let interval: any = undefined
    AdminNotificationManager.onSnapshot((notification) => {
      this.globalNotification = notification.backendNotification

      clearInterval(interval)

      const applyShow = () => {
        this.globalNotificationActive = this.globalNotification.show
      }
      interval = setInterval(applyShow, this.globalNotification.reshowEverySeconds * 1000)
      applyShow()

    }, (e) => console.error(e))

  }


  @Watch('$auth.user', { immediate: true })
  @Watch('$auth.tenantID')
  public async onUserChanged() {
    this.isLoading = true
    if (this.$auth.user && this.$auth.tenantID) {
      try {
        // dispose all snapshots if logging in as another user
        this.$disposeSnapshots()
        // init all globals and wait for loaded
        this.$unbindHandle(await initGlobals(this.$auth))
      } catch (error) {
        this.$helpers.notification.Error(error)
      } finally {
        this.isLoading = false
      }
    } else {
      // displose all snapshots if logging out
      this.$disposeSnapshots()
    }

  }

  @Watch('$auth.user', { immediate: true })
  @Watch('$auth.tenantID')
  public onUserOrTenantChanged() {
    if (process.env.NODE_ENV === 'development') return // no chat in local env to reduce external networking

    // convert date to berlin timezone
    // function convertTZ(date: Date) {
    //   return new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Berlin' })
    // }

    // get hour of day in berlin timezone
    // const dateInBerlin = new Date(convertTZ(new Date()))

    // const hoursInBerlin = dateInBerlin.getHours()

    // only show during 9 to 4pm berlin time and not on weekends
    // const isBusinessHours = hoursInBerlin >= 9 && hoursInBerlin <= 16 && dateInBerlin.getDay() !== 0 && dateInBerlin.getDay() !== 6

    // if (isBusinessHours)
    //   this.$freshchat.showWidget()
    // else
    //   this.$freshchat.hideWidget()

    // if (this.$auth.tenantID && this.$auth.userID)
    //   this.$freshchat.setExternalId(this.$auth.tenantID + this.$auth.userID)
    // else if (this.$auth.userID) // if not assigned or selected a tenant yet
    //   this.$freshchat.setExternalId(this.$auth.userID)

    // this.$freshchat.setUserProperties({
    //   firstName: this.$auth.userName,
    //   lastName: this.$auth.tenantName, // reusing last name as company name, since FreshChat does not allow for custom fields in our plan
    //   email: this.$auth.userEmail,
    //   tenantID: this.$auth.tenantID,
    //   userName: this.$auth.userName
    // })
  }
}
