
import TenantManager from '@/database/tenantManager'
import { Component, ModelSync, Vue } from 'vue-property-decorator'


import VDocSelectionView from './VDocSelectionView.vue'


@Component({
  components: { VDocSelectionView }
})
export default class VAdminTenantSelectionView extends Vue {

  @ModelSync('tenantID', 'tenantSelected', { type: String, required: true })
  public readonly tenantIdModel!: string

  public collectionPath = 'Tenants'
  public queryLikeProps = ['name']
  public queryExactProps = ['_number']

  public formatPadded(number: number) {
    return TenantManager.formatPaddedNumber(number)
  }

}
