
import { Component, Vue, Watch, Prop, ModelSync } from 'vue-property-decorator'

import { locale, LocalizedField } from '../types/typeI18n'

import VInputLocalizedFieldLocaleselectorView from '@/components/VInputLocalizedFieldLocaleselectorView.vue'
import { arrayUnique } from '@/helpers/arrayHelper'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobe, faTimes } from '@fortawesome/free-solid-svg-icons'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { translatableType } from '@/components/VTranslationModal.vue'


library.add(faGlobe, faTimes)

@Component({
  components: {
    VInputLocalizedFieldLocaleselectorView,
    // async import to solve dependency loop - https://v2.vuejs.org/v2/guide/components-edge-cases.html?redirect=true#Circular-References-Between-Components
    VTranslationModal: () => import('@/components/VTranslationModal.vue')
  },
  inheritAttrs: false
})
export default class VInputLocalizedText extends Vue {

  @ModelSync('localizedField', 'lt-changed', { type: Object })
  public readonly localizedFieldModel!: LocalizedField

  @Prop({ type: Boolean, required: false, default: false })
  readonly isTranslateable!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  readonly isCustomTranslationModal!: boolean

  // to only display locale selection and translateable button
  @Prop({ type: Boolean, required: false, default: false })
  readonly hideInput!: boolean

  @Prop({ type: String, required: false, default: () => 'text' })
  readonly displayType!: translatableType

  @Prop({ type: String, required: false, default: () => 'none' })
  readonly externalSelectedLocale!: locale

  @Prop({ type: String, required: false, default: () => '' })
  placeholder!: string

  public formLocales: { [key: string]: string } = {
    default: ''
  }

  public formSelectedLocale = 'default' as locale

  @Watch('formSelectedLocale', { immediate: true })
  public onSelectedLocaleChanged() {
    if (!(this.formSelectedLocale in this.formLocales)) {
      this.formLocales[this.formSelectedLocale] = ''
    }

    this.$emit('changedLocale', this.formSelectedLocale)
  }

  @Watch('externalSelectedLocale', { immediate: true })
  public externalSnselectedLocaleChanged() {
    if (this.externalSelectedLocale !== 'none' as locale) {
      this.formSelectedLocale = this.externalSelectedLocale
    }
  }

  get availableLocales() {
    // get all configured locales and combine with locales that are already in the v-model
    return arrayUnique([
      ...Object.entries(this.localizedFieldModel.locales).filter(([key, value]) => value !== '').map(([key, value]) => key),
      ...this.$i18n.backendEnabledLocales
    ]) as locale[]
  }

  public onFormLocalesChanged() {
    // dont remove empty locales -> removed keys wount update the db -> keep locale if it was previously in the v-model
    // only add a new locale key if it contains text, otherwise just selecting an unused locale, without inputting anything causes a doc changed prompt
    const newLocales: LocalizedField['locales'] = {}

    Object.entries(this.formLocales).forEach(([locale, text]: [string, string]) => {
      // add locale if it did exist on the current object or is not empty
      if (text !== '' || locale in this.localizedFieldModel.locales)
        newLocales[locale] = text
    })

    const lt: LocalizedField = {
      _ltType: true,
      locales: newLocales
    }

    this.$emit('lt-changed', lt)
  }

  get formInput() {
    return this.formLocales[this.formSelectedLocale]
  }

  @Watch('formInput', { immediate: true })
  private onFormInputChanged() {
    this.$emit('input-value', this.formInput)
  }

  @Watch('localizedFieldModel', { immediate: true, deep: true })
  private onLocalizedFieldChanged() {
    this.formLocales = cloneObject(this.localizedFieldModel.locales)
  }

  public isTranslationModalActive = false
  public showTranslationModal() {
    this.isTranslationModalActive = true
  }

  public async onInput(val: string) {
    this.formLocales[this.formSelectedLocale] = val
    this.onFormLocalesChanged()
    await this.handleTextareaVisibility()
  }

  // #region textarea

  public TEXTAREA_INPUT_LENGTH_THRESHOLD = 10
  public showTextarea = false

  public async handleTextareaVisibility() {
    // show textarea if input length exceeds limit
    if (this.formSelectedLocale in this.formLocales && this.formLocales[this.formSelectedLocale].length > this.TEXTAREA_INPUT_LENGTH_THRESHOLD) {
      this.showTextarea = true
      await this.$nextTick()
        ; (this.$refs.textarea as any).focus()
    } else {
      // dont hide the textarea again, as this resets the carret to the end of the input
    }
  }

  public onBlurTextarea() {
    this.onLocalizedFieldChanged()
    this.showTextarea = false
  }
  // #endregion textarea
}
