// cache of all handlers
const handlers: { [key: string]: (e: any) => any } = {}

// to only set the listener once
export function setSingletClickEventListener<K extends keyof DocumentEventMap>(
  container: Document,
  namespace: string,
  eventName: K,
  cb: (e: DocumentEventMap[K]) => any
) {
  const handlerKey = `${namespace}.${eventName}`

  if (handlerKey in handlers) container.removeEventListener(eventName, handlers[handlerKey])

  if (cb) {
    handlers[handlerKey] = cb
    container.addEventListener<K>(eventName, cb)
  } else {
    delete handlers[handlerKey]
  }
}
