
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons'

library.add(faGripVertical)


import { ModuleManager } from '@/modules/moduleManager'
import color from 'color'
import BaseModule from '@/modules/baseModule'
import Draggable from 'vuedraggable'
import { BaseGroupDB, ElementWithTypeAndID, hasModuleType, ModuleType } from '../../modules/typeModules'
import { SortingOrderHelper } from '@/helpers/sortingOrderHelper'
import { RequiredDocPrivileges } from '@/types/typeRequiredPrivileges'
import VRecordMeta from '@/components/VRecordMeta.vue'
import { hasDBid } from '@/types/typeGeneral'
import { AppPreview } from '@/components/VBackendAppPreview.vue'
import { moduleOrder } from '@/shared/general'

@Component({
  components: {
    SlickList,
    SlickItem,
    VRecordMeta,
    Draggable
  },
  directives: {
    handle: HandleDirective
  }
})
export default class BackendWidgetsList extends Vue {

  public groups: Array<BaseGroupDB & hasModuleType & hasDBid> = []

  public modules: typeof ModuleManager.moduleClasses = []

  public isLoading = 0

  private newWidgetIndex = 0
  public newWidgetModal = false
  public newWidgetName = ''
  public selectedWidgetType = '' as ModuleType
  private moduleElementsMatchingFilter: ElementWithTypeAndID[] = []
  public filteredGroups: (BaseGroupDB & hasModuleType & hasDBid)[] = []

  //#region RecordMeta
  get documentPrivileges(): RequiredDocPrivileges {
    return { r: this.modules.flatMap(m => m.authPrivileges.r), w: this.modules.flatMap(m => m.authPrivileges.w) }
  }
  //#endregion RecordMeta

  public async onCreateWidget() {
    this.newWidgetModal = false
    this.isLoading += 1

    const orderIndex =
      (this.newWidgetIndex < 0) ?
        (this.filteredGroups.length === 0) ? 0 : // first widget
          this.filteredGroups[0].public.order - 100 : // add in front
        (this.newWidgetIndex === this.filteredGroups.length - 1) ? this.filteredGroups[this.filteredGroups.length - 1].public.order + 100 : // add last
          (this.filteredGroups[this.newWidgetIndex].public.order + this.filteredGroups[this.newWidgetIndex + 1].public.order) / 2 // add inbetween


    await ModuleManager.getModuleClassByType(this.selectedWidgetType)
      .addGroup(this.$auth.tenant.id,
        this.$auth.userEmail,
        {
          name: this.newWidgetName,
          public: { order: orderIndex }
        }
      )

    this.newWidgetName = ''
    this.isLoading -= 1
  }

  public onAddWidget(index: number) {
    this.newWidgetModal = true
    this.newWidgetIndex = index
    this.newWidgetName = `Widget #${index}`
  }

  public async onSortEnd({ event, newIndex, oldIndex }: { event: any, newIndex: number, oldIndex: number }) {
    if (newIndex === oldIndex) return

    this.isLoading += 1
    SortingOrderHelper.manageOrder(
      this.filteredGroups,
      oldIndex,
      newIndex,
      (el, order) => {
        return ModuleManager.getModuleClassByType(el.type)
          .updateGroup(this.$auth.tenant.id, this.$auth.userEmail, el.id, { public: { order: order } })
          .then(() => this.$helpers.notification.Success('Order updated ' + el.type))
          .catch((e) => this.$helpers.notification.Error('Error updating Order ' + el.type + ' ' + e.toString()))

      },
      (el) => el.public.order,
      (el, order) => el.public.order = order,
      true)
      .then(() => this.$helpers.notification.Success('Order updated'))
      .catch((e) => this.$helpers.notification.Error('Error updating Order ' + e.toString()))
      .finally(() => this.isLoading -= 1)

  }

  public getModuleRoute(modType: string) {
    const module = this.modules.find(m => m.type === modType)
    return module?.routeNameGroup || ''
  }

  public getDisplayTypeName(group: BaseGroupDB) {
    return BaseModule.viewOptions.find(el => el[0] === group.public.display.displayType)?.[1] || ''
  }

  public getDisplayName(modType: string) {
    const module = this.modules.find(m => m.type === modType) || BaseModule
    return module.displayName
  }

  public getBackgroundStyle(group: BaseGroupDB & hasModuleType) {
    const module = this.modules.find(m => m.type === group.type) || BaseModule
    return {
      backgroundImage: `linear-gradient( 155deg, ${module.color} 10%, ${color(module.color).rotate(-15).lighten(.1)} 100%)`,
      color: (color(module.color).isDark()) ? '#fff' : '#000',
      width: group.public.display.displayType === 'tile-half' ? 'calc(50% - 0.8em)' : '100%'
    }
  }
  public getTileColor(modType: string) {
    const module = this.modules.find(m => m.type === modType) || BaseModule
    return {
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }

  private getActivatedModulesSnapshotHandle: (() => void) | undefined
  private getModulesGroupsSnapshotHandle: (() => void) | undefined

  public async created() {

    try {

      this.isLoading += 1

      AppPreview.setMode('references')

      // dont call with privileges, since user must have the manage privieleg anyway to do this operation
      this.getActivatedModulesSnapshotHandle = ModuleManager.onSnapshotActivatedModuleClasses(this.$auth.tenant.id, undefined, (modules) => {
        this.modules = modules.filter(g => g.hasWidget)
      }, (e) => {/** */ }, 'orderWidgets')

      this.getModulesGroupsSnapshotHandle = ModuleManager.onSnapshotModuleGroups(
        this.$auth.tenant.id,
        undefined,
        { includeDeleted: false },
        (groups) => {
          console.log('onSnapshotModuleGroups')

          this.groups = groups
            .sort((a, b) =>
              // if the order is qual, sort by the initial module order, if equal, sort by group id
              a.public.order - b.public.order ||
              (moduleOrder[a.type] || 0) - (moduleOrder[b.type] || 0) ||
              a.id.localeCompare(b.id, undefined, { numeric: true, sensitivity: 'base' }))
            .filter(g => ModuleManager.getModuleClassByType(g.type).hasWidget)
        },
        (err) => this.$helpers.notification.Error(err)
      )


      // make sure newly added widgets have a proper order no
      // disable automatic order change for now, since sideffects of a user with not all required privileges have still to be identified
      // let updateRequired = false
      // SortingOrderHelper.checkAndFixOrder(
      //   this.groups,
      //   (el, order) => {
      //     updateRequired = true
      //     return ModuleManager.getModuleClassByType(el.type)
      //       .updateGroup(this.$auth.tenant.id, this.$auth.userEmail, el.id, { public: { order: order } })
      //       .catch((e) => this.$helpers.notification.Error('Error updating Order ' + el.type + ' ' + e.toString()))
      //   },
      //   (el) => el.public.order,
      //   (el, order) => el.public.order = order)

      // if (updateRequired)
      //   this.$helpers.notification.Success('Order updated for new Widgets')
    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading -= 1
    }
  }

  @Watch('groups', { immediate: true, deep: true })
  @Watch('$localSettings.modules.filters.categories', { deep: true })
  @Watch('moduleElementsMatchingFilter', { deep: true })
  private onGroupsChange() {

    // filter groups by elements which match the global category filter
    this.filteredGroups = (this.$localSettings.modules.filters.categories.length > 0
      ? this.groups.filter(g => this.moduleElementsMatchingFilter.some(el => el.public.groupID === g.id && el.type === g.type))
      : this.groups)
      // filter out goups with public.groupType === 'group-type_group'
      .filter(g => g.public.groupType !== 'group-type_group')
  }

  // public get filteredGroups() {
  //   // filter groups by elements which match the global category filter
  //   return this.$localSettings.modules.filters.categories.length > 0
  //     ? this.groups.filter(g => this.moduleElementsMatchingFilter.some(el => el.public.groupID === g.id && el.type === g.type))
  //     : this.groups
  // }


  @Watch('$localSettings.modules.filters.categories', { immediate: true, deep: true })
  @Watch('$localSettings.modules.filters.categoriesIncludeChildCats')
  @Watch('$categories')
  private async onChangeGlobalCategoryFilter() {
    // get a list of all elements matching the global category filter

    const filterInputCagegoryIDs = this.$localSettings.modules.filters.categories

    if (filterInputCagegoryIDs.length === 0) {
      this.moduleElementsMatchingFilter = []
      return
    }

    this.isLoading += 1

    try {
      const elements = await ModuleManager.getModuleElementsForCategories({
        allCategories: this.$categories,
        filterCategoryIDs: filterInputCagegoryIDs,
        includeChildCategories: this.$localSettings.modules.filters.categoriesIncludeChildCats,
        includeParentCategories: this.$localSettings.modules.filters.categoriesIncludeParentCats,
        tenantID: this.$auth.tenant.id,
        userPrivileges: this.$auth.userPrivileges
      })

      this.moduleElementsMatchingFilter = elements

    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading -= 1
    }

  }

  beforeDestroy() {
    console.info('beforeDestroy')
    this.getModulesGroupsSnapshotHandle && this.getModulesGroupsSnapshotHandle()
    this.getActivatedModulesSnapshotHandle && this.getActivatedModulesSnapshotHandle()
  }

}
