
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class VBreadcrumb extends Vue {
  get matchedRoutes() {
    return this.$route.matched
  }

  get route() {
    return this.$route
  }
  public showName(item: any) {
    return (item.meta && item.meta.label) || item.name
  }

}
