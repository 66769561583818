
import { Component, Vue, Prop } from 'vue-property-decorator'
import { NodeViewWrapper } from '@tiptap/vue-2'


import VImageUploadModal, { inferImgDescriptorType } from '@/components/image/VImageUploadModal.vue'

@Component({
  components: {
    NodeViewWrapper,
    VImageUploadModal
  }
})
export default class VResizableImage extends Vue {

  // #region tiptap props passed to component 
  // https://tiptap.dev/guide/node-views/vue

  @Prop({ type: Object, required: true})
  readonly node!: any
  
  @Prop({ type: Object, required: true})
  readonly extension!: any
  
  @Prop({ type: Function, required: true})
  readonly updateAttributes!: (d:any)=>void
  // #endregion tiptap props 

    
  get uploadPath(){
    return this.extension.options.uploadPath
  }

  public isImageUploadModalActive = false
  public variableUrl = require('@/assets/editor/placeholderImage.jpeg')
  
  public get imageDescriptor(){
    return (this as any).node.attrs.src
  }
  
  public onRemoveImage() {
      (this as any).deleteNode()
  }

  public onUpdateImage(imageDescriptor:string) {
    this.updateAttributes({ src: imageDescriptor})     
  }

  public editImage() {
    this.isImageUploadModalActive = true
  }
  
  get imageDescriptorType() {
    return inferImgDescriptorType(this.imageDescriptor)
  }

  get  isDraggable() {
    return this.node?.attrs?.isDraggable
  }
    
  get width() {
    console.log('get', (+(this as any).node.attrs.width.split('%')[0]) || 0)
    return (+(this as any).node.attrs.width.split('%')[0]) || 0
  }

  set width (width: number) {
    console.log('set', {
      width: '' + width + '%'
    });
    (this as any).updateAttributes({
      width: '' + width + '%'
    })
  }
  
  get sliderWidths() {
    const leftPadding = (100 - (this as any).width) / 2
    return [leftPadding, leftPadding + (this as any).width]
  }

  set sliderWidths (sliderWidths: number[]) {
        (this as any).width = sliderWidths[1] - sliderWidths[0]
  }
  
}
