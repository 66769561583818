
import { Component, Vue } from 'vue-property-decorator'
import VInputColorpicker from '@/components/VInputColorpicker.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { saveAs } from 'file-saver'
import 'prismjs'
import 'prismjs/themes/prism.css'
// import PrismEditor from 'vue-prism-editor'
import AsidManager from '@/database/asidManager'

library.add(faUndo)


@Component({
  components: { VInputColorpicker }
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class AdminQRGenerator extends Vue {


  public qrStats: {
    mask: number
    version: number
    ecl: string
    mode: string
    QRsvg: string
  } = {
      mask: 0,
      version: 0,
      ecl: '',
      mode: '',
      QRsvg: ''
    }

  public url: string = ''
  public ecl: 'L' | 'M' | 'Q' | 'H' = 'L'
  public bgColor: string = 'black'
  public fgColor: string = 'white'

  get qrCode() {
    this.qrStats = AsidManager.getDebugQrCodeSvg(
      this.url,
      this.ecl,
      this.bgColor,
      this.fgColor
    )

    return this.qrStats.QRsvg
  }

  public onDownload() {

    const svgText = (this.$refs.qr_code as Element).innerHTML
    const svgBlob = new Blob([svgText], { type: 'image/svg+xml;charset=utf-8' })
    console.log('sdsdsdsdsd')

    saveAs(svgBlob, `QR_CODE-${this.url}.svg`)
  }

  public async mounted() {
    // this.isLoading = true
    // this.tasks.forEach((t) => {
    //   t.inputPayloadString = JSON.stringify(t.taskData.payload)
    // })
    // this.isLoading = false
  }

}
