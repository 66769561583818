
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import vModuleMenu from '../modules/VModuleMenu.vue'
import VBackendAppPreview from '@/components/VBackendAppPreview.vue'

@Component({
  components: {
    vModuleMenu, VBackendAppPreview, Splitpanes, Pane
  }
})
export default class VBackendAppPreviewSplitpane extends Vue {

  @Prop({ type: Boolean, required: false, default: false })
  public hidePreview!: boolean

  public async created() {
    //
  }
}
