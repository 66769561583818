import Vue from 'vue'

import BaseGlobals from './baseGlobals'

import { VueConstructor } from 'vue/types/umd'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'

import { UserPrivilegeIdDB } from '@/types/typeUser'
import { intersectSome } from '@/helpers/arrayHelper'
import { hasDBid, objectID } from '@/types/typeGeneral'
import ProtectionModule from '../protection/protectionModule'
import { ProtectionGroupDB } from '../protection/typeProtectionModule'
import notificationHelper from '@/helpers/notificationHelper'

const ENABLE_LOG = true

class ProtectionGlobals extends BaseGlobals {
  constructor() {
    super()
  }

  // init is only called in backend
  init(tenantID: objectID, userPrivileges: UserPrivilegeIdDB[]): Promise<SnapshotUnbindHandle> {
    if (ENABLE_LOG) console.log('init protection globals')

    if (!intersectSome([...ProtectionModule.authPrivileges.r], userPrivileges)) {
      return new Promise((resolve) =>
        resolve(() => {
          /** */
        })
      )
    } else {
      return new Promise((resolve, reject) => {
        const unbindSnapshot = ProtectionModule.onSnapshotGroups(
          tenantID,
          (protectionGroups) => {
            this.obersvableData.splice(0)

            protectionGroups.forEach((protectionGroup, i) => {
              Vue.set(this.obersvableData, i, protectionGroup)
            })

            resolve(unbindSnapshot)
          },
          (e) => {
            notificationHelper.Error(`Error while loading protection groups: ${e}`)
            reject(e)
          }
        )
      })
    }
  }

  /**
   * array of protection group names and IDs
   */
  public obersvableData: Array<ProtectionGroupDB & hasDBid> = Vue.observable([])
}

export const ProtectionGlobalsInst = new ProtectionGlobals()

declare module 'vue/types/vue' {
  interface Vue {
    $protectionGroups: Array<ProtectionGroupDB & hasDBid>
  }
}

export default {
  install(Vue: VueConstructor, options: any) {
    Vue.prototype.$protectionGroups = ProtectionGlobalsInst.obersvableData
  }
}
