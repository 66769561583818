// make listeners global for simpler debugging
let listeners: {
  eventName: string
  cb: (eventData: any) => void
  listenerToken: number
}[] = []

let listenerTokenCounter = 0

export default class Event<EventType> {
  private debugName = ''
  private eventName = ''

  constructor(eventName: string, debugName: string) {
    this.eventName = eventName
    this.debugName = debugName
  }

  /**
   * listen to a specific event topic
   * call returned function to unsubscribe
   */
  public on(cb: (eventData: EventType) => void) {
    const listenerToken = listenerTokenCounter++

    console.debug(
      `EventBus [${this.debugName}]: on '${this.eventName}' registered. Currently ${listeners.length} listeners`
    )

    listeners.push({
      listenerToken,
      eventName: this.eventName,
      cb: (data) => {
        if (this.debugName) console.debug(`EventBus [${this.debugName}]: on '${this.eventName} called'`)
        cb(data)
      }
    })

    if (this.debugName) console.debug(`EventBus [${this.debugName}]: on '${this.eventName} registered'`)

    return () => {
      if (this.debugName) console.debug(`EventBus [${this.debugName}]: on '${this.eventName} unbound'`)

      listeners = listeners.filter((listener) => listener.listenerToken !== listenerToken)
    }
  }

  /**
   * emit data to a specific event topic
   */
  public emit(eventData: EventType) {
    if (this.debugName) console.debug(`EventBus [${this.debugName}]: emit '${this.eventName} emitted'`)

    listeners.forEach((listener) => {
      if (listener.eventName === this.eventName) {
        listener.cb(eventData)
      }
    })
  }

  /**
   * unsubscribe from all topics
   */
  public unsubscribeAll() {
    if (this.debugName) console.debug(`EventBus [${this.debugName}]: unsubscribeAll`)

    listeners = listeners.filter((listener) => listener.eventName !== this.eventName)
  }
}
