
import { Component, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEdit, faExternalLinkAlt, faSearch
} from '@fortawesome/free-solid-svg-icons'

import Tenant, { TenantDB } from '@/types/typeTenant'
import TenantManager from '../../database/tenantManager'
import VPaginationMixin from '../../components/mixins/VPaginateMixin.vue'
import { mixins } from 'vue-class-component'
import AsidManager from '@/database/asidManager'
import { PlanDB, PlanType } from '@/types/typePlan'
import { hasDBid, objectID } from '@/types/typeGeneral'
import { MenuNotifications } from '@/components/global/VNotificationCounts.vue'
import databaseSchema from '@/database/databaseSchema'
import { typedWhereV9 } from '@/database/dbHelper'
import db from '@/firebase'
import { collectionGroup, getDocs } from 'firebase/firestore'
import { FilterConfigNew } from '@/database/filterUtil'
import { plans } from '@/businessLogic/plans'
import { handlePromiseError } from '@/helpers/notificationHelper'


library.add(faEdit, faSearch, faExternalLinkAlt)


export async function getMenuNotification(): Promise<MenuNotifications> {
  // get all plans where the type is active_plan 

  const activePlansQuery = typedWhereV9(
    collectionGroup(db, databaseSchema.COLLECTIONS.TENANTS.DATA.__NAME__),
    { type: 'active_plan' }, '==', 'active_plan'
  )

  const activePlansDocSnaps = await getDocs(activePlansQuery)

  const activePlans = activePlansDocSnaps.docs.map((doc) => doc.data() as PlanDB)

  // check if the _computed.activatedAsids > availableAsidSlots

  const activePlansWithNotOverusedAsids = activePlans.filter((plan) => plan._computed.activatedAsids < plan.availableAsidSlots)

  // if only 10% of the availableAsidSlots are left, show a warning
  const activePlansWithAlmostOverusedAsids = activePlansWithNotOverusedAsids.filter((plan) => plan._computed.activatedAsids > plan.availableAsidSlots * 0.9)

  return {
    info: { count: 0, text: '' },
    warning: { count: activePlansWithAlmostOverusedAsids.length, text: 'Tenants with less than 10% free slots' },
    error: { count: activePlans.length - activePlansWithNotOverusedAsids.length, text: 'Tenants without free slots' }
  }
}

@Component({
  components: {}
})
export default class AdminTenantList extends mixins<VPaginationMixin<TenantDB>>(VPaginationMixin) {
  public pagination_sortDirection: 'asc' | 'desc' = 'desc'
  public pagination_sortField: string = '_number'
  public pagination_perPage = 500

  public checkedRows: Array<TenantDB & hasDBid & { plan: PlanDB }> = []
  public isLoading = false
  public pagination_paginatedData: Array<TenantDB & hasDBid & { plan: PlanDB }> = []

  protected pagination_collectionReference = TenantManager.getDbCollectionReference()

  public backendBaseUrl = `${process.env.VUE_APP_URL_PROTOCOL}${process.env.VUE_APP_BACKEND_BASE_URL}`

  public columnsVisible = {
    Email: false
  }

  public getPlanName(planType: PlanType) {
    return plans[planType]
  }

  public formDoFilterByActive = false
  private tenantIDwithActivePlan: Array<objectID> = []

  @Watch('formDoFilterByActive')
  public async onChangeFormDoFilterByActive() {


    const activePlansQuery = typedWhereV9(
      collectionGroup(db, databaseSchema.COLLECTIONS.TENANTS.DATA.__NAME__),
      { type: 'active_plan' }, '==', 'active_plan'
    )

    const activePlansDocSnaps = await getDocs(activePlansQuery)

    const activePlanPaths = activePlansDocSnaps.docs.map((doc) => doc.ref.path)

    this.tenantIDwithActivePlan = activePlanPaths.map((path) => path.split('/')[1])

    // reset fetch queue
    this.planDocIDsFetched = []
    this.planDocIDsFetchQueue = []
    this.pagination_getData(true)
  }

  protected pagination_filter(): FilterConfigNew<TenantDB & hasDBid>[] {
    return (this.formDoFilterByActive)
      ? [{
        fieldAccessor: { id: '' },
        opStr: 'in',
        values: this.tenantIDwithActivePlan,
        indexGroups: [],
        isMandatory: false
      }]
      : []
  }


  // protected filter(query: firebase.firestore.Query<firebase.firestore.DocumentData>) {
  // filter does only work when grouped by the filter property. 
  // It does not really work in combination with pagination. -> only filter locally if all elements are loaded
  // console.log('wugawuga') 
  // const searchText = 'AC'
  // this.pagination_sortField = 'name'
  // return query.startAt(searchText).endAt(searchText + '\uf8ff')
  // // return typedWhere<TenantDB>(query, { name: '' }, '>=', 'ACME')
  // }

  public formatPadded(number: number) {
    return TenantManager.formatPaddedNumber(number)
  }

  public onSort(field: string, order: 'asc' | 'desc') {
    console.log(field, order)

    this.pagination_sortField = field
    this.pagination_sortDirection = order
  }

  public onPageChange(page: number = 1) {
    this.pagination_currentPage = page
  }

  public getAvailableInteractionsCount(plan: PlanDB) {
    return AsidManager.getAvailableInteractionsCount(plan)
  }

  private filteredColumns: Tenant[] = []

  public onFiltersChange(doc: any) {
    console.log('onFiltersChange', doc)
    const tableRef = this.$refs.table as any
    this.filteredColumns = tableRef?.newData ? tableRef.newData : []

    console.log('filteredColumns', this.filteredColumns)
  }

  public beforeDestroy() {
    clearInterval(this.interval)
    this.interval = 0
  }

  @Watch('filteredColumns')
  private onFilteredColumnsChange() {
    console.log('onFilteredColumnsChange', this.filteredColumns)

    //add the filtered tenant ids to the top of the fetch plan queue
    this.planDocIDsFetchQueue = this.filteredColumns.map((U: Tenant) => U.id).concat(this.planDocIDsFetchQueue)
  }

  private planDocIDsFetchQueue: Array<string> = []
  private planDocIDsFetched: Array<string> = [] // fetched or fetching
  private interval: any = 0
  private concurrentFetches = 0

  private throttledGetPlanDoc() {
    const FETCH_DOCS_PER_SECOND = 10
    const SIMULTANEOUS_FETCHES = 5


    const fetchDocs = async () => {
      // filter out all duplicates, keeping the first occurence
      this.planDocIDsFetchQueue = this.planDocIDsFetchQueue.filter((value, index, self) => self.indexOf(value) === index)

      // filter out already fetched
      this.planDocIDsFetchQueue = this.planDocIDsFetchQueue.filter((value) => !this.planDocIDsFetched.includes(value))

      console.log('fetchDocs', this.planDocIDsFetchQueue.length, this.planDocIDsFetched.length, this.concurrentFetches)


      if (this.concurrentFetches >= SIMULTANEOUS_FETCHES) return

      if (this.planDocIDsFetchQueue.length > 0) {
        const docID = this.planDocIDsFetchQueue.shift()
        if (docID) {
          this.planDocIDsFetched.push(docID)

          this.concurrentFetches++


          console.log('fetchDoc', docID)
          const s = await TenantManager.getDbPlanDocReference(docID).get()
          const plan = s.data() as PlanDB
          const index = this.pagination_paginatedData.findIndex((U: Tenant) => U.id === docID)

          this.concurrentFetches--

          if (index !== -1) {
            this.$set(this.pagination_paginatedData[index], 'plan', plan)
          }
        }
      } else {
        clearInterval(this.interval)
        this.interval = 0
      }
    }

    if (this.interval === 0)
      this.interval = setInterval(() => handlePromiseError(fetchDocs()), 1000 / FETCH_DOCS_PER_SECOND)

  }

  protected async pagination_foreachDoc(doc: any) {
    this.planDocIDsFetchQueue.push(doc.id as string)


    this.throttledGetPlanDoc()


    // (TenantManager.getDbPlanDocReference(doc.id).get())
    //   .then((s) => {
    //     const plan = s.data() as PlanDB

    //     console.log('plan', plan)
    //     if (!plan) return
    //     this.$set(doc, 'plan', plan)
    //   })
  }

  public created() {
    this.getData()
  }

  public formRemoveTenant(id: string) {
    const tmpTenant = this.pagination_paginatedData.find((U: Tenant) => U.id === id)// todo is this check neccessary?

    if (tmpTenant) {
      this.$buefy.dialog.confirm({
        title: 'Deleting account',
        message: `Are you sure you want to <b>delete ${tmpTenant.name} </b> account and all linked ECHO CODES? This action cannot be undone.`,
        confirmText: 'Delete Account',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {

          this.isLoading = true

          TenantManager.deleteTenantAndLinkedAsids(id)
            .then((result) => {
              this.$helpers.notification.Success({ message: 'Delete success: ' + JSON.stringify(result), duration: 2000 })
              this.getData(true)
            })
            .catch((err) => {
              this.$helpers.notification.Error(`Delete failed, see console, ${err}`)
            }).finally(() => this.isLoading = false)
        }
      })
    }
  }
}
