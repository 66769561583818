
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { NodeViewWrapper } from '@tiptap/vue-2'

@Component({
  components: {
    NodeViewWrapper
  }
})
export default class VYoutubeExtension extends Vue {

  @Prop()
  readonly nodeViewProps!: object

  public isDraggable = false
  public videoLinkUrl = ''
  public videoModalActive = false
  public videoType = ''
  public videoMessage = ''
  public regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/

  get addNode() {
    return (this as any).$parent.$parent.addNode
  }

  @Watch('videoLinkUrl', { immediate: true })
  onImageChangeUrl() {
    this.videoMessage = ''
    this.videoType = ''
  }

  public youtubeParser(url: string) {
    const match = url.match(this.regExp)
    url = match && match[7].length === 11 ? match[7] : ''
    return url
  }

  public createEmbedUrl(urlOrId: string) {
    return 'https://www.youtube.com/embed/' + (urlOrId.length === 11 ? urlOrId : this.youtubeParser(urlOrId))
  }

  public onRemoveYoutubeLink() {
    (this as any).deleteNode()
  }

  public onUpdateYoutubeLink() {
    if (this.videoLinkUrl.match(this.regExp)) {
      (this as any).updateAttributes({ src: this.videoLinkUrl })
      this.videoModalActive = false
    } else {
      this.videoType = 'is-danger'
      this.videoMessage = 'please enter a valid youtube url'
    }
    if (this.videoLinkUrl == '') {
      this.videoType = 'is-danger'
      this.videoMessage = 'field is empty'
    }
  }
  public editVideo() {
    this.videoLinkUrl = (this as any).node.attrs.src
    this.videoModalActive = true
  }
}
