import Vue from 'vue'

import BaseGlobals from './baseGlobals'

import { VueConstructor } from 'vue/types/umd'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'

import { UserPrivilegeIdDB, UserReferenceDB } from '@/types/typeUser'
import { intersectSome } from '@/helpers/arrayHelper'
import { hasDBid, objectID } from '@/types/typeGeneral'

import notificationHelper from '@/helpers/notificationHelper'
import UserManager from '@/database/userManager'
import { dbPrivileges } from '@/helpers/privileges'

const ENABLE_LOG = true

class UserGlobals extends BaseGlobals {
  constructor() {
    super()
  }

  // init is only called in backend
  init(tenantID: objectID, userPrivileges: UserPrivilegeIdDB[]): Promise<SnapshotUnbindHandle> {
    if (ENABLE_LOG) console.log('init User globals')

    if (!intersectSome([dbPrivileges.USER_READ], userPrivileges)) {
      return new Promise((resolve) => {
        console.warn('UserGlobals: no privileges to read users, skip init', userPrivileges)
        return resolve(() => {
          /** */
        })
      })
    } else {
      return new Promise((resolve, reject) => {
        const unbindSnapshot = UserManager.getDbCollectionReference(tenantID).onSnapshot(
          (querySnapshot) => {
            const userDocs = querySnapshot.docs.map((doc) => {
              const data = doc.data() as UserReferenceDB
              return {
                ...data,
                id: doc.id
              }
            })

            // reset all
            this.obersvableData.splice(0)

            userDocs.forEach((userDoc, i) => {
              Vue.set(this.obersvableData, i, userDoc)
            })

            resolve(unbindSnapshot)
          },
          (e) => {
            notificationHelper.Error(`Error while loading Users: ${e}`)
            reject(e)
          }
        )
      })
    }
  }

  /**
   * array of users names and IDs
   */
  public obersvableData: Array<UserReferenceDB & hasDBid> = Vue.observable([])
}

export const UserGlobalsInst = new UserGlobals()

declare module 'vue/types/vue' {
  interface Vue {
    $users: Array<UserReferenceDB & hasDBid>
  }
}

export default {
  install(Vue: VueConstructor, options: any) {
    Vue.prototype.$users = UserGlobalsInst.obersvableData
  }
}
