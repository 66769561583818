import { RouteConfig } from 'vue-router'
import BaseModuleApp from '@/modules/baseModuleApp'
import { ModuleType } from '../typeModules'

import AppGroupLoading from '@/pages/app/App_Group_Loading.vue'
import { objectID } from '@/types/typeGeneral'
import { TenantID } from '@/types/typeTenant'
import { FormResponseAppData } from './typeFormModule'
import { asidID } from '@/types/typeAsid'

const App_Module_Form_View = () =>
  import(/* webpackPrefetch: true, webpackChunkName: "App_Module_Form_View" */ './App_Module_Form_View.vue')

export default class FormModuleApp extends BaseModuleApp {
  public static type: ModuleType = 'Form'

  public static MAX_FILESIZE = 1024 * 1024 * 50 // 50MB
  public static MAX_APP_IMAGE_SIZE = 1024 * 1024 * 2 // 2MB

  public static async getResponses(
    elementID: objectID,
    tenantID: TenantID,
    asidID: asidID,
    responseIDs: objectID[] = [],
    responseTransactionIDs: objectID[] = []
  ) {
    return this.getResponsesHelper<FormResponseAppData>(
      elementID,
      tenantID,
      asidID,
      responseIDs,
      responseTransactionIDs
    )
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'form/:groupID',
        name: 'form',
        component: App_Module_Form_View
      }
    ]
  }

  public static registerOverviewVue() {
    return () => ({
      component: App_Module_Form_View(),
      loading: AppGroupLoading
    })
  }

  public static registerBackgroundVue() {
    //
  }
}
