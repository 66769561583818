import FileSaver from 'file-saver'

export default function downloadString(data: string, filename: string, mimetype: string = 'text/plain') {
  console.log(data)

  // var blob = new Blob(['Hello, world!'], { type: 'text/plain;charset=utf-8' })
  // FileSaver.saveAs(blob, 'hello world.txt')

  const blob = new Blob([data], { type: mimetype + ';charset=utf-8' })
  FileSaver.saveAs(blob, filename)
  // downloadjs(data, filename, mimetype)

  // const element = document.createElement('a')
  // element.setAttribute('href', `data:${mimetype};charset=utf-8,` + encodeURIComponent(data))
  // element.setAttribute('download', filename)

  // element.style.display = 'none'
  // document.body.appendChild(element)

  // element.click()

  // document.body.removeChild(element)
}

export function downloadBlob(data: Blob, filename: string) {

  FileSaver.saveAs(data, filename)

}
