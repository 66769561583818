
let clientSessionID = ''

export function getSessionID() {
  return clientSessionID
}

export function setSessionID(sessionID: string) {
  clientSessionID = sessionID
}
