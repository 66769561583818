import { ModuleType } from '@/modules/typeModules'

import { RouteConfig, RawLocation } from 'vue-router'
import BaseModule from '@/modules/baseModule'

import { ScriptModuleDB, ScriptElementDB, RequiredScriptPrivileges } from './typeScriptModule'

import { defaultScriptModuleDB, defaultScriptElementDB } from '@/database/databaseSchema'
import { UserPrivilegeIdDB } from '@/types/typeUser'

export default class ScriptModule extends BaseModule {
  public static type: ModuleType = 'Script'
  public static displayName = 'Script'
  public static color = '#de344c'
  public static hasWidget = false
  public static description = 'Embed custom JavaScript code.'
  public static descriptionLong =
    'Define custom scripts (JavaScript) which will be executed in the background on every App page.'

  public static authPrivileges: RequiredScriptPrivileges = {
    r: ['script:read'],
    w: ['script:write'],
    view: ['script:view']
  }

  public static moduleDB: ScriptModuleDB = defaultScriptModuleDB
  public static elementDB: ScriptElementDB = defaultScriptElementDB

  constructor() {
    super()
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return [
      {
        to: { name: this.routeNameList },
        displayName: this.displayName
      }
    ]
  }

  public static get routeNameGroup() {
    return this.routeNameElement
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'module/script',
        name: this.routeNameList,
        component: () => import('./Backend_Module_Script_List.vue'),
        meta: {
          label: this.displayName,
          description: 'Manage your Scripts',
          breadcrumbs: false,
          isFullsize: true
        },
        children: [
          {
            path: ':id',
            name: this.routeNameElement,
            component: () => import('./Backend_Module_Script_Single.vue'),
            meta: {
              label: this.displayName,
              description: 'Manage your Script',
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          }
        ]
      }
    ]
  }
}
