import { ModuleType } from '@/modules/typeModules'

import { RouteConfig, RawLocation } from 'vue-router'
import BaseModule from '@/modules/baseModule'

import { HtmlModuleDB, HtmlElementDB, RequiredHtmlPrivileges, HtmlGroupDB } from './typeHtmlModule'

import {
  defaultHtmlGroupDB,
  defaultHtmlElementDB,
  defaultHtmlModuleDB
} from '@/database/databaseSchema'
import { UserPrivilegeIdDB } from '@/types/typeUser'

export default class HtmlModule extends BaseModule {
  public static type: ModuleType = 'Html'
  public static displayName = 'Text'
  public static color = '#e8b50c'
  public static description = 'Create textual Information Widgets.'
  public static descriptionLong =
    'Create and edit the text widgets of the App. Use this Widget for example for welcome text, news or general information content.'

  public static authPrivileges: RequiredHtmlPrivileges = {
    r: ['html:read'],
    w: ['html:write'],
    view: ['html:view']
  }

  public static moduleDB: HtmlModuleDB = defaultHtmlModuleDB
  public static elementDB: HtmlElementDB = defaultHtmlElementDB
  public static groupDB: HtmlGroupDB = defaultHtmlGroupDB

  constructor() {
    super()
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return [
      {
        to: { name: 'module-html-list' },
        displayName: this.displayName
      }
    ]
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'module/html',
        name: this.routeNameList,
        component: () => import('./Backend_Module_Html_List.vue'),
        meta: {
          label: this.displayName,
          description: 'Manage your Text',
          privileges: this.authPrivileges.view,
          breadcrumbs: false,
          isFullsize: true
        },
        children: [
          {
            path: 'groups/:groupID',
            name: this.routeNameGroup,
            component: () => import('./Backend_Module_Html_Group_Single.vue'),
            meta: {
              label: 'Text Widget',
              description: 'Manage your Text Widget',
              isFullsize: true
            },
            props: true
          },
          {
            path: ':id',
            name: this.routeNameElement,
            component: () => import('./Backend_Module_Html_Single.vue'),
            meta: {
              label: 'Text',
              description: 'Manage your Text',
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          }
        ]
      }
    ]
  }
}
