
import { Component } from 'vue-property-decorator'

import VFormAddress from '@/components/VFormAddress.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'


import { Address } from '@/types/typeTenant'


import TenantManager from '@/database/tenantManager'
import { ModalProgrammatic } from 'buefy'
import VModalTOS from '@/components/VModalTOS.vue'
import TosManager from '@/database/tosManager'
import databaseSchema from '@/database/databaseSchema'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'

library.add(faCalendarDay)

@Component({
  components: { VFormAddress, VRecordMeta },
  props: {}
})
export default class AdminTenantSingle extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {

  public isLoading: boolean = false

  public tenant = { ...TenantManager.defaultDocDB, id: '' }

  get documentPath() {
    return TenantManager.getDbDocReference(this.$auth.tenant.id).path
  }

  // public planExpires = new Date()
  // public planType: string = ''
  // // used for form controls
  // public formAvailablePlans: Array<{ key: string, value: ValueOf<Plans> }> = Object.keys(plans).map(p => ({
  //   key: p,
  //   value: plans[p as keyof Plans]
  // })) // Enum to string array

  public formName: string = ''
  public formMasterDataPhone: string = ''
  public formMasterDataEmail: string = ''
  public formMasterDataBillingEmail: string = ''


  public formMasterDatabillingAddress: Address = {} as Address
  public formMasterDatashippingAddress: Address = {} as Address

  get termsAccepted() {
    return TosManager.areTOSAccepted(this.tenant.terms, 'terms')
  }

  get odpAccepted() {
    return TosManager.areTOSAccepted(this.tenant.odp, 'odp')
  }

  get documentPrivileges() {
    return databaseSchema.COLLECTIONS.TENANTS.__PRIVILEGES__
  }

  get formTermsDateAccepted() {
    return (this.tenant && this.tenant.terms.dateAccepted) || new Date()
  }

  get formOdpDateAccepted() {
    return (this.tenant && this.tenant.odp.dateAccepted) || new Date()
  }

  public onCopyBillingToShipping() {
    this.formMasterDatashippingAddress = { ...this.formMasterDatabillingAddress }
  }

  public onShowTerms(type: 'odp' | 'terms') {
    ModalProgrammatic.open({
      component: VModalTOS,
      // parent: this,
      props: {
        terms: this.tenant[type],
        type
      },
      onCancel: () => {
        //
      },
      events: {
        accepted: async () => {
          await TosManager.acceptTerms(this.tenant.id, this.$auth.userEmail, this.tenant[type], type).catch((e) =>
            this.$helpers.notification.Error('Terms could not be accepted ' + e)
          ).then(() =>
            this.$helpers.notification.Success('Terms accepted')
          )
        }
      }
    })
  }

  public get formTenantNumber() {
    return (this.$auth.tenant._number > 0) ? TenantManager.formatPaddedNumber(this.$auth.tenant._number) : ''
  }

  public async $save() {
    this.isLoading = true

    try {
      await TenantManager.update(this.$auth.tenant.id, this.$auth.userEmail, {
        name: this.formName,
        masterData: {
          phone: this.formMasterDataPhone,
          email: this.formMasterDataEmail,
          billingEmail: this.formMasterDataBillingEmail,
          billingAddress: this.formMasterDatabillingAddress,
          shippingAddress: this.formMasterDatashippingAddress
        }
      })
      this.$helpers.notification.Success('Masterdata updated')
    } catch (error) {
      this.$helpers.notification.Error('Error updating Masterdata' + error)
    } finally {
      this.isLoading = false
    }
  }

  public async created() {
    await this.initialize()
  }

  public async initialize() {
    this.isLoading = true
    try {

      await this.$bindSnapshot('tenant', TenantManager.getDbDocReference(this.$auth.tenant.id))

      // const tmpDefaultTenantMasterData = TenantManager.defaultDocDB.masterData
      // const tmpTenantData = this.tenant
      // const tmpTenantMasterData = this.$helpers.merge(tmpDefaultTenantMasterData, tmpTenantData.masterData as MasterData)

      // const planDoc = await TenantManager.getDbPlanDocReference(this.$auth.tenant.id).get()
      // const planData = planDoc.data() as Plan
      // if (!planDoc.exists || !planData) {
      //   this.$helpers.notification.Error(`plan ${this.$auth.tenant.id} not found`)
      //   return
      // }

      // this.planExpires = planData.expires.toDate()
      // this.planType = planData.type

      this.formName = this.tenant.name
      this.formMasterDataPhone = this.tenant.masterData.phone
      this.formMasterDataEmail = this.tenant.masterData.email
      this.formMasterDataBillingEmail = this.tenant.masterData.billingEmail
      this.formMasterDatabillingAddress = this.tenant.masterData.billingAddress
      this.formMasterDatashippingAddress = this.tenant.masterData.shippingAddress

    } catch (error) {
      this.$helpers.notification.Error('Error loading tenant data' + error)
    } finally {
      this.isLoading = false
    }
  }
}
