
import { Component, Vue, Prop } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSearch, faFilter
} from '@fortawesome/free-solid-svg-icons'

import { asidID } from '../../types/typeAsid'
import { BaseModuleGroupAppData } from '@/modules/typeModules'


library.add(faSearch, faFilter)

@Component({
  components: {}
})
export default class AppGroupLoading extends Vue {
  @Prop({ type: Object, required: true }) readonly moduleAppData!: BaseModuleGroupAppData

  @Prop({ type: Boolean, required: false, default: () => false }) readonly isOverview!: boolean

  @Prop({ type: String, required: false, default: () => 'test-asid' }) readonly asid!: asidID

  public created() {
    //
  }

}
