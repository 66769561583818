
import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator'

import 'prismjs/themes/prism.css'
// import 'prismjs/themes/prism-okaidia.css'

import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere
// import highlighting library (you can use any library you want just return html string)
import Prism from 'prismjs'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-javascript'
// import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles


@Component({
  components: {
    PrismEditor
  }
})
export default class VInputCodeEditor extends Vue {


  @ModelSync('inputData','changeEvent', { type: String, required: true, default: () => '' })
  readonly code!: string


  @Prop({ type: Boolean, required: false, default: () => false })
  readonly readOnly!: boolean

  @Prop({ type: String, required: false, default: () => 'js' })
  readonly language!: string

  @Prop({ type: String, required: false, default: () => '' })
  readonly preValue!: string

  @Prop({ type: String, required: false, default: () => '' })
  readonly postValue!: string

  public highlighter(code: string) {

    switch (this.language) {
      case 'js':
        return Prism.highlight(code, Prism.languages.javascript, 'javascript')
      case 'html':
        return Prism.highlight(code, Prism.languages.html, 'html')
      default:
        console.warn(`language ${this.language} not supported`)
        break
    }
  }

  public onExecuteCode() {
    this.$emit('execute')
  }

}
