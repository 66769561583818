import merge from 'deepmerge'
import { isPlainObject } from 'is-plain-object'

import Transformer from '@/helpers/transformer'
import RecordMetaHelper from '@/database/recordMetaHelper'
import firebase from 'firebase/compat/app'
import { serverTimestamp, increment } from '@/firebase'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { DeepPartial, hasDBid } from '@/types/typeGeneral'
import { typedWhere } from './dbHelper'

export default abstract class BaseManager {
  public static defaultDocDB: any = {}

  public static addDoc<
    T extends { _meta: any, id?: string },
    U extends firebase.firestore.CollectionReference<any> | firebase.firestore.DocumentReference<any>
  >(
    reference: U,
    authEmail: string,
    fields: DeepPartial<T> = {},
    defaultData: T
  ): Promise<firebase.firestore.DocumentReference<any>> {
    // todo use this.defaultData???
    // merge also array
    const tmpElement = merge(defaultData, fields as Partial<T>, {
      clone: true,
      isMergeableObject: isPlainObject,
      arrayMerge: (target, source, options) => {
        const destination: any = target.slice()

        source.forEach((item, index) => {
          if (typeof destination[index] === 'undefined') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            destination[index] = (options as any).cloneUnlessOtherwiseSpecified(item, options)
          } else if (options?.isMergeableObject?.(item)) {
            destination[index] = merge(target[index], item, options)
          } else if (target.indexOf(item) === -1) {
            destination.push(item)
          }
        })
        return destination
      }
    })

    if ('_meta' in tmpElement) delete tmpElement._meta
    // if ('_computed' in tmpElement) delete tmpElement._computed allow computed here so it can be created
    if ('_local' in tmpElement) delete tmpElement._local
    if ('id' in tmpElement) delete tmpElement.id

    console.log('addDoc', tmpElement)

    if (reference instanceof firebase.firestore.CollectionReference) {
      return reference.add({
        ...tmpElement,
        ...RecordMetaHelper.getNewMetaInstructions(serverTimestamp, authEmail)
      })
    } else if (reference instanceof firebase.firestore.DocumentReference) {
      return new Promise((res, rej) => {
        reference
          .set({
            ...tmpElement,
            ...RecordMetaHelper.getNewMetaInstructions(serverTimestamp, authEmail)
          })
          .then(() => res(reference))
          .catch(rej)
      })
    }
    return undefined as any // ts hack to use conditional return types
  }

  public static addDocBatch<T extends { _meta: any, id?: string }, U extends firebase.firestore.DocumentReference<any>>(
    reference: U,
    authEmail: string,
    fields: DeepPartial<T> = {},
    defaultData: T,
    batch: firebase.firestore.WriteBatch
  ) {
    // todo use this.defaultData???
    // merge also array
    const tmpElement = merge(defaultData, fields as Partial<T>, {
      clone: true,
      isMergeableObject: isPlainObject,
      arrayMerge: (target, source, options) => {
        const destination = target.slice()

        source.forEach((item, index) => {
          if (typeof destination[index] === 'undefined') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            destination[index] = (options as any).cloneUnlessOtherwiseSpecified(item, options)
          } else if (options?.isMergeableObject?.(item)) {
            destination[index] = merge(target[index], item, options)
          } else if (target.indexOf(item) === -1) {
            destination.push(item)
          }
        })
        return destination
      }
    })

    if ('_meta' in tmpElement) delete tmpElement._meta
    if ('_local' in tmpElement) delete tmpElement._local
    if ('id' in tmpElement) delete tmpElement.id

    return batch.set(reference, {
      ...tmpElement,
      ...RecordMetaHelper.getNewMetaInstructions(serverTimestamp, authEmail)
    })
  }

  private static updateDocHelper<T extends { _meta: any, id?: string }>(
    docRef: firebase.firestore.DocumentReference<any>,
    authEmail: string,
    fields: DeepPartial<T>,
    batch?: firebase.firestore.WriteBatch,
    keepComputed = false,
    flatten = true
  ) {
    const data = this.prepareUpdateHelper<T>(authEmail, fields, keepComputed, flatten)

    return batch ? batch.update(docRef, data) : docRef.update(data)
  }

  /**
   * flattens all data to be updated
   * removes _meta, _local, _computed and id from update
   * remove any undefined key
   * adds _meta instructions
   */
  protected static prepareUpdateHelper<T extends { _meta: any, id?: string }>(
    authEmail: string,
    fields: DeepPartial<T>,
    keepComputed = false,
    flatten = true
  ) {
    // hydrate with default data
    // todo validat this!!
    // const tmpElement = merge(this.defaultDocDB, fields as Partial<T>, { clone: true })
    fields = { ...fields } // gets rid of non enumerable e.g. id

    if ('_meta' in fields) {
      console.warn('updating an Doc shall not complete _meta information because its added by default')
      delete fields._meta
    }

    if ('id' in fields) {
      console.warn('removing id from doc update')
      delete fields.id
    }

    const data: { [key: string]: any } = {
      ...(flatten ? Transformer.flatten(fields, '.') : fields),
      ...RecordMetaHelper.getUpdateMetaInstructions(serverTimestamp, increment, authEmail)
    }

    // remove if computed is in any path
    Object.keys(data).forEach((updatePath) => {
      if ((updatePath.includes('_computed') && !keepComputed) || updatePath.includes('_local')) {
        console.log('removing _computed and _local from update')
        delete data[updatePath]
      }
    })

    // remove any empty key. delete obj.key on a reactive prop only sets it to undefined
    Object.keys(data).forEach((key) => data[key] === undefined && delete data[key])

    console.debug('prepareUpdateHelper', data)

    return data
  }

  public static updateDoc<T>(
    docRef: firebase.firestore.DocumentReference<any>,
    authEmail: string,
    fields: DeepPartial<T>,
    keepComputed = false,
    flatten = true
  ) {
    return this.updateDocHelper(docRef, authEmail, fields, undefined, keepComputed, flatten) as Promise<void>
  }

  protected static updateDocBatch<T>(
    docRef: firebase.firestore.DocumentReference<any>,
    authEmail: string,
    fields: DeepPartial<T>,
    batch: firebase.firestore.WriteBatch,
    keepComputed = false
  ) {
    return this.updateDocHelper(docRef, authEmail, fields, batch, keepComputed) as firebase.firestore.WriteBatch
  }

  /**
   *
   * @param docRef document reference
   * @param onNext when data/new data is available
   * @param onError when an error occured
   * @param onFinally called once on error or on next
   */
  protected static onSnapshotHelper<T>(
    docRef: firebase.firestore.DocumentReference<any>,
    onNext: (data: T & hasDBid) => void,
    onOnce: (data: T & hasDBid) => void,
    onError: (e: any) => void,
    onFinally: () => void = () => {
      /**/
    }
  ): SnapshotUnbindHandle {
    let finallyCalled = false
    let onceCalled = false
    const helper = (d?: T & hasDBid) => {
      if (!onceCalled && d) {
        onceCalled = true
        onOnce(d)
      }
      if (!finallyCalled) {
        finallyCalled = true
        onFinally()
      }
    }
    return docRef.onSnapshot(
      (d) => {
        if (d.exists === false) {
          helper()
          onError('empty snapshot response, document may not exist or connection error')
          return
        }

        const data = { ...(d.data() as T), id: d.id }
        helper(data)
        onNext(data)
      },
      (e) => {
        helper()
        onError(e)
      }
    )
  }

  /**
   *
   * @param docRef document reference
   * @param onNext when data/new data is available
   * @param onError when an error occured
   * @param onFinally called once on error or on next
   */
  protected static onSnapshotQueryHelper<T>(
    docRef: firebase.firestore.Query<any>,
    onNext: (data: Array<T & hasDBid>) => void,
    onError: (e: any) => void,
    onOnce?: (data: Array<T & hasDBid>) => void,
    onFinally: () => void = () => {
      /**/
    }
  ): SnapshotUnbindHandle {
    let finallyCalled = false
    let onceCalled = false
    const helper = (d?: Array<T & hasDBid>) => {
      if (!onceCalled && d) {
        onceCalled = true
        onOnce?.(d)
      }
      if (!finallyCalled) {
        finallyCalled = true
        onFinally()
      }
    }

    const unbindHandle = docRef.onSnapshot(
      (res) => {
        const data = res.docs.map((d) => ({ ...(d.data() as T), id: d.id }))
        helper(data)
        onNext(data)
      },
      (e) => {
        helper()
        onError(e)
      }
    )
    return () => {
      console.log('unbind snapshot')
      unbindHandle()
    }
  }

  protected static async getDocHelper<T>(docRef: firebase.firestore.DocumentReference<any>) {
    const doc = await docRef.get()
    if (!doc.exists) throw `document ${doc.id} not found`
    return { ...(doc.data() as T), id: doc.id }
  }

  protected static async getDocsHelper<T>(query: firebase.firestore.Query<firebase.firestore.DocumentData>) {
    const queryResult = await query.get()
    return queryResult.docs.map((doc) => ({ ...(doc.data() as T), id: doc.id }))
  }

  protected static async deleteDoc(docRef: firebase.firestore.DocumentReference<any>, authEmail: string) {
    return this.updateDoc(docRef, authEmail, { publishingState: 'deleted' })
  }

  protected static getWhereHelper<T>(
    query: firebase.firestore.Query<firebase.firestore.DocumentData>,
    path: DeepPartial<T>,
    op: firebase.firestore.WhereFilterOp,
    value: any
  ) {
    return this.getDocsHelper<T>(typedWhere<T>(query, path, op, value))
  }

}
