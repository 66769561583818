
import { Component, Vue, Prop } from 'vue-property-decorator'
import RecordMeta from '@/types/typeRecordMeta'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfo } from '@fortawesome/free-solid-svg-icons'


library.add(faInfo)

import VModuleCompareChangelogModal from '@/components/VModuleCompareChangelogModal.vue'

import { RequiredDocPrivileges } from '@/types/typeRequiredPrivileges'

@Component({
  components: {
    VModuleCompareChangelogModal
  }
})
export default class VRecordMeta extends Vue {
  @Prop({ type: Object, required: false, default: () => null })
  public recordMeta!: RecordMeta

  @Prop({ type: String, required: false, default: () => 'is-bottom-right' })
  public position!: string

  @Prop({ type: Object, required: false, default: () => ({ r: [], w: [] }) })
  public requiredPrivileges!: RequiredDocPrivileges

  @Prop({ type: String, required: false, default: () => '' })
  public documentPath!: string


  @Prop({ type: Boolean, required: false, default: () => false })
  private disallowResetToRevision!: boolean

  get viewPrivileges() {
    return this.$route.meta?.privileges || []
  }

  onOpenChangelogModal() {
    this.$buefy.modal.open({
      props: {
        documentPath: this.documentPath,
        disallowResetToRevision: this.disallowResetToRevision
      },
      events: {

      },
      parent: this,
      component: VModuleCompareChangelogModal,
      hasModalCard: true
    })
  }

  public changelog: { _meta: RecordMeta }[] = []
  // @Watch('documentPath', { immediate: true })
  // async onDocPathChanged() {
  //   const changelogDocs = (await db.doc(this.documentPath).collection('Changelog').orderBy('_meta.dateUpdated', 'desc').limit(10).get()).docs
  //   const changeLogData = changelogDocs.map(doc => doc.data() as { _meta: RecordMeta })
  //   console.log(changeLogData)

  //   this.changelog = changeLogData
  // }
}
