import BaseManager from './baseManager'

import { getAdminUploadPath } from '@/helpers/storageHelper'

import db from '@/firebase'
import databaseSchema, { defaultCodeConfig, defaultRecordMeta } from './databaseSchema'
import { GlobalAdminCodeConfigDB } from '@/types/typeAdmin'
import { DeepPartial } from '@/types/typeGeneral'


export default class AdminManager extends BaseManager {
  public static defaultAdminCodeConfigDB: GlobalAdminCodeConfigDB = {
    config: {
      baseUrl: '',
      codes: [defaultCodeConfig]
    },
    _meta: defaultRecordMeta
  }

  public static async initAdminDB(authEmail: string) {
    const codeConfigDoc = await db.doc(databaseSchema.COLLECTIONS.ADMIN.CODE_CONFIG.__DOCUMENT_PATH__()).get()
    if (!codeConfigDoc.exists) return this.addCodeConfig(authEmail)
  }

  public static addCodeConfig(authEmail: string) {
    return this.addDoc(this.getCodeConfigDocRef(), authEmail, {}, this.defaultAdminCodeConfigDB)
  }

  public static updateCodeConfig(authEmail: string, fields: DeepPartial<GlobalAdminCodeConfigDB>) {
    return this.updateDoc(this.getCodeConfigDocRef(), authEmail, fields)
  }

  public static getCodeConfigDocRef() {
    return db.doc(databaseSchema.COLLECTIONS.ADMIN.CODE_CONFIG.__DOCUMENT_PATH__())
  }

  public static getUploadPath(path: string) {
    return getAdminUploadPath(path)
  }
}
