
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { SettingsConfig } from '@/types/typeLocalSettings'
library.add(faCog)

@Component({})
export default class VButtonSettingsModal extends Vue {

  public isSettingsModalActive = false

  @Prop({ required: true })
  public config!: SettingsConfig[]

  @PropSync('settings', { required: true })
  public formSettings!: any

  public onSaveSettings() {
    this.$emit('save')
    this.isSettingsModalActive = false
  }

}
