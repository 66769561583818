


import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimesCircle, faBook, faSearch } from '@fortawesome/free-solid-svg-icons'


import { Component, Vue } from 'vue-property-decorator'


library.add(faSearch, faBook, faTimesCircle)


@Component({
  components: {}
})
export default class AdminHelpCenter extends Vue {


}
