import { ModuleManager } from '@/modules/moduleManager'
import { viewPrivileges, dbPrivileges } from './privileges'
import { cloneObject } from './dataShapeUtil'
import { TreeData } from '@/types/typeGeneral'
import ServiceModule from '@/modules/service/serviceModule'

export const modulePrivileges = ModuleManager.getModulePrivileges()

// const moduleClasses = ModuleManager.moduleClasses.filter((mc) => mc.type !== 'Notification')
const moduleClasses = ModuleManager.moduleClasses

export default class PrivilegeHelper {
  private static uniqueID() {
    return '_' + Math.random().toString(36).substr(2, 9)
  }

  private static userRoles: TreeData[] = [
    {
      name: 'Roles',
      id: this.uniqueID(),
      children: [
        {
          name: 'Admin',
          id: this.uniqueID(),
          children: [
            ...Object.values(dbPrivileges)
              .filter((p) => p !== 'any')
              .map((p) => ({ name: p, id: this.uniqueID() })),
            ...Object.values(viewPrivileges).map((p) => ({ name: p, id: this.uniqueID() })),

            ...moduleClasses.map((mc) => ({
              name: mc.type,
              id: this.uniqueID(),
              children: Object.values(mc.authPrivileges).flatMap((p: string[]) =>
                p.map((i) => ({ name: i, id: this.uniqueID() }))
              )
            }))
          ]
        },
        // {
        //   name: 'Accountant',
        //   children: [{ name: viewPrivileges.BILLING_VIEW }]
        // },

        {
          name: 'Code Activator',
          id: this.uniqueID(),
          children: [
            { name: viewPrivileges.CODE_ACTIVATE_VIEW, id: this.uniqueID() },
            { name: dbPrivileges.CONFIG_READ, id: this.uniqueID() },
            { name: dbPrivileges.CATEGORIES_READ, id: this.uniqueID() },
            { name: dbPrivileges.ASID_READ, id: this.uniqueID() },
            { name: dbPrivileges.ASID_WRITE, id: this.uniqueID() }
          ]
        },
        {
          name: 'Configuration',
          id: this.uniqueID(),
          children: [
            { name: viewPrivileges.APP_CONFIG_VIEW, id: this.uniqueID() },
            { name: viewPrivileges.BACKEND_CONFIG_VIEW, id: this.uniqueID() },
            { name: viewPrivileges.USER_MANAGE_VIEW, id: this.uniqueID() },
            { name: viewPrivileges.MASTERDATA_MANAGE_VIEW, id: this.uniqueID() },
            { name: dbPrivileges.CONFIG_READ, id: this.uniqueID() },
            { name: dbPrivileges.CONFIG_WRITE, id: this.uniqueID() }
          ]
        },
        {
          name: 'Service Module Agent',
          id: this.uniqueID(),
          children: [
            { name: ServiceModule.authPrivileges.view[0], id: this.uniqueID() },
            { name: ServiceModule.authPrivileges.r[0], id: this.uniqueID() },
            { name: ServiceModule.authPrivileges.w[0], id: this.uniqueID() },
            { name: dbPrivileges.CONFIG_READ, id: this.uniqueID() },
            { name: dbPrivileges.CATEGORIES_READ, id: this.uniqueID() },
            { name: dbPrivileges.ASID_READ, id: this.uniqueID() },
            { name: dbPrivileges.ASID_WRITE, id: this.uniqueID() }
          ]
        },
        {
          name: 'Module User',
          id: this.uniqueID(),
          children: [
            ...moduleClasses.map((mc) => ({
              name: mc.type,
              id: this.uniqueID(),
              children: [
                ...Object.values(mc.authPrivileges).flatMap((p: string[]) =>
                  p.map((i) => ({ name: i, id: this.uniqueID() }))
                ),
                { name: dbPrivileges.CONFIG_READ, id: this.uniqueID() },
                { name: dbPrivileges.CATEGORIES_READ, id: this.uniqueID() },
                { name: 'i18n:read', id: this.uniqueID() }
              ]
            }))
          ]
        }
      ]
    },
    {
      name: 'Views',
      id: this.uniqueID(),
      children: [
        ...Object.values(viewPrivileges)
          .filter((p) => p !== 'any')
          .map((p) => ({ name: p, id: this.uniqueID() })),

        ...moduleClasses.map((mc) => ({
          name: mc.type,
          id: this.uniqueID(),
          children: (mc.authPrivileges.view as string[]).map((i) => ({ name: i, id: this.uniqueID() }))
        }))
      ]
    },
    {
      name: 'Data Access',
      id: this.uniqueID(),
      children: [
        ...Object.values(dbPrivileges)
          .filter((p) => p !== 'any')
          .map((p) => ({ name: p, id: this.uniqueID() })),

        ...moduleClasses.map((mc) => ({
          name: mc.type,
          id: this.uniqueID(),
          children: [mc.authPrivileges.r, mc.authPrivileges.w].flatMap((p: string[]) =>
            p.map((i) => ({ name: i, id: this.uniqueID() }))
          )
        }))
      ]
    }
  ]

  public static getAvailableRolesAndPrivilegesTree(hideViews = false) {
    if (!hideViews) {
      return cloneObject(this.userRoles)
    } else {
      console.log('filter------')

      const viewPrivilegesValue = Object.values(viewPrivileges)
      const iterateTree = (tree: TreeData[]): TreeData[] => {
        return tree.filter((t) => {
          if (viewPrivilegesValue.some((v) => t.name === v) || t.name.includes(':view')) {
            return false
          } else if (t.children) {
            t.children = iterateTree(t.children)
            return !!(t.children && t.children?.length > 0)
          }
          return true
        })
      }
      return iterateTree(cloneObject(this.userRoles))
    }
  }

  // public static async onUser(cb?: (user: User) => void) {
  //   return new Promise<User>(async (resolve, reject) => {
  //     let user = {
  //       email: 'beck@glumb.de',
  //       name: 'Maximilian Beck'
  //     }

  //     // await timeout(5000)
  //     resolve(user)
  //     if (cb) cb(user)
  //     // await timeout(5000)
  //     // user = {
  //     //   email: 'beck@glumb.de',
  //     //   name: 'Maxi Beck'
  //     // }
  //     // if (cb) cb(user)
  //     // resolve(user)
  //   })
  // }
}
