
import { Component, Vue } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus
} from '@fortawesome/free-solid-svg-icons'


import VInputMultiUserManagementView from '@/components/VInputMultiUserManagementView.vue'
import VInputMultiApiUserManagementView from '@/components/VInputMultiApiUserManagementView.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'
import databaseSchema from '@/database/databaseSchema'


library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus)

@Component({
  components: {
    VInputMultiUserManagementView,
    VInputMultiApiUserManagementView,
    VRecordMeta
  }
})
export default class BackendUserList extends Vue {

  public isLoading = false

  get documentPrivileges() {
    return databaseSchema.COLLECTIONS.TENANTS.USERS.__PRIVILEGES__
  }

  //  get documentPath() { // todo users are mutliple docs
  //     return UserManager.ge(this.$auth.tenant.id).path
  //   }

  public async initialise() {
    (this.$refs.userRef as any).initialise()
    // (this.$refs.apiUserRef as any).initialise()
  }

  public async $save() {
    (this.$refs.userRef as any).save()
    // (this.$refs.apiUserRef as any).save()
  }

}
