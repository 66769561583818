import { ModuleType } from '@/modules/typeModules'

import { RouteConfig, RawLocation } from 'vue-router'
import BaseModule from '@/modules/baseModule'

import { ProtectionModuleDB, ProtectionElementDB, RequiredProtectionPrivileges } from './typeProtectionModule'

import { defaultProtectionModuleDB, defaultProtectionElementDB } from '@/database/databaseSchema'
import { UserPrivilegeIdDB } from '@/types/typeUser'

export default class ProtectionModule extends BaseModule {
  public static type: ModuleType = 'Protection'
  public static displayName = 'Protection'
  public static color = '#19248c'
  public static description = 'Restrict App data access.'
  public static descriptionLong =
    'Create an access restriction for certain categories either by password protection or by entering an identifier which may be different for each ECHO Code.'
  public static hasWidget = false

  public static authPrivileges: RequiredProtectionPrivileges = {
    r: ['protection:read'],
    w: ['protection:write'],
    view: ['protection:view']
  }

  public static moduleDB: ProtectionModuleDB = defaultProtectionModuleDB
  public static elementDB: ProtectionElementDB = defaultProtectionElementDB

  constructor() {
    super()
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return [
      {
        to: { name: this.routeNameList },
        displayName: this.displayName
      }
    ]
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'module/protection',
        name: this.routeNameList,
        component: () => import('./Backend_Module_Protection_List.vue'),
        meta: {
          label: 'Protections',
          description: 'Manage your Protection',
          breadcrumbs: false,
          isFullsize: true
        },
        children: [
          {
            path: 'groups/:groupID',
            name: this.routeNameGroup,
            component: () => import('./Backend_Module_Protection_Group_Single.vue'),
            meta: {
              label: 'Protection',
              description: 'Manage your Protections',
              isFullsize: true
            },
            props: true
          },
          {
            path: ':id',
            name: this.routeNameElement,
            component: () => import('./Backend_Module_Protection_Single.vue'),
            meta: {
              label: this.displayName,
              description: 'Manage your Protetcions',
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          }
        ]
      }
    ]
  }
}
