
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import { CategoryID } from '@/types/typeCategory'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import CategoryHelper from '@/database/categoryHelper'

library.add(faTimes)

/**
 * a modal to manage the global category filter.
 * the user filter is stored in localstorage
 * if the user has limited acess to the categories based on the visibleCategories in the user document
 * the user filter will only be applied to subcategories of the visibleCategories
 */

@Component({
  components: {
    VInputMultiCategorySelection
  }
})
export default class VModalCategoryFilter extends Vue {

  @PropSync('active', { default: false })
  public isFilterModalActive!: boolean

  public formSelectedCategories: CategoryID[] = []
  public formCheckedParent = false
  public formCheckedChild = false

  public onApply() {
    this.$localSettings.modules.filters.categories = this.formSelectedCategories
    this.$localSettings.modules.filters.categoriesIncludeParentCats = this.formCheckedParent
    this.$localSettings.modules.filters.categoriesIncludeChildCats = this.formCheckedChild
    this.isFilterModalActive = false
  }

  public onResetFilter() {
    this.$localSettings.modules.filters.categories = []
    this.isFilterModalActive = false
  }

  @Watch('$localSettings.modules.filters.categories', { immediate: true })
  private onChangeGlobalCategoryFilter() {
    this.formSelectedCategories = this.$localSettings.modules.filters.categories
  }

  @Watch('$localSettings.modules.filters.categoriesIncludeParentCats', { immediate: true })
  private onChangeGlobalCategoryFilterIncludeParent() {
    this.formCheckedParent = this.$localSettings.modules.filters.categoriesIncludeParentCats
  }

  @Watch('$localSettings.modules.filters.categoriesIncludeChildCats', { immediate: true })
  private onChangeGlobalCategoryFilterIncludeChild() {
    this.formCheckedChild = this.$localSettings.modules.filters.categoriesIncludeChildCats
  }

  get userVisibleCategories() {
    return this.$auth?.user?.visibleCategories || []
  }

  public getAvailableUserCategories() {
    return CategoryHelper.getAvailableUserCategoriesCollection(this.$categories, this.$auth?.user?.visibleCategories)
  }
}

