
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUp, faLanguage, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import ModuleManagerApp, { ModuleAppDataVueComponentTuple } from '@/modules/moduleManagerApp'

import { locale } from '@/types/typeI18n'

import VAppRouterLink from '@/pages/app/components/VAppRouterLink.vue'
import CustomScriptExecution from '@/modules/custom/customModuleUtils'
import { onAppPathChange, setAppPath } from '@/pages/app/appStateRouter'
import { asidID } from '@/types/typeAsid'
import { ModuleType } from '@/modules/typeModules'
import { objectID } from '@/types/typeGeneral'
import { intersect } from '@/helpers/arrayHelper'
import { install } from 'resize-observer'
import { copyDebugInformationToClipboard } from '@/helpers/debugInfoHelper'
import { RPCAppDataResponse } from '@/types/typeRPC'
import { setSingletClickEventListener } from '@/helpers/domHelper'
import Event from '@/helpers/eventBus'

library.add(faArrowUp, faLanguage, faExclamationCircle)

export interface AppClickedEvent {
  type: 'header' | 'footer' | 'element' | 'group'
  moduleType?: ModuleType
  docID?: objectID
}

const PreviewEventBus = new Event<AppClickedEvent>('click-app-preview', 'vapp')

export const clickAppPreview = (appClickEvent: AppClickedEvent) => {
  PreviewEventBus.emit(appClickEvent)
}

export let urlPathPrefix = '' // for generating hrefs in the app, the correct path shall be used
export const setUrlPathPrefix = (prefix: string) => {
  urlPathPrefix = prefix
}

@Component({
  components: { VAppRouterLink }
})
export default class VApp extends Vue {
  /**
   * { moduleGroupAppDatas: [], globalData: { tenantID: '', sessionID: '', dataHash: '', appData.globalData.legal: defaultLegalConfig } }
   */
  @Prop({ type: Object, required: true })
  public readonly appData!: RPCAppDataResponse

  @Prop({ type: String, required: true })
  public readonly asid!: asidID

  @Prop({ type: Boolean, required: false, default: false })
  public liveUpdatePreviewMode!: boolean

  public initialLoad = true

  @Prop({ type: String, required: false, default: '' })
  public externalErrorText!: string

  public get legalKeys(): Array<keyof typeof this.appData.globalData.legal> {
    return ['imprint', 'terms', 'privacy', 'other']
  }

  public getLocaleName(locale: locale) {
    // return getLocaleName(locale, false)
    return locale
  }

  //#region debug info handling

  /**
   * copy debug info to clipboard if the footer is pressed for longer than 5 seconds
   */

  private timer?: any
  private isPressedForFiveSeconds = false
  private TIME_PRESS_TITLE_TO_COPY_DEBUG = 1000 * 5 // 5s

  public onTouchStartFooter() {
    console.log('start')

    this.timer = setTimeout(() => {
      this.isPressedForFiveSeconds = true
      this.$helpers.notification.Info('Release your finger to copy debug info.')
    }, this.TIME_PRESS_TITLE_TO_COPY_DEBUG)
  }

  public onTouchEndFooter() {
    console.log('end')

    if (this.isPressedForFiveSeconds) {
      const successfull = copyDebugInformationToClipboard({
        asid: this.asid,
        sessionID: this.appData.globalData.sessionID,
        tenantID: this.appData.globalData.tenantID
      })

      if (successfull) {
        this.$helpers.notification.Info('Debug information copied to clipboard')
      } else {
        this.$helpers.notification.Error('Error occured while trying to copy debug information to clipboard')
      }
    }

    this.isPressedForFiveSeconds = false
    clearTimeout(this.timer)
  }

  //#endregion debug info handling

  //#region path handling

  @PropSync('path', { type: String, required: false, default: '' })
  public pathSync!: string

  @Watch('pathSync')
  private onRouteChanged() {
    CustomScriptExecution.emitEvent().onPageChanged(this.$router.currentRoute.fullPath)
  }

  @Watch('pathSync', { immediate: true })
  private onPathChanged() {
    const p = this.pathSync
    // remove trailing slash "/"" from path
    const path = p.replace(/\/$/, '')
    setAppPath(path)
  }

  private pathHandlingUnbindHandler?: () => void

  private initPathHandling() {
    if (this.pathHandlingUnbindHandler) this.pathHandlingUnbindHandler()

    this.pathHandlingUnbindHandler = onAppPathChange((path) => {
      this.pathSync = path
    })
  }

  //#endregion path handling

  get errorText() {
    return [this.localErrorText, this.externalErrorText, this.localRouteErrorText]
      .filter(t => t) // filter out empty strings
      .join('\n')
  }

  // todo may be used to identify if user was already on this page
  public appLifecycle = { initialAsidVisit: true, initialAppVisit: true }

  public isLoading = true

  public localErrorText = ''
  public localRouteErrorText = ''


  public moduleGroupsOverviewVue: (ModuleAppDataVueComponentTuple & { localData: { position: 'left' | 'right' } })[] =
    []
  public moduleGroupsBackgroundVue: ModuleAppDataVueComponentTuple[] = []

  get isOverviewPage() {
    const isOverviewPage = this.pathSync === ''

    if (isOverviewPage) this.localRouteErrorText = ''

    return isOverviewPage
  }

  get moduleAppDataForRoute() {
    if (this.isOverviewPage) return false

    try {
      const touple = ModuleManagerApp.getRouteTuple(this.appData, this.pathSync)
      if (touple) this.localRouteErrorText = ''
      return touple
    } catch (error) {
      this.$helpers.notification.Error(error)
      this.localRouteErrorText += `Error 404 \npage "${this.pathSync}" not found`
    }

    return false
  }

  get globalAvailableLocales(): locale[] {
    const locales = this.$i18n.appEnabledLocales
      .map(l => l.split('-')[0]) // use only main locales de-at => de
      .filter(l => l !== 'default')

    return intersect(this.$i18n.globalAvailableLocales, [...new Set(locales)]) as locale[]
  }

  @Emit('keyProvided')
  public onKeyProvided(key: string) {
    return key
  }

  public created() {
    this.initPathHandling()
  }

  /**
   * expand the autotile to a full tile to fill gaps in the layout
   * also swap it one position back if necessary (ahhh => fhhh => hfhh)
   */
  public processAutomaticTile(
    groups: (ModuleAppDataVueComponentTuple & { localData?: { position: 'left' | 'right' } })[]
  ) {
    let sectionStartPointer = 0
    let sectionWidgetCount = 0
    // console.log(groups.map(g => g.moduleAppData.group.display.displayType))
    for (let i = 0; i < groups.length; i++) {
      const widgetType = groups[i].moduleAppData.group.display.displayType

      if (['tile-half', 'tile-auto'].includes(widgetType)) {
        sectionWidgetCount++
      }

      groups[i]['localData'] = { position: sectionWidgetCount % 2 !== 0 ? 'left' : 'right' }

      if (!['tile-half', 'tile-auto'].includes(widgetType) || groups.length - 1 === i) {
        const sectionEndPointer = sectionStartPointer + sectionWidgetCount - 1
        // process section
        if (sectionWidgetCount > 0) {
          let autoTileExpanded = false
          let tileBeforeAutoTileCounter = 0

          for (let j = sectionEndPointer; j >= sectionStartPointer; j--) {
            if (sectionWidgetCount % 2 === 0 || autoTileExpanded) {
              groups[j].moduleAppData.group.display.displayType = 'tile-half'
            } else if (!autoTileExpanded && groups[j].moduleAppData.group.display.displayType === 'tile-auto') {
              groups[j].moduleAppData.group.display.displayType = 'tile-full'
              autoTileExpanded = true

              if (tileBeforeAutoTileCounter % 2 !== 0) {
                const tempWidget = groups[j]
                groups[j] = groups[j + 1]
                groups[j + 1] = tempWidget
              }
            }
            tileBeforeAutoTileCounter++
          }
          // console.log(sectionStartPointer, ':', sectionStartPointer + sectionWidgetCount - 1)
        }
        sectionWidgetCount = 0
        sectionStartPointer = i + 1
      }
    }

    // console.log(groups.map(g => g.moduleAppData.group.display.displayType))
    return groups as (ModuleAppDataVueComponentTuple & { localData: { position: 'left' | 'right' } })[]
  }

  @Watch('appData', { immediate: true, deep: true })
  public async prepareData() {
    this.isLoading = true

    // dynamically import the module manager to enable code splitting
    // is already loaded when called from appAsid
    // not necessary as the individual vue app components are already loaded dynamically by getOverviewsTuple
    // const ModuleManagerApp = (await getModuleManagerApp()).default

    try {
      // get the overviews also loads the data for the modules since its split into individual js files
      const moduleGroupsOverviewVue = ModuleManagerApp.getOverviewsTuple(this.appData.moduleGroupAppDatas)
      this.moduleGroupsOverviewVue = this.processAutomaticTile(moduleGroupsOverviewVue)

      // only on initial page load get the background vues to prvent reexecuton when appling protection keys
      // todo to make this more egneric, compare the ne with the current data and apply a selective update
      if (this.initialLoad || this.liveUpdatePreviewMode)
        this.moduleGroupsBackgroundVue = ModuleManagerApp.getBackgroundTuple(this.appData.moduleGroupAppDatas)

      console.log(this.appData)

    } catch (e: any) {
      this.localErrorText += `An error occured [20220721]: ${e.toString()}`
      console.error(e)
    } finally {
      this.isLoading = false
      this.initialLoad = false
      await this.$nextTick()
      this.onRouteChanged()
    }
  }

  public onResetAppActiveLocales() {
    this.$i18n.appActiveLocales = (window.navigator.languages || [window.navigator.language]).map((l) =>
      l.toLowerCase()
    ) as locale[]
  }

  public async mounted() {
    this.initLinkHandler()
    this.initListenClickAppPreview()
    this.initResizeWatcher()
  }

  private resizeObserver?: ResizeObserver

  private initResizeWatcher() {
    // polyfill if required https://stackoverflow.com/questions/65819296/unhandled-runtime-error-referenceerror-cant-find-variable-resizeobserver-on-s
    if (!window.ResizeObserver) install()

    if (this.resizeObserver) this.resizeObserver.disconnect()

    // set container width for sizing the font. --widget-container-width-unitless * --scaler => font-size: clamp(min, scaler, max)
    const $ref = (this.$refs as any)['widget-container']
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return
          }
          const width = '' + entry.contentRect.width
          document.documentElement.style.setProperty('--widget-container-width-unitless', width)
          // console.log('width', width)
          // console.log('height', entry.contentRect.height)
        })

      })
    })

    this.resizeObserver.observe($ref)
  }

  @Watch('$i18n.appActiveLocales', { immediate: true })
  public onActiveLocaleChanged() {
    CustomScriptExecution.emitEvent().onLocaleChanged(this.$i18n.appActiveLocales)
  }

  private initLinkHandler() {

    // all internal links shall be processed by vue router to prevent reloading of the page
    //https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
    // only apply this to links that are under the .app-main element to not use for backend links
    const appElements = document.getElementsByClassName('app-main')

    if (appElements.length === 0) {
      console.warn('no app-main element found')
      return
    }

    setSingletClickEventListener(
      document,
      'app',
      'click',
      (event) => {

        // ensure the click happened on the app element
        if (!appElements[0] || !appElements[0].contains(event.target as any)) return

        // ensure we use the link, in case the click has been received by a subelement
        let target = event.target as any
        while (target && target.tagName !== 'A') target = target.parentNode
        // handle only links that do not reference external resources
        if (target && target.matches('a:not([href*=\'://\'])') && target.href) {
          // some sanity checks taken from vue-router:
          // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
          const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
          // don't handle with control keys
          if (metaKey || altKey || ctrlKey || shiftKey) return
          // don't handle when preventDefault called
          if (defaultPrevented) return
          // don't handle tel, mailto
          if ((target.href as string)?.startsWith('tel') || (target.href as string)?.startsWith('mail')) return
          // don't handle right clicks
          if (button !== undefined && button !== 0) return
          // don't handle if `target="_blank"`
          if (target && target.getAttribute) {
            const linkTarget = target.getAttribute('target')
            if (/\b_blank\b/i.test(linkTarget)) return
          }
          // don't handle same page links/anchors
          const url = new URL(target.href)
          const pathName = url.pathname
          const searchParams = url.search
          const hash = url.hash
          const urlPath = pathName + searchParams + hash

          if (window.location.pathname !== pathName && event.preventDefault) {
            event.preventDefault()
            console.log('setting app path', urlPath)
            setAppPath(urlPath)
          }
        }
      })
  }

  // #region preview
  /**
   * when the app is rendered in the backend as preview. various click & hover events are emitted to be able to show the respective setting in the bakcned
   */

  public initListenClickAppPreview() {
    PreviewEventBus.unsubscribeAll()

    PreviewEventBus.on((appClickedEvent) =>
      this.handleClickForPreview(appClickedEvent)
    )
  }

  public beforeDestroy() {
    PreviewEventBus.unsubscribeAll()
    if (this.resizeObserver) this.resizeObserver.disconnect()
    if (this.pathHandlingUnbindHandler) this.pathHandlingUnbindHandler()
  }

  public handleClickForPreview(appClickedEvent: AppClickedEvent) {
    this.$emit('selected', appClickedEvent)
  }
  // #endregion preview
}
