import objEqual from 'fast-deep-equal'
import deepmerge from 'deepmerge'
import { hasDBid } from '@/types/typeGeneral'

export function cloneObject<T>(obj: T) {
  return deepmerge({}, obj, {
    clone: true
  }) as T
}

// https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
export function deepCompare(...args: any[]) {
  let i, l, leftChain: any, rightChain: any

  function compare2Objects(x: any, y: any) {
    let p

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
      return true
    }

    // Compare primitives and functions.
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
      return true
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if (
      (typeof x === 'function' && typeof y === 'function') ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)
    ) {
      return x.toString() === y.toString()
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
      return false
    }

    // eslint-disable-next-line no-prototype-builtins
    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
      return false
    }

    if (x.constructor !== y.constructor) {
      return false
    }

    if (x.prototype !== y.prototype) {
      return false
    }

    // Check for infinitive linking loops
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
      return false
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for (p in y) {
      // eslint-disable-next-line no-prototype-builtins
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false
      } else if (typeof y[p] !== typeof x[p]) {
        return false
      }
    }

    for (p in x) {
      // eslint-disable-next-line no-prototype-builtins
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false
      } else if (typeof y[p] !== typeof x[p]) {
        return false
      }

      switch (typeof x[p]) {
        case 'object':
        case 'function':
          leftChain.push(x)
          rightChain.push(y)

          if (!compare2Objects(x[p], y[p])) {
            return false
          }

          leftChain.pop()
          rightChain.pop()
          break

        default:
          if (x[p] !== y[p]) {
            return false
          }
          break
      }
    }

    return true
  }

  if (args.length < 1) {
    return true //Die silently? Don't know how to handle such case, please help...
    // throw "Need two or more arguments to compare";
  }

  for (i = 1, l = args.length; i < l; i++) {
    leftChain = [] //Todo: this can be cached
    rightChain = []

    if (!compare2Objects(args[0], args[i])) {
      return false
    }
  }

  return true
}

// todo add gorupby and stuff here

/**
 * [{
 *   d1: any
 *   d2: ['a','b']
 *   dn: any
 * }]
 *
 * [{
 *   d1: any
 *   d2: 'a'
 *   dn: any
 * },
 * {
 *   d1: any
 *   d2: 'b'
 *   dn: any
 * }]
 */
export function unrollDimension<T>(
  objects: T[],
  dimension: keyof T,
  unrollCallback = (value: any, key?: string) => value
) {
  return objects.flatMap((object) => {
    const dimValue = object[dimension] // use variable here to not confuse ts -> that the typeguard actaully works
    if (dimValue instanceof Array) {
      return dimValue.map((c: any) => ({
        ...object,
        [dimension]: unrollCallback(c)
      }))
    }
    if (typeof dimValue === 'object' && dimValue) {
      return Object.entries(dimValue).map(([key, value]) => ({
        ...object,
        [dimension]: unrollCallback(value, key)
      }))
    }
    return [object]
  })
}

/**
 * [{
 *   id: string
 *   d1: ['c']
 *   d2: ['a','b']
 *   dn: any
 * }]
 *
 * [{
 *   id: string
 *   d1: 'c'
 *   d2[]: 'a'
 *   dn: any
 * },
 * {
 *   id: string
 *   d2[]: 'b'
 * }]
 *
 * 1. add all props and >=1 length arrays to obje
 * 2. remove all of the added ones
 * 3. repeat for all array props where elements are left
 */
export function unrollArrayDimensionCSV<T extends hasDBid>(objects: T[], arrayIdentifier = '[]') {
  function unrollProps(obj: any & hasDBid, rowArray: any[] = []): any[] {
    const currentObj: any = {}
    const remainingObj: any = {}

    Object.entries(obj).forEach(([key, value]) => {
      if (value instanceof Array) {
        if (value.length > 0) {
          currentObj[`${key}${arrayIdentifier}`] = value.pop()
          if (value.length > 0) remainingObj[key] = value
        }
      } else {
        currentObj[key] = value
      }
    })

    rowArray.push(currentObj)

    if (Object.keys(remainingObj).length === 0) return rowArray
    return unrollProps({ id: obj.id, ...remainingObj }, rowArray)
  }

  return objects.flatMap((object) => {
    return unrollProps(object)
  })
}

/**
 * {a:[1,2,3,4]}
 * {'a[]': '1,2,3,4'}
 *
 * @param objects
 * @param arrayIdentifier
 * @returns
 */
export function inlineArrayDimensionCSV<T extends Record<string, unknown>>(
  objects: T[],
  arrayIdentifier = '[]',
  joinChar = ',',
  escapeChar = '\\'
) {
  return objects.map((obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        return value instanceof Array
          ? [
              key + arrayIdentifier,
              value.map((v: any) => String(v).replaceAll(joinChar, escapeChar + joinChar)).join(joinChar)
            ]
          : [key, value]
      })
    )
  )
}

/**
 * {'a[]': '1,2,3,4'}
 * {a:[1,2,3,4]}
 *
 * @param objects
 * @param arrayIdentifier
 * @returns
 */
export function reverseInlineArrayDimensionCSV<T extends Record<string, unknown>>(
  objects: T[],
  arrayIdentifier = '[]',
  joinChar = ',',
  escapeChar = '\\'
) {
  return objects.map((obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        return key.endsWith(arrayIdentifier)
          ? [
              key.substring(0, key.length - arrayIdentifier.length),
              String(value)
                .replaceAll(escapeChar + joinChar, '##NONBREAKING##')
                .split(joinChar)
                .map((v: string) => v.replaceAll('##NONBREAKING##', joinChar))
            ]
          : [key, value]
      })
    )
  ) as T[]
}

/**
 * [{
 *   id: string
 *   d1: 'c'
 *   d2: 'a'
 *   dn: any
 * },
 * {
 *   id: string
 *   d2: 'b'
 * }]
 *
 * [{
 *   id: string
 *   d1: ['c']
 *   d2: ['a','b']
 *   dn: any
 * }]
 *
 * 1. add all props to object with certain id
 * 2. find all objs with same id
 * 3. add all found obj props to initial object.
 */
export function reverseUnrollArrayDimensionCSV(
  objects: Array<any & hasDBid>,
  arrayIdentifier = '[]',
  ingnoreSuffix = '_READONLY'
) {
  const newObjs = []
  while (objects.length > 0) {
    const selectedObject = objects[0]
    // transform to array if needed
    const newObj: any = {}

    for (let j = objects.length - 1; j >= 0; j--) {
      const searchObject = objects[j]

      if (searchObject.id !== selectedObject.id) continue

      // add all props to the new object & collectiong array elements
      // add all props, even empty ones, once
      Object.entries(searchObject).forEach(([key, value]) => {
        if (key.includes(ingnoreSuffix)) {
          //
        } else if (key.includes(arrayIdentifier)) {
          const keyWithoutArrayIdentifier = key.split(arrayIdentifier)[0]

          if (!(keyWithoutArrayIdentifier in newObj)) {
            // empty csv 'values' contain null
            newObj[keyWithoutArrayIdentifier] = value ? [value] : []
          } else if (value) {
            newObj[keyWithoutArrayIdentifier].push(value)
          }
        } else if (!newObj[key]) {
          // prop is not there OR is empty
          newObj[key] = value
        } else if (key !== 'id' && value !== null && value !== undefined && value !== '') {
          throw `malformed input data [8349]: a duplicated value (${value}) was detected for a non array field (${key})`
        }
      })

      objects.splice(j, 1)
    }
    newObjs.push(newObj)
  }
  return newObjs
}

/**
 * [{
 *   d1: any
 *   d2: 'a'
 *   dn: any
 * },
 * {
 *   d1: any
 *   d2: 'b'
 *   dn: any
 * }]
 *
 * [{
 *   d1: any
 *   d2: ['a','b']
 *   dn: any
 * }]
 *
 * 1. get first object
 * 2. find all objs that only differ on the selected dimension
 * 3. combine all found data in one array
 */
export function reverseUnrollDimension<T>(objects: T[], dimension: keyof T) {
  for (let i = 0; i < objects.length; i++) {
    const selectedObject = objects[i]
    const { [dimension]: dim, ...selectedObjWithoutDim } = selectedObject

    const dimData = [dim]
    for (let j = objects.length - 1; j >= 0; j--) {
      const searchObject = objects[j]

      if (searchObject === selectedObject) continue

      const { [dimension]: dim2, ...searchObjWithoutDim } = searchObject

      if (!objEqual(selectedObjWithoutDim, searchObjWithoutDim)) continue

      dimData.push(dim2)
      objects.splice(j, 1)
    }
    (objects[i] as any) = { ...selectedObjWithoutDim, [dimension]: dimData }
  }
  return objects
}

/**
 * [{
 *   d1: any
 *   d2: {a: 2, b: 1}
 *   dn: any
 * }]
 *
 * [{
 *   d1: any
 *   d2.a: 2
 *   d2.b: 1
 *   dn: any
 * }]
 */
export function inlineDimension<T extends Record<string, unknown>>(
  objects: T[],
  dimension?: keyof T, // inline all objects if no dimension is given
  inlineCallback = (value: any, key: string, dimension: keyof T) => ({ value, key: `${String(dimension)}.${key}` })
) {
  return objects.map((object) => {
    const newObject: any = {
      ...object
    }
    ;(dimension ? [dimension] : Object.keys(object)).forEach((dim: any) => {
      const dimValue = object[dim as keyof T] // use variable here to not confuse ts -> that the typeguard actaully works

      if (dimValue && typeof dimValue === 'object' && !(dimValue instanceof Array)) {
        // add all nested obj props as inlined props
        Object.entries(dimValue).forEach(([key, value]) => {
          newObject[inlineCallback(value, key, dim).key] = inlineCallback(value, key, dim).value
        })

        delete newObject[dim]
      }
    })
    return newObject
  })
}

/**
 * [{
 *   d1: any
 *   d2.a: 2
 *   d2.b: 1
 *   dn: any
 * }
 *
 * [{
 *   d1: any
 *   d2: {a: 2, b: 1}
 *   dn: any
 * }]
 */
export function reverseInlineDimension<T extends { [key: string]: any }>(
  objects: T[],
  dimension?: keyof T,
  dimensionKeyCallback = (dimension: string, objectKey: keyof T) => {
    const keyArr = String(objectKey).split('.')
    return keyArr.length > 1 ? keyArr[1] : false
  }
) {
  return objects.map((object) => {
    (dimension ? [dimension] : Object.keys(object)).forEach((dim: any) => {
      dim = String(dim).split('.')[0]
      Object.entries(object).forEach(([key, value]) => {
        const t = dimensionKeyCallback(dim, key as keyof T)

        if (t) {
          if (typeof object[dim] !== 'object') (object as any)[dim] = {}
          object[dim][t] = value
          delete object[key]
        }
      })
    })
    return object
  })
}
