
import { Component, Vue, Watch } from 'vue-property-decorator'

import { CategoryFlattened } from '@/database/categoryHelper'

@Component({
  components: {},
  model: {
    prop: 'selectCategoryID',
    event: 'selected'
  },
  props: {
    flatCategoriesTree: { //tree of categoeirs
      type: Object, //CategoryFlattened
      required: true
    },
    selectCategoryID: {
      // selecetd category ID
      type: String,
      required: true,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
export default class VInputCategorySelection extends Vue {
  public search = ''
  public selectedID = ''

  public get filteredDataObj() {
    return Object.values(this.$props.flatCategoriesTree).filter(
      (option: any) =>
        option.path &&
        option.path
          .toString()
          .toLowerCase()
          .indexOf(this.search.toLowerCase()) >= 0
    )
  }

  public get selectedIDs(): Array<string> {
    const flatCategoryTree = this.$props.flatCategoriesTree as { [k: string]: CategoryFlattened }
    if (!(this.selectedID in flatCategoryTree)) {
      console.warn(`selectd category ${this.selectedID} not found`)
      return []
    }

    let tempCat = flatCategoryTree[this.selectedID]
    const tempSelectedCats = []

    // add all cats going up the tree to the selection
    while (tempCat.parentCategory != null) {
      tempSelectedCats.push(tempCat.id)
      tempCat = tempCat.parentCategory as CategoryFlattened
    }

    return tempSelectedCats.reverse()
  }

  /**
   * selectCategory
   */
  public onSelectCategory(categoryID: string) {
    if (categoryID) {
      this.search = ''
      this.$emit('selected', categoryID)
    }
  }

  /**
   * mounted
   */
  public mounted() {
    // this.selectedID = this.$props.value todo done by immediate watch?
  }

  @Watch('selectCategoryID', { immediate: true })
  onValueChanged(val: string, oldVal: string) {
    this.selectedID = val
  }
}
