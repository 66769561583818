
import { Component, Vue, Watch } from 'vue-property-decorator'


@Component({})
export default class VTabHashMixin extends Vue {
  // #region tab handling
  public tabHashMixin_activeTab = 0

  protected tabHashMixin_HASH_TAB_MAP: string[] = []

  /** when the route changes, change the tab */
  @Watch('$route', { immediate: true })
  public onRouteHashChange() {
    if (Object.keys(this.tabHashMixin_HASH_TAB_MAP).length === 0)
      console.error('tabHashMixin_HASH_TAB_MAP is empty [202308144]')

    const newIndex = this.tabHashMixin_HASH_TAB_MAP.indexOf(this.$route.hash) || 0

    if (newIndex !== this.tabHashMixin_activeTab) {
      this.tabHashMixin_activeTab = newIndex
      this.tabHashMixin_activeTab_onActiveTabChangedHook()
    }
  }

  public tabHashMixin_activeTab_onActiveTabChangedHook() {
    //
  }

  /** when the tab changes, change the route */
  @Watch('tabHashMixin_activeTab')
  private async tabHashMixin_onActiveTabChanged() {

    if (this.tabHashMixin_HASH_TAB_MAP.length <= this.tabHashMixin_activeTab) {
      console.error('tab index out of bounds [20230818]', this.tabHashMixin_activeTab)
      return
    }

    console.debug('changing tab', this.tabHashMixin_activeTab, this.tabHashMixin_HASH_TAB_MAP[this.tabHashMixin_activeTab], this.$route.hash)

    if (this.$route.hash === this.tabHashMixin_HASH_TAB_MAP[this.tabHashMixin_activeTab]) return

    await this.$router.replace({ hash: this.tabHashMixin_HASH_TAB_MAP[this.tabHashMixin_activeTab] })
  }
  // #endregion tab handling
}
