
import { Component, Watch } from 'vue-property-decorator'

import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'
import VModalCreateAsidCodes from '@/components/VModalCreateAsidCodes.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'
import color from 'color'


import { Address } from '@/types/typeTenant'

import { plans } from '../../businessLogic/plans'
import TenantManager from '@/database/tenantManager'


import { ModuleManager } from '@/modules/moduleManager'
import BaseModule from '@/modules/baseModule'
import AsidManager from '@/database/asidManager'
import BackendConfigManager from '@/database/backendConfigManager'
import { PlanDB, Plans } from '@/types/typePlan'
import { BackendConfigDB } from '@/types/typeBackendConfig'
import databaseSchema from '@/database/databaseSchema'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { ValueOf } from '@/types/typeGeneral'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import { handlePromiseError } from '@/helpers/notificationHelper'

library.add(faCalendarDay)

@Component({
  components: {
    VFormConfigureSticker,
    VRecordMeta,
    VModalCreateAsidCodes
  },
  props: {}
})
export default class BackendConfigBilling extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {

  public isLoading: boolean = false
  public isModalRequestAsidCodesActive: boolean = false

  public activeStep = 0
  public plan: PlanDB = { ...TenantManager.defaultPlanDB }
  private additionalInteractions: number = 0

  @Watch('isModalRequestAsidCodesActive')
  private onTenantDocUpdate() {
    if (!this.isModalRequestAsidCodesActive) this.activeStep = 0
  }

  public tenant = { ...TenantManager.defaultDocDB, id: '' }

  get documentPath() {
    return TenantManager.getDbPlanDocReference(this.$auth.tenant.id).path
  }

  get documentPrivileges() {
    return databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__PRIVILEGES__
  }

  public planExpires = new Date()
  public planType: string = ''
  // used for form controls
  public formAvailablePlans: Array<{ key: string, value: ValueOf<Plans> }> = Object.keys(plans).map(p => ({
    key: p,
    value: plans[p as keyof Plans]
  })) // Enum to string array

  public formName: string = ''
  public formMasterDataPhone: string = ''
  public formMasterDataEmail: string = ''


  public formMasterDatabillingAddress: Address = {} as Address
  public formMasterDatashippingAddress: Address = {} as Address

  // PRM Modules

  public modules = ModuleManager.moduleClasses
  public activeModules: typeof ModuleManager.moduleClasses = []

  public getTileStyle(module: typeof BaseModule) {
    return {
      backgroundImage: `linear-gradient( 155deg, ${module.color} 10%, ${color(module.color).rotate(-20).lighten(.1)} 100%)`,
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }
  public getTileColor(module: typeof BaseModule) {
    return {
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }

  get widgetModules() {
    return this.modules.filter(M => M.hasWidget)
  }

  get nonWidgetModules() {
    return this.modules.filter(M => !M.hasWidget)
  }

  private getActivatedModulesSnapshotHandle: (() => void) | undefined
  public async createdPrmModules() {
    this.isLoading = true
    this.getActivatedModulesSnapshotHandle = ModuleManager.onSnapshotActivatedModuleClasses(this.$auth.tenant.id, undefined, (modules) => {
      console.log('ouou')
      this.activeModules = modules
      this.isLoading = false
    })
  }

  beforeDestroy() {
    console.info('beforeDestroy')
    this.getActivatedModulesSnapshotHandle && this.getActivatedModulesSnapshotHandle()
  }

  //// PRM Modules

  // ASID Codes

  public formQuantity = 0

  public activated = 0
  public availableAsidSlots = 0
  public totalUsedInteractions = 0
  public availableInteractions = 0

  public backendConfig: BackendConfigDB = { ...BackendConfigManager.defaultDocDB }
  public formSelectedCodeConfig = { ...BackendConfigManager.defaultDocDB.codes[0] }

  private onActivatedAsidsHandle?: SnapshotUnbindHandle = undefined
  public async createdAsidCodes() {

    try {
      this.isLoading = true
      await this.$bindSnapshot('backendConfig', BackendConfigManager.getDbDocReference(this.$auth.tenant.id))
    } catch (error) {
      this.$helpers.notification.Error('Error loading Backend Config: ' + error)
    } finally {
      this.isLoading = false
    }

    const tenant = this.$auth.tenant
    this.formSelectedCodeConfig = this.backendConfig.codes[0]

    // await this.$firestoreBind('asids', AsidManager.getDbCollectionReference().where('Tenant', '==', tenant.id))
    // await this.$firestoreBind('categoriesDoc', CategoryHelper.getCategoriesDoc(this.$auth.tenant.id))
    this.onActivatedAsidsHandle = AsidManager.onPlanData(tenant.id, (data) => {
      this.activated = data._computed.activatedAsids
      this.availableAsidSlots = data.availableAsidSlots
      this.totalUsedInteractions = data._computed.totalUsedInteractions
      this.additionalInteractions = data.additionalInteractions
      this.availableInteractions = AsidManager.getAvailableInteractionsCount(data)
    }, (e) => {
      this.$helpers.notification.Error('Error loading Plan: ' + e)
    })
  }
  //// ASID Codes


  public get formTenantNumber() {
    return (this.$auth.tenant._number > 0) ? TenantManager.formatPaddedNumber(this.$auth.tenant._number) : ''
  }

  public async onUpdate() {
    // TenantManager.update(this.$auth.tenant.id, this.$auth.userEmail, {
    //   name: this.formName,
    //   masterData: {
    //     phone: this.formMasterDataPhone,
    //     email: this.formMasterDataEmail,
    //     billingAddress: this.formMasterDatabillingAddress,
    //     shippingAddress: this.formMasterDatashippingAddress
    //   }
    // })
    //   .then(() => this.$helpers.notification.Success('Masterdata updated'))
    //   .catch((e) => this.$helpers.notification.Error('Error updating Masterdata' + e))
  }

  public created() {
    handlePromiseError(async () => {
      await this.initialize()
      await this.createdPrmModules()
      await this.createdAsidCodes()
    })
  }

  public async initialize() {
    this.isLoading = true

    try {

      await this.$bindSnapshot('tenant', TenantManager.getDbDocReference(this.$auth.tenant.id))

      // const tmpDefaultTenantMasterData = TenantManager.defaultDocDB.masterData
      const tmpTenantData = this.tenant
      // const tmpTenantMasterData = this.$helpers.merge(tmpDefaultTenantMasterData, tmpTenantData.masterData as MasterData)

      const planDoc = await TenantManager.getDbPlanDocReference(this.$auth.tenant.id).get()
      const planData = planDoc.data() as PlanDB
      this.plan = planData
      if (!planDoc.exists || !planData) {
        this.$helpers.notification.Error(`plan ${this.$auth.tenant.id} not found`)
        return
      }

      this.planExpires = planData.expires.toDate()
      this.planType = planData.type

      this.formName = tmpTenantData.name
      // this.formMasterDataPhone = tmpTenantMasterData.phone
      // this.formMasterDataEmail = tmpTenantMasterData.email
      // this.formMasterDatabillingAddress = tmpTenantMasterData.billingAddress
      // this.formMasterDatashippingAddress = tmpTenantMasterData.shippingAddress


    } catch (error) {
      this.$helpers.notification.Error('Error loading Tenant: ' + error)
    } finally {
      this.isLoading = false
    }

  }
}
