
import { Component, Vue, ModelSync, Prop, Watch } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faColumns, faMinusSquare, faSquareFull } from '@fortawesome/free-solid-svg-icons'


library.add(faMinusSquare, faCheckSquare, faSquareFull, faColumns)

@Component({
})
export default class VTableColumnsDropdown extends Vue {
  // v-model for selected columns
  @ModelSync('hiddenColumns', 'updateColumns', { type: Array, default: () => [] }) hiddenColumnsSync!: string[]

  @Prop({ type: Array, default: () => [] }) readonly columns!: string[]

  @Prop({ type: String, default: () => '' }) readonly buttonText!: string


  // hiddenColumnsSync is the inverse of selectedColumns
  selectedColumns: string[] = []

  @Watch('hiddenColumnsSync', { immediate: true })
  @Watch('columns')
  private onHiddenColumnsSyncChanged() {
    this.selectedColumns = this.columns.filter((column) => !this.hiddenColumnsSync.includes(column))
  }

  private onSelectedColumnsChanged() {
    this.hiddenColumnsSync = this.columns.filter((column) => !this.selectedColumns.includes(column))
  }

  // if a hiddenColumnSync is set whcih is not in the columns array, remove it
  // todo this ends up in an infintite loop for some reason
  // @Watch('columns')
  // private updateColumns() {
  //   this.hiddenColumnsSync = this.hiddenColumnsSync.filter((column) => this.columns.includes(column))
  // }
}
