
import { Component, Vue, ModelSync, Prop } from 'vue-property-decorator'
import { ModuleType } from '@/modules/typeModules'
import { ModuleManager } from '@/modules/moduleManager'
import color from 'color'


@Component({
  components: {}
})
export default class VInputMultiModuleSelection extends Vue {

  @ModelSync('moduleTypes', 'input', { type: Array, required: true, default: () => [] })
  readonly selectedModuleTypes!: ModuleType[]

  @Prop({
    type: Array, required: false, default: () => [
      'Form',
      'File',
      'Html',
      'Script',
      'Custom',
      'Protection',
      'Data'
    ]
  })
  readonly availableModules!: ModuleType[]


  private filterText = ''
  public setFilterText(text: string) {
    this.filterText = text.toLowerCase()
  }


  public get filteredTags(): ModuleType[] {
    return this.availableModules
      .filter(av => !this.selectedModuleTypes.includes(av))
      .filter(av => av.toLowerCase().includes(this.filterText))
  }

  public getColorStyle(moduleType: ModuleType) {
    const module = ModuleManager.getModuleClassByType(moduleType)
    return {
      backgroundImage: `linear-gradient( 155deg, ${module.color} 10%, ${color(module.color).rotate(-20).lighten(.1)} 100%)`,
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }

  public getDisplayName(moduleType: ModuleType) {
    const module = ModuleManager.getModuleClassByType(moduleType)
    return module.displayName
  }

  public getTileColor(moduleType: ModuleType) {
    const module = ModuleManager.getModuleClassByType(moduleType)
    return {
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }

  public async created() {
    // this.initialise()
  }

}
