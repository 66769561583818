
import { Component, Watch } from 'vue-property-decorator'
import VApp, { AppClickedEvent } from '@/pages/app/components/VApp.vue'
import { defaultAppData, defaultBackendConfigDB, defaultBaseGroupDB, defaultCiElementDB, defaultHtmlGroupDB } from '@/database/databaseSchema'
import { BaseElementDB, BaseGroupDB, BaseModuleDB, BaseModuleGroupAppData, ElementWithTypeAndID, hasModuleType, ModuleType } from '@/modules/typeModules'
import { hasDBid } from '@/types/typeGeneral'
import { AppData } from '@/types/typeAppData'
import { BaseDB } from '@/types/typeBase'
import { ModuleManager } from '@/modules/moduleManager'
import { SnapshotParallel, typedWhere } from '@/database/dbHelper'
import Event from '@/helpers/eventBus'
import { AsidReference, createAppData, LogContext } from '@/shared/appDataHelper'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { throttle } from 'throttle-debounce'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import { intersectEvery } from '@/helpers/arrayHelper'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { assetAttributeValue, IdentifierValue, isAssetAttributeKey, isIdentifierKey } from '@/types/typeAsid'
import { CiElementDB } from '@/modules/ci/typeCiModule'
import CiModule from '@/modules/ci/ciModule'
import { I18nModuleDB } from '@/modules/i18n/typeI18nModule'
import VFormDataEntry from './VFormDataEntry.vue'
import VNotificationReadMore from './VNotificationReadMore.vue'
import { mixins } from 'vue-class-component'
import VCustomVueFireBindMixin from './mixins/VCustomVueFireBindMixin.vue'
import { DataDefinition } from '@/types/typeDataDefinition'
import DataModule from '@/modules/data/dataModule'
import { DataElementDB, DataGroupDB } from '@/modules/data/typeDataModule'
import ScriptExecution from '../modules/custom/customModuleUtils'


library.add(faCog)

const ENABLE_LOG = false

/**
 * The App Preview has two modes. One 'single item' mode which focuses on one item onyl and a 'references' mode which shows the item in the context of other items
 * 'References' mode is configured within this component, loading all data from db and processing it identical to the App CF
 * The actively edited item is controlled using published methods to add and reset elements and groups
 */

/**
 * Events are used to interface with the Vue instance from the outside 'AppPreview' interface
 */
const eventAddGroupAndElements = new Event<{
  type: ModuleType
  group: (BaseGroupDB & hasDBid)
  elements: (BaseElementDB & hasDBid)[]
  itemType: 'element' | 'group' | 'module' | 'other'
  update: boolean
}>('add-group-and-elements', 'VAppBackendAppPreview')

const eventSetMode = new Event<'references' | 'individual'>('mode', 'VAppBackendAppPreview')
const eventHideNotifications = new Event<boolean>('hide-errors', 'VAppBackendAppPreview')
const eventSetExternalAsidReferences = new Event<{ asidReference: AsidReference, assetAttribute: assetAttributeValue }>('references', 'VAppBackendAppPreview')
const eventUpdatePreview = new Event<void>('update', 'VAppBackendAppPreview')
const eventResetPreview = new Event<void>('reset', 'VAppBackendAppPreview')
const eventItemSelected = new Event<AppPreviewSelectionEvent>('selected', 'VAppBackendAppPreview')


interface AppPreviewSelectionEvent extends AppClickedEvent {
  docDB?: BaseDB
}

/** used for items which are not actual docs in DB */
export const DUMMY_ITEM_PREFIX = '_dummy_'

export abstract class AppPreview {

  /**
   * add multiple elements and one group. Preview is updated automatically
   */
  public static addGroupsAndElements<T extends BaseElementDB>(
    type: ModuleType,
    elements: (T & hasDBid)[],
    group: (BaseGroupDB & hasDBid),
    itemType: 'element' | 'group' | 'module' | 'other' = 'other',
    update = true
  ) {
    return eventAddGroupAndElements.emit({ type, elements, group, itemType, update })
  }

  /** 
   * call this multiple times and then 'flush' to add many items at once 
   * does not immediately update the preview until 'flush' is called
  */
  public static addGroupsAndElementsTransaction<T extends BaseElementDB>(
    type: ModuleType,
    elements: (T & hasDBid)[],
    group: (BaseGroupDB & hasDBid),
    itemType: 'element' | 'group' | 'module' | 'other' = 'other'
  ) {
    return AppPreview.addGroupsAndElements(type, elements, group, itemType, false)
  }

  /**
   * manually updates thre preview. Use for 'addGroupsAndElementsTransaction'
   */
  public static flush() {
    return eventUpdatePreview.emit()
  }

  /** 
   * displays a single element inline.
   * since some settings are stored on group level, still provide it for better preview
  */
  public static setElements(type: ModuleType, elements: (BaseElementDB & hasDBid)[], group: (BaseGroupDB & hasDBid)) {
    this.reset()

    // if a group is set to e.g. widget and we force it to inline here, the background of the tile would be applied
    if (group.public.display.displayType !== 'inline')
      group.public.display.displayType = 'preview-single'

    this.addGroupsAndElements(type, elements, group, 'element')
  }

  public static setGroup(type: ModuleType, elements: (BaseElementDB & hasDBid)[], group: (BaseGroupDB & hasDBid)) {
    this.reset()
    this.addGroupsAndElements(type, elements, group, 'group')
  }

  /** 
   * disables the single mode and set the given references
  */
  public static setReferences(references: AsidReference, attributes: assetAttributeValue) {
    this.reset()

    eventSetExternalAsidReferences.emit({ asidReference: references, assetAttribute: attributes })
  }


  /** 
  * override the user selectable mode
  */
  public static setMode(mode: 'references' | 'individual', reset = true) {
    if (reset) this.reset()

    eventSetMode.emit(mode)
  }

  /**
   * resets the currently displayed items 
   * used before adding items
   */
  public static reset() {
    return eventResetPreview.emit()
  }

  /**
   * hides all notifications
   */
  public static hideNotifications(doHide: boolean) {
    return eventHideNotifications.emit(doHide)
  }

  /**
   * called when an item is selected in the preview
  */
  public static onSelected(cb: (d: AppPreviewSelectionEvent) => void) {
    return eventItemSelected.on(cb)
  }
}

@Component({
  components: {
    VApp,
    VInputMultiCategorySelection,
    VFormDataEntry,
    VNotificationReadMore
  }
})
export default class VBackendAppPreview extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {

  /** force reapply styles and skripts when setting to true */
  public reloadBackgroundModules = true

  /** used to reinstantiate the app on data change. => using key also resets the scroll position which is bad ux */
  public key = 0

  /** overrides the locally selected mode */
  public externalMode: 'references' | 'individual' | '' = ''

  /** 'references': show preview based on selected asid references 'individual': preview based on explicitely set elements/groups */
  public get mode(): 'references' | 'individual' {
    if (this.externalMode) return this.externalMode

    switch (this.modeIndex) {
      case 1:
        this.appPath = ''
        return 'individual'
      case 2:
        this.appPath = ''
        return 'references'
      default:
        return 'references'
    }
  }

  /** index for tabs to switch mode */
  public modeIndex = 1

  /** based on item a validation is performed in 'references' mode to tell the user if the item is shown in the preview */
  public itemType: 'element' | 'group' | 'module' | 'other' = 'other'

  /** references used for 'references' mode, like refs on an asid */
  // this.$localSettings.appPreview.references

  public externalRefMode = false
  /** external refs might be supplied from asid-single page */
  public externalReferences: AsidReference = {
    asidID: 'previ-ew111-echo1-code1',
    categoryIDs: [],
    identifierValue: {
      i1: '',
      i2: '',
      i3: '',
      i4: '',
      i5: '',
      i6: ''
    }
  }

  /** external attributes might be supplied from asid-single page */
  public externalAttributes: assetAttributeValue = {
    a1: '',
    a2: '',
    a3: '',
    a4: '',
    a5: '',
    a6: '',
    a7: '',
    a8: '',
    a9: '',
    a10: '',
    a11: '',
    a12: '',
    a13: '',
    a14: '',
    a15: '',
    a16: '',
    a17: '',
    a18: '',
    a19: '',
    a20: ''
  }

  // wether to hide all notifications
  public hideNotifications = false

  /** set to inform user about items not shown due to references (see itemType and mode) */
  public notificationText = ''

  // display warnings on top of the preview 
  public warnNotificationTexts: string[] = []

  // display errors on top of the preview
  public errorNotificationTexts: string[] = []

  // show all warning and error notifications
  public showAllNotifications = false

  // are non reactive for performance reasons
  private localModuleDatas!: Array<BaseModuleDB & hasDBid & { type: ModuleType }>
  private dbModuleDatas!: Array<BaseModuleDB & hasDBid & { type: ModuleType }>
  private localElementDatas!: Array<BaseElementDB & hasDBid & { type: ModuleType }>
  private dbElementDatas!: Array<BaseElementDB & hasDBid & { type: ModuleType }>
  private localGroupDatas!: Array<BaseGroupDB & hasDBid & { type: ModuleType }>
  private dbGroupDatas!: Array<BaseGroupDB & hasDBid & { type: ModuleType }>

  private dataModuleGroups: Array<DataGroupDB & hasDBid> = []

  //#region references modal

  /** modal to set references */
  public isModalActive = false

  /** also preview elements in draft state. Is disabled when in ExternalRefMode */
  //this.$localSettings.appPreview.includeDraft


  //#endregion references modal

  /**
   * item was selected in the VApp preview
   */
  public async onItemSelected(event: AppClickedEvent) {

    if (!event.docID || event.docID.startsWith(DUMMY_ITEM_PREFIX)) {
      // emit what item was selecetd, if its a dummy item
      eventItemSelected.emit(event)

    } else if (event.docID && event.moduleType) {
      const module = ModuleManager.getModuleClassByType(event.moduleType)

      // navigate to the selected item if it has no dummy id (starting with '_dummy_')
      if (event.type === 'element') {
        await this.$router.push({
          name: module.routeNameElement,
          params: { id: event.docID }
        })
      } else if (event.type === 'group') {
        await this.$router.push({
          name: module.routeNameGroup,
          params: { groupID: event.docID }
        })
      }

    } else {
      console.warn('onItemSelected without docID nor moduleType', event)
    }
  }

  /** navigation path in app */
  public appPath = ''

  private accessKeys: string[] = []

  public onKeyProvided(key: string) {
    this.accessKeys.push(key)
    this.updateAppData()
  }

  private dataEntrySampleValues(dataDefinition: DataDefinition) {
    switch (dataDefinition.datatype) {
      case 'string':
        return `sample ${dataDefinition.name}`
      case 'number':
        return '12345678'
      case 'boolean':
        return 'true'
      case 'image':
        return require('@/assets/editor/placeholderImage.jpeg')
      case 'auto_generated':
        return 'AUTO_GENERATED'
      case 'email':
        return 'sample-email@example.com'
      case 'gps':
        return '48.8566, 2.3522'
      case 'other':
        return 'other sample value'
      default: {
        // use ts never to make sure all cases are covered
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _exhaustiveCheck: never = dataDefinition.datatype
        return 'unknown type'
      }

    }
  }

  // used to display a single element in the preview. Are merged with the user provided attributes
  private get dummyAssetAttributes(): assetAttributeValue {
    return Object.fromEntries(Object.entries(this.$backendConfig.asid.assetAttributeDefinitions).map(([key, dataDef]) => {

      // only use the generated value if the user did not provide one
      if (this.$localSettings.appPreview.assetAttributeValue[key as isAssetAttributeKey] !== null)
        return [key, this.$localSettings.appPreview.assetAttributeValue[key as isAssetAttributeKey]]

      let value = this.dataEntrySampleValues(dataDef)

      return [key, value]

    })) as assetAttributeValue
  }

  private get dummyIdentifierValues(): IdentifierValue {
    return Object.fromEntries(Object.entries(this.$backendConfig.asid.identifierDefinition).map(([key, dataDef]) => {

      // only use the generated value if the user did not provide one
      if (this.$localSettings.appPreview.references.identifierValue[key as isIdentifierKey] !== null)
        return [key, this.$localSettings.appPreview.references.identifierValue[key as isIdentifierKey]]

      let value = this.dataEntrySampleValues(dataDef)

      return [key, value]

    })) as IdentifierValue
  }

  public appData: AppData = cloneObject(defaultAppData)

  private updateAppData() {

    if (ENABLE_LOG) console.log('ABS ---- updateAppData fired')
    // if (this.localModuleDatas === undefined) return // fix for hmr ot intialising but resetting non reactive vars

    // reset script execution pubsub
    ScriptExecution.resetPubSub()

    // clone to not mutate the original
    const localSettingsClone = cloneObject(this.$localSettings)

    let moduleDatas = [...this.dbModuleDatas]
    let elementDatas = [...this.dbElementDatas.filter(gd => gd.publishingState === 'published' || (localSettingsClone.appPreview.includeDraft && gd.publishingState === 'draft' && !this.externalRefMode))]
    let groupDatas = [...this.dbGroupDatas.filter(gd => gd.publishingState === 'published' || (localSettingsClone.appPreview.includeDraft && gd.publishingState === 'draft'))]

    let references: AsidReference = this.externalRefMode ? this.externalReferences : localSettingsClone.appPreview.references
    // clone to not mutate the original
    references = cloneObject(references)

    //  in 'individual' mode, dummy attributes are merged with the user provided attributes
    //  in 'references' mode, the attributes are provided by the user
    //  in external ref mode, the attributes are provided from external via prop
    let attributes = this.externalRefMode
      ? this.externalAttributes
      : this.mode === 'individual'
        ? this.dummyAssetAttributes
        : localSettingsClone.appPreview.assetAttributeValue
    // clone to not mutate the original
    attributes = cloneObject(attributes)


    const mode = this.externalRefMode ? 'references' : this.mode

    if (mode === 'individual') {
      references.identifierValue = this.dummyIdentifierValues
    }

    if (ENABLE_LOG) console.log('mode', mode)
    if (ENABLE_LOG) console.log('externalRefMode', this.externalRefMode)

    let ignoreReferenceFilter = false

    if (ENABLE_LOG) console.log('moduleDatas1', [...this.localModuleDatas])
    if (ENABLE_LOG) console.log('elementDatas1', [...this.localElementDatas])
    if (ENABLE_LOG) console.log('groupDatas1', [...this.localGroupDatas.map(gd => ({ ...gd, protectionGroupID: '' }))])

    switch (mode) {
      case 'references': {
        // todo create app data here to interpolate variables

        // merge data from db with the local update items. Overwrite with the local updates
        const mergeDatasetsById = <T extends hasDBid & { type: ModuleType }>(dbDatas: T[], localDatas: T[]) => {
          return dbDatas.map(dbData => localDatas.find(lmd => lmd.id === dbData.id && lmd.type === dbData.type) || dbData)
        }

        elementDatas = [...elementDatas, this.defaultCi] // add default ci so the apps resets the design if no ci element is present

        if (this.itemType === 'element')
          elementDatas = mergeDatasetsById(elementDatas, this.localElementDatas)
        if (this.itemType === 'group')
          groupDatas = mergeDatasetsById(groupDatas, this.localGroupDatas)

        moduleDatas = mergeDatasetsById(moduleDatas, this.localModuleDatas)
      }
        break

      case 'individual': {

        // add enabled languages to show in app
        moduleDatas = [...this.localModuleDatas, cloneObject({ ...DataModule.moduleDB, id: 'data_module_id_dummy', type: 'Data' as const })].map(md => {
          if (md.type === 'I18n')
            return {
              ...md,
              public: {
                ...md.public,
                enabledLocales: this.$i18n.backendEnabledLocales
              }
            } as (I18nModuleDB & hasDBid & {
              type: ModuleType
            })
          else
            return md
        })

        const dataModuleElements: Array<DataElementDB & hasDBid & { type: ModuleType }> = []
        // create dummy Data Module elements based on the data definitions
        this.dataModuleGroups.forEach(dataModuleGroup => {
          const clonedDefaultDataElement: typeof elementDatas[0] & DataElementDB = cloneObject({
            ...DataModule.elementDB,
            id: DUMMY_ITEM_PREFIX + 'some_id',
            type: 'Data',
            public: {
              ...DataModule.elementDB.public,
              groupID: dataModuleGroup.id
            }
          })
          const dataValues = {
            ...clonedDefaultDataElement.data,
            ...Object.fromEntries(Object.entries(dataModuleGroup.dataDefinition).map(([key, dataDefinition]) => {
              return [key, this.dataEntrySampleValues(dataDefinition)]
            }))
          }
          clonedDefaultDataElement.data = dataValues
          // set the reference asid to the preview asid, so it is shown
          // clonedDefaultDataElement.reference.asidIDs = ['previ-ew111-echo1-code1'] => this is taken care of by igoreReferenceFilter

          dataModuleElements.push(clonedDefaultDataElement)
        })


        // add dataModuleGroups so the data variable interpolation works
        // rmove protection for 'individual' view
        groupDatas = [...this.localGroupDatas.map(gd => ({ ...gd, protectionGroupID: '' })), ...this.dataModuleGroups.map(gd => ({ ...gd, type: 'Data' as ModuleType, id: gd.id }))]
        elementDatas = [...this.localElementDatas, this.getSingleItemCi(), ...dataModuleElements]

        if (ENABLE_LOG) console.log('moduleDatas', moduleDatas)
        if (ENABLE_LOG) console.log('groupDatas', groupDatas)
        if (ENABLE_LOG) console.log('elementDatas', elementDatas)
        ignoreReferenceFilter = true

        break
      }
      default:
        break
    }

    // if an image is used in asset attribute data definitions, set a placeholder instead
    Object.entries(this.$backendConfig.asid.assetAttributeDefinitions).forEach(([key, value]) => {
      if (value.datatype === 'image') {
        localSettingsClone.appPreview.assetAttributeValue[key as isAssetAttributeKey] = require('@/assets/editor/placeholderImage.jpeg')
      } else if (value.datatype === 'auto_generated') {
        localSettingsClone.appPreview.assetAttributeValue[key as isAssetAttributeKey] = 'AUTO_GENERATED'
      }
    })

    Object.entries(this.$backendConfig.asid.identifierDefinition).forEach(([key, value]) => {
      if (value.datatype === 'image') {
        localSettingsClone.appPreview.references.identifierValue[key as isIdentifierKey] = require('@/assets/editor/placeholderImage.jpeg')
      } else if (value.datatype === 'auto_generated') {
        localSettingsClone.appPreview.references.identifierValue[key as isIdentifierKey] = 'AUTO_GENERATED'
      }
    })

    this.warnNotificationTexts = []
    this.errorNotificationTexts = []


    // app data for local (not ind DB) element overrides
    const localAppData = createAppData(
      moduleDatas,
      groupDatas,
      elementDatas,
      cloneObject(defaultBackendConfigDB),
      this.accessKeys,
      references,
      attributes,
      this.$categories,
      (s) => (1), // create protection response
      ignoreReferenceFilter,
      {
        debug: (message: string, logContext: LogContext, ...args: any[]) => {
          console.debug(message, logContext, ...args)
        },
        error: (message: string, logContext: LogContext, ...args: any[]) => {
          // format log context as table string
          const logContextTable = Object.entries(logContext).map(([key, value]) => `${key}: ${value}`).join('\n')
          if (mode === 'references') { // dont show the errors in the individual view as they are likely related to missing data
            console.error(message, logContext, ...args)
            this.$helpers.notification.Error(message + '\n' + logContextTable)
          }
        },
        userError: (message: string, logContext: LogContext, ...args: any[]) => {
          // format log context as table string
          const logContextTable = Object.entries(logContext).map(([key, value]) => `${key}: ${value}`).join('\n')
          if (mode === 'references') { // dont show the errors in the individual view as they are likely related to missing data
            console.warn(message, logContext, ...args)
            // this.$helpers.notification.Error(message + '\n' + logContextTable)
            this.errorNotificationTexts.push(message + '\n' + logContextTable)
          }
        },
        warn: (message: string, logContext: LogContext, ...args: any[]) => {
          // format log context as table string
          const logContextTable = Object.entries(logContext).map(([key, value]) => `${key}: ${value}`).join('\n')
          if (mode === 'references') { // dont show the errors in the individual view as they are likely related to missing data
            console.warn(message, logContext, ...args)
            // this.$helpers.notification.Warn(message + '\n' + logContextTable)
            this.warnNotificationTexts.push(message + '\n' + logContextTable)
          }

        },
        info: (message: string, logContext: LogContext, ...args: any[]) => {
          console.info(message, logContext, ...args)
        }
      }
    )

    if (this.hideNotifications) {
      this.warnNotificationTexts = []
      this.errorNotificationTexts = []
    }

    // check if the supplied item is displayed or hidden due to gien references
    // only the last element is checked to allow for supporting elements to be added before, like in C

    if (this.localElementDatas.length > 0 && this.itemType === 'element' && !intersectEvery([this.localElementDatas[this.localElementDatas.length - 1]].map(e => e.id), localAppData.flatMap(lad => lad.elements.map(el => el.id))))
      this.notificationText = 'The current Element is not visible in the preview, since its refrences do not overlap with the preview references.'
    else if (this.localGroupDatas.length > 0 && this.itemType === 'group' && !intersectEvery([this.localGroupDatas[this.localGroupDatas.length - 1]].map(e => e.id + e.type), localAppData.flatMap(lad => lad.group.id + lad.public.type)))
      this.notificationText = 'The current Widget is not visible in the preview, since its refrences do not overlap with the preview references.'
    else this.notificationText = ''

    // if (this.itemType === 'module' && intersectEvery(this.localModuleDatas.map(e=>e.id), localAppData.flatMap(lad=>lad..map(el=>el.id))) )
    //   this.notificationText = 'The current Module is not visible in the preview, since its refrences do not overlap with the preview references.'

    if (ENABLE_LOG) console.log(localAppData)

    this.appData.globalData.tenantID = 'preview'
    this.$set(this.appData, 'moduleGroupAppDatas', localAppData)
    // this.appData = {
    //   ...defaultAppData,
    //   moduleGroupAppDatas: localAppData
    // }
  }

  /** used for reative locally update elements and groups */
  public localPreviewAppDatas: BaseModuleGroupAppData[] = []

  /** used for user selected asid refrence  */
  public asidReferenceAppDatas: BaseModuleGroupAppData[] = []

  /** used for single element design selection */
  public ciElements: (CiElementDB & hasDBid)[] = []

  async created() {
    // init non reactive variables
    if (ENABLE_LOG) console.log('ABS --- created')

    this.localModuleDatas = []
    this.dbModuleDatas = []
    this.localElementDatas = []
    this.dbElementDatas = []
    this.localGroupDatas = []
    this.dbGroupDatas = []

    this.initEventBus()
    await this.getElementsForReferences()

    // bind ci design elements
    if (this.$auth.userHasAllPrivilege(CiModule.authPrivileges.r))
      await this.$firestoreBind(
        'ciElements',
        typedWhere<CiElementDB>(CiModule.getElementsDbReference(this.$auth.tenant.id), { publishingState: 'published' }, 'in', ['published', 'draft']),
        { wait: true }
      )

    // bind data groups
    if (this.$auth.userHasAllPrivilege(DataModule.authPrivileges.r))
      await this.$firestoreBind(
        'dataModuleGroups',
        typedWhere<BaseGroupDB>(DataModule.getGroupsDbReference(this.$auth.tenant.id), { publishingState: 'published' }, 'in', ['published', 'draft']),
        { wait: true }
      )

    this.throttledUpdateAppData()
  }

  private appDataUnbind?: SnapshotUnbindHandle
  public async getElementsForReferences() {
    this.appDataUnbind?.()

    const snapPar = new SnapshotParallel(true, 'VBackendPreview')

    snapPar
      .addSnapShotPar((cb, errCB) => ModuleManager.onSnapshotModuleGroups(
        this.$auth.tenant.id, this.$auth.user?.privileges, { includeDeleted: false }, cb, errCB, 'vBackendAbbPreviewGroups'
      ))
      .addSnapShotPar((cb, errCB) => ModuleManager.onSnapshotModuleElements(
        this.$auth.tenant.id, this.$auth.user?.privileges, { includeDeleted: false }, cb, errCB, 'vBackendAbbPreviewElements'
      ))
      .addSnapShotPar((cb, errCB) => ModuleManager.onSnapshotActivatedModules(
        this.$auth.tenant.id, this.$auth.user?.privileges, cb, errCB, 'vBackendAbbPreviewModules'
      ))


    // this.appDataUnbind = snapPar.onSnapshot((d)=>if(ENABLE_LOG) console.log(d),err=>console.error(err))

    this.appDataUnbind = snapPar.onSnapshot(
      (datas) => {
        let [groupDocs, elementDocs, modules] = datas as [
          (BaseGroupDB & hasDBid & hasModuleType)[],
          ElementWithTypeAndID[],
          (BaseModuleDB & hasDBid)[]
        ]

        if (!modules || !modules[0]) {
          modules = []
        }


        this.dbGroupDatas = groupDocs
        this.dbElementDatas = elementDocs
        this.dbModuleDatas = modules.map(m => ({ ...m, type: m.public.type }))

        this.throttledUpdateAppData()
      },
      (err) => this.$helpers.notification.Error(err)
    )
    this.$unbindHandle(this.appDataUnbind)

  }

  private throttledUpdateAppData = throttle(300, async () => {
    if (ENABLE_LOG) console.log('ABS ---- throttled update')
    await this.$nextTick()
    this.updateAppData()
  }, { noLeading: true, noTrailing: false })

  @Watch('mode')
  @Watch('$localSettings.appPreview.includeDraft')
  @Watch('$localSettings.appPreview.references', { deep: true })
  @Watch('$localSettings.appPreview.assetAttributeValue', { deep: true })
  private onUpdateAppData() {
    this.throttledUpdateAppData()
  }

  /** number to be updated on every update to make sure the latest ci el update has the highest order */
  private orderIncrement = 10

  @Watch('$localSettings.appPreview.ciElementId')
  private onSingleCiElementChange() {
    this.throttledUpdateAppData()
  }

  private getSingleItemCi(): BaseElementDB & hasDBid & { type: ModuleType } {

    // add default ci for the preview
    const ciElement = this.ciElements.find(el => el.id === this.$localSettings.appPreview.ciElementId)
    const defaultCi: CiElementDB & hasDBid & { type: ModuleType } =
      ciElement
        ? {
          ...ciElement, type: 'Ci',
          public: {
            ...ciElement.public,
            order: -1000 // negative so if an actual ci element is present it will take presedence
          }
        }
        : this.defaultCi


    return defaultCi
  }

  /** used to force app in default ci when no other ci element is selected */
  private defaultCi: CiElementDB & hasDBid & { type: ModuleType } = {
    id: 'default-ci',
    type: 'Ci',
    ...defaultCiElementDB,
    reference: {
      ...defaultCiElementDB.reference,
      asidIDs: ['previ-ew111-echo1-code1'] // this echoID is also used for the reference view. If no other ref Ci ref matches, it'll default to this
    },
    public: {
      ...defaultCiElementDB.public,
      header: {
        color: defaultCiElementDB.public.header.color,
        logo: { url: '', maxHeight: 0, maxWidth: 0, alignment: 'center', fullHeight: false, fullWidth: false },
        title: { locales: { default: 'ECHO PRM' }, _ltType: true }
      },

      order: -10000,
      groupID: 'default-group'
    }
  }


  private initEventBus() {
    eventAddGroupAndElements.unsubscribeAll()
    eventResetPreview.unsubscribeAll()
    eventUpdatePreview.unsubscribeAll()
    eventSetMode.unsubscribeAll()
    eventSetExternalAsidReferences.unsubscribeAll()
    eventHideNotifications.unsubscribeAll()


    // todo debounce events

    eventAddGroupAndElements.on((data) => {
      if (ENABLE_LOG) console.log('ABS ---- update')

      this.itemType = data.itemType
      data.group = data.group || defaultBaseGroupDB

      // store to use them when 'refrences' preview mode is used
      this.localElementDatas = ([...this.localElementDatas, ...data.elements.map(el => ({ ...el, id: el.id, type: data.type }))])
      this.localGroupDatas = ([...this.localGroupDatas, { ...data.group, id: data.group.id, type: data.type }])
      this.localModuleDatas = ModuleManager.moduleClasses.map(mc => ({ ...mc.moduleDB, id: mc.type, type: mc.type }))
      // add i18n config also to local modules
      // const dbI18nData = this.dbModuleDatas.find(d => d.type === 'I18n')
      // this.localModuleDatas = this.localModuleDatas
      //   .map(md => ({ ...(dbI18nData && md.type === 'I18n') ? dbI18nData : md }))

      if (ENABLE_LOG) console.log('localElementDatas', this.localElementDatas)
      if (ENABLE_LOG) console.log('localGroupDatas', this.localGroupDatas)
      if (ENABLE_LOG) console.log('data', data)

      if (data.update)
        this.throttledUpdateAppData()
    })

    eventResetPreview.on(() => {
      if (ENABLE_LOG) console.log('ABS ---- reset')

      this.localPreviewAppDatas = []
      this.localElementDatas = []
      this.localGroupDatas = []
      this.localModuleDatas = []

      this.externalMode = ''
      this.externalRefMode = false

      // add default ci for the preview

      AppPreview.addGroupsAndElementsTransaction('Ci', [this.defaultCi], { ...defaultHtmlGroupDB, id: 'default-group' }, 'element')
    })

    eventUpdatePreview.on(() => {
      if (ENABLE_LOG) console.log('ABS ---- event update')
      this.throttledUpdateAppData()
    })

    eventSetMode.on((mode) => {
      if (ENABLE_LOG) console.log('ABS ---- event setmode')
      this.externalMode = mode

      this.throttledUpdateAppData()
    })

    /** when external references are set, disable the single item mode and use those refs instead of the locally set ones */
    eventSetExternalAsidReferences.on(({ asidReference, assetAttribute }) => {
      if (ENABLE_LOG) console.log('ABS ---- event referneces')
      this.externalRefMode = true
      this.externalReferences = asidReference
      this.externalAttributes = assetAttribute

      this.throttledUpdateAppData()
    })

    eventHideNotifications.on((hide) => {
      if (ENABLE_LOG) console.log('ABS ---- event hide errors')
      this.hideNotifications = hide
    })
  }
}
