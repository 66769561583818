// This is a simple cache that can be used to cache data from any source
// it returns a promise that resolves to the data
// if the data is already cached, the promise is resolved immediately
// if the data is not cached, the query function is called ONCE to get the data
export class DataCache<T> {
  private cacheMap: { [key: string]: Promise<T> } = {}
  private query: (key: string) => Promise<T>

  constructor(query: (key: string) => Promise<T>) {
    this.query = query
  }

  /**
   *
   * @param key dummy key is used if the query shall always return the same data
   * @returns
   */
  public get(key: string = 'dummy'): Promise<T> {
    if (!(key in this.cacheMap)) {
      this.cacheMap[key] = this.query(key)
    }

    return this.cacheMap[key]
  }

  /**
   * @param key the key to clear from the cache
   */
  public clear(key: string) {
    delete this.cacheMap[key]
  }

  /**
   * Clear all cache
   */
  public clearAll() {
    this.cacheMap = {}
  }

}

// const C = new DataCache(async key => (await FormModule.getElementsQuery(key).get()).docs)
// C.get('abc')
