import firebase from 'firebase/compat/app'
import md5 from 'md5'
import { TenantID, Terms, TenantDB } from '@/types/typeTenant'

import { encodeEmailToKey } from './encodeEmailToKey'

import termsHtml from '@/businessLogic/terms.html?raw'
import odpHtml from '@/businessLogic/odp.html?raw'
import TenantManager from './tenantManager'
import { ACCEPT_TOS_UNTIL } from '@/businessLogic/constants'
import { DeepPartial } from '@/types/typeGeneral'

export default class TosManager {

  public static acceptTerms(
    tenantId: TenantID,
    authEmail: string,
    tos: Terms,
    type: 'odp' | 'terms',
    acceptedByEmail?: string,
    acceptedDate?: Date
  ) {
    const dateAccepted = acceptedDate
      ? firebase.firestore.Timestamp.fromDate(acceptedDate)
      : firebase.firestore.FieldValue.serverTimestamp()
    // mapped to this object to get ts type hints
    const terms = {
      acceptedByUserId: encodeEmailToKey(acceptedByEmail || authEmail),
      acceptedTermsHash: this.getTermsHash(tos, type),
      dateAccepted
    } as Terms

    const tenantData: DeepPartial<TenantDB> = {
      [type == 'odp' ? 'odp' : 'terms']: terms
    }

    return TenantManager.update(tenantId, authEmail, tenantData)
  }

  public static getTermsHtml(tos: Terms, type: 'odp' | 'terms') {
    return tos.customTermsText !== '' ? tos.customTermsText : type === 'terms' ? termsHtml : odpHtml
  }

  private static getTermsHash(tos: Terms, type: 'odp' | 'terms') {
    return md5(this.getTermsHtml(tos, type))
  }

  public static areTOSAccepted(tos: Terms, type: 'odp' | 'terms') {
    return this.getTermsHash(tos, type) === tos.acceptedTermsHash
  }

  public static daysUntilToAcceptTOS(tos: Terms) {
    function daysBetween(date1: number, date2: number) {
      // The number of milliseconds in one day
      const ONE_DAY = 1000 * 60 * 60 * 24

      // Calculate the difference in milliseconds
      const differenceMs = date1 - date2

      // Convert back to days and return
      return Math.round(differenceMs / ONE_DAY)
    }

    const acceptDeadline =
      tos.customTermsText != '' ? (tos.acceptCustomTermsUntil.toDate() as Date).getTime() : ACCEPT_TOS_UNTIL.getTime()

    return daysBetween(acceptDeadline, Date.now())
  }
}
