
import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator'
import { Cropper } from 'vue-advanced-cropper'
import VResizableImage from '@/components/editor/VEditorResizableImage.vue'
import VYoutubeExtension from '@/components/editor/VEditorYoutubeExtension.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUndo,
  faRedo,
  faBold,
  faItalic,
  faUnderline,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faStrikethrough,
  faCode,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteLeft,
  faRulerHorizontal,
  faImage,
  faUpload,
  faExpandArrowsAlt,
  faFilm,
  faLink,
  faSquare
} from '@fortawesome/free-solid-svg-icons'
import VModuleElementBaseConfig from '@/components/VModuleElementBaseConfig.vue'
import VImageUploadModal from '@/components/image/VImageUploadModal.vue'
import VInputLocalizedText from '@/components/VInputLocalizedText.vue'
import VColorPicker from '@/components/editor/VEditorColorPicker.vue'

library.add(
  faUndo,
  faLink,
  faSquare,
  faImage,
  faRedo,
  faBold,
  faItalic,
  faUnderline,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faStrikethrough,
  faStrikethrough,
  faCode,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteLeft,
  faRulerHorizontal,
  faUpload,
  faExpandArrowsAlt,
  faFilm
)

import { locale, LocalizedField } from '@/types/typeI18n'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import { Editor, EditorContent, VueNodeViewRenderer } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Youtube from '@tiptap/extension-youtube'
import Focus from '@tiptap/extension-focus'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import UserManager from '@/database/userManager'
import { arrayUnique } from '@/helpers/arrayHelper'

const CustomYoutube = Youtube.extend({
  name: 'custom-youtube',
  addAttributes() {
    return {
      ...this.parent?.(),
      isEditable: {
        default: true
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'iframe'
      }
    ]
  },
  draggable: true,
  addCommands() {
    return {
      ...this.parent?.()
      /*unsetYoutubeVideo: () => (chain()) => {
          return chain()
            .unsetMark(this.name, { extendEmptyMarkRange: true })
            .setMeta('preventAutolink', true)
            .run()
        }*/
    }
  },
  addNodeView() {
    return VueNodeViewRenderer(VYoutubeExtension)
  }
})

const ImageResizable: any = Image.extend({
  name: 'image-resizable',
  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: '100%',
        renderHTML: (attributes: any) => {
          return {
            width: attributes.width
          }
        }
      },
      height: {
        default: 'auto',
        renderHTML: (attributes: any) => {
          return {
            height: attributes.height
          }
        }
      },
      isDraggable: {
        default: false,
        renderHTML: (attributes: any) => {
          return {}
        }
      }
    }
  },
  addOptions() {
    return {
      ...this.parent?.(),
      uploadPath: ''
    }
  },
  addNodeView() {
    return VueNodeViewRenderer(VResizableImage)
  }
})

@Component({
  components: {
    Cropper,
    VInputLocalizedText,
    VModuleElementBaseConfig,
    EditorContent,
    VImageUploadModal,
    VColorPicker
  }
})
export default class VInputEditorLocalized extends Vue {
  @Model('input', { type: Object, required: true, default: (): LocalizedField => ({ _ltType: true, locales: {} }) })
  readonly data!: LocalizedField

  @Prop({ type: String, required: false, default: () => '' })
  readonly uploadPath!: string

  @Prop({ type: String, required: false, default: () => '' })
  readonly limitHeight!: string

  @Prop({ type: Number, required: false, default: () => 1024 * 1024 /*1 MB*/ })
  readonly maxImagesize!: number

  @Prop({ type: Number, required: false, default: () => 1000 })
  readonly maxImageHeight!: number

  @Prop({ type: Number, required: false, default: () => 1000 })
  readonly maxImageWidth!: number

  @Prop({ type: Boolean, required: false, default: false })
  readonly isTranslateable!: boolean


  get editorStyle() {
    return this.limitHeight !== '' ? { maxHeight: this.limitHeight, minHeight: '30em' } : { minHeight: '30em' }
  }

  public textColor: string = ''
  // editor is not reactive when initialised in the created hook
  public editor: null | Editor = null
  initEditor() {
    if (!this.data.locales[this.formSelectedLocale]) this.$set(this.data.locales, this.formSelectedLocale, '')

    this.editor = new Editor({
      content: this.data.locales[this.formSelectedLocale],
      extensions: [
        // Bold,
        // Italic,
        // Strike,
        Underline,
        // Paragraph,
        // Heading,
        StarterKit,
        // BulletList,
        // OrderedList,
        // ListItem,
        TextStyle,
        Color,
        // HorizontalRule,
        Link.configure({
          openOnClick: false,
          autolink: false,
          HTMLAttributes: {
            contenteditable: true
          }
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph']
        }),
        ImageResizable.configure({
          inline: true,
          uploadPath: this.uploadPath
        }),
        Table.configure({
          resizable: false
        }),
        TableRow,
        TableHeader,
        TableCell,
        CustomYoutube,
        Focus.configure({
          className: 'has-focus',
          mode: 'all'
        })
      ],
      autofocus: true,
      enableInputRules: false,
      enablePasteRules: false,
      onUpdate: () => {
        if (!this.editor) return

        this.onEditorHtmUpdate(this.editor.getHTML())
      }
      /* content: '',
      onUpdate:({ editor }) => ({ getHTML }: { getHTML: () => string }) => {
        let html = editor.getHTML()
        if (html === '<p></p>') html = '' // fix <p> when content empty
        this.onEditorHtmUpdate(html)
      } */
    })
  }

  private mounted() {
    this.initEditor()
  }

  /** for some reason the html is not updated on backspace, which is confusing UX when using the preview
   * => resolved: only happens when editor is initialized in created hook
   */
  public updateHtmlOnBackspace() {
    // workaround to force update
    // this.editor.blur()
    // this.editor.focus()
  }

  public formSelectedLocale = this.$i18n.activeLocales[0]

  get availableLocales() {
    // get all configured locales and combine with locales that are already in the v-model
    return arrayUnique([
      ...Object.entries(this.data.locales).filter(([key, value]) => value !== '').map(([key, value]) => key),
      ...this.$i18n.backendEnabledLocales
    ]) as locale[]
  }

  private onEditorHtmUpdate(html: string) {
    if (!this.data.locales[this.formSelectedLocale]) this.$set(this.data.locales, this.formSelectedLocale, '')
    this.data.locales[this.formSelectedLocale] = html || ''
    this.$emit('input', this.data)
    this.updateLinksFootNotes()
  }

  @Watch('formSelectedLocale', { immediate: true })
  @Watch('data', { deep: true }) // false to not update editor on typing (cursor jumps)
  private updateEditorHtm() {
    if (!this.data.locales[this.formSelectedLocale]) this.$set(this.data.locales, this.formSelectedLocale, '')

    const localeValue: string = this.data.locales[this.formSelectedLocale]

    if (this.editor) {
      const isSame = this.editor.getHTML() === localeValue

      if (!isSame)
        this.editor.commands.setContent(localeValue)
    }

    this.updateLinksFootNotes()
  }

  @Watch('formLinkUrl', { immediate: true })
  @Watch('linkModalActive')
  private resetValidationMessage() {
    this.type = ''
    this.message = ''
  }

  public linksInText: string[] = []
  private updateLinksFootNotes() {
    this.linksInText = []
    const as = ((this.$refs.editorRef as any)?.$el as HTMLElement)?.getElementsByTagName('a')
    if (as)
      for (const a of as) {
        this.linksInText.push(a.getAttribute('href') || '')
      }
  }

  public onClearContent() {
    this.data.locales[this.formSelectedLocale] = ''
    this.updateEditorHtm()
  }

  beforeDestroy() {
    // Always destroy your editor instance when it's no longer needed
    if (this.editor) this.editor.destroy()
  }

  private command: any = null

  // IMAGE Upload

  public isImageUploadModalActive = false
  public currentImageUrl = ''
  public imageUpdate = false

  public showImagePrompt() {
    if (!this.editor) return
    this.currentImageUrl = this.editor.getAttributes('image')?.src || ''
    this.imageUpdate = !!this.currentImageUrl

    this.isImageUploadModalActive = true
    // this.command = command
  }

  /* public onUploadVariable(variable : string) {
      this.editor?.commands.insertContent(variable)
        const path = 'src/assets/editor/placeholder-image.jpeg'
      const url = new URL(path, window.location.origin)
      console.log('url ' +  url.href)
      if (this.editor) {
        this.editor.commands.setImage({ src: url.href})
      }  
    }*/

  public onImageUrl(url: string) {
    if (!this.editor) return
    // if (this.imageUpdate) {
    // this.editor.activeNodes.image.updateAttrs({ src: url, width: '50%' })
    // } else {
    //if (this.command) this.command({ src: url })
    this.editor.commands.setImage({ src: url })
    // if (this.command) this.command({ src: url, width: '50%' })
    // }
  }

  // #region Link

  public formLinkUrl = ''
  public formLinkType = 'web'
  public linkUpdate = false
  public linkModalActive = false
  public formLinkIsButton = false
  public formLinkIsSameWindow = true

  public showLinkPrompt() {
    if (!this.editor) return

    this.linkModalActive = true
    this.formLinkIsButton = false
    this.formLinkIsSameWindow = true

    const url: string = this.editor.getAttributes('link')?.href || ''
    this.linkUpdate = !!url
    this.formLinkUrl = ''
    this.formLinkType = 'web'

    const cssClass: string = this.editor.getAttributes('link')?.class || ''
    this.formLinkIsButton = !!cssClass

    const target: string = this.editor.getAttributes('link')?.target || '_self'
    this.formLinkIsSameWindow = target === '_self'

    // parse form fields from url
    if (this.linkUpdate) {
      if (url.startsWith('mailto:')) {
        this.formLinkType = 'mail'
        this.formLinkUrl = url.substring(7)
      } else if (url.startsWith('tel:')) {
        this.formLinkType = 'tel'
        this.formLinkUrl = url.substring(4)
      } else if (url.startsWith('http')) {
        this.formLinkType = 'web'
        this.formLinkUrl = url
      } else {
        this.formLinkType = 'custom'
        this.formLinkUrl = url
      }
    }

    //this.command = command
    // this.$buefy.dialog.prompt({
    //   message: 'insert link',
    //   icon: 'link',
    //   title: 'Link',
    //   inputAttrs: {
    //     placeholder: 'https://example.com',
    //     value: url,
    //     name: 'Link'
    //   },
    //   confirmText: (url) ? 'Update Link' : 'Insert Link',
    //   onConfirm: (url: string) => command({ href: addUrlSchemaIfNeeded(url) })
    // })
  }

  get linkHref() {
    return this.getHref(false)
  }

  public getHref(validate = false) {
    function addUrlSchemaIfNeeded(url: string) {
      const regex = /^(http|https|mailto:|tel:)/
      if (url.length > 3 && !url.match(regex)) {
        url = 'http://' + url
      }
      return url
    }

    let href = ''
    switch (this.formLinkType) {
      case 'web':
        href = addUrlSchemaIfNeeded(this.formLinkUrl)
        break
      case 'custom':
        href = this.formLinkUrl
        break
      case 'tel':
        href = `tel:${this.formLinkUrl}`
        break
      case 'mail':
        if (validate && !UserManager.validateEmail(this.formLinkUrl.split('?')[0])) {
          throw `Email address ${this.formLinkUrl} not valid`
        }
        href = `mailto:${this.formLinkUrl}`

        break
      default:
        break
    }

    return href
  }
  public type: string = ''
  public message: string = ''
  public href = ''

  public onSetLink() {
    if (!this.editor) return

    try {
      if (this.formLinkUrl === '') {
        this.type = 'is-danger'
        this.message = 'please fill out this field'
      } else {
        this.href = this.getHref(true)
        /*  if (this.command) this.command(
            {
              href,
              class: (this.formLinkIsButton ? 'button' : ''),
              target: (this.formLinkIsSameWindow ? '_self' : '_blank')
            }
          ) */
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({
            href: this.href,
            class: this.formLinkIsButton ? 'button' : '',
            target: this.formLinkIsSameWindow ? '_self' : '_blank'
          } as any)
          .run()
        this.linkModalActive = false
      }
    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
    }
  }

  public onRemoveLink() {
    if (!this.editor) return

    this.formLinkUrl = ''
    /* if (this.command) this.command({ href: '' }) */
    this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
    this.linkModalActive = false
  }
  // #endregion Link

  // #region video
  public videoModalActive = false
  public videoLinkUrl = ''
  //public videoLinkUpdate = false
  public removeYouTubeLink = false
  public videoType = ''
  public videoMessage = ''
  public youtubeRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  public setVideoUrl() {
    this.videoModalActive = true
    if (this.videoLinkUrl !== '') {
      //this.videoLinkUpdate = true
      this.removeYouTubeLink = true
    }
  }
  public onSetYoutubeLink() {
    if (this.videoLinkUrl.match(this.youtubeRegex)) {
      this.editor?.commands.setYoutubeVideo({
        src: this.videoLinkUrl
      })
      this.videoModalActive = false
      this.videoLinkUrl = ''
    } else {
      this.videoType = 'is-danger'
      this.videoMessage = 'please enter a valid youtube url'
    }
    if (this.videoLinkUrl == '') {
      this.videoType = 'is-danger'
      this.videoMessage = 'field is empty'
    }
  }

  @Watch('videoLinkUrl', { immediate: true })
  public resetVideoLinkUrl() {
    this.videoType = ''
    this.videoMessage = ''
  }
  // #endregion video
}
