export const getRegex = () => {
  // https://regexr.com/4jk1n
  return new RegExp('(([eac][a-zA-Z0-9]{4})(-[a-zA-Z0-9]{5}){3})', 'gm')
}

export const DEFAULT_CODE_VERSION = 'e'

export function createID(codeVersion = DEFAULT_CODE_VERSION) {
  function formatToID(formatString: string) {
    // const charList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    // removed uppercase and chars that might be confused (l1i, o0). Removed aeiou for curse words
    // 28^19 = 3E27 possibilities
    const charList = 'bcdfghjkmnpqrstvwxyz23456789'
    const dt = Date.now()
    return formatString.replace(/[x]/g, (c) =>
      charList.charAt((dt + Math.random() * charList.length) % charList.length | 0)
    )
  }

  const uuid = codeVersion + formatToID('xxxx-xxxxx-xxxxx-xxxxx')
  return uuid
}
