import db from '@/firebase'

import BaseManager from './baseManager'

import databaseSchema from './databaseSchema'
import { DeepPartial, hasDBid, objectID } from '@/types/typeGeneral'
import { BatchDB } from '@/types/typeBatch'
import moment from 'moment'

export default class BatchManager extends BaseManager {
  public static defaultDocDB: BatchDB = databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__EMPTY_DOC__

  public static generatePrintExportTxt(batch: BatchDB & hasDBid) {
    return `
Begleitdokument ECHO Code Sticker Druckauftrag

----
Datum Übermittlung Druckauftrag:\t${moment().format('L')}
Verantwortlich ECHO PRM:\t${batch.responsible}
ECHO PRM internal ID:\t${batch.id}
ECHO PRM Bestellnummer:\t${batch.name}
ECHO PRM Batchnummer (falls abweichend):\t-
zu verwendendes Masterlayout:\t${batch.print.layoutNo}
Gesamtstückzahl Sticker:\t${batch._computed.asidCount}
----

${batch.print.notesExported}
`
  }

  public static async update(batchID: objectID, authEmail: string, fields: DeepPartial<BatchDB> = {}) {
    return this.updateDoc(this.getDbDocReference(batchID), authEmail, fields)
  }

  public static async add(authEmail: string, fields: DeepPartial<BatchDB> = {}) {
    return this.addDoc(this.getDbCollectionReference(), authEmail, fields, this.defaultDocDB)
  }

  public static async get(batchID: objectID) {
    return this.getDocHelper<BatchDB>(this.getDbDocReference(batchID))
  }

  public static getDbDocReference(batchID: objectID) {
    return db.doc(databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__DOCUMENT_PATH__(batchID))
  }

  public static getDbCollectionReference() {
    return db.collection(databaseSchema.COLLECTIONS.ADMIN.BATCH.BATCHES.__COLLECTION_PATH__())
  }
}
