import { ModuleType } from '@/modules/typeModules'

import { RouteConfig, RawLocation } from 'vue-router'
import BaseModule from '@/modules/baseModule'

import { I18nModuleDB, I18nElementDB, RequiredI18nPrivileges, I18nGroupDB } from './typeI18nModule'

import {
  defaultI18nModuleDB,
  defaultI18nGroupDB,
  defaultI18nElementDB
} from '@/database/databaseSchema'
import { UserPrivilegeIdDB } from '@/types/typeUser'

export default class I18nModule extends BaseModule {
  public static type: ModuleType = 'I18n'
  public static displayName = 'Language'
  public static color = '#ab5aa8'
  public static description = 'Enables Multilanguage Support.'
  public static hasWidget = false

  public static authPrivileges: RequiredI18nPrivileges = {
    r: ['i18n:read'],
    w: ['i18n:write'],
    view: ['i18n:view']
  }

  public static moduleDB: I18nModuleDB = defaultI18nModuleDB
  public static elementDB: I18nElementDB = defaultI18nElementDB
  public static groupDB: I18nGroupDB = defaultI18nGroupDB

  constructor() {
    super()
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return [
      {
        to: { name: 'module-i18n-list' },
        displayName: this.displayName
      }
    ]
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'module/i18n',
        name: this.routeNameList,
        component: () => import('./Backend_Module_I18n_List.vue'),
        meta: {
          label: this.displayName,
          description:
            'Manage the different languages of your content. Choose the languages you want to use and translate directly or export, translate and import',
          breadcrumbs: false,
          // isFullsize: true,
          helpSection: this.type
        }
      }
    ]
  }
}
