import dayjs from 'dayjs'
import firebase from 'firebase/compat/app'
import { CollectionReference, DocumentData } from 'firebase-admin/firestore'
import { locale } from '@/types/typeI18n'
import { getLocalizedObjectLocale } from '../../helpers/i18nUtil'

// i18n added so it will be recomputed once the active locale changes
export function getLastUpdatedString(d: { _seconds: number } | null, appActiveLocale: locale[]) {
  // use moment to create a last updated string. when under 1 minute, show 'just now', otherwise show 'x minutes ago', if over 1 hour, show the date and time
  if (!d) return ''
  const then = dayjs(new Date(d._seconds * 1000))
  const now = dayjs(new Date())
  const diff = now.diff(then, 'minutes')

  // localise the string
  const i18n = {
    en: {
      just_now: 'just now',
      minutes_ago: 'minutes ago'
    },
    de: {
      just_now: 'gerade eben',
      minutes_ago: 'Minuten her'
    },
    fr: {
      just_now: 'juste maintenant',
      minutes_ago: 'minutes ago'
    },
    it: {
      just_now: 'proprio ora',
      minutes_ago: 'minuti fa'
    },
    es: {
      just_now: 'justo ahora',
      minutes_ago: 'minutos atrás'
    },
    pl: {
      just_now: 'właśnie teraz',
      minutes_ago: 'minut temu'
    },
    pt: {
      just_now: 'agora mesmo',
      minutes_ago: 'minutos atrás'
    },
    hr: {
      just_now: 'upravo sada',
      minutes_ago: 'minuta prije'
    }
  }

  const locale = getLocalizedObjectLocale(
    { locales: i18n as any, _ltType: true },
    appActiveLocale,
    'auto'
  ) as keyof typeof i18n

  if (!locale) 'en'

  const localeStringObject = i18n[locale]
  console.log('locale', appActiveLocale, localeStringObject)

  if (diff < 1) {
    return localeStringObject['just_now']
  } else if (diff < 60) {
    return `${diff} ${localeStringObject['minutes_ago']}`
  } else if (diff < 1440) {
    return then.format('HH:mm')
  } else {
    return then.format('YYYY.MM.DD')
  }
}

// create a unique incident ID
export async function createIncidentId(
  collectionRef:
    | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    | CollectionReference<DocumentData>,
  warnLogger: (msg: string) => void
) {
  // create a human readable incident id
  const formatToID = (formatString: string) => {
    // const charList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    // removed uppercase and chars that might be confused (l1i, o0). Removed aeiou for curse words
    // 28^19 = 3E27 possibilities
    const charList = 'bcdfghjkmnpqrstvwxyz23456789'
    const dt = Date.now()
    return formatString.replace(/[x]/g, (c) =>
      charList.charAt((dt + Math.random() * charList.length) % charList.length | 0)
    )
  }

  // validate that no other incident has the same id
  async function validateIdNotTaken(retries = 0): Promise<string> {
    const docID = formatToID('xxxxxx')

    const incidentIDDocRef = collectionRef.doc(docID)
    const incidentIDDoc = await incidentIDDocRef.get()
    if (incidentIDDoc.exists) {
      if (retries > 10) throw 'could not create unique ticket id after 10 retries'

      warnLogger(`incidentID ${docID} already exists`)

      // try again
      return await validateIdNotTaken(retries + 1)
    } else {
      return docID
    }
  }

  return await validateIdNotTaken()
}
