import { storage } from '@/firebase'

import Compressor from 'compressorjs'
import { uniqueID } from '@/database/dbHelper'

import deepmerge from 'deepmerge'
import { handlePromiseError } from './notificationHelper'

export default class StorageManager {
  public static returnFileSize(size: number) {
    if (size >= 1099511627776) {
      return (size / 1099511627776).toFixed(1) + 'TB'
    } else if (size >= 1073741824) {
      return (size / 1073741824).toFixed(1) + 'GB'
    } else if (size >= 1048576) {
      return (size / 1048576).toFixed(1) + 'MB'
    } else if (size >= 1024) {
      return (size / 1024).toFixed(1) + 'KB'
    } else {
      return size + 'bytes'
    }
  }

  public static validateFileSize(file: File, maxFileSize: number) {
    if (file.size > maxFileSize)
      throw `file size ${this.returnFileSize(file.size)} exceeds max file size ${this.returnFileSize(maxFileSize)}`
  }

  public static validateFileTypes(file: File, allowedFileType: readonly string[] = []) {
    if (allowedFileType.length > 0 && !allowedFileType.includes(file.type))
      throw `file type ${file.type} is not supported. Use one of (${allowedFileType.join(',')})`
  }

  /**
   *
   * @param uploaderEmail
   * @param file
   * @param fileName
   * @param uploadPath
   * @param progress in percent 0..100
   * @param maxFileSize
   * @param allowedFileTypes
   * @param metadata
   * @param uploaderDocumentPath
   * @param dontModify
   * @returns url of uploaded file
   */
  public static uploadImage(
    uploaderEmail: string,
    file: File,
    uploadPath: string,
    progress: (progress: number) => void,
    onWarning: (warning: string) => void,
    targetFormat: string,
    allowedFormats: readonly string[] = [
      'image/bmp',
      'image/gif',
      'image/jpeg',
      'image/pjpeg',
      'image/png',
      'image/svg+xml',
      'image/svg'
    ] as const,
    uploaderDocumentPath: string,
    maxFileSize = 1024 * 1024,
    maxImageWidth = 1024,
    maxImageHeight = 1024,
    dontModify = false
  ): Promise<string> {
    return new Promise<string>((res, rej) => {
      const processFileBlob = async (resultBlob: Blob, dontModify = false) => {
        let fileObj = new File([resultBlob], file.name, { type: targetFormat })
        try {
          StorageManager.validateFileSize(fileObj, maxFileSize)
        } catch (e: any) {
          // image size still too big, try reducing its size
          console.log(e, '. Image size still to big, reduce resolution')

          if (dontModify) {
            maxFileSize = 1024 * 1024 * 10 //10MB
            onWarning('image exceeds 1MB. This may decrease the app loading performance')
          } else {
            onWarning('image exceeds 1MB. It is compressed automatically')
            const imageCompression = (await import('browser-image-compression')).default

            fileObj = (await imageCompression(fileObj, {
              maxSizeMB: maxFileSize / (1024 * 1024)
            })) as File
          }
        }

        await StorageManager.uploadFile(
          uploaderEmail,
          fileObj,
          file.name,
          uploadPath,
          (p) => {
            progress(p * 100)
          },
          maxFileSize,
          allowedFormats,
          {},
          uploaderDocumentPath
        )
          .then((url) => {
            console.log(url)
            res(url)
          })
          .catch((e) => rej(e))
      }

      if (dontModify) {
        processFileBlob(file, dontModify).catch((e) => rej(e))
      } else {
        // compress file
        new Compressor(file, {
          quality: 0.8,
          maxWidth: maxImageWidth,
          maxHeight: maxImageHeight,

          success: (file) => {
            processFileBlob(file).catch((e) => rej(e))
          },
          error: (err) => {
            rej(err)
          }
        })
      }
    })
  }

  /**
   *
   * @param uploaderEmail
   * @param file
   * @param fileName
   * @param uploadPath
   * @param progress
   * @param maxFileSize
   * @param allowedFileTypes
   * @param metadata
   * @param uploaderDocumentPath
   * @returns url of uploaded file
   */
  public static uploadFile(
    uploaderEmail: string,
    file: File,
    fileName: string,
    uploadPath: string,
    progress: (progress: number) => void,
    maxFileSize = 1024 * 1024,
    allowedFileTypes: readonly string[] = [],
    metadata?: firebase.default.storage.UploadMetadata,
    uploaderDocumentPath?: string
  ): Promise<string> {
    this.validateFileSize(file, maxFileSize)
    this.validateFileTypes(file, allowedFileTypes)

    metadata = deepmerge(metadata || {}, { customMetadata: { uploaderEmail, uploaderDocumentPath } })
    return new Promise<string>((res, rej) => {
      // const storageRef = storage.ref(`${file.name}`).put(file)
      const storageRef = storage.ref(`${uploadPath}/${fileName}`).put(file, metadata)

      storageRef.on(
        'state_changed',
        (snapshot) => {
          progress(snapshot.bytesTransferred / snapshot.totalBytes)
        },
        (error) => {
          rej('error in uploadFile [20210910]: ' + error.message)
        },
        () => {
          storageRef.snapshot.ref
            .getDownloadURL()
            .then((url: string) => res(url))
            .catch((e) => rej('error in uploadFile [20210911]: ' + e))
        }
      )
    })
  }

  public static async appUploadImage(
    uploaderEmail: string,
    file: File,
    uploadPath: string,
    progress: (progress: number) => void,
    maxFileSize = 1024 * 1024 * 2, // in Bytes -> 2 MB
    maxWidth = 2024,
    maxHeight = 2024,
    compressQuality = 0.9,
    allowedFileTypes: string[] = ['image/bmp', 'image/gif', 'image/jpeg', 'image/pjpeg', 'image/png'],
    uploaderDocumentPath?: string
  ) {
    this.validateFileTypes(file, allowedFileTypes)
    return new Promise<string>((res, rej) => {
      // compress file
      new Compressor(file, {
        quality: compressQuality,
        maxWidth,
        maxHeight,
        mimeType: 'image/jpeg',

        success: (resultBlob) => {
          handlePromiseError(async () => {
            let compressedFile = new File([resultBlob], file.name, { type: 'image/jpeg' })

            try {
              this.validateFileSize(compressedFile, maxFileSize)
            } catch (e: any) {
              // image size still too big, try reducing its size
              console.log(e, '. Image size still to big, reduce resolution')

              const imageCompression = (await import('browser-image-compression')).default

              compressedFile = (await imageCompression(compressedFile, {
                maxSizeMB: maxFileSize / (1024 * 1024)
              })) as File
            }

            this.validateFileSize(compressedFile, maxFileSize)

            const fileName = `${uniqueID()}.jpeg`

            this.uploadFile(
              uploaderEmail,
              compressedFile,
              fileName,
              uploadPath,
              progress,
              maxFileSize,
              allowedFileTypes,
              {
                contentType: 'image/jpeg'
              },
              uploaderDocumentPath
            )
              .then((url) => res(url))
              .catch((e) => rej(e))
          })
        },
        error: (err) => {
          rej(err)
        }
      })
    })
  }
}
