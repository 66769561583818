
import { Component } from 'vue-property-decorator'


import VFormAddress from '@/components/VFormAddress.vue'
import VInputUserRolesTags from '@/components/VInputUserRolesTags.vue'
import VInputMultiUserManagementView from '@/components/VInputMultiUserManagementView.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay, faPlus, faMinus, faEnvelope, faTag } from '@fortawesome/free-solid-svg-icons'


import VAdminTenantSelectionView from './components/VAdminTenantSelectionView.vue'
import VAdminExportAsidCodes from './components/VAdminExportAsidCodes.vue'
import { cloneObject } from '@/helpers/dataShapeUtil'

import { BatchDB } from '@/types/typeBatch'
import { hasDBid } from '@/types/typeGeneral'
import BatchManager from '@/database/batchManager'
import VAdminCreateAsidModal from './components/VAdminCreateAsidModal.vue'
import moment from 'moment'
import TenantManager from '@/database/tenantManager'
import AsidManager from '@/database/asidManager'
import { AsidDB } from '@/types/typeAsid'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'

library.add(faCalendarDay, faPlus, faMinus, faEnvelope, faTag, faCalendarDay)


@Component({
  components: {
    VFormAddress,
    VInputUserRolesTags,
    VInputMultiUserManagementView,
    VAdminTenantSelectionView,
    VAdminExportAsidCodes,
    VAdminCreateAsidModal
  },
  props: {
    id: {
      type: String,
      required: true,
      default: () => 'new'
    }
  }
})
export default class AdminBatchSingle extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public showCreateAsidsModal = false
  public showUpdateTemplateAsidsModal = false

  public isLoading: boolean = false
  public formAvailableTypes = [{ value: 'Print', key: 'print' }, { value: 'Other', key: 'other' }]

  public batchDB: BatchDB & hasDBid = cloneObject({ ...BatchManager.defaultDocDB, id: '' })

  public get exportTxt() {
    return BatchManager.generatePrintExportTxt(this.batchDB)
  }

  public async onGenerateName() {
    this.isLoading = true
    if (this.batchDB.tenantID) {
      const tenant = await TenantManager.get(this.batchDB.tenantID)

      this.batchDB.name = `${moment().format('D-M-YYYY')}_${tenant.name}`
    } else {
      this.batchDB.name = `${moment().format('D-M-YYYY')}_generic`
    }
    this.isLoading = false
  }

  public checkAsidsResult = {
    tenantIDs: {},
    templateURLs: {},
    checked: false
  }

  private async onCheckAsids() {
    await this.checkAsids()
  }

  private async checkAsids() {

    try {
      this.isLoading = true
      this.checkAsidsResult.checked = false
      this.checkAsidsResult.tenantIDs = {}
      this.checkAsidsResult.templateURLs = {}

      const asidDocs = await AsidManager.getWhere<AsidDB>({ batchIDs: [] }, 'array-contains', this.batchDB.id)

      const incrementProp = (obj: any, prop?: string | null) => {
        prop = prop || 'none'

        if (!obj[prop])
          this.$set(obj, prop, 0)

        obj[prop]++
      }

      asidDocs.forEach(ad => {
        incrementProp(this.checkAsidsResult.tenantIDs, ad.tenantID)
        incrementProp(this.checkAsidsResult.templateURLs, ad.codeConfig.svgTemplateUrl)
      })

      this.checkAsidsResult.checked = true
    } catch (error: any) {
      this.$helpers.notification.Error(`Error occured while checking Asid codes: ${error.toString()}`)
    } finally {
      this.isLoading = false
    }

  }

  private async onAssignAsidsToTenant() {

    if (!this.batchDB.tenantID || this.batchDB._computed.asidCount === 0) {
      this.$helpers.notification.Warn('No Tenant selected or no Asids available')
      return
    }

    try {
      this.isLoading = true
      const asidDocs = await AsidManager.getWhere<AsidDB>({ batchIDs: [] }, 'array-contains', this.batchDB.id)

      await AsidManager.assignAsids(this.batchDB.tenantID, this.$auth.userEmail, asidDocs.map(a => a.id))
        .then(d => {
          this.$helpers.notification.Success(`${asidDocs.length} Asid codes Assigned`)
        })
    } catch (error: any) {
      this.$helpers.notification.Error(`Error occured while assigning Asid codes: ${error.toString()}`)
    } finally {
      this.isLoading = false
    }

  }

  public async updateBatch() {
    // validation
    let isValid = true
    // if (this.batch.name.length < 2) {
    //   this.$helpers.notification.Warn('Batch name to short')
    //   isValid = false
    // }
    if (!isValid) return

    this.isLoading = true


    let batchID: string = this.$props.id
    if (this.$props.id === 'new') {
      try {
        batchID = (await BatchManager.add(this.$auth.userEmail, this.batchDB)).id

        this.$helpers.notification.Success('Batch created')
        await this.$router.push({ name: 'batches-single', params: { id: batchID } })
      } catch (e: any) {
        this.$helpers.notification.Error('Error creating Batch ' + e)
      }
    } else {
      await BatchManager.update(this.batchDB.id, this.$auth.userEmail, this.batchDB)
    }

    this.$helpers.notification.Success('Batch saved')

    this.isLoading = false
  }


  public async created() {
    this.isLoading = true

    this.batchDB = cloneObject({ ...BatchManager.defaultDocDB, id: '' })

    if (this.$props.id !== 'new') {

      try {
        await this.$bindSnapshot('batchDB', BatchManager.getDbDocReference(this.$props.id))

      } catch (e: any) {
        this.$helpers.notification.Error(e.toString())
      }
    }

    this.isLoading = false

  }

}
