import { VueConstructor } from 'vue'

import { allUserPrivileges, UserPrivilegeIdDB, UserReferenceDB } from '@/types/typeUser'

import { TenantDB, TenantID } from '@/types/typeTenant'
import { firebase } from '@/firebase'
import notificationHelper, { handlePromiseError } from './notificationHelper'
import { viewPrivileges, dbPrivileges } from './privileges'
import localstorage from './localstorage'
import { ModalProgrammatic } from 'buefy'

import VFormLoginTenantSelectorModalView from '@/components/VFormLoginTenantSelectorModalView.vue'
import VModalTOS from '@/components/VModalTOS.vue'
import TenantManager from '@/database/tenantManager'
import UserManager from '@/database/userManager'

import TosManager from '@/database/tosManager'
import databaseSchema, { defaultTenantDB } from '@/database/databaseSchema'
import router from '@/pages/backend/router'
import md5 from 'md5'
import { intersectSome, intersectEvery } from './arrayHelper'
import { hasDBid } from '@/types/typeGeneral'
import { LocalSettingsGlobalsInst } from '@/modules/_globals/localSettingsGlobals'
import { encodeEmailToKey } from '@/database/encodeEmailToKey'
import { cloneObject } from './dataShapeUtil'
import { modulePrivileges } from './privilegeHelper'

export interface AuthUser {
  email: string
  name: string
}

class Deferred {
  public promise: Promise<unknown>
  public reject!: (reason?: any) => void
  public resolve!: (value?: unknown) => void
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject
      this.resolve = resolve
    })
  }
}

const onUser = async (cb: (user: AuthUser | null) => void) => {
  if (window.location.hash == '#testAuth') {
    console.warn('testAuth activated')
    localstorage.set('dev.testAuth', true, Boolean)
  }
  if (window.location.hash.toLowerCase() == '#noTestAuth'.toLowerCase()) {
    console.warn('testAuth deactivated')
    localstorage.set('dev.testAuth', false, Boolean)
  }

  if (process.env.VUE_APP_FIREBASE_ENV === 'emulator') {
    console.log('VUE_APP_FIREBASE_ENV', process.env.VUE_APP_FIREBASE_ENV)
    // console.warn('url set #testAuth or #noTestAuth to login with test@glumb.de automatically')
  }

  // await timeout(3000)
  if (localstorage.get('dev.testAuth', false, Boolean)) {
    console.warn('testAuth active')
    cb({
      email: 'admin@glumb.de',
      name: 'Emu Admin User'
    })
  } else {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        cb(null)
        return
      }

      if (user.emailVerified) {
        cb({ email: user.email || '', name: user.displayName || '' })
        console.log('Email is verified')
      } else {
        cb(null)
        console.log('Email is not verified')
        await user.sendEmailVerification().then(async () => {
          notificationHelper.Warn({
            duration: 30000,
            message: `Your email adress is not verified yet. A link has been sent to ${user.email}. Check your email to activate the account.`
          })
          await firebase.auth().signOut()
          console.log('email verification sent to user')
        })
      }
    })
    firebase.auth().useDeviceLanguage()
  }
  // await timeout(3000)
  // user = {
  //   email: 'beck@glumb.de',
  //   name: 'Maxi Beck'
  // }
  // if (cb) cb(user)
  // resolve(user)
}

export class BaseAuth {
  public privileges = viewPrivileges
  public dbPrivileges = dbPrivileges
  protected userLoaded = false

  protected state: {
    userPrivileges: UserPrivilegeIdDB[]
    user: UserReferenceDB | null
    tenant: (TenantDB & hasDBid) | null
  }
  protected waitForUserPomises: Deferred[] = []

  constructor(Vue: VueConstructor) {
    this.state = Vue.observable({ user: null, tenant: null, userPrivileges: [] })
    console.log('created')

    onUser((user) => {
      handlePromiseError(this.onUserChanged(user))
    })
      .then(() => {
        console.log('onUser resolved')
      })
      .catch((e) => {
        console.error('onUser error', e)
      })
  }

  protected async onUserChanged(user: AuthUser | null) {
    console.log('userchaged')

    if (!user) {
      this.state.tenant = null
      this.state.user = null
      this.resolveWaitForUserListeners()
    } else {
      // Vue.set(this, 'user', U)
      // this.user = Object.assign({}, this.user, U)
      const userMail = user.email
      return TenantManager.getTenantsByUserEmail(userMail).then(
        async (docs) => {
          // todo get batter name for docs and doc.data() which is the actual data of the doc
          const tenantDocs = docs.map((d) => ({ ...(d.data() as TenantDB), id: d.id }))
          const preselectedTenantID = LocalSettingsGlobalsInst.localSettingsGlobal.login.selectedTenantID

          // if tenantid is in the url query, use it
          const urlParams = new URLSearchParams(window.location.search)
          const queryParamTenantID = urlParams.get('tenant-id')

          if (queryParamTenantID) {
            const preselectedTenant = tenantDocs.find((td) => td.id === queryParamTenantID)

            // true if the user is in the user collection of this tenant
            if (preselectedTenant) {
              await this.tenantSelected(preselectedTenant, preselectedTenant.id, user.email)
            } else if (this.isEchoPrmStaffEmail(userMail)) {
              // set the user here as he/she is not be in the user collection of this tenant
              this.state.user = {
                ...databaseSchema.COLLECTIONS.TENANTS.USERS.__EMPTY_DOC__,
                privileges: [...allUserPrivileges, ...Object.values(modulePrivileges)],
                email: userMail,
                name: user.name
              }
              // get the tenant by the id
              await TenantManager.get(queryParamTenantID)
                .then(async (doc) => {
                  await this.tenantSelected(doc, doc.id, user.email, true)
                })
                .catch((e) => notificationHelper.Error(e.toString()))
            } else {
              notificationHelper.Error('No Tenant found for ' + userMail + ' with id ' + queryParamTenantID)
              this.state.user = {
                ...databaseSchema.COLLECTIONS.TENANTS.USERS.__EMPTY_DOC__,
                email: userMail,
                name: user.name
              } // set user last to make sure other props are set
            }
          } else if (tenantDocs.length === 0) {
            notificationHelper.Error('No Tenant found for ' + userMail)
            this.state.user = {
              ...databaseSchema.COLLECTIONS.TENANTS.USERS.__EMPTY_DOC__,
              email: userMail,
              name: user.name
            } // set user last to make sure other props are set
          } else if (tenantDocs.length > 1) {
            const preselectedTenant = tenantDocs.find((td) => td.id === preselectedTenantID)
            if (preselectedTenant) {
              await this.tenantSelected(preselectedTenant, preselectedTenant.id, user.email)
            } else {
              ModalProgrammatic.open({
                component: VFormLoginTenantSelectorModalView,
                // parent: this,
                props: {
                  tenants: tenantDocs
                },
                onCancel: async () => {
                  await this.logout()
                },
                events: {
                  selected: async (tenant: TenantDB & hasDBid) => {
                    LocalSettingsGlobalsInst.localSettingsGlobal.login.selectedTenantID = tenant.id
                    await this.tenantSelected(tenant, tenant.id, user.email)
                  }
                }
              })
            }
          } else if (tenantDocs.length === 1) {
            await this.tenantSelected(tenantDocs[0], tenantDocs[0].id, user.email)
          }
        },
        (e) => notificationHelper.Error(e.toString())
      )
    }
  }

  private daysBetween(date1: number, date2: number) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2)

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY)
  }

  private async tenantSelected(tenantDB: TenantDB, id: TenantID, userEmail: string, skipUser = false) {
    notificationHelper.InfoToast(`Tenant ${tenantDB.name} loaded`)

    // if the user is a super admin he might not be in the user collection of this tenant
    if (!skipUser) this.state.user = await UserManager.getUserByEmail(id, userEmail) // set user last to make sure other props are set

    this.resolveWaitForUserListeners()

    const showTermsAcceptanceModal = (type: 'odp' | 'terms') => {
      return new Promise<void>((res, rej) => {
        if (TosManager.areTOSAccepted(tenantDB[type], type)) {
          res()
          return
        }

        const termsName = type === 'odp' ? 'Order Data Processing' : 'Terms Of Service'

        const showNotification = () => {
          const acceptanceDate = TosManager.daysUntilToAcceptTOS(tenantDB[type])
          if (acceptanceDate > 0) {
            notificationHelper.Warn(
              `${termsName} have not been accepted by your tenant. Accept them within ${acceptanceDate} days.`
            )
            res()
          } else {
            notificationHelper.Error(
              `${termsName} have not been accepted by your tenant. Accept them to acess the backend.`
            )
            rej()
          }
        }

        if (this.state.user?.privileges.includes(dbPrivileges.TOS_ACCEPT)) {
          ModalProgrammatic.open({
            component: VModalTOS,
            // parent: this,
            props: {
              terms: tenantDB[type],
              type
            },
            onCancel: () => {
              //
            },
            events: {
              accepted: async () => {
                await TosManager.acceptTerms(id, userEmail, tenantDB[type], type).catch(() =>
                  notificationHelper.Error(`${termsName} could not be accepted - internal error`)
                )

                res()
              },
              closed: () => {
                showNotification()
                rej()
              }
            }
          })
        } else {
          showNotification()
        }
      })
    }
    try {
      await showTermsAcceptanceModal('terms')
      await showTermsAcceptanceModal('odp')

      this.state.tenant = { ...tenantDB, id }
    } catch (e: any) {
      //
    }
  }

  protected resolveWaitForUserListeners() {
    this.userLoaded = true
    this.waitForUserPomises.forEach((def) => def.resolve())
    this.waitForUserPomises = []
  }

  public async waitForUser() {
    if (this.userLoaded) return

    const def = new Deferred()
    this.waitForUserPomises.push(def)

    return def.promise
  }

  public async loginEmailPassword(email: string, password: string) {
    // if (process.env.VUE_APP_FIREBASE_ENV === 'emulator') {
    //   await timeout(100)
    //   console.warn('emulator auth active')
    //   return this.onUserChanged({ email, name: email.split('@')[0] })
    // } else {
    return firebase.auth().signInWithEmailAndPassword(email, password)
    // }
  }

  public async deleteUser() {
    const user = firebase.auth().currentUser

    if (user) return user.delete()
  }

  public async setPersistence(persist: boolean) {
    // if (process.env.VUE_APP_FIREBASE_ENV === 'emulator') return
    if (persist) {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      console.log('persist local')
    } else {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      console.log('persist session')
    }
  }

  public async registerEmailPassword(email: string, password: string) {
    // console.log('registerEmailPassword',email,password)

    return firebase.auth().createUserWithEmailAndPassword(email, password)
    // .then(data => {
    //   //  data.user
    //   //    .updateProfile({
    //   //      displayName: this.form.name
    //   //    })
    //   //    .then(() => {})
    // })
    // .catch(err => {
    //   //  this.error = err.message
    // })
  }

  public async sendPwResetLink(email: string) {
    return firebase.auth().sendPasswordResetEmail(email)
  }

  public async logout() {
    this.state.user = null
    this.state.tenant = null
    this.state.userPrivileges = []

    if (localstorage.get('dev.testAuth', false, Boolean)) {
      //
    } else {
      return firebase.auth().signOut()
    }
  }

  public createRegistrationCode(email: string, companyName: string) {
    return md5(email + 'email' + companyName)
  }

  public validateRegistrationCode(registrationCode: string, email: string, companyName: string) {
    return this.createRegistrationCode(email, companyName) === registrationCode
  }

  public getBackendUserRegistrationLink(email: string, companyName = '') {
    const props = router.resolve({
      name: 'register',
      query: {
        email,
        companyname: companyName,
        registrationcode: this.createRegistrationCode(email, companyName)
      }
    })

    return `${process.env.VUE_APP_URL_PROTOCOL}${process.env.VUE_APP_BACKEND_BASE_URL}${props.href}`
  }

  public userHasSomePrivilege(privileges: Array<UserPrivilegeIdDB>) {
    return intersectSome(this.userPrivileges, privileges)
  }

  public userHasAllPrivilege(privileges: Array<UserPrivilegeIdDB>) {
    return intersectEvery(privileges, this.userPrivileges)
  }

  public userHasPrivilege(privilege: UserPrivilegeIdDB) {
    return this.userPrivileges.includes(privilege)
  }

  get user() {
    return this.state.user
  }

  get userPrivileges() {
    return this.state.user?.privileges || []
  }

  get userEmail() {
    return this.state.user?.email || ''
  }

  get userName() {
    return this.state.user?.name || ''
  }

  get userID() {
    return encodeEmailToKey(this.state.user?.email || '')
  }

  get tenant() {
    return this.state.tenant || cloneObject({ ...defaultTenantDB, id: '' })
  }

  get tenantName() {
    return this.state.tenant?.name || ''
  }

  get tenantID() {
    return this.state.tenant?.id || ''
  }

  get isEchoPrmStaff() {
    return this.isEchoPrmStaffEmail(this.userEmail)
  }

  private isEchoPrmStaffEmail(email: string) {
    return email.endsWith('@echoprm.com')
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $auth: BaseAuth
  }
}
