import { render, staticRenderFns } from "./VTooltipIconHelp.vue?vue&type=template&id=9dcc553e&functional=true&"
import script from "./VTooltipIconHelp.vue?vue&type=script&lang=ts&"
export * from "./VTooltipIconHelp.vue?vue&type=script&lang=ts&"
import style0 from "./VTooltipIconHelp.vue?vue&type=style&index=0&id=9dcc553e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports