import db, { functions } from '@/firebase'

import { TenantID } from '@/types/typeTenant'
import BaseManager from './baseManager'
import { ApiUserReferenceDB } from '@/types/typeUser'

import databaseSchema from './databaseSchema'
import { DeepPartial, objectID } from '@/types/typeGeneral'

import { RPCRequestAddApiUser, RPCResponseAddApiUser } from '@/types/typeRPC'

export default class ApiUserManager extends BaseManager {
  public static defaultDocDB: ApiUserReferenceDB = databaseSchema.COLLECTIONS.TENANTS.APIUSERS.__EMPTY_DOC__

  public static add(tenantID: TenantID, apiUserName: string) {
    const addApiUserRPC = functions.httpsCallable('addApiUser')

    const addApiUserRPCData: RPCRequestAddApiUser = {
      apiUserName,
      tenantID
    }

    return addApiUserRPC(addApiUserRPCData).then((response) => response.data as RPCResponseAddApiUser)
  }

  public static async remove(tenantId: TenantID, userID: objectID) {
    return this.getDbDocReference(tenantId, userID).delete()
  }

  public static async update(
    tenantId: TenantID,
    authEmail: string,
    userID: objectID,
    fields: DeepPartial<ApiUserReferenceDB> = {}
  ) {
    return this.updateDoc(this.getDbDocReference(tenantId, userID), authEmail, fields)
  }

  public static getDbDocReference(tenantId: TenantID, userID: objectID) {
    return db.doc(databaseSchema.COLLECTIONS.TENANTS.APIUSERS.__DOCUMENT_PATH__(tenantId, userID))
  }

  public static getDbCollectionReference(tenantId: TenantID) {
    return db.collection(databaseSchema.COLLECTIONS.TENANTS.APIUSERS.__COLLECTION_PATH__(tenantId))
  }
}
