
import { Component, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons'


import AsidManager from '@/database/asidManager'

import VRecordMeta from '@/components/VRecordMeta.vue'
import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'

import BackendConfigManager from '@/database/backendConfigManager'
import { BackendConfigDB } from '@/types/typeBackendConfig'
import databaseSchema, { defaultCodeConfig } from '@/database/databaseSchema'
import VFormDataDefinition from '@/components/VFormDataDefinition.vue'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { CategoryID } from '@/types/typeCategory'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import VFormCategoryEntryDefinition from '@/components/VFormCategoryEntryDefinition.vue'
import VInputMultiCategoryEntry from '@/components/VInputMultiCategoryEntry.vue'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'

library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faChevronUp, faChevronDown)

interface Workflow {
  title: string
  description: string
  categories: CategoryID[]
  steps: WorkflowStep[]
  completeStep: {
    title: string
    description: string

    setCategory: CategoryID
  }
}

interface WorkflowStep {
  title: string
  description: string
  showWithPreviousStep: boolean
  order: number
  action: {
    type: 'workflowStepType_setCategory' | 'workflowStepType_setAttribute' | 'workflowStepType_setIdentifier'
    mandatory: boolean
    value: string // references the id of the dataDefiniton. e.g. i1 for identifier 1, a5 for attribute 5
  }
}

@Component({
  components: {
    SlickList,
    SlickItem,
    VRecordMeta,
    VFormConfigureSticker,
    VFormDataDefinition,
    VInputMultiCategorySelection,
    VFormCategoryEntryDefinition,
    VInputMultiCategoryEntry
  },
  directives: {
    handle: HandleDirective
  }
})
export default class BackendConfigAsid extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {

  public testCatSelection: CategoryID[] = ['featureExt2Id', 'featureInt3Id']

  public isOpen = -1

  public AsidManager = AsidManager
  // public formSampleAsid = AsidManager.createID('a')


  // #region workflows
  /**
   * Workflows can be created to guide the user through the activation and assignement process
   * each workflow has a name and a description
   * a workflow consists of on or multiple steps
   * a workflow can be assigned to a category
   *  each step has a name and a description
   *  each step can have different actions:
   *  - set a category
   *  - set an attribute
   *  - set an identifier
   * 
   * at the end of the workflow it is possible to change the categories to advance the process step
   */

  public formWorkflows: Workflow[] = [
    {
      title: 'Default',
      description: 'Default workflow',
      categories: [],
      steps: [
        {
          title: 'Set category',
          description: 'Set category',
          showWithPreviousStep: false,
          order: 0,
          action: {
            type: 'workflowStepType_setCategory',
            mandatory: true,
            value: ''
          }
        }
      ],
      completeStep: {
        title: 'Complete',
        description: 'Complete',
        setCategory: ''
      }
    },
    {
      title: 'Sample Workflow 2 Title',
      description: 'Sample Workflow 2 Description',
      categories: [],
      steps: [
        {
          title: 'Step 1',
          description: 'Step 1',
          showWithPreviousStep: false,
          order: 0,
          action: {
            type: 'workflowStepType_setCategory',
            mandatory: true,
            value: ''
          }
        },
        {
          title: 'Step 2',
          description: 'Step 2',
          showWithPreviousStep: false,
          order: 1,
          action: {
            type: 'workflowStepType_setCategory',
            mandatory: true,
            value: ''
          }
        }
      ],
      completeStep: {
        title: 'Complete',
        description: 'Complete',
        setCategory: ''
      }
    }
  ]


  public onClickAddWorkflow() {
    this.formWorkflows.push({
      title: 'New workflow',
      description: 'New workflow',
      categories: [],
      steps: [
        {
          title: 'New step',
          description: 'New step',
          showWithPreviousStep: false,
          order: 0,
          action: {
            type: 'workflowStepType_setCategory',
            mandatory: true,
            value: ''
          }
        }
      ],
      completeStep: {
        title: 'Complete',
        description: 'Complete',
        setCategory: ''
      }
    })
  }

  public onAddWorkflowStep(workflowIndex: number) {
    const workflow = this.formWorkflows[workflowIndex]
    workflow.steps.push({
      title: 'New step',
      description: 'New step',
      showWithPreviousStep: false,
      order: workflow.steps.length,
      action: {
        type: 'workflowStepType_setCategory',
        mandatory: true,
        value: ''
      }
    })
  }

  public ACTION_NAME_MAP = {
    workflowStepType_setCategory: 'Set category',
    workflowStepType_setAttribute: 'Set attribute',
    workflowStepType_setIdentifier: 'Set identifier'
  }

  public getHumanReadableActionValue(actionType: string, actionValue: string) {
    if (actionType === 'workflowStepType_setCategory') {
      return this.backendConfig.asid.categoryDefinitions[actionValue as keyof typeof this.backendConfig.asid.categoryDefinitions].title
    } else if (actionType === 'workflowStepType_setAttribute') {
      return this.backendConfig.asid.assetAttributeDefinitions[actionValue as keyof typeof this.backendConfig.asid.assetAttributeDefinitions].title
    } else if (actionType === 'workflowStepType_setIdentifier') {
      return this.backendConfig.asid.identifierDefinition[actionValue as keyof typeof this.backendConfig.asid.identifierDefinition].title
    }
  }

  // #endregion workflows

  // public formPreviewWidth = false
  public formSelectedCodeConfigIndex = 0
  // public formSvgText = ''

  public backendConfig: BackendConfigDB = { ...BackendConfigManager.defaultDocDB }

  public get documentPath() {
    return BackendConfigManager.getDbDocReference(this.$auth.tenant.id).path
  }

  get documentPrivileges() {
    return databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__PRIVILEGES__
  }

  //#region code config
  public onAddCodeConfig() {
    this.backendConfig.codes.push({ ...defaultCodeConfig, id: '' + (this.backendConfig.codes.length + 1) })
  }

  public onRemoveCodeConfig() {
    if (this.backendConfig.codes.length === 1) return

    const removeIndex = this.formSelectedCodeConfigIndex
    this.backendConfig.codes.splice(removeIndex, 1)

  }

  @Watch('backendConfig.codes', { immediate: true })
  private onBackendConfigUpdate() {
    this.formSelectedCodeConfigIndex = this.backendConfig.codes.length - 1
  }

  //#endregion code config

  // @Watch('formSelectedCodeConfigIndex')
  // private async onSelectedTemplateChanged() {
  //   if (this.formSelectedCodeConfigID === 'Default') {
  //     this.formSvgText = ''
  //     return
  //   }

  //   // eslint-disable-next-line @typescript-eslint/no-var-requires
  //   const template = await require('!!raw-loader!@/assets/echoCodeTemplates/' + this.formSelectedCodeConfigID + '.svg')
  //   if (!template) {
  //     this.$helpers.notification.Error(`template ${this.formSelectedCodeConfigID} not found`)
  //   } else {
  //     this.formSvgText = template.default
  //   }
  // }


  //#region identifier

  public isLoading = false

  public async $save() {
    this.isLoading = true

    try {
      // try to save the identifier definition
      const identifierDefSaveError = (this.$refs['identifier-definition'] as VFormDataDefinition).saveLocal()
      if (identifierDefSaveError) {
        throw 'Error in identifier definition: ' + identifierDefSaveError
      }
      // try to save the asset attribute definition
      const assetAttributeDefSaveError = (this.$refs['asset-attribute-definition'] as VFormDataDefinition).saveLocal()
      if (assetAttributeDefSaveError) {
        throw 'Error in attribute definition: ' + assetAttributeDefSaveError
      }
      // try to save category definition
      const categoryDefSaveError = (this.$refs['category-definition'] as VFormCategoryEntryDefinition).saveLocal()
      if (categoryDefSaveError) {
        throw 'Error in category definition: ' + categoryDefSaveError
      }

      const backendConfigClone = cloneObject(this.backendConfig)

      await BackendConfigManager.update(this.$auth.tenant.id, this.$auth.userEmail, backendConfigClone)
      this.$helpers.notification.Success('identifierDefinitions updated & asset attibutes updated')

    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
      return false
    } finally {
      this.isLoading = false
    }
  }


  //#endregion identifier


  public async loadData() {
    this.isLoading = true

    await this.$bindSnapshot('backendConfig', BackendConfigManager.getDbDocReference(this.$auth.tenant.id))

    this.isLoading = false
  }


  public async created() {
    await this.loadData()
  }

}
