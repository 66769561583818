import BaseModuleApp from '@/modules/baseModuleApp'
import { ModuleType } from '../typeModules'
import { RouteConfig } from 'vue-router'
import AppGroupLoading from '@/pages/app/App_Group_Loading.vue'

const App_Module_Html = import(/* webpackChunkName: "App_Module_Html" */ './App_Module_Html.vue')

export default class HtmlModuleApp extends BaseModuleApp {
  public static type: ModuleType = 'Html'

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'content/:groupID',
        name: 'html',
        component: () => App_Module_Html
      }
    ]
  }

  public static registerOverviewVue() {
    return () => ({
      component: App_Module_Html,
      loading: AppGroupLoading
    })
  }
}
