import { TenantID } from '@/types/typeTenant'
import merge from 'deepmerge'
import { RawLocation, RouteConfig } from 'vue-router'
import {
  BaseElementDB,
  BaseModuleDB,
  ModuleType,
  BaseGroupDB,
  ElementID,
  GroupID,
  BaseResponseDB,
  BaseResponseItemDB,
  PublishingState
} from './typeModules'

import db from '@/firebase'
import databaseSchema, { defaultBaseGroupDB } from '@/database/databaseSchema'

import { getBackendUploadPathModule } from '@/helpers/storageHelper'
import { typedWhere, typedWhereExpressionV9 } from '@/database/dbHelper'
import BaseManager from '@/database/baseManager'
import { RequiredDocPrivileges } from '@/types/typeRequiredPrivileges'
import { UserPrivilegeIdDB } from '@/types/typeUser'
import { DeepPartial, hasDBid, objectID } from '@/types/typeGeneral'
import { collection, or, query } from 'firebase/firestore'

export default abstract class BaseModule extends BaseManager {
  public static type: ModuleType
  public static displayName: string

  public static viewOptions = [
    ['inline', 'Inline'],
    ['button', 'Button'],
    ['tile-half', 'Tile Half'],
    ['tile-full', 'Tile Full'],
    ['tile-auto', 'Auto Tile']
  ]

  public static authPrivileges: RequiredDocPrivileges & { view: UserPrivilegeIdDB[] } = {
    r: [],
    w: [],
    view: []
  }

  public static moduleDB: BaseModuleDB
  public static elementDB: BaseElementDB
  public static responseDB: BaseResponseDB
  public static responseItemDB: BaseResponseItemDB
  public static groupDB: BaseGroupDB = { ...defaultBaseGroupDB }
  public static color = '#2a363b'
  public static description = ''
  public static descriptionLong = ''

  public static hasWidget = true

  public static defaultGroupID = 'default-group'
  public static defaultGroupName = 'default Widget'
  public static defaultGroupTitle = ''

  // constructor() {
  //   //
  // }

  public static get routeNameGroup() {
    return `module-${this.type.toLowerCase()}-group-single`
  }

  public static get routeNameElement() {
    return `module-${this.type.toLowerCase()}-single`
  }

  // list of widget groups
  public static get routeNameList() {
    return `module-${this.type.toLowerCase()}-list`
  }

  // list of grouping groups
  public static get routeNameListGroups() {
    return `module-${this.type.toLowerCase()}-list-group`
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return []
  } // todo return html instead for more customiced styling. e.g a new items counter

  public static getRoutes(): RouteConfig[] {
    return []
  }

  public static getUploadPath(tenantId: TenantID) {
    return getBackendUploadPathModule(tenantId, this.type.toLowerCase())
  }

  public static async addGroup<T extends BaseGroupDB>(
    tenantId: TenantID,
    authEmail: string,
    fields: DeepPartial<T> = {},
    defaultGroup = false
  ) {
    if (!fields.name) fields.name = `${this.type} Widget`

    const ref = this.getGroupDocDbReference(tenantId, defaultGroup ? this.defaultGroupID : undefined)
    await this.addDoc(ref, authEmail, fields, this.groupDB)

    return ref
  }

  // todo flatten specified fields by dot notation to conform with firebases partial update syntax. {a:{b:2}} => 'a.b':2
  public static updateGroup<T extends BaseGroupDB>(
    tenantId: TenantID,
    authEmail: string,
    groupId: objectID,
    fields: DeepPartial<T>
  ) {
    return this.updateDoc(this.getGroupDocDbReference(tenantId, groupId), authEmail, fields)
  }

  public static async deleteGroup(tenantId: TenantID, groupId: objectID, authEmail: string, includeElements = false) {
    const elementsInGroup = await typedWhere<BaseElementDB>(
      this.getElementsDbReference(tenantId),
      { public: { groupID: '' } },
      '==',
      groupId
    ).get()

    if (!elementsInGroup.empty && !includeElements) {
      throw new Error('cant delete group with elements')
    } else {
      if (!elementsInGroup.empty && includeElements) {
        for (const El of elementsInGroup.docs) {
          await this.deleteElement(tenantId, authEmail, El.ref.id)
          // El.ref.delete() // todo error handling
        }
      }
      return this.updateGroup(tenantId, authEmail, groupId, { publishingState: 'deleted' })
    }
  }

  //todo use base manager
  public static addElement<T extends BaseElementDB>(
    tenantId: TenantID,
    authEmail: string,
    fields: DeepPartial<T> = {}
  ) {
    if (!fields.name) fields.name = `new ${this.type} Element`

    return this.addDoc(this.getElementsDbReference(tenantId), authEmail, fields, this.elementDB)
  }

  public static async copyElement<T extends BaseElementDB>(
    tenantId: TenantID,
    authEmail: string,
    baseElementId: objectID,
    fields: DeepPartial<Omit<T, '_meta'>> = {}
  ) {
    const sourceEl = await this.getElementDocDbReference(tenantId, baseElementId).get()

    if (!sourceEl.exists) throw 'cant copy - base element does not exist'

    // override arrays with the newly provided data
    const overwriteMerge = (destinationArray: any[], sourceArray: any[], options: any) => sourceArray

    // give exessive stack error
    // const tmpElement: DeepPartial<T> = merge(sourceEl.data() as T, fields, { arrayMerge: overwriteMerge })
    const tmpElement = merge(sourceEl.data() as any, fields, { arrayMerge: overwriteMerge }) as DeepPartial<T>

    if (tmpElement?.public?.groupID === '') tmpElement.public.groupID = this.defaultGroupID

    if (tmpElement._meta) delete tmpElement._meta

    tmpElement.name = `Copy of ${tmpElement.name}`
    tmpElement.publishingState = 'draft'

    delete tmpElement._computed
    delete tmpElement._local

    return this.addElement(tenantId, authEmail, tmpElement)
  }

  private static updateElementHelper<T extends typeof BaseModule.elementDB>(
    tenantId: TenantID,
    authEmail: string,
    elementId: ElementID,
    fields: DeepPartial<T>,
    batch?: firebase.default.firestore.WriteBatch,
    flatten = true
  ) {
    return batch
      ? this.updateDocBatch(this.getElementDocDbReference(tenantId, elementId), authEmail, fields, batch)
      : this.updateDoc(this.getElementDocDbReference(tenantId, elementId), authEmail, fields, false, flatten)
  }

  public static updateElement<T extends typeof BaseModule.elementDB>(
    tenantId: TenantID,
    authEmail: string,
    elementId: ElementID,
    fields: DeepPartial<T>,
    flatten = true
  ) {
    return this.updateElementHelper(tenantId, authEmail, elementId, fields, undefined, flatten) as Promise<void>
  }

  public static updateElementBatch<T extends typeof BaseModule.elementDB>(
    tenantId: TenantID,
    authEmail: string,
    elementId: ElementID,
    fields: DeepPartial<T>,
    batch: firebase.default.firestore.WriteBatch
  ) {
    return this.updateElementHelper(
      tenantId,
      authEmail,
      elementId,
      fields,
      batch
    ) as firebase.default.firestore.WriteBatch
  }

  public static updateModule<T extends typeof BaseModule.moduleDB>(
    tenantId: TenantID,
    authEmail: string,
    fields: DeepPartial<T>
  ) {
    return this.updateDoc(this.getModuleDbReference(tenantId), authEmail, fields)
  }

  // update response
  public static updateResponse<T extends typeof BaseModule.responseDB>(
    tenantId: TenantID,
    authEmail: string,
    responseId: ElementID,
    fields: DeepPartial<T>,
    batch?: firebase.default.firestore.WriteBatch
  ) {
    return batch
      ? this.updateDocBatch(this.getResponseDocDbReference(tenantId, responseId), authEmail, fields, batch)
      : this.updateDoc(this.getResponseDocDbReference(tenantId, responseId), authEmail, fields)
  }

  // update responseitem
  public static updateResponseItem<T extends typeof BaseModule.responseItemDB>(
    tenantId: TenantID,
    authEmail: string,
    responseItemId: ElementID,
    fields: DeepPartial<T>,
    batch?: firebase.default.firestore.WriteBatch
  ) {
    return batch
      ? this.updateDocBatch(this.getResponseItemDocDbReference(tenantId, responseItemId), authEmail, fields, batch)
      : this.updateDoc(this.getResponseItemDocDbReference(tenantId, responseItemId), authEmail, fields)
  }

  public static addResponse<T extends typeof BaseModule.responseDB>(
    tenantId: TenantID,
    authEmail: string,
    fields: DeepPartial<T>
  ) {
    return this.addDoc(this.getResponsesDbReference(tenantId), authEmail, fields, this.responseDB)
  }

  public static addResponseItem<T extends typeof BaseModule.responseItemDB>(
    tenantId: TenantID,
    authEmail: string,
    fields: DeepPartial<T>
  ) {
    return this.addDoc(this.getResponseItemsDbReference(tenantId), authEmail, fields, this.responseItemDB)
  }

  // get response
  public static getResponse<T extends typeof BaseModule.responseDB>(tenantId: TenantID, responseId: ElementID) {
    return this.getDocHelper<T & hasDBid>(this.getResponseDocDbReference(tenantId, responseId))
  }

  // get response by transactionID
  public static getResponseByTransactionID<T extends typeof BaseModule.responseDB>(
    tenantId: TenantID,
    transactionID: string
  ) {
    const query = typedWhere<BaseResponseDB>(
      this.getResponsesDbReference(tenantId),
      { public: { responseTransactionID: '' } },
      '==',
      transactionID
    )
    return this.getDocsHelper<T & hasDBid>(query)
  }

  public static deleteElement(tenantId: TenantID, authEmail: string, elementID: ElementID) {
    // return this.getElementDocDbReference(tenantId, elementID).delete()
    return this.updateElement(tenantId, authEmail, elementID, { publishingState: 'deleted' })
  }

  public static deleteResponse(tenantId: TenantID, authEmail: string, elementID: ElementID) {
    // return this.getElementDocDbReference(tenantId, elementID).delete()
    return this.updateDoc(this.getResponseDocDbReference(tenantId, elementID), authEmail, {
      publishingState: 'deleted'
    })
  }

  public static getModuleDbReference(tenantId: TenantID) {
    return db.doc(databaseSchema.COLLECTIONS.TENANTS.MODULES.__DOCUMENT_PATH__(tenantId, this.type))
  }

  public static getElementsDbReference(tenantId: TenantID) {
    return db.collection(databaseSchema.COLLECTIONS.TENANTS.MODULES.ELEMENTS.__COLLECTION_PATH__(tenantId, this.type))
  }

  public static getElementsDocs<T>(tenantId: TenantID, includeDeleted = false, includeArchived = false) {
    return this.getDocsHelper<T>(this.getElementsQuery(tenantId, includeDeleted, includeArchived))
  }

  public static getElementsQuery(tenantId: TenantID, includeDeleted = false, includeArchived = false) {
    const query = this.getElementsDbReference(tenantId)
    return includeDeleted
      ? query
      : typedWhere<BaseElementDB>(query, { publishingState: 'deleted' }, 'not-in', [
          ...(!includeArchived ? ['archived'] : []),
          ...(!includeDeleted ? ['deleted'] : [])
        ])
  }

  // get Element
  public static getElement<T extends typeof BaseModule.elementDB>(tenantId: TenantID, elementId: ElementID) {
    return this.getDocHelper<T & hasDBid>(this.getElementDocDbReference(tenantId, elementId))
  }

  public static getElementsByGroupDbQuery(
    tenantId: TenantID,
    groupID: GroupID,
    includeDeleted = false,
    includeArchived = false
  ) {
    const query = typedWhere<BaseElementDB>(
      this.getElementsDbReference(tenantId),
      { public: { groupID: '' } },
      '==',
      groupID
    )
    return includeDeleted
      ? query
      : typedWhere<BaseElementDB>(query, { publishingState: 'deleted' }, 'not-in', [
          ...(!includeArchived ? ['archived'] : []),
          ...(!includeDeleted ? ['deleted'] : [])
        ])
  }

  public static getGroupsDbReference(tenantId: TenantID) {
    return db.collection(databaseSchema.COLLECTIONS.TENANTS.MODULES.GROUPS.__COLLECTION_PATH__(tenantId, this.type))
  }

  public static getGroupsQuery(
    tenantId: TenantID,
    includeDeleted = false,
    onlyPublished = false,
    includeArchived = false
  ) {
    const query = this.getGroupsDbReference(tenantId)
    return onlyPublished
      ? typedWhere<BaseElementDB>(query, { publishingState: 'published' }, '==', 'published')
      : includeDeleted
      ? query
      : typedWhere<BaseElementDB>(query, { publishingState: 'deleted' }, 'not-in', [
          ...(!includeArchived ? ['archived'] : []),
          ...(!includeDeleted ? ['deleted'] : [])
        ])
  }

  public static getResponsesDbReference(tenantId: TenantID) {
    return db.collection(databaseSchema.COLLECTIONS.TENANTS.MODULES.RESPONSES.__COLLECTION_PATH__(tenantId, this.type))
  }

  public static getResponsesDbReferenceV9(tenantId: TenantID) {
    return collection(db, databaseSchema.COLLECTIONS.TENANTS.MODULES.RESPONSES.__COLLECTION_PATH__(tenantId, this.type))
  }

  public static getResponseItemsDbReference(tenantId: TenantID) {
    return db.collection(
      databaseSchema.COLLECTIONS.TENANTS.MODULES.RESPONSE_ITEMS.__COLLECTION_PATH__(tenantId, this.type)
    )
  }

  public static getResponseItemsDbReferenceV9(tenantId: TenantID) {
    return collection(
      db,
      databaseSchema.COLLECTIONS.TENANTS.MODULES.RESPONSE_ITEMS.__COLLECTION_PATH__(tenantId, this.type)
    )
  }

  public static getElementDocDbReference(tenantId: TenantID, elementID?: ElementID) {
    const collRef = this.getElementsDbReference(tenantId)

    return elementID ? collRef.doc(elementID) : collRef.doc()
  }

  public static getResponseDocDbReference(tenantId: TenantID, responseID: ElementID) {
    const collRef = this.getResponsesDbReference(tenantId)

    return collRef.doc(responseID)
  }

  // get response item doc reference
  public static getResponseItemDocDbReference(tenantId: TenantID, responseItemID: ElementID) {
    const collRef = this.getResponseItemsDbReference(tenantId)

    return collRef.doc(responseItemID)
  }

  public static getResponsesQuery(tenantId: TenantID, includeDeleted = false, includeArchived = false) {
    const query = this.getResponsesDbReference(tenantId)
    return includeDeleted && includeArchived
      ? query
      : typedWhere<BaseResponseDB>(query, { publishingState: 'deleted' }, 'not-in', [
          ...(!includeArchived ? ['archived'] : []),
          ...(!includeDeleted ? ['deleted'] : [])
        ])
  }

  public static getResponsesQueryV9(tenantId: TenantID, includeDeleted = false, includeArchived = false) {
    const collRef = this.getResponsesDbReferenceV9(tenantId)

    let statesToInclude: PublishingState[] = ['published', 'draft']

    if (includeDeleted) statesToInclude = [...statesToInclude, 'deleted']
    if (includeArchived) statesToInclude = [...statesToInclude, 'archived']

    return includeDeleted && includeArchived
      ? collRef
      : query(
          collRef,
          or(
            ...statesToInclude.map((state) =>
              typedWhereExpressionV9<BaseResponseDB>({ publishingState: state }, '==', state)
            )
          )
        )
    // : typedWhereV9<BaseResponseDB>(collRef, { publishingState: 'deleted' }, 'not-in', [
    //     ...(!includeArchived ? ['archived'] : []),
    //     ...(!includeDeleted ? ['deleted'] : [])
    //   ])
  }

  public static getResponseItemsQueryV9(tenantId: TenantID, includeDeleted = false, includeArchived = false) {
    const collRef = this.getResponseItemsDbReferenceV9(tenantId)
    let statesToInclude: PublishingState[] = ['published', 'draft']

    if (includeDeleted) statesToInclude = [...statesToInclude, 'deleted']
    if (includeArchived) statesToInclude = [...statesToInclude, 'archived']

    return includeDeleted && includeArchived
      ? collRef
      : query(
          collRef,
          or(
            ...statesToInclude.map((state) =>
              typedWhereExpressionV9<BaseResponseItemDB>({ publishingState: state }, '==', state)
            )
          )
        )
  }

  public static getGroupDocDbReference(tenantId: TenantID, groupID?: objectID) {
    const collRef = this.getGroupsDbReference(tenantId)

    return groupID ? collRef.doc(groupID) : collRef.doc()
  }

  public static getGroup<T extends BaseGroupDB>(tenantId: TenantID, groupID?: ElementID) {
    return this.getDocHelper<T & hasDBid>(this.getGroupDocDbReference(tenantId, groupID))
  }

  public static getGroups<T>(tenantId: TenantID) {
    return this.getDocsHelper<BaseGroupDB & hasDBid & T>(this.getGroupsDbReference(tenantId))
  }

  public static onSnapshot<T extends typeof BaseModule.elementDB>(
    tenantId: TenantID,
    elementID: ElementID,
    onNext: (data: T & hasDBid) => void,
    onOnce: (data: T & hasDBid) => void,
    onError: (e: any) => void,
    onFinally: (data?: T & hasDBid) => void
  ) {
    return this.onSnapshotHelper(this.getElementDocDbReference(tenantId, elementID), onNext, onOnce, onError, onFinally)
  }

  public static onSnapshotElements<T extends typeof BaseModule.elementDB>(
    tenantId: TenantID,
    onNext: (data: Array<T & hasDBid>) => void,
    onError: (e: any) => void,
    onOnce?: (data: Array<T & hasDBid>) => void,
    onFinally?: (data?: Array<T & hasDBid>) => void
  ) {
    return this.onSnapshotQueryHelper<T>(this.getElementsQuery(tenantId), onNext, onError, onOnce, onFinally)
  }

  public static onSnapshotGroups<T extends typeof BaseModule.groupDB>(
    tenantId: TenantID,
    onNext: (data: Array<T & hasDBid>) => void,
    onError: (e: any) => void,
    onOnce?: (data: Array<T & hasDBid>) => void,
    onFinally?: (data?: Array<T & hasDBid>) => void
  ) {
    return this.onSnapshotQueryHelper<T>(this.getGroupsQuery(tenantId), onNext, onError, onOnce, onFinally)
  }

  public static async activateOrCreateModule(tenantId: TenantID, authEmail: string) {
    const moduleRef = this.getModuleDbReference(tenantId)

    const moduleDoc = await moduleRef.get()

    if (!moduleDoc.exists) {
      await this.addDoc(moduleRef, authEmail, { activated: true }, this.moduleDB)
      return this.addGroup(
        tenantId,
        authEmail,
        {
          name: this.defaultGroupName,
          public: { title: { _ltType: true, locales: { default: this.defaultGroupTitle } }, order: 0 }
        },
        true
      )
    } else {
      return this.updateDoc(moduleRef, authEmail, {
        activated: true
      })
    }
  }

  public static async deactivateModule(tenantId: TenantID, authEmail: string) {
    const moduleRef = this.getModuleDbReference(tenantId)
    const moduleDoc = await moduleRef.get()

    if (moduleDoc.exists) {
      return this.updateDoc(moduleRef, authEmail, {
        activated: false
      })
    }
  }
}
