
import { Component, Vue, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTag, faUndo } from '@fortawesome/free-solid-svg-icons'
import { Chrome as ColorPicker } from 'vue-color'

library.add(faTag, faUndo)

@Component({
  components: { ColorPicker },
  model: {
    prop: 'color',
    event: 'changed'
  },
  props: {
    color: {
      type: String,
      required: true,
      default: () => '#000000'
    },
    allowReset: {
      type: Boolean,
      required: false,
      default: false
    },
    colorFormat: {
      type: String,
      required: false,
      default: () => 'hex'
    }
  }
})
export default class VColorPicker extends Vue {

  public formColor: any = {
    hex: ''
  }
  public showPicker = false

  public onColorChanged(val: any) {
    // debugger
    this.$emit('changed', val[this.$props.colorFormat])
  }

  @Watch('color', { immediate: true })
  onValueChanged(val: string[], oldVal: string[]) {
    // when a accessor value is specified, create array of tag objects based on the passed array of values
    this.formColor[this.$props.colorFormat] = val
  }

  displayPicker() {
    document.addEventListener('click', this.documentClick)
    this.showPicker = true
  }
  
  hidePicker() {
    document.removeEventListener('click', this.documentClick)
    this.showPicker = false
  }

  togglePicker() {
    this.showPicker ? this.hidePicker() : this.displayPicker()
  }

  beforeDestroy() {
    // unbind listeners
    document.removeEventListener('click', this.documentClick)
  }

  documentClick(e: Event) {
    const el = this.$refs['editor-colorpicker'] as Vue,
      target = e.target as Element
    if (el.$el !== target && !el.$el.contains(target)) {
      this.hidePicker()
    }
  }
}
