
import { Component, Vue, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import 'prismjs'
import 'prismjs/themes/prism.css'
// import PrismEditor from 'vue-prism-editor'
import { AsidDB } from '@/types/typeAsid'
import databaseSchema from '@/database/databaseSchema'
import { cloneObject } from '@/helpers/dataShapeUtil'
import VFormDataEntry from '@/components/VFormDataEntry.vue'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import VAdminTenantSelectionView from './components/VAdminTenantSelectionView.vue'
import DataModule from '@/modules/data/dataModule'
import { DataGroupDB, DataElementDB, isDataKey } from '@/modules/data/typeDataModule'
import { hasDBid } from '@/types/typeGeneral'
import { CategoryCollection, CategoryCollectionDocDB } from '@/types/typeCategory'
import CategoryHelper from '@/database/categoryHelper'
import { BackendConfigDB } from '@/types/typeBackendConfig'
import BackendConfigManager from '@/database/backendConfigManager'
import VEchoCode from '@/components/VEchoCode.vue'
import VInputCodeEditor from '@/components/VInputCodeEditor.vue'

library.add(faUndo)


@Component({
  components: {
    VEchoCode,
    VAdminTenantSelectionView,
    VInputMultiCategorySelection,
    VFormDataEntry,
    VInputCodeEditor
  }
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class AdminQRGenerator extends Vue {


  public asidDB: AsidDB & hasDBid = { ...cloneObject(databaseSchema.COLLECTIONS.ASID.__EMPTY_DOC__), id: '' }

  public svg: string = ''

  public tenantID: string = ''
  public uploadPath: string = ''
  public docPath: string = ''

  public backendConfig: BackendConfigDB = cloneObject(databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__EMPTY_DOC__)
  public dataModuleGroups: (DataGroupDB & hasDBid)[] = []
  public dataElements: DataElementDB[] = []
  public categoryCollectionDoc: CategoryCollectionDocDB = cloneObject(databaseSchema.COLLECTIONS.TENANTS.DATA.CATEGORIES.__EMPTY_DOC__)

  public get categoryCollection(): CategoryCollection {
    return this.categoryCollectionDoc.collection
  }

  get dataByDefinitionKeys() {
    // {varName1: 12, varNameFromOtherElement: '23'}
    let combinesDataElements: { [key: string]: any } = {}
    this.dataModuleGroups.forEach(dd => {
      Object.entries(dd.dataDefinition).map(([key, def]) => {
        const element = this.dataElements.find(me => me.public.groupID === dd.id)
        if (element && def.name)
          combinesDataElements[def.name] = element?.data?.[key as isDataKey]
      }
      )
    })
    return combinesDataElements
  }

  @Watch('tenantID')
  private async onTenantIDSelected() {
    this.uploadPath = `Tenants/${this.tenantID}/ASID`
    this.docPath = `Tenants/${this.tenantID}/ASID`

    await this.$firestoreBind('dataModuleGroups', DataModule.getGroupsDbReference(this.tenantID))
      .catch((e) => {
        throw `dataModuleGroups not found. ${e}[20220226]`
      })

    await this.$firestoreBind('dataElements', DataModule.getElementsDbReference(this.tenantID))

    await this.$firestoreBind('categoryCollectionDoc', CategoryHelper.getCategoriesDocRef(this.tenantID))

    await this.$firestoreBind('backendConfig', BackendConfigManager.getDbDocReference(this.tenantID))
  }

  public async mounted() {
    //            
  }

}
