import {
  locale,
  LOCALES_NAME_MAP,
  LocalizedField,
  LocalizedFieldObject,
  LocalizedFieldObjectLocales
} from '../types/typeI18n'

/**
 * 1. try match preferred locales 'de-de'
 * 1.1 match preferred primary lannguage 'de'
 * 2. try match default locale 'default' (mode=auto)
 * 3. try match english locale 'en' (mode=auto)
 * 4. match any locale (mode=auto)
 * @param localizedField lt object
 * @param mode defines how to macth when no macthing locale is found
 */
export function getLocalString(
  localizedField: LocalizedField,
  preferredLocales: locale[],
  mode: 'auto' | 'auto-hide' | locale | 'none' = 'auto' // auto: select any locale, auto-hide: select locale, en, or default, hide otherwise
) {
  const locale = getLocalizedTextLocale(localizedField, preferredLocales, mode)

  return locale ? localizedField.locales[locale] : ''
}

export function getLocalizedTextLocale(
  localizedField: LocalizedField,
  preferredLocales: locale[],
  mode: 'auto' | 'auto-hide' | locale | 'none' = 'auto' // auto: select any locale, auto-hide: select locale, en, or default, hide otherwise
) {
  return getLocalizedObjectLocale<string>(localizedField, preferredLocales, mode)
}

/**
 * 1. try match preferred locales 'de-de'
 * 1.1 match preferred primary lannguage 'de'
 * 2. try match default locale 'default' (mode=auto)
 * 3. try match english locale 'en' (mode=auto)
 * 4. match any locale (mode=auto)
 * @param localizedField lt object
 * @param mode defines how to macth when no macthing locale is found
 */
export function getLocalizedObjectLocale<T>(
  localizedField: LocalizedFieldObject<T>,
  preferredLocales: locale[],
  mode: 'auto' | 'auto-hide' | locale | 'none' = 'auto' // auto: select any locale, auto-hide: select locale, en, or default, hide otherwise
) {
  const availableLocales = localizedField.locales

  // console.log(mode, availableLocales)

  const nonEmptyAvailableLocales: LocalizedFieldObjectLocales<T> = {}

  // is string typeguard
  function isString(value: any): value is string {
    return typeof value === 'string'
  }

  // copy non empty locales
  Object.keys(availableLocales).forEach((key) => {
    const locale = availableLocales[key]
    if (isString(locale)) {
      if (locale.trim() !== '') nonEmptyAvailableLocales[key] = locale
    } else {
      nonEmptyAvailableLocales[key] = locale
    }
  })

  // match exactly
  for (const preferredLocale of preferredLocales) {
    if (preferredLocale in nonEmptyAvailableLocales) return preferredLocale
  }

  // match primary preferred
  preferredLocales = preferredLocales.map((l) => (l.includes('-') ? l.substring(0, 2) : l) as locale)
  for (const preferredLocale of preferredLocales) {
    if (preferredLocale in nonEmptyAvailableLocales) return preferredLocale
  }

  // match primary preferred & primary available
  const nonEmptyAvailableLocaleNames = Object.keys(nonEmptyAvailableLocales)

  // if primary locale of specific locale matches preferred locale, return it (de-de matches de)
  for (const preferredLocale of preferredLocales) {
    for (const locale of nonEmptyAvailableLocaleNames) {
      if (locale.split('-')[0] === preferredLocale) return locale
    }
  }

  // console.log('no exact match found')

  switch (mode) {
    case 'auto':
    case 'auto-hide':
      return 'default' in nonEmptyAvailableLocales
        ? 'default'
        : 'en' in nonEmptyAvailableLocales
        ? 'en'
        : mode === 'auto-hide'
        ? ''
        : Object.keys(nonEmptyAvailableLocales).length > 0
        ? Object.keys(nonEmptyAvailableLocales)[0]
        : ''

    case 'none':
      break

    default:
      return mode in nonEmptyAvailableLocales ? mode : ''
  }

  return ''
}

/**
 * returns human readable locale name E.g. English, German
 *
 * @param localeId
 * @returns
 */
export function getLocaleName(localeId: locale, addLocaleID = true) {
  // if locale is 'default' skip returning the actual locale in brackets e.g. English (en)
  if (localeId === 'default') return LOCALES_NAME_MAP[localeId]

  if (!addLocaleID) {
    return LOCALES_NAME_MAP[localeId] || localeId
  } else {
    return `${LOCALES_NAME_MAP[localeId] || localeId} (${localeId})`
  }
}
