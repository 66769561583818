import db from '@/firebase'

import { TenantID } from '@/types/typeTenant'
import BaseManager from './baseManager'
import { UserReferenceDB } from '@/types/typeUser'

import { encodeEmailToKey } from './encodeEmailToKey'
import databaseSchema from './databaseSchema'
import { DeepPartial, objectID } from '@/types/typeGeneral'

export default class UserManager extends BaseManager {
  public static defaultDocDB: UserReferenceDB = databaseSchema.COLLECTIONS.TENANTS.USERS.__EMPTY_DOC__

  // todo this will be done using a cloud function in the future
  public static async add(tenantId: TenantID, authEmail: string, fields: DeepPartial<UserReferenceDB> = {}) {    
    if (fields.email) {
      const email = fields.email.trim().toLowerCase()

      if (!this.validateEmail(email)) throw `invalid email address ${email}`

      const allFields = { ...this.defaultDocDB, ...fields, email: email }
      const userID = encodeEmailToKey(email)
      const newUser = await this.addDoc(
        this.getDbDocReference(tenantId, userID),
        authEmail,
        allFields,
        this.defaultDocDB
      )

      return newUser
    } else {
      throw 'email must be given when adding a user'
    }
  }

  public static async remove(tenantId: TenantID, userID: objectID) {
    return this.getDbDocReference(tenantId, userID).delete()
  }

  public static validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  /**
   * get all user docs across all tenants
   */
  public static getAllUserReferenceDocsByEmail(email: string) {
    // const userID = encodeEmailToKey(email)

    return (
      db
        .collectionGroup(databaseSchema.COLLECTIONS.TENANTS.USERS.__NAME__)
        .where('email', '==', email.toLowerCase())
        .where('active', '==', true)
        // .where(firebase.firestore.FieldPath.documentId(), '==', userID) // currently not supported by firebase
        .get()
    )
  }

  public static getUserByEmail(tenantId: TenantID, email: string) {
    const userID = encodeEmailToKey(email)

    return this.getDocHelper<UserReferenceDB>(this.getDbDocReference(tenantId, userID))
  }

  public static getUserByID(tenantId: TenantID, userID: objectID) {
    return this.getDocHelper<UserReferenceDB>(this.getDbDocReference(tenantId, userID))
  }

  public static async update(
    tenantId: TenantID,
    authEmail: string,
    userID: objectID,
    fields: DeepPartial<UserReferenceDB> = {}
  ) {
    return this.updateDoc(this.getDbDocReference(tenantId, userID), authEmail, fields)
  }

  public static getDbDocReference(tenantId: TenantID, userID: objectID) {
    return db.doc(databaseSchema.COLLECTIONS.TENANTS.USERS.__DOCUMENT_PATH__(tenantId, userID))
  }

  public static getDbCollectionReference(tenantId: TenantID) {
    return db.collection(databaseSchema.COLLECTIONS.TENANTS.USERS.__COLLECTION_PATH__(tenantId))
  }

  public static getDbCollectionGroupReference() {
    return db.collectionGroup(databaseSchema.COLLECTIONS.TENANTS.USERS.__NAME__)
  }

  public static getCollectionGroupName() {
    return databaseSchema.COLLECTIONS.TENANTS.USERS.__NAME__
  }
}
