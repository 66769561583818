
import { Component, Vue, PropSync, Watch } from 'vue-property-decorator'
import firebase from 'firebase/compat/app'

import db from '@/firebase'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilter, faTimesCircle, faTimes } from '@fortawesome/free-solid-svg-icons'

import { acessorObjectToDatatype, acessorObjectToString } from '@/database/dbHelper'
import { FilterConfig } from './mixins/VPaginateMixin.vue'

library.add(faTimesCircle, faFilter, faTimes)

@Component({
  components: {},
  inheritAttrs: false,
  props: {
    default: {
      type: Boolean,
      required: false,
      default: () => true
    }
  }
})
export default class VFilterDropdownView extends Vue {
  public isLoading = false
  public dropdownIsOpen = false

  public filterMode = 'exact'
  public filterSearchTermMin = ''
  public filterSearchTermMax = ''

  public filterData: { [key: string]: any, __result__: string, __resultDisplay__: string }[] = []

  public filterCheckedRows: { __result__: string, __resultDisplay__: string, [key: string]: any }[] = []
  public filterColumns = [

    {
      field: '__resultDisplay__',
      label: 'Result'
    }
  ]


  @PropSync('config', { type: Object })
  public filterConfig!: FilterConfig


  get propertyAcessorPath() {
    return acessorObjectToString(this.filterConfig.objAcessor)
  }

  get propertyDisplayAndQueryAcessorPath() {
    return acessorObjectToString(this.filterConfig.objDisplayAcessor || this.filterConfig.objAcessor)
  }

  /**
   * get docs from the db or hardcoded option set to show the user as possible filter values
   */
  @Watch('filterConfig', { immediate: true, deep: true })
  @Watch('dropdownIsOpen', { immediate: false })
  private async queryResults() {
    if (!this.dropdownIsOpen) return

    function resolvePath(path: string, object: any) {
      const value = path.split('.').reduce((p, c) => p?.[c], object)
      return value === undefined ? '_nopath_' : value
    }
    console.log('here')

    const searchTermMax = (this.filterConfig.type === 'exact-number')
      ? (this.filterSearchTermMax) ? +this.filterSearchTermMax : +this.filterSearchTermMin
      : (this.filterSearchTermMax) ? this.filterSearchTermMax + '\uf8ff' : this.filterSearchTermMin + '\uf8ff'

    const searchTermMin = (this.filterConfig.type === 'exact-number')
      ? +this.filterSearchTermMin
      : this.filterSearchTermMin


    try {
      this.isLoading = true

      let data: any[] = []
      const dataType = acessorObjectToDatatype(this.filterConfig.objAcessor)

      if (this.filterConfig.collectionPath) {
        let query = db.collection(this.filterConfig.collectionPath)
          .limit(50)

        if (this.filterConfig.queryFilter)
          query = this.filterConfig.queryFilter(query)

        if (searchTermMin !== '' && searchTermMin !== 0) {
          if (this.propertyDisplayAndQueryAcessorPath === 'id') {
            query = query.where(firebase.firestore.FieldPath.documentId(), '>=', searchTermMin)
              .where(firebase.firestore.FieldPath.documentId(), '<=', searchTermMax)
              .orderBy(firebase.firestore.FieldPath.documentId(), 'asc')
          } else {
            if (dataType === 'array') {
              query = query.where(this.propertyDisplayAndQueryAcessorPath, 'array-contains-any', [searchTermMin, searchTermMax])
            } else {
              query = query.where(this.propertyDisplayAndQueryAcessorPath, '>=', searchTermMin)
                .where(this.propertyDisplayAndQueryAcessorPath, '<=', searchTermMax)
                .orderBy(this.propertyDisplayAndQueryAcessorPath, 'asc')
            }
          }
        }
        const querySnapshot = await query.get()

        data = querySnapshot.docs.map(d => {
          return { ...d.data(), id: d.id }
        })
      } else if (this.filterConfig.options) {
        data = this.filterConfig.options
      } else {
        throw 'either "collectionPath" or "options" must be specified for a filter.'
      }

      const uniqueResultList: any[] = []
      this.filterData = [
        ...[(!this.filterConfig.hideEmptyOption && {
          __result__: '_empty_', __resultDisplay__: 'none'
        })],
        ...data.flatMap(d => {
          // __result__ is used for filtering. __resultDisplay__ for user display
          const __result__ = resolvePath(this.propertyAcessorPath, d)
          const __resultDisplay__ = resolvePath(this.propertyDisplayAndQueryAcessorPath, d)
          // if the value is an array, we need to flatten it
          if (Array.isArray(__result__)) {
            return __result__.map((v: any, index: number) => {
              return { __result__: v, __resultDisplay__: __resultDisplay__[index], ...d }
            })
          }
          // __result__ is used for filtering. __resultDisplay__ for user display
          return { __result__, __resultDisplay__, ...d }
        }).filter(d => {
          if (d.__result__ === '_nopath_') return false
          // only unique entries
          if (!uniqueResultList.includes(d.__result__)) {
            uniqueResultList.push(d.__result__)
            return true
          } else {
            return false
          }
        })
      ]
        .filter(v => v) // remove emtpy entries when hideEmptyOption is true
        .filter(v => v.__resultDisplay__ !== '' && (Array.isArray(v.__resultDisplay__) ? v.__resultDisplay__.length > 0 : true)) // remove empty entries
        .map(d => {
          // for the checked rows to still work the object must be the same
          const checkedRow = this.filterCheckedRows.find(cr => cr.__result__ === d.__result__)
          return (checkedRow) ? checkedRow : d
        })
    } catch (e: any) {
      this.$helpers.notification.Error(e.toString())
    } finally {
      this.isLoading = false
    }

  }

  @Watch('filterCheckedRows')
  public onCheckedRowsChange() {
    if (this.filterCheckedRows.length > 10)
      this.$helpers.notification.Warn('Only 10 Elements may be selected in the ' + this.propertyAcessorPath + ' filter')
  }

  public onApplyFilter() {
    this.filterConfig.in = this.filterCheckedRows.map(cr => cr.__result__)
      ; (this.$refs.dropdown as any).toggle()
  }

  public onResetFilter() {
    this.filterConfig.in = []
    this.filterCheckedRows = []
    if (this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }

  public onOpenFilter() {
    // update checked rows based on filterConfig.in
    this.filterCheckedRows = this.filterData.filter(d => {
      const result = d.__result__
      return this.filterConfig.in.some((item: any) => item === result)
    })

    if (!this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }


}
