import { userRoles } from '../businessLogic/acl'
import { CategoryID } from './typeCategory'
import RecordMeta from './typeRecordMeta'

// export default interface User {
//   readonly id?: string
//   firstName: string
//   lastName: string
//   email: string
//   description: string
//   roles: Array<UserRole>
//   dateCreated: Timestamp
//   dateUpdated: Timestamp | null
// }

export type userID = string

export type UserRoles = typeof userRoles

export type UserRole = keyof UserRoles

export type UserPrivileges = {
  // used a dict to store user privs. Not used as array of privs assigned to user
  [key: string]: UserPrivilegeIdDB
}

// <path name>:view
// sometimes e.g. wih settings some extra logic is used to determine if e.g. the overview is displayed
type privilegeViewIds =
  | 'activate-echo-code:view'
  | 'manage-echo-codes:view'
  | 'manage-categories:view'
  | 'manage-modules:view'
  | 'manage-widgets:view'
  | 'config-backend:view'
  | 'config-app:view'
  | 'config-masterdata:view'
  | 'config-user:view'
  | 'config-billing:view'
  | 'form:view'
  | 'html:view'
  | 'ci:view'
  | 'file:view'
  | 'script:view'
  | 'custom:view'
  | 'i18n:view'
  | 'abc:view'
  | 'protection:view'
  | 'notification:view'
  | 'data:view'
  | 'service:view'
  | 'service-config:view'
  | 'dasboard:view'

type privilegeDbIds =
  | privilegeViewIds
  | 'SUPER_ADMIN'
  | 'any'

  // config
  | 'user:read'
  | 'user:write'
  | 'code:read'
  | 'code:write'
  | 'config:read'
  | 'config:write'
  | 'masterdata:write'
  // | 'tos:accept' > via config:read
  // | 'tos:accept' > via config:writee
  | 'statistics:read'
  | 'contact:read'
  | 'contact:write'

  // categories
  | 'category:read'
  | 'category:write'

  //#region modules
  | 'modules:read'
  | 'modules:write'
  | 'form:read'
  | 'form:write'
  | 'html:read'
  | 'html:write'
  | 'ci:read'
  | 'ci:write'
  | 'file:read'
  | 'file:write'
  | 'script:read'
  | 'script:write'
  | 'custom:read'
  | 'custom:write'
  | 'i18n:read'
  | 'i18n:write'
  | 'abc:read'
  | 'abc:write'
  | 'protection:read'
  | 'protection:write'
  | 'notification:read'
  | 'notification:write'
  | 'data:read'
  | 'data:write'
  | 'service:read'
  | 'service:write'
//#endregion modules

export const allUserPrivileges: privilegeDbIds[] = [
  'SUPER_ADMIN',
  'code:read',
  'code:write',
  'config:read',
  'config:write',
  // 'code:purchase',
  'user:read',
  'user:write',
  'category:read',
  'category:write',
  // 'masterdata:read', everyone can read
  'masterdata:write',
  'contact:read',
  'contact:write',

  // modules
  'form:read',
  'form:write',
  'html:read',
  'html:write',
  'ci:read',
  'ci:write',
  'file:read',
  'file:write',
  'script:read',
  'script:write',
  'custom:read',
  'custom:write',
  'i18n:read',
  'i18n:write',
  'abc:read',
  'abc:write',
  'protection:read',
  'protection:write',
  'data:read',
  'data:write',
  'service:read',
  'service:write',

  // view
  'activate-echo-code:view',
  'manage-echo-codes:view',
  'manage-categories:view',
  'manage-modules:view',
  'manage-widgets:view',
  'config-backend:view',
  'config-app:view',
  'config-masterdata:view',
  'config-user:view',
  'config-billing:view',
  'form:view',
  'html:view',
  'ci:view',
  'file:view',
  'script:view',
  'custom:view',
  'i18n:view',
  'abc:view',
  'protection:view',
  'notification:view',
  'service:view',
  'service-config:view'
  // 'tos:accept',
]

// export type privilegesDB = [
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?,
//   privileges?
// ]

export type UserPrivilegeIdDB = privilegeDbIds

// export const defaultUser: User = {
//   id: 'default',
//   firstName: '',
//   lastName: '',
//   email: '',
//   description: '',
//   dateCreated: null,
//   dateUpdated: null,
//   roles: ['user' as UserRole]
// }

interface baseUserDB {
  active: boolean
  notes: string
  privileges: Array<UserPrivilegeIdDB>
  _meta: RecordMeta
}

// write: user:manage, owner
export interface UserReferenceDB extends baseUserDB {
  email: string
  name: string
  settings: any
  state: { // todo store this in the doc, as there might be fewer users than topics
    seenUnseen: { // time in seconds when the topic was last seen
      [topic: string]: number 
    }
  }
  visibleCategories: CategoryID[] // limits the categories the user can see. User can see all subcategories and the categories in this array
}

export interface ApiUserReferenceDB extends baseUserDB {
  apiTokenHash: string
  apiTokenID: string
  apiUserName: string
}
