import BaseManager from './baseManager'

import db from '@/firebase'
import databaseSchema from './databaseSchema'
import { GlobalAdminNotificationDB } from '@/types/typeAdmin'
import { hasDBid } from '@/types/typeGeneral'

export default class AdminNotificationManager extends BaseManager {
  public static defaultAdminAdminNotificationDB: GlobalAdminNotificationDB =
    databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__EMPTY_DOC__

  public static async initAdminNotificationDB(authEmail: string) {
    const AdminNotificationDoc = await db.doc(databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__DOCUMENT_PATH__()).get()
    if (!AdminNotificationDoc.exists) return this.addAdminNotification(authEmail)
  }

  public static addAdminNotification(authEmail: string) {
    return this.addDoc(this.getAdminNotificationDocRef(), authEmail, {}, this.defaultAdminAdminNotificationDB)
  }

  public static updateAdminNotification(authEmail: string, fields: Partial<GlobalAdminNotificationDB>) {
    return this.updateDoc(this.getAdminNotificationDocRef(), authEmail, fields)
  }

  public static getAdminNotificationDocRef() {
    return db.doc(databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__DOCUMENT_PATH__())
  }

  public static onSnapshot(onNext: (data: GlobalAdminNotificationDB & hasDBid) => void, onError: (e: any) => void) {
    return this.onSnapshotHelper<GlobalAdminNotificationDB>(this.getAdminNotificationDocRef(), onNext, d => d, onError)
  }

  public static async getAdminNotification() {
    return this.getDocHelper<GlobalAdminNotificationDB>(this.getAdminNotificationDocRef())
  }
}
