
import { Component, Vue, Prop } from 'vue-property-decorator'


import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faTrash,
  faQrcode,
  faChevronLeft
} from '@fortawesome/free-solid-svg-icons'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'


library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faTrash, faQrcode, faChevronLeft)

@Component({
  components: { VueApexCharts }
  // firestore: {
  //   data: AsidManager.getDbCollectionReference()
  // }
})
export default class VTimeSeriesGraph extends Vue {

  @Prop({ type: Array, required: true, default: () => [] })
  public series!: any[]

  @Prop({ type: Array, required: false })
  public color!: string[]

  @Prop({ type: Array, required: false, default: () => [] })
  public xAxisCategories!: string[]

  @Prop({ type: Boolean, required: false, default: false })
  public isDateTime!: boolean

  public apexHeight = 300

  // https://apexcharts.com/docs/series/

  // -> moved to db helper
  // private groupTimeSeriesByTimeAndCountOccurences<T>(data: T[], dateAcessor: (obj: T) => Date) {

  //   const groupedData = groupBy(data, a => moment(dateAcessor(a)).format('DD/MM/YYYY') || '')

  //   return Object.keys(groupedData).map((group) => {
  //     return {
  //       x: group,
  //       y: groupedData[group].length
  //     }
  //   })

  // }


  public apexChartType = 'bar'
  public apexChartOptions = {
    states: {
      active: {
        filter: {
          type: 'none' /* none, lighten, darken */
        }
      }
    },
    colors: this.color || ['#743ee2', '#7cd6fd', '#5e63ff', '#ff5858', '#ff9f07', '#feef72', '#27a744', '#98d85a', '#b554ff', '#ffa3ef', '#f0f4f7', '#b8c2cc', '#4f4f4f', '#222222'],
    theme: {
      palette: 'palette1' // upto palette10
    },
    chart: {
       animations: {
        enabled: false
        // easing: 'easeinout',
        // speed: 800,
        // animateGradually: {
        //     enabled: true,
        //     delay: 150
        // },
        // dynamicAnimation: {
        //     enabled: true,
        //     speed: 350
        // }
        },
      type: 'bar',
      // type: 'bar',
      stacked: !this.isDateTime,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        show: true,
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    title: {
      // text: 'Assigned Codes',
      align: 'left'
    },
    plotOptions: {
      bar: {
        columnWidth: '100%' // 3 % for 30 day period?
        // endingShape: 'rounded'  
      }
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shadeIntensity: 1,
    //     inverseColors: false,
    //     opacityFrom: 0.5,
    //     opacityTo: 0.5,
    //     stops: [0, 90, 100]
    //   }
    // },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          // console.log(val)

          return val.toFixed(0)
          // return (val / 1000000).toFixed(0)
        }
      },
      title: {
        // text: 'Price'
      }
    },
    xaxis: {
      type: this.isDateTime ? 'datetime' : 'category',
      ... this.isDateTime ? { min: moment(new Date()).startOf('month').toDate().getTime(), max: moment(new Date()).endOf('month').toDate().getTime() } : {},
      categories: this.xAxisCategories
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val: number) {
          return val.toFixed(0)
          // return (val / 1000000).toFixed(0)
        }
      }
    }
  }


}
