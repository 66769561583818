import { asidID } from '@/types/typeAsid'
import { functions as firebaseFunctions } from '@/firebaseApp'
import { RPCGenericRequest, RPCGenericResponse } from '@/types/typeRPC'
import { getLocalString } from '@/helpers/i18nUtil'
import { I18nGlobalsInst } from '../_globals/i18nGlobals'
import { LocalizedField } from '@/types/typeI18n'
import { httpsCallable } from 'firebase/functions'

interface Topic {
  events: any[]

  // instance ID is used to selectively remove all listeners for one script instance
  listeners: Array<{ instanceID: string, cb: (data: any) => void }>
}

export default class CustomScriptExecution {
  public static topicList: {
    PAGE_CHANGE: Topic
    FORM_SENT: Topic
    LOCALE_CHANGE: Topic
  } = {
    PAGE_CHANGE: {
      events: [],
      listeners: []
    },
    FORM_SENT: {
      events: [],
      listeners: []
    },
    // is emmitted atleast once on page load
    LOCALE_CHANGE: {
      events: [],
      listeners: []
    }
  }

  private static publish(topic: Topic, event: any) {
    topic.events.push(event)
    topic.listeners.forEach((listener) => listener.cb(event))
  }

  public static subscribe(topic: Topic, instanceID: string, cb: (data: any) => void, invokeWithPastEvents: boolean) {
    topic.listeners.push({ instanceID, cb: cb })
    if (invokeWithPastEvents) topic.events.forEach((event) => cb(event))
  }

  public static resetPubSub(instanceID?: string) {
    Object.values(this.topicList).forEach((topic) => {
      if (instanceID) {
        topic.listeners = topic.listeners.filter((listener) => listener.instanceID !== instanceID)
      } else {
        topic.events = []
        topic.listeners = []
      }
    })
  }

  public static emitEvent() {
    return {
      onPageChanged: (data: any) => this.publish(this.topicList.PAGE_CHANGE, data),
      onFormSent: (data: any) => this.publish(this.topicList.FORM_SENT, data),
      onLocaleChanged: (data: string[]) => this.publish(this.topicList.LOCALE_CHANGE, data)
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private static instances: { [key: string]: Function } = {}

  public static execute(
    codeString: string,
    ctx: {
      asid: asidID
      identifiers: { [key: string]: string | number }
      directCategoryNames: string[]
      allCategoryNames: string[]
      backend: boolean
    },
    functions: {
      log: any
      addResponse: (data: any) => Promise<any> // keys starting with '_' are not shown in the tabular view
      hideLoading: () => void
      showLoading: () => void
    },
    window: Window,
    instanceID: string, // is used to make sure that upon recurring invocation each function instance only exists once
    tenantID: string,
    $i18n: typeof I18nGlobalsInst,
    $widgetElement?: Element
  ) {
    const events = {
      onPageChanged: (cb: (data: any) => void, invokeWithPastEvents = true) =>
        this.subscribe(this.topicList.PAGE_CHANGE, instanceID, cb, invokeWithPastEvents),
      onFormSent: (cb: (data: any) => void, invokeWithPastEvents = true) =>
        this.subscribe(this.topicList.FORM_SENT, instanceID, cb, invokeWithPastEvents),
      onLocaleChanged: (cb: (locale: string[]) => void, invokeWithPastEvents = true) =>
        this.subscribe(this.topicList.LOCALE_CHANGE, instanceID, cb, invokeWithPastEvents)
    }

    const loadJs = function (url: string) {
      return new Promise(function (resolve, reject) {
        const scriptTag = document.createElement('script')
        scriptTag.src = url

        scriptTag.onload = resolve
        // scriptTag.onreadystatechange = callback

        document.body.appendChild(scriptTag)
      })
    }

    const loadCss = function (url: string) {
      return new Promise(function (resolve, reject) {
        const link = document.createElement('link')

        link.rel = 'stylesheet'
        link.href = url

        link.onload = resolve

        document.head.appendChild(link)
      })
    }

    // callRPC: (rpcName: string, data: any) => Promise<any>
    const callRPC = async (rpcName: string, data: any): Promise<RPCGenericResponse> => {
      const genericRequest = httpsCallable(firebaseFunctions, 'genericRequestRPC')

      const rpcData: RPCGenericRequest = {
        asidID: ctx.asid,
        tenantID: tenantID,
        functionName: rpcName,
        data: data
      }

      const result = (await genericRequest(rpcData)) as RPCGenericResponse

      return result
    }

    // wrapper around the fetch api
    const proxyFetch = async (url: string, options: RequestInit = {}, proxyID = '') => {
      const cloudFunctionUrl = getProxyUrl(url, proxyID)

      const response = await fetch(cloudFunctionUrl, {
        ...options,
        headers: {
          ...(options.headers || {})
        }
      })

      return response
    }

    // return a url which is processed by the proxy. Used e.g. for image src. E.g. url: https://customdomain.com/image.png => https://cloudfunction.com/appHttpProxy/image.png?echo-proxy-id=customdomain.com&echo-proxy-tenant=tenantID
    const getProxyUrl = (url: string, proxyID = '') => {
      const urlObject = new URL(url)
      const domainAndProtocol = urlObject.protocol + '//' + urlObject.hostname
      const pathAndQuery = urlObject.pathname + urlObject.search

      const proxyUrlParams = {
        'echo-proxy-id': proxyID || domainAndProtocol,
        'echo-proxy-tenant': tenantID
      }

      const cloudFunctionUrl = process.env.VUE_APP_FIREBASE_CLOUD_FUNCTION_BASE_DOMAIN + '/appHttpProxy' + pathAndQuery

      const proxyUrl = new URL(cloudFunctionUrl)
      Object.entries(proxyUrlParams).forEach(([key, value]) => proxyUrl.searchParams.append(key, value))

      return proxyUrl.toString()
    }

    const getLocalizedString = (localizedText: { [key: string]: string }, cb: (text: string) => void) => {
      this.subscribe(
        this.topicList.LOCALE_CHANGE,
        instanceID,
        (locale) => {
          const localizedField: LocalizedField = { _ltType: true, locales: localizedText }
          // not sure if acessing I18nGlobalsInst here is a good idea, as it created a dependency between the two modules
          cb(getLocalString(localizedField, $i18n.appActiveLocales, $i18n.fallbackMode))
        },
        true
      )
    }

    // todo this shall make sure a new instance is created whenever clicking 'execute' while developing the script
    // however this does not work, as the instacnes are still "alive" after removing them
    // => eventlistsners are still being fired. Workaround: change the html so the listeners are not bund to the same objects again?
    if (instanceID in this.instances) {
      this.resetPubSub(instanceID)
      delete this.instances[instanceID]
    }

    this.instances[instanceID] = new Function('ctx', 'events', 'functions', 'window', '$widgetElement', codeString)

    this.instances[instanceID](
      ctx,
      events,
      { ...functions, loadJs, loadCss, callRPC, proxyFetch, getProxyUrl, getLocalizedString },
      window,
      $widgetElement
    )
  }

  public static validate(codeString: string) {
    return new Function('ctx', 'events', 'log', 'window', '$widgetElement', codeString)
  }
}
