
import { Component, Vue, Model, Emit, Watch, Prop } from 'vue-property-decorator'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilter, faTimesCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import VInputMultiModuleSelection from '@/components/VInputMultiModuleSelection.vue'

import { ModuleType } from '@/modules/typeModules'

library.add(faTimesCircle, faFilter, faTimes)

@Component({
  components: {
    VInputMultiModuleSelection
  },
  inheritAttrs: false,
  props: {
    default: {
      type: Boolean,
      required: false,
      default: () => true
    }
  }
})
export default class VFilterModulesDropdownView extends Vue {
  @Model('input', { type: Array, required: true, default: () => [] })
  readonly selectedModuleTypes!: ModuleType[]

  public localSelectedModuleTypes: ModuleType[] = []

  @Prop({ type: String, required: false, default: '' })
  readonly size!: 'is-small' | ''


  @Watch('selectedModuleTypes', { immediate: true })
  public onSelectedModuleTypesChange() {
    this.localSelectedModuleTypes = [...this.selectedModuleTypes] // spread to not pass reference
  }

  @Emit('input')
  public onApplyFilter() {
    this.onCloseFilter()
    return this.localSelectedModuleTypes
  }

  @Emit('input')
  public onResetFilter() {
    this.onCloseFilter()
    return []
  }

  public dropdownIsOpen = false

  public onOpenFilter() {
    if (!this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }

  public onCloseFilter() {
    if (this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }


}
