import { ModuleType } from '@/modules/typeModules'
import { RouteConfig } from 'vue-router'
import BaseModuleApp from '@/modules/baseModuleApp'
import AppGroupLoading from '@/pages/app/App_Group_Loading.vue'

const App_Module_File_View = import(/* webpackChunkName: "App_Module_File_View" */ './App_Module_File_View.vue')

export default class FileModuleApp extends BaseModuleApp {
  public static type: ModuleType = 'File'

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'file/:groupID',
        name: 'file',
        component: () => App_Module_File_View
      }
    ]
  }

  public static registerOverviewVue() {
    return () => ({
      component: App_Module_File_View,
      loading: AppGroupLoading
    })
  }
}
