
import { hasDBid } from '@/types/typeGeneral'
import { ModuleManager } from '../modules/moduleManager'
import { ModuleType, BaseElementDB } from '../modules/typeModules'

export default {
  props: {
    element: {
      type: Object, // Module Element with id and type
      required: true
    },
    getColorForType: {
      type: Function,
      default(type: ModuleType) {
        return ModuleManager.getModuleClassByType(type).color
      }
    },
    getLinkToElement: {
      type: Function,
      default(mod: (BaseElementDB & hasDBid & { type: ModuleType })) {
        const name = ModuleManager.getModuleClassByType(mod.type).routeNameElement
        return { name, params: {id: mod.id} }
        // return 4
      }
    }
  }
}
