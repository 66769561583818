
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

library.add(faQuestionCircle)


@Component
export default class VTooltipHelp extends Vue {
  @Prop({ type: String, required: false, default: 'is-dark' })
  public type!: string

  @Prop({ type: String, required: false })
  public text!: string

  @Prop({ type: String, required: false })
  public textHtml!: string

  @Prop({ type: String, required: false, default: 'is-top' })
  public position!: string

  @Prop({ type: Boolean, required: false, default: true })
  public appendToBody!: boolean
}

// export default {
//   props: {
//     type: {
//       type: String,
//       required: false,
//       default: 'is-dark'
//     },
//     text: {
//       type: String,
//       required: true
//     },
//     position: {
//       type: String,
//       required: false,
//       default: 'is-top'
//     }
//   }
// }
